import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
      gridColumnGap: 10,
      gridRowGap: [12, 14],
    };

    this.moreArticles = {
      variant: 'text.base',
    };

    this.loadMore = {
      mt: 13,
      width: '100%',
    }
  })()
);
