import { Heading, Flex, Grid } from 'theme-ui';
import PropTypes from 'prop-types';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from './Empty.theme';

export const Empty = themed(({ theme: themes, inSidebar, ...props }) => {
  const [{ }, { closeSidebar }] = useSidebar();
  const settings = useSettings();
  const empty = settings?.cart?.empty;

  const theme = inSidebar ? themes.sidebar : themes.page;

  return (
    <Flex
      data-comp={Empty.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme,
      }}
    >
      <Heading sx={theme.text} as="h4">
        {empty?.message}
      </Heading>

      <Grid sx={theme.grid}>
        {empty?.ctas?.map((item, index) => (
          <Link
            key={index}
            href={item.cta.url}
            newTab={item.cta.newTab}
            onClick={() => {
              if (!inSidebar) return;
              closeSidebar();
            }}
            variant="buttons.secondary"
          >
            {item.cta.text}
          </Link>
        ))}
      </Grid>
    </Flex>
  );
});

Empty.displayName = 'CartEmpty';
Empty.propTypes = {
  inSidebar: PropTypes.bool,
};
