import { useState, useEffect } from 'react';
import { Box, Grid } from 'theme-ui';

import { NavList } from './NavList';
import { NavMenu } from './NavMenu';
import { themed } from './MenuDrawer.theme';

export const MenuDrawer = themed(({ theme, hovered, ...props }) => {
  const { secondaryMenu } = { ...hovered };
  const [activeNavMenu, setActiveNavMenu] = useState(null);

  useEffect(() => {
    if (secondaryMenu?.[0]?.tertiaryMenu) {
      setActiveNavMenu(secondaryMenu?.[0]);
    } else {
      setActiveNavMenu(null);
    }
  }, [hovered]);

  return secondaryMenu ? (
    <Box data-comp={MenuDrawer.displayName} {...props} sx={theme.drawer}>
      <Box sx={theme.megaMenu}>
        <Grid sx={theme.grid}>
          <NavList
            links={secondaryMenu}
            activeNavMenu={activeNavMenu}
            setActiveNavMenu={setActiveNavMenu}
          />
          {hovered && (
            <NavMenu
              activeNavMenu={activeNavMenu}
              featuredImage={hovered?.featuredImage}
            />
          )}
        </Grid>
      </Box>
    </Box>
  ) : null;
});

MenuDrawer.displayName = 'MenuDrawer';
