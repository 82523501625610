import { Box } from 'theme-ui';

import { themed } from './Search.theme';

import { Input } from './Input';
import { Suggestions } from './Suggestions';
import { Results } from './Results';
import { SuggestionsHeader } from './SuggestionsHeader';
import { Empty } from './Empty';
import { Recommendations } from './Recommendations';

export const Search = themed(({ theme }) => {
  return (
    <Box data-comp={Search.displayName}>
      <Box sx={theme.stickySearch}>
        <Box sx={theme.inputWrapper}>
          <Input />
        </Box>

        <SuggestionsHeader />
      </Box>

      <Empty />

      {/* If no results — show suggestions */}
      <Suggestions />

      {/* If no results — show recommendations */}
      <Recommendations />

      {/* If results — render results, else nothing found empty */}
      <Results />
    </Box>
  );
});

Search.displayName = 'Search';
