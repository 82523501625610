import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.option = {
      mt: 8,
      width: '100%',
    };

    this.name = {
      variant: 'text.label.1',
      mr: 4,
    };

    this.color = {
      color: 'mediumDarkGray',
    };

    this.values = {
      variant: 'flex.row.start',
      pl: 0,
      flexWrap: 'wrap',
      flexBasis: '100%',
      mr: 'auto',
      gap: 6,
      '@media screen and (max-width: 479px)': {
        gap: 4,
      },
    };

    this.value = {
      p: 4,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '12px',
      height: '64px',
      minWidth: '64px',
      borderRadius: '4px',
      position: 'relative',
      transition: 'all 0.15s ease-in-out',
      ':hover': {
        border: (t) => `1px solid ${t.colors.mediumDarkGray}`,
      },
      '@media screen and (max-width: 479px)': {
        minWidth: '62px',
      },

      available: {
        selected: {
          border: (t) => `1px solid ${t.colors.text}`,
          bg: 'background',
          color: 'text',
        },
        unselected: {
          border: (t) => `1px solid ${t.colors.gray}`,
          bg: 'background',
          color: 'text',
        },
      },
    };

    this.seeAll = {
      height: '64px',
      minWidth: '64px',
      borderRadius: '4px',
      p: 4,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      border: (t) => `1px solid ${t.colors.gray}`,
      bg: 'background',
      color: 'text',
      transition: 'all 0.15s ease-in-out',
      ':hover': {
        border: (t) => `1px solid ${t.colors.mediumDarkGray}`,
      },

      icon: {
        width: '20px',
        color: 'text',
      },

      text: {
        fontSize: '10px',
        lineHeight: '12px',
      },
    };
  })()
);
