import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      flexDirection: 'column',
      mb: [8, null, null, 14],
      borderBottom: '1px solid',
      borderColor: 'mediumGray',
    };

    this.heading = {
      variant: 'text.h2',
      flex: 1,
      mb: [10, 11],
    };

    this.tabs = {
      width: ['100%', 'auto'],
      height: '100%',
      overflowY: 'hidden',
      overflowX: 'auto',
      webkitOverflowScrolling: 'touch',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      mb: '-1px',
    };

    this.list = {
      display: 'flex',
    };

    this.listItem = {
      minWidth: 'auto',
      fontWeight: 500,
      'is:last-child': {
        mr: 0,
      },
    };

    this.button = {
      variant: 'buttons.groupText',
      fontSize: 5,
      fontWeight: 500,
    };
  })()
);
