import { ArticleSchemaMarkup } from './ArticleSchemaMarkup';
import { BlogSchemaMarkup } from './BlogSchemaMarkup';
import { CollectionHead } from './CollectionHead';
import { MainSchemaMarkup } from './MainSchemaMarkup';
import { ProductHead } from './ProductHead';

export function SeoPageSchema({
  page,
  product,
  collection,
  blog,
  article,
  ...props
}) {
  const baseUrl = process.env.SITE_URL;
  let resourceType = page?.resourceType;
  let pageUrl = '';

  if (page?.resourceType === 'home_page') {
    pageUrl = baseUrl;
  } else if (page?.resourceType === 'page') {
    pageUrl = `${baseUrl}/pages/${page.handle}`;
  } else if (page?.resourceType === 'product_page') {
    pageUrl = `${baseUrl}/products/${page.handle}`;
    resourceType = 'product_page';
  } else if (page?.resourceType === 'collection_page') {
    pageUrl = `${baseUrl}/collections/${page.handle}`;
    resourceType = 'collection_page';
  } else if (article) {
    pageUrl = `${baseUrl}/articles/${article.handle}`;
    resourceType = 'article';
  } else if (blog) {
    pageUrl = `${baseUrl}/blogs/${blog.handle}`;
    resourceType = 'blog';
  } else {
    pageUrl = baseUrl;
  }

  return (
    <>
      <MainSchemaMarkup pageUrl={pageUrl} />

      {resourceType === 'article' && (
        <ArticleSchemaMarkup article={article} pageUrl={pageUrl} />
      )}

      {resourceType === 'blog' && (
        <BlogSchemaMarkup blog={blog} pageUrl={pageUrl} />
      )}

      {resourceType === 'product_page' && (
        <ProductHead product={product} pageUrl={pageUrl} />
      )}

      {resourceType === 'collection_page' && (
        <CollectionHead collection={collection} pageUrl={pageUrl} />
      )}
    </>
  );
}
