/* eslint-disable*/
import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      minHeight: '200px'
    }

    this.header = {
      variant: 'text.reviewsHeading',
      mt: [15, 80],
    };

    this.slider = {
      mt: [11, 12],
    };
  })()
);
