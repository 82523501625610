import { section, color } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Four Sixty',
    key: 'four-sixty',
    fields: [
      {
        label: 'Gallery ID',
        name: 'galleryId',
        component: 'text',
        defaultValue: 'kryo-gear_1',
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.white,
          color: color.value.white,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 's',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
};
