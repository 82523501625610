import { useEffect } from 'react';
import { Box, Flex, Heading, Button } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';
import store, { useSetRecoilState } from '@store';

import { useSearch } from '../hooks';
import { themed } from './Suggestions.theme';

export const Suggestions = themed(({ theme, ...props }) => {
  const settings = useSettings();
  const suggestedTerms = settings?.search?.suggested;
  const suggestedHeading =
    settings?.search?.suggestedHeading || 'Top search terms';
  const setRawTerm = useSetRecoilState(store.rawTerm);
  const [{ totalResults }, { getSearchTrending }] = useSearch();

  useEffect(() => {
    getSearchTrending();
  }, []);

  return (
    <Flex
      data-comp={Suggestions.displayName}
      {...props}
      sx={totalResults ? theme.hidden : theme.visible}
    >
      {!!suggestedTerms?.length && (
        <Box>
          <Heading as="h5" sx={theme.heading}>
            {suggestedHeading}
          </Heading>

          <Box as="ul" sx={{ pl: 0 }}>
            {suggestedTerms.map(({ label }, index) => (
              <Flex key={label + index} as="li" sx={{ mb: 2 }}>
                <Button
                  variant="buttons.plain"
                  onClick={() => setRawTerm(label)}
                >
                  {label}
                </Button>
              </Flex>
            ))}
          </Box>
        </Box>
      )}
    </Flex>
  );
});

Suggestions.displayName = 'Suggestions';
