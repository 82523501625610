import { section } from '@settings/common';

export function Schema({ page }) {
  if (!page) return null;

  return {
    label: 'Locator',
    key: 'locator',
    fields: [
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'container',
            mt: 'none',
          },
          mobile: {
            container: 'container',
            mt: 'none',
          },
        },
      },
    ],
  };
}
