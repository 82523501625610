import {
  useMemo,
  Children,
  cloneElement,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

const defaultProps = {
  initial: null,
  variant: 'default',
  children: null,
  onSelected: () => {},
  onSelecting: () => {},
};

export const useDropdown = (passedProps) => {
  const option = { ...defaultProps, ...passedProps };

  const dropdownRef = useRef(null);
  const [selected, setSelected] = useState(option.initial);
  const [selecting, setSelecting] = useState(false);
  const [mounted, setMounted] = useState(false);

  // toggle dropdown open/close
  const toggle = useCallback(() => {
    setSelecting((selecting) => !selecting);
  }, []);

  // filter and sort valid Dropdown (Selected or Option ) children components
  const Component = useMemo(() => {
    let Selected = null;
    let Options = [];

    let index = 0;

    // map children to supported sub components
    Children.forEach(option.children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'DropdownSelected': {
          Selected = cloneElement(child, {
            key: `selected.option`,
            label: option.label,
            selecting,
            selected,
            toggle,
            variant: option.variant,
            ...child.props,
          });
          break;
        }

        case 'DropdownOption': {
          Options = [
            ...Options,
            cloneElement(child, {
              key: `option.${selected}.${index}`,
              selecting,
              selected,
              setSelected,
              toggle,
              variant: option.variant,
              ...child.props,
            }),
          ];
          break;
        }

        default:
          // not a valid child component
          break;
      }
      index += 1;
    });

    return { Selected, Options };
  }, [selecting, selected, Children.count(option.children)]);

  const closeOnClickOutside = useCallback((event) => {
    if (!dropdownRef.current?.contains(event.target)) {
      return setSelecting(false);
    }
  }, []);

  const setOutsideClickHandler = () => {
    document.addEventListener('mouseup', closeOnClickOutside);
    return () => document.removeEventListener('mouseup', closeOnClickOutside);
  };

  // run passed callback when selection changes
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }
    if (typeof option.onSelected === 'function') option.onSelected(selected);
  }, [selected]);

  // run passed callback when selecting
  useEffect(() => {
    selecting &&
      typeof option.onSelecting === 'function' &&
      option.onSelecting();
  }, [selecting]);

  useEffect(() => {
    setOutsideClickHandler();
  }, []);

  return [
    {
      dropdownRef,
      Component,
      selecting,
    },
    {
      toggle,
    },
  ];
};
