export function Schema({ page }) {
  if (!page) return null;

  return {
    label: 'Super Collection',
    key: 'super-collection',
    fields: [
      {
        label: 'Super Collection Title',
        name: 'title',
        component: 'text',
        defaultValue: 'All Can Holders',
      },
      {
        label: 'Product Tiles',
        name: 'tiles',
        component: 'group',
        fields: [
          {
            name: 'products',
            label: 'Products',
            description:
              'Primary product handle and See All link for each tile',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}' || 'New Product',
            },
            defaultItem: {
              product: {
                handle: 'hopsulator-slim-onyx-leopard-12oz-slim-cans',
              },
              link: { url: '/collections/hopsulator-slim' },
            },
            fields: [
              {
                label: 'See All Link',
                name: 'link',
                component: 'link',
              },
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
              {
                name: 'description',
                component: 'textarea',
                label: 'Product Description',
              },
            ],
            defaultValue: [
              {
                product: {
                  handle: 'hopsulator-slim-onyx-leopard-12oz-slim-cans',
                },
                link: { url: '/collections/hopsulator-slim' },
              },
              {
                product: {
                  handle:
                    'hopsulator-duo-2-in-1-onyx-leopard-12oz-cans-tumbler',
                },
                link: { url: '/collections/hopsulator-duo' },
              },
              {
                product: {
                  handle: 'hopsulator-trio-3-in-1-onyx-leopard-16oz-12oz-cans',
                },
                link: { url: '/collections/hopsulator-trio' },
              },
              {
                product: {
                  handle: 'hopsulator-bottl-onyx-leopard-12oz-bottles',
                },
                link: { url: '/collections/hopsulator-bott-l' },
              },
            ],
          },
        ],
      },
      {
        label: 'Collection Categories',
        name: 'menu',
        component: 'group',
        description: 'Preset list of collection or page links',
        fields: [
          {
            label: 'Name',
            name: 'name',
            component: 'text',
            defaultValue: 'Size',
          },
          {
            name: 'collapsed',
            label: 'Dropdown Collapsed (Desktop)',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Categories',
            name: 'categories',
            component: 'group-list',
            itemProps: {
              label: '{{item.link.text}}',
            },
            defaultItem: {
              link: {
                text: 'Slim (12oz Slim Cans)',
                url: '/collections/hopsulator-slim',
              },
              mobileImage: {
                product: {
                  handle: 'hopsulator-slim-onyx-leopard-12oz-slim-cans',
                },
              },
            },
            fields: [
              {
                label: 'Collection Link',
                name: 'link',
                component: 'link',
              },
              {
                label: 'Image (mobile)',
                name: 'mobileImage',
                description:
                  'Use default image of specific product or manual upload of image',
                component: 'group',
                fields: [
                  {
                    name: 'image',
                    label: 'Manual Image',
                    component: 'image',
                    description:
                      'If provided, this image will override the default image of the product',
                  },
                  {
                    name: 'product',
                    component: 'productSearch',
                    label: 'Default Image of Specific Product',
                  },
                ],
                defaultValue: {},
              },
            ],
            defaultValue: [
              {
                link: {
                  text: 'All Can Holders',
                  url: '/collections/can-holders',
                },
                mobileImage: {
                  product: {
                    handle: 'hopsulator-slim-gold-leopard-12oz-slim-cans',
                  },
                },
              },
              {
                link: {
                  text: 'Slim (12oz Slim Cans)',
                  url: '/collections/hopsulator-slim',
                },
                mobileImage: {
                  product: {
                    handle: 'hopsulator-slim-onyx-leopard-12oz-slim-cans',
                  },
                },
              },
              {
                link: {
                  text: 'Trio (12/16oz Cans)',
                  url: '/collections/hopsulator-trio',
                },
                mobileImage: {
                  product: {
                    handle: 'hopsulator-trio-v2-0-by-brumate-matte-navy',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        label: 'SEO Footer',
        name: 'seo',
        component: 'group',
        fields: [
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
        ],
        defaultValue: {
          body: `**THE INSULATED BEER TUMBLER YOU'VE BEEN LOOKING FOR**\n\nBrüMate's range of beer and seltzer can coolers are here to take your drinking game to the next level. No matter what you're drinking, we have an insulated tumbler for it. Are you a fan of White Claw or Truly? Try our Hopsulator Slim. Think beer just tastes better from a bottle? We've made the Bott'l just for you. Don't like drinking from a can? Check out our 20oz Imperial Pint. Big beer drinker? We have the Juggernaut to keep your tall boys covered.\n\n**A BEER TUMBLER TO TAKE WITH YOU ANYWHERE**\n\nWith BrüMate beer tumblers and can coolers, the days of worrying about glass at the pool are over! You can safely bring your beer on the pool deck, out on your back patio, out stargazing, to the beach. A Hopsulator or Imperial Pint definitely solves everyone's least favorite party problem: lukewarm beers and seltzer. Not to mention, if you snag our state of the art Growl'r, you can bring your tasty homebrew or local brewery favorite to your next gathering.\n\n**INNOVATIVE, UNIQUE DRINKWARE AND GOOD TIMES**\n\nAt BrüMate, we are committed to designing the products you need to keep your beers and seltzer ice-cold from the first sip to the last. To put it plainly: Life is better with a cold beer in hand. So go ahead, and have fun. Grab some friends, kick back, and relax, knowing we've got your back, and your beers will stay ice cold.`,
        },
      },
    ],
  };
}
