import { useRouter } from 'next/router';
import { Box, Paragraph } from 'theme-ui';

import { Link, Picture } from '@snippets';

import { useBreakpointValue } from '@hooks';

import { themed } from './Tile.theme';

export const Tile = themed(({ theme, item, ...props }) => {
  const { alt, image, hoverImage, cta, borderColor } = item;

  const router = useRouter();
  const isActive = router.asPath?.split('?')[0] === cta?.url;

  const disableHoverMobile = useBreakpointValue([true, false]);

  return (
    <Box data-comp={Tile.displayName} {...props}>
      <Link
        href={cta?.url}
        newTab={cta?.newTab}
        sx={theme.imageLink({ isActive, borderColor })}
      >
        <Picture
          alt={alt}
          images={[
            {
              src: image?.src,
              ratio: 1.33,
            },
          ]}
          hoverImages={[
            {
              src: hoverImage?.src,
              ratio: 1.33,
            },
          ]}
          disableHoverMobile={disableHoverMobile}
          imageBgColor="lightGray"
          hoverImageBgColor="lightGray"
          sx={{ borderRadius: '4px' }}
        />

        {cta?.text && (
          <Paragraph variant="text.h5" sx={theme.heading}>
            {cta?.text}
          </Paragraph>
        )}
      </Link>
    </Box>
  );
});

Tile.displayName = 'Tile';
