import { section, position } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Image',
    key: 'image',
    fields: [
      {
        name: 'alt',
        component: 'text',
        label: 'Image Alt',
        description:
          'Provide a brief description of the image for accessibility compliance',
        defaultValue:
          'General description of what the image is without using  "an image/picture of"',
      },
      {
        label: 'Mobile Settings',
        name: 'mobile',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image position',
            options: position.object,
          },
          {
            name: 'ratio',
            label: 'Aspect ratio',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: '0.75',
                label: '3:4',
              },
              {
                value: '1',
                label: '1:1',
              },
              {
                value: '1.25',
                label: '5:4',
              },
              {
                value: '1.5',
                label: '3:2',
              },
            ],
          },
          {
            name: 'minHeight',
            component: 'number',
            label: 'Min pixel height (optional)',
          },
          {
            name: 'maxHeight',
            component: 'number',
            label: 'Max pixel height (optional)',
          },
        ],
        defaultValue: {
          src: {
            src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=90',
          },
          position: 'center center',
          ratio: '1',
          minHeight: '',
          maxHeight: '',
        },
      },
      {
        label: 'Desktop Settings',
        name: 'desktop',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image position',
            options: position.object,
          },
          {
            name: 'ratio',
            label: 'Aspect ratio',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: '1',
                label: '1:1',
              },
              {
                value: '1.25',
                label: '5:4',
              },
              {
                value: '1.5',
                label: '3:2',
              },
              {
                value: '1.75',
                label: '7:4',
              },
              {
                value: '2',
                label: '2:1',
              },
            ],
          },
          {
            name: 'minHeight',
            component: 'number',
            label: 'Min pixel height (optional)',
          },
          {
            name: 'maxHeight',
            component: 'number',
            label: 'Max pixel height (optional)',
          },
        ],
        defaultValue: {
          src: {
            src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=75',
          },
          position: 'center center',
          ratio: '1.75',
          minHeight: '',
          maxHeight: '',
        },
      },
      section,
    ],
  };
};
