import { Flex, Button, Paragraph, Box } from 'theme-ui';

import { Svg } from '@snippets';

import { useCollectionFilters } from '../hooks';
import { themed } from './Filters.theme';

export const CurrentFilters = themed(({ theme, ...props }) => {
  const [
    { filterSummary, totalResults },
    { removeFromSelectedFilters, clearSelectedFilters },
  ] = useCollectionFilters();

  return (
    <Box
      data-comp={CurrentFilters.displayName}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      <Paragraph sx={{ fontSize: '15px', display: ['none', 'block'], my: 4 }}>{totalResults} Products</Paragraph>
      <Flex sx={{ marginBottom: '18px'}}>
        <Flex sx={{
          ...theme.filtersRow,
          flex: 1,
          }}
        >
          {filterSummary?.map((filter) => {
            return (
              <Button
                key={filter.field + filter.value}
                variant="buttons.plain"
                aria-label={`Remove ${filter.filterLabel} ${filter.filterValue} from filters`}
                onClick={() => {
                  const { value } = filter;
                  removeFromSelectedFilters({
                    field: filter.field,
                    option:
                      typeof value === 'object'
                        ? {
                            type: 'range',
                            low: value.rangeLow,
                            high: value.rangeHigh,
                          }
                        : { type: 'value', value },
                  });
                }}
                sx={theme.filter}
              >
                <Paragraph sx={theme.filterValue}>{filter.filterValue}</Paragraph>
                <Svg
                  alt="Close"
                  src="/svgs/close.svg#close"
                  viewBox="0 0 48 48"
                  data-comp="Close"
                  sx={theme.close}
                />
              </Button>
            );
          })}
        </Flex>
        <Paragraph sx={{ fontSize: '11px', display: ['block', 'none'], width: 'auto', lineHeight: '32px' }}>{totalResults} Products</Paragraph>
      </Flex>

      {filterSummary?.length > 0 && (
        <Button
          variant="buttons.link.1"
          onClick={clearSelectedFilters}
          aria-label="Clear all filters"
          sx={{
            ...theme.clearAll,
            display: filterSummary?.length > 0 ? ['none', 'block'] : 'none',
          }}
        >
          Clear All
        </Button>
      )}
    </Box>
  );
});

CurrentFilters.displayName = 'CurrentFilters';
