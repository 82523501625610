import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Text } from 'theme-ui';

import { themed } from './Dropdown.theme';

export const DropdownSelected = themed(
  ({
    children,
    label,
    selected,
    selecting,
    theme,
    toggle,
    variant,
    inputRef,
    ...props
  }) => {
    const toggleDropdown = useCallback((e) => {
      e.preventDefault();
      e.stopPropagation();
      toggle();
    }, []);

    return (
      <Label
        data-comp={DropdownSelected.displayName}
        sx={theme[variant]?.label}
      >
        <Text sx={theme[variant]?.label.text}>{label}</Text>
        <Button
          variant="buttons.plain"
          role="button"
          aria-haspopup="listbox"
          aria-live="assertive"
          aria-expanded={selecting}
          onClick={toggleDropdown}
          {...props}
          sx={{
            ...theme[variant]?.selected,
            ...props.sx,
          }}
        >
          {children({ selecting, selected })}
        </Button>
      </Label>
    );
  }
);

DropdownSelected.displayName = 'DropdownSelected';

DropdownSelected.propTypes = {
  children: PropTypes.func,
  selected: PropTypes.any,
  selecting: PropTypes.bool,
  toggle: PropTypes.func,
};
