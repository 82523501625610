import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.link = {
      mr: 7,
      transition: 'opacity 0.2s ease-in-out',
      ':hover': {
        opacity: 0.8,
      },
    };

    this.icon = {
      color: 'currentColor',
    };
  })()
);
