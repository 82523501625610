import { useState } from 'react';
import { Container, Box } from 'theme-ui';

import { withInView, pxWithFullBleed } from '@utils';
import { Section } from '@snippets';

import { Schema } from './TabbedThreeTile.schema';
import { themed } from './TabbedThreeTile.theme';
import { Header } from './Header';
import { Slider } from './Slider';

export const TabbedThreeTile = withInView(
  themed(({ theme, cms }) => {
    const { tabs, section, settings } = cms;
    const [activeIndex, setActiveIndex] = useState(0);
    const [swiper, setSwiper] = useState();

    return (
      <Section data-comp={TabbedThreeTile.displayName} section={section}>
        <Container
          data-comp={`${TabbedThreeTile.displayName} container`}
          sx={theme.wrapper}
        >
          <Box sx={{ ...theme.header, ...pxWithFullBleed(section) }}>
            <Header
              tabs={tabs}
              settings={settings}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              swiper={swiper}
            />
          </Box>

          {tabs?.length > 0 ? (
            <Slider
              tabs={tabs}
              settings={settings}
              activeIndex={activeIndex}
              setSwiper={setSwiper}
            />
          ) : null}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

TabbedThreeTile.displayName = 'TabbedThreeTile';
TabbedThreeTile.Schema = Schema;
