import { useMemo, useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import store, { useRecoilState } from '@store';
import { useCartAddItem } from '@backpackjs/storefront';
import { useSidebar } from '@hooks';

export function VideoWiseScript({
  template,
  product,
  collection,
  page,
  article,
  blog,
}) {
  const { asPath } = useRouter();
  const [videoWiseConfig, setVideoWiseConfig] = useRecoilState(store.videoWiseConfig);
  const { cartAddItem } = useCartAddItem();
  const [, { openSidebar }] = useSidebar();

  const st = useMemo(() => {
    if (template === 'collection_page' && collection) {
      return {
        rid: collection?.legacyResourceId || null,
        p: 'collection',
        rtyp: 'collection',
      };
    }
    if (template === 'product_page' && product) {
      return {
        rid: product?.legacyResourceId || null,
        p: 'product',
        rtyp: 'product',
      };
    }
    if (template === 'page' && page) {
      return {
        rid: null,
        p: 'page',
        rtyp: 'page',
        pageurl: `${process.env.SITE_URL}/pages/${page?.handle}`,
      };
    }
    if (template === 'article' && article) {
      return {
        rid: null,
        p: 'article',
        rtyp: 'article',
        pageurl: `${process.env.SITE_URL}/articles/${article?.handle}`,
      };
    }
    if (template === 'blog' && blog?.handle) {
      return {
        rid: null,
        p: 'blog',
        rtyp: 'blog',
        pageurl: `${process.env.SITE_URL}/blogs/${blog?.handle}`,
      };
    }
    return {
      rid: null,
      p: 'home',
      pageurl: `https://${process.env.SITE_URL}`,
    };
  }, [template, product?.id, collection, page, article, asPath]);

  const handleVideowiseProductAddToCart = useCallback(async (event) => {
    if (!event?.detail?.productId || !event?.detail?.variantId) {
      return false;
    }
    const { variantId } = event.detail;
    if (!variantId) return false;
    const item = {
      merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
      quantity: 1,
    };

    // adds shipscout attribute to collect data for abtest
    if (typeof window.ShipScoutLineItemProperty !== 'undefined') {
      item.attributes = [
        { key: '_sc', value: window.ShipScoutLineItemProperty },
      ];
    }
    if (typeof window.ShipScoutTrackAddToCart === 'function') {
      window.ShipScoutTrackAddToCart();
    }
    const cart = await cartAddItem(item);
    const addedItem = cart?.lines?.slice(-1);
    if (
      addedItem?.length &&
      addedItem?.[0].variant?.id === `gid://shopify/ProductVariant/${variantId}`
    ) {
      openSidebar('cart');
    }

    return true;
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.SKIP_CART = true;
      window.FORCE_DOMAIN = true;
      window.VIDEOWISE_FAST_INLINE_VIDEO_PLAYER = true;
      window.Shopify = {
        shop: 'kryo-gear.myshopify.com',
        currency: { active: 'USD', rate: '1.0' },
      };
      window.__st = st;
      //setLoaded(true);
      setVideoWiseConfig(true);
      window.addEventListener(
        'videowiseProductAddToCart',
        handleVideowiseProductAddToCart
      );
    }
    return () => {
      //setLoaded(false);
      setVideoWiseConfig(false);
      window.removeEventListener(
        'videowiseProductAddToCart',
        handleVideowiseProductAddToCart
      );
    };
  }, []);

  return null;
}

VideoWiseScript.displayName = 'VideoWiseScript';
