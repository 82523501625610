import { section, color } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Form',
    key: 'form',
    fields: [
      {
        label: 'Form ID',
        name: 'formId',
        component: 'text',
        defaultValue: 'R64QCXVXVeSvVeROi5opSg',
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.white,
          color: color.value.white,
          desktop: {
            container: 'contentNarrow',
            mt: 'none',
            py: 'xl',
          },
          mobile: {
            container: 'contentNarrow',
            mt: 'none',
            py: 'xl',
          },
        },
      },
    ],
  };
};
