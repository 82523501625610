import { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';
import { useBlog, useRouter } from '@backpackjs/storefront';

import { Tile } from './Tile';
import { Pagination } from './Pagination';
import { Schema } from './BlogGrid.schema';
import { themed } from './BlogGrid.theme';

export const BlogGrid = withInView(
  themed(({ theme, cms }) => {
    const { tile, section } = cms;
    const blog = useBlog();
    const articles = blog?.articles?.filter((article) => !!article.excerpt);

    const resultsPerPage = 9;
    const [currentResultsPage, setCurrentResultsPage] = useState(1);

    const router = useRouter();
    const urlCategory = router?.query?.category || null;
    const page = parseInt(router?.query?.page || '1', 10);
    const routePath = router.asPath?.split('?')[0];

    useEffect(() => {
      if (!articles) return;
      setCurrentResultsPage(page || 1);
    }, [page]);

    const categoryArticles = useMemo(() => {
      if (!articles?.length) return [];

      const articlesMap = articles.reduce((map, article) => {
        if (map[article.id]) return map;
        map[article.id] = article;
        return map;
      }, {});

      const articlesObj = Object.values(articlesMap).reverse();

      const sortedByDate = articlesObj.sort((a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);

        return bDate.getTime() - aDate.getTime();
      });

      const articlesCache = {};
      const uniqueArticles = sortedByDate.filter((article) => {
        if (articlesCache[article.handle]) return false;
        articlesCache[article.handle] = true;
        return true;
      });

      return urlCategory
        ? uniqueArticles.filter(
            ({ category }) =>
              category.toLowerCase() === urlCategory.toLowerCase()
          )
        : uniqueArticles;
    }, [articles, urlCategory]);

    const totalPages = Math.ceil(categoryArticles.length / resultsPerPage);
    const previousPage = currentResultsPage > 1 ? currentResultsPage - 1 : null;
    const nextPage =
      currentResultsPage < totalPages ? currentResultsPage + 1 : null;

    const goToPrevPage = useCallback(() => {
      if (!previousPage) return;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      router.push({
        pathname: routePath,
        query: { category: urlCategory, page: previousPage },
      });
    }, [previousPage]);

    const goToNextPage = useCallback(() => {
      if (!nextPage) return;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      router.push({
        pathname: routePath,
        query: { category: urlCategory, page: nextPage },
      });
    }, [nextPage]);

    const goToPage = useCallback(
      (num) => {
        if (!num || num > totalPages) return;
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        router.push({
          pathname: routePath,
          query: {
            category: urlCategory,
            page: num,
          },
        });
      },
      [totalPages]
    );

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(8);

    useEffect(() => {
      if (!articles) return;
      setStartIndex(currentResultsPage * resultsPerPage - resultsPerPage);
      setEndIndex(currentResultsPage * resultsPerPage);
    }, [currentResultsPage, resultsPerPage]);

    return articles ? (
      <Section section={section}>
        <Grid data-comp={BlogGrid.displayName} sx={theme.grid}>
          {categoryArticles?.length
            ? categoryArticles
                .slice(startIndex, endIndex)
                .map((article, index) => {
                  if (!tile?.toggleVisibility && index === 0) return null;
                  return (
                    <Tile
                      key={article?.id}
                      article={article}
                      tileSettings={tile}
                    />
                  );
                })
            : `No articles to display for ${router.query.category}.`}
        </Grid>

        <Pagination
          resultsPerPage={resultsPerPage}
          currentPage={currentResultsPage}
          totalPages={totalPages || 1}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToPage={goToPage}
        />
      </Section>
    ) : null;
  }),
  { renderOnce: true }
);

BlogGrid.displayName = 'BlogGrid';
BlogGrid.Schema = Schema;
