import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.swiper = {
      '.swiper-container': {
        cursor: ['grab', 'auto'],
      },
      '.swiper-container, .swiper-wrapper': {
        height: 'auto !important',
        width: '100%',
      },
      '.swiper-slide': {
        height: 'auto !important',
        width: '100%',
        borderRadius: '4px',
      },
    };
  })()
);
