import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slider = {
      '.swiper-container': {
        height: '100%',
        width: '100%',
      },
      height: '100%',
      width: '100%',
    };

    this.bullets = {
      backgroundColor: 'none',
      color: 'text',
      display: 'flex',
      height: 10,
      justifyContent: 'center',
      pb: 11,
      span: {
        backgroundColor: 'text',
        height: 10,
        width: 10,
      },
      width: '75px',
    };

    this.contentSlide = {
      position: 'relative',
    };
  })()
);
