import account from './account';
import analytics from './analytics';
import blog from './blog.js';
import cart from './cart.js';
import collection from './collection.js';
import footer from './footer.js';
import gatedPages from './gatedPages';
import giftWithPurchase from './giftWithPurchase';
import header from './header';
import markdownmodal from './markdownmodal';
import product from './product';
import search from './search.js';
import social from './social.js';

export default [
  account,
  analytics,
  blog,
  cart,
  collection,
  footer,
  gatedPages,
  giftWithPurchase,
  header,
  markdownmodal,
  product,
  search,
  social,
];
