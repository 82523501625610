import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.title = {
      textAlign: 'center',
      mt: 2,
      textUnderlineOffset: '2px',
      textDecorationThickness: '1px',
    };

    this.list = {
      display: ['none', 'flex'],
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 3,
      mb: 10,
    };

    this.heading = {
      variant: 'text.h3',
      mb: '28px',
      overflowWrap: 'break-word',
    };

    this.accordionHeader = {
      heading: {
        variant: 'text.label.1',
        display: 'inline-block',
        mr: 4,
      },

      selected: {
        flex: 1,
        color: 'mediumDarkGray',
        variant: 'text.base',
        fontSize: [3, 4],
        whiteSpace: 'nowrap',
        textTransform: 'none',
        letterSpacing: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    };

    this.item = (isActive) => ({
      link: {
        display: 'flex',
      },

      picture: {
        width: '40px',
        mr: 5,
      },

      label: {
        pt: 5,
        flex: 1,
        textDecoration: 'none',
        textUnderlineOffset: '2px',
        textDecorationThickness: '1px',
        color: 'text',
        ':hover': {
          textDecoration: 'underline',
        },
      },
    });

    this.sticky = {
      top: ['var(--header-height)'],
      position: 'sticky',
      bg: 'white',
      zIndex: 2,
      gridArea: 'sidebar',
    };
  })()
);
