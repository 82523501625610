import { Container, Box } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { themed } from './ShopByCategory.theme';
import { Schema } from './ShopByCategory.schema';
import { Header } from './Header';
import { Slider } from './Slider';

export const ShopByCategory = withInView(
  themed(({ theme, cms }) => {
    const { content, section, tiles, media } = cms;

    return (
      <Section section={section} sx={theme.wrapper}>
        <Container data-comp={ShopByCategory.displayName}>
          <Box sx={theme.header}>
            <Header content={content} />
          </Box>
          {tiles?.length > 0 && <Slider tiles={tiles} settings={media} />}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ShopByCategory.displayName = 'Shop By Category';
ShopByCategory.Schema = Schema;
