import { section, color, align } from '@settings/common';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Review Slider',
    key: 'review-slider',
    fields: [
      {
        label: 'Text Settings',
        name: 'text',
        description: 'Heading, alignment',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'markdown',
            defaultValue: '## 135,000+ 5-Star Reviews',
          },
          {
            name: 'textAlign',
            label: 'Text align',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
            defaultValue: align.value.center,
          },
        ],
      },
      {
        label: 'Review Settings',
        name: 'reviewSettings',
        description: 'Count, order',
        component: 'group',
        fields: [
          {
            name: 'count',
            label: 'Review count',
            component: 'number',
            description:
              'Multiples of three will provide a smoother transition on desktop displays',
            defaultValue: 6,
          },
          {
            name: 'orderBy',
            label: 'Order by',
            component: 'select',
            description: 'Order in which the reviews will be displayed',
            options: [
              {
                label: 'Newest (Default)',
                value: 'date desc',
              },
              {
                label: 'Oldest',
                value: 'date asc',
              },
              {
                label: 'Highest Rating',
                value: 'rating desc',
              },
              {
                label: 'Lowest Rating',
                value: 'rating asc',
              },
            ],
            defaultValue: 'date desc',
          },
        ],
      },
      {
        name: 'sliderSettings',
        label: 'Slider Settings',
        component: 'group',
        description: 'Loop',
        fields: [
          {
            name: 'loop',
            label: 'Loop',
            component: 'toggle',
            description:
              'Loop back to the first set of reviews seamlessly when the last review is reached',
            toggleLabels: {
              true: 'Enable (default)',
              false: 'Disable',
            },
          },
        ],
        defaultValue: {
          loop: true,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.dark,
          color: color.value.white,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'xl',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'l',
          },
        },
      },
    ],
  };
}
