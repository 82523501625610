export const Schema = ({ product }) => {
  if (!product) return;

  // we create a cms select with the collections this product belongs to
  const productCollectionsOptions = product?.collections?.length
    ? product?.collections.map((collection) => {
        const collectionTitle = collection.handle.replace(/-/g, ' ');
        const collectionTitleCapitalized =
          collectionTitle.charAt(0).toUpperCase() + collectionTitle.slice(1);
        return {
          label: collectionTitleCapitalized,
          value: collection.handle,
        };
      })
    : [];

  return {
    label: 'Back to Collection',
    description:
      'Demonstrate how we can use product data inside our cms editor section',
    key: 'functionalSchemaDemo',
    fields: [
      // field with "dynamic" product data
      {
        name: 'backToCollection',
        label: 'Back to collection',
        component: 'select',
        options: productCollectionsOptions,
        defaultValue: productCollectionsOptions?.length
          ? productCollectionsOptions[0].value
          : '',
      },
      // conditional field for "Premium Fixed Gear Bike" product types only
      product?.productType === 'Premium Fixed Gear Bike'
        ? {
            name: 'tiresOnly',
            label: 'Tire brand',
            component: 'text',
            defaultValue: 'Good Year',
          }
        : null,
    ].filter(Boolean),
  };
};
