// <Container> variants

export const layout = {
  container: {
    position: 'relative', // full-bleed
  },
  padded: {
    position: 'relative',
    px: [8, 10, 11, 80],
  },
  content: {
    position: 'relative',
    px: [8, 10, 11, 12],
    maxWidth: '84em', // 1344px => 1344px - 64px = 1280px
    mx: 'auto',
  },
  contentNarrow: {
    position: 'relative',
    px: [8, 10, 11, 12],
    maxWidth: '61em', // 976px
    mx: 'auto',
  },
  contentWide: {
    position: 'relative',
    px: [8, 10, 11, 12],
    maxWidth: '90em', // 1440px
    mx: 'auto',
  },
  header: {
    position: 'relative',
    px: [8, 10, 11, 12],
  },
};
