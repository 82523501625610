import { Box, Spinner, Flex } from 'theme-ui';
import { useCartEmpty } from '@backpackjs/storefront';

import { Search, ItemsOrEmpty, ColorsSidebar } from '@snippets';
import { useSidebar, useCartStatus } from '@hooks';

import { themed } from './Content.theme';

export const Content = themed(({ theme, ...props }) => {
  const cartEmpty = useCartEmpty();
  const [{ isCart, isSearch, isColors }] = useSidebar();
  const [{ addingToCartStatus }] = useCartStatus();
  const isAddingToCart =
    addingToCartStatus.started && !addingToCartStatus.finished;

  // Cart ItemsOrEmpty or Search — side drawers
  return (
    <>
      <Box
        data-comp={Content.displayName}
        {...props}
        sx={{
          ...theme.wrapper,
          height: isCart && cartEmpty ? '100%' : 'auto',
        }}
      >
        {isCart ? <ItemsOrEmpty /> : null}

        {isSearch ? <Search /> : null}

        {isColors ? <ColorsSidebar /> : null}
      </Box>

      {isAddingToCart && (
        <Flex
          variant="flex.row.center.center"
          sx={{
            variant: 'box.fill',
            bg: 'rgba(255,255,255,0.5)',
          }}
        >
          <Spinner width="24px" color="mediumDarkGray" />
        </Flex>
      )}
    </>
  );
});

Content.displayName = 'CartSearchSidebarContent';
