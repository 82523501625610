import { Container, Flex } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link } from '@snippets';

import { Tile } from '../Tile';
import { themed } from './Slider.theme';

export const Slider = themed(
  ({ theme, tabs, settings, activeIndex, setSwiper }) => {
    const cta = tabs?.[activeIndex]?.cta;
    const ctaStyle = settings?.ctaStyle;

    return (
      <Container
        data-comp={Slider.displayName}
        sx={{
          ...theme.swiper,
          '.swiper-slide': {
            ...(settings?.imageRatio === 'square'
              ? theme.square
              : theme.portrait),
          },
        }}
      >
        <Swiper onSwiper={setSwiper} {...defaultParams}>
          {tabs.slice(0, 5).map((tab, index) => {
            if (index !== activeIndex || tab.length === 0) return null;
            return tab?.items.slice(0, 3).map((item, index) => {
              return (
                <SwiperSlide key={tab?.cta.url + index}>
                  <Tile
                    key={index + tab?.cta.url}
                    item={item}
                    settings={settings}
                  />
                </SwiperSlide>
              );
            });
          })}
        </Swiper>
        <Flex sx={theme.flex}>
          {cta?.text && (
            <Link
              variant={`buttons.${ctaStyle}`}
              href={cta?.url}
              newTab={cta?.newTab}
              sx={theme.button}
            >
              {cta?.text}
            </Link>
          )}
        </Flex>
      </Container>
    );
  }
);

Slider.displayName = 'Slider';

const defaultParams = {
  spaceBetween: 16,
  slidesPerView: 1.3,
  slidesOffsetBefore: 16,
  slidesOffsetAfter: 16,
  slidesPerGroup: 1,
  centeredSlides: false,
  centeredSlidesBounds: true,
  centerInsufficientSlides: true,
  autoHeight: true,
  navigation: false,
  loop: false,
  watchOverflow: true,
  grabCursor: true,
  breakpoints: {
    480: {
      spaceBetween: 16,
      slidesPerView: 1.3,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
    },
    600: {
      spaceBetween: 16,
      slidesPerView: 2.4,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
    },
    768: {
      spaceBetween: 20,
      slidesPerView: 3,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1024: {
      spaceBetween: 20,
      slidesPerView: 3,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
    1280: {
      spaceBetween: 20,
      slidesPerView: 3,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: false,
      centerInsufficientSlides: true,
    },
  },
};
