import { useMemo } from 'react';
import { Flex, Heading, Paragraph, Spinner, Box } from 'theme-ui';
import { useProductByHandle } from '@backpackjs/storefront';

import { Picture, Locale, Link } from '@snippets';
import { UpsellAddToCart } from '@snippets/Cart/Upsell/SliderItem/UpsellAddToCart';
import { useSidebar } from '@hooks';

import { themed } from './RecommendationItem.theme';

export const RecommendationItem = themed(({ theme, handle, isLast }) => {
  const { product } = useProductByHandle({ handle });
  const [, { closeSidebar }] = useSidebar();

  const title = useMemo(() => {
    const fullTitle = product?.title;
    const splitIndex = fullTitle?.indexOf('|') || -1;
    return {
      product:
        splitIndex >= 0 ? fullTitle?.slice(0, splitIndex).trim() : fullTitle,
      variant: splitIndex >= 0 ? fullTitle?.slice(splitIndex + 1).trim() : '',
    };
  }, [product?.title]);

  const firstImage = useMemo(() => {
    return product?.media?.find((item) => {
      return item.mediaContentType === 'IMAGE';
    })?.image;
  }, [product?.id]);

  return (
    <Flex data-comp={RecommendationItem.displayName} sx={theme.wrapper(isLast)}>
      <Link href={`/products/${handle}`} onClick={closeSidebar}>
        <Picture
          alt={product?.title}
          data-comp={`${RecommendationItem.displayName}Picture`}
          images={[
            {
              src: firstImage?.originalSrc,
              width: 350,
              ratio: 1,
            },
          ]}
          sx={theme.picture}
        >
          {!firstImage && <Spinner sx={theme.picture.spinner} />}
        </Picture>
      </Link>

      <Flex sx={theme.details}>
        <Link href={`/products/${handle}`} onClick={closeSidebar}>
          <Heading
            data-comp={`${RecommendationItem.displayName}Heading`}
            as="h6"
            sx={theme.title}
          >
            {title?.product}
          </Heading>
        </Link>

        <Paragraph sx={theme.variant}>{title?.variant}</Paragraph>

        <Flex
          variant="flex.row.between"
          sx={{
            mt: 10,
            width: '100%',
          }}
        >
          {product && (
            <UpsellAddToCart
              product={product}
              selectedVariant={product?.variants?.[0]}
              style={theme.atc}
            />
          )}

          {product?.variants?.[0] && (
            <Locale.Variant variant={product.variants[0]} sx={theme.price}>
              <Locale.Price>
                {({ priceV2, compareAtPriceV2, isOnSale }) => {
                  return isOnSale ? (
                    <Flex>
                      <Paragraph sx={theme.newPrice}>
                        {priceV2.locale}
                      </Paragraph>
                      <Paragraph sx={theme.compareAtPrice}>
                        {compareAtPriceV2.locale}
                      </Paragraph>
                    </Flex>
                  ) : (
                    <Paragraph>{priceV2.locale}</Paragraph>
                  );
                }}
              </Locale.Price>
            </Locale.Variant>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
});

RecommendationItem.displayName = 'SearchRecommendationItem';
