export const formatAmount = ({ amount = 0, currency = 'USD' }) => {
  if (amount !== 0 && !amount) return null;
  const total = typeof amount === 'string' ? amount : amount.toFixed(2);
  let locale;
  if (currency === 'EUR') {
    locale = `${total.replaceAll('.', ',')} €`;
  } else if (currency === 'GBP') {
    locale = `£${total}`;
  } else {
    locale = `$${total}`;
  }
  return locale;
};

export const formatAmountItem = (item, qty, discountAmount = 0) => {
  if (!item) return null;

  const total = Math.round((item.amount - discountAmount) * 100) / 100;

  const currency = item.currencyCode;

  return qty
    ? {
        ...item,
        amount: total / qty,
        localeTotal: formatAmount({ amount: total, currency }),
        locale: formatAmount({
          amount: total / qty,
          currency,
        }),
      }
    : {
        ...item,
        amount: total,
        locale: formatAmount({ amount: total, currency }),
      };
};
