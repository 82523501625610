import { color } from '@settings/common';

const page = () => ({
  label: 'Page Settings',
  name: 'page',
  description: 'Creates Pages to add products, toggle products as actionable,',
  component: 'group',
  fields: [
    {
      component: 'group-list',
      name: 'pages',
      label: 'Pages',
      itemProps: {
        label: '{{item.pageUrl}}' || 'Page Url',
      },
      fields: [
        {
          name: 'enableFullProductAccess',
          label: 'Enable Products As Actionable',
          description: 'Enables products to be accessible and purchaseable',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Optional: Page Url',
          name: 'pageUrl',
          component: 'text',
          description:
            'This is required if you need to override products in collection grids Ex: /collections/vintage-florals or /pages/vintage-florals. ',
        },
        {
          component: 'group-list',
          name: 'products',
          label: 'Products',
          description:
            'These products will display in collection grids and will also be hidden from search results',
          itemProps: {
            label: '{{item.product.handle}}' || 'New Product',
          },
          fields: [
            {
              component: 'productSearch',
              name: 'product',
              label: 'Product',
            },
          ],
        },
      ],
    },
  ],
});

const image = () => ({
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      description:
        'Provide a brief description of the image for accessibility compliance',
      label: 'Alt',
    },
    {
      name: 'imageDt',
      label: 'Image (desktop)',
      component: 'image',
    },
    {
      name: 'image',
      label: 'Image (mobile)',
      component: 'image',
    },
    {
      name: 'position',
      component: 'select',
      label: 'Image position',
      options: [
        {
          value: 'left-aligned',
          label: 'Left Aligned',
        },
        {
          value: 'full',
          label: 'Full',
        },
      ],
    },
  ],
  defaultValue: {
    alt: 'Password Protected Image',
    imageDt: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Era-Teaser-FullSize_D.jpg?v=1685638677',
    },
    image: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Era-Teaser-FullSize_M.jpg?v=1685638680',
    },
    position: 'full',
  },
});

const text = ({ color }) => ({
  label: 'Text Settings',
  name: 'text',
  description: 'Superheading, heading, text color',
  component: 'group',
  fields: [
    {
      name: 'superheading',
      label: 'Superheading',
      component: 'text',
    },
    {
      name: 'superheadingColor',
      label: 'Superheading Text color',
      component: 'select',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'heading',
      label: 'Heading',
      component: 'markdown',
      description:
        'Tip: You can ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1"',
    },
    {
      name: 'headingColor',
      label: 'Heading Text color',
      component: 'select',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'headingOption',
      label: 'Font Family Heading Option',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Reckless Neue', value: 'text.h1' },
        { label: 'Fellix', value: 'text.h1.2' },
      ],
      defaultValue: 'text.h1.2',
    },
    {
      name: 'copy',
      label: 'Copy',
      component: 'markdown',
    },
    {
      name: 'copyColor',
      label: 'Copy Text color',
      component: 'select',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    superheading: '07.24.23',
    heading: 'A New Era',
    copy: 'Unlock Your Exclusive Access To See Era',
    headingOption: 'text.h1.2',
    superheadingColor: '#FFFFFF',
    headingColor: '#FFFFFF',
    copyColor: '#FFFFFF',
  },
});

const closeControl = () => ({
  label: 'Close Control Settings',
  name: 'closeControl',
  description: 'Icon color, Redirect Url',
  component: 'group',
  fields: [
    {
      label: 'Redirect URL Path',
      name: 'redirectUrlPath',
      description:
        'Add a url path to redirect users to when closing password protected modal. Example: /collections/brutank',
      component: 'text',
    },
    {
      name: 'iconColor',
      label: 'Icon Color',
      component: 'select',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    redirectUrlPath: '/',
    iconColor: '#FFFFFF',
  },
});

const password = ({ color }) => ({
  label: 'Password Settings',
  name: 'password',
  description: 'Icon color, Redirect Url',
  component: 'group',
  fields: [
    {
      label: 'Password',
      name: 'password',
      component: 'text',
    },
    {
      name: 'passwordColor',
      label: 'Password Field and Text Color',
      component: 'select',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      label: 'Error Message',
      name: 'errorMessage',
      component: 'text',
    },
    {
      name: 'errorMessageColor',
      label: 'Error Message Color',
      component: 'select',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    password: 'password123',
    passwordColor: '#E1FF01',
    errorMessage: 'Incorrect Password. Please try again',
    errorMessageColor: '#AE2807',
  },
});

export default {
  label: 'Gated Pages Settings',
  name: 'gatedPages',
  component: 'group',
  fields: [
    {
      component: 'group-list',
      name: 'gatedPages',
      label: 'Gated Pages',
      itemProps: {
        label: '{{item.text.heading}}' || 'New Gated Page',
      },
      fields: [
        {
          name: 'enable',
          label: 'Enable',
          component: 'toggle',
          description:
            'Disables or Activates Gated Page. Disabling will ignore all settings below',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          name: 'show',
          label: 'Hide/Show',
          component: 'toggle',
          description: 'Hides or Shows Gated Page',
          toggleLabels: {
            true: 'Show',
            false: 'Hide',
          },
          defaultValue: true,
        },
        {
          name: 'backgroundColor',
          label: 'Background Color',
          component: 'select',
          options: [{ label: 'Default', value: '' }, ...color.options],
          defaultValue: '#010101',
        },
        closeControl({ color }),
        image(),
        page(),
        password({ color }),
        text({ color }),
      ],
    },
  ],
};
