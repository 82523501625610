import { Box, Heading, Button } from 'theme-ui';

import { Svg } from '@snippets';
import { useFiltersSidebar } from '@hooks';
import { themed } from './Filter.theme';

export const Filter = themed(
  ({ theme }) => {
    const [, { toggleFiltersSidebar }] = useFiltersSidebar();

    return (
      <Box data-comp={Filter.displayName} sx={theme.wrapper}>
          <Button
            variant="buttons.plain"
            onClick={() =>
              toggleFiltersSidebar({
                label: 'Filter',
                isFiltersAndSort: true,
              })
            }
            sx={theme.button}
          >
            <Heading as="h5" sx={theme.title}>
              Filter
            </Heading>

            <Svg
              alt="filter"
              viewBox="0 0 16 16"
              src="/svgs/noprecache/svg/filters.svg"
              sx={{
                ...theme.icon,
              }}
            />
          </Button>
      </Box>
    );
  }
);

Filter.displayName = 'Filter';
