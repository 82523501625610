import { section, align } from '@settings/common';

export function Schema() {
  return {
    category: 'Embed',
    label: 'VideoWise',
    key: 'videoWise',
    fields: [
      {
        label: 'Embed Content',
        name: 'embedContent',
        component: 'html',
      },
      section,
    ],
  };
}
