import { useMemo } from 'react';
import { Container } from 'theme-ui';

import { Section } from '@snippets';
import { calcResponsiveHeight, withInView } from '@utils';

import { Content } from './Content';
import { Image } from './Image';
import { Schema } from './CollectionHero.schema';
import { themed } from './CollectionHero.theme';

export const CollectionHero = withInView(
  themed(({ cms }) => {
    const { section, image, text, content, showBreadCrumb = false } = cms;

    const height = useMemo(() => {
      return [
        section?.mobile?.maxHeight
          ? calcResponsiveHeight({
              maxHeight: section?.mobile?.maxHeight,
              viewport: 768,
            })
          : 'auto',
        section?.desktop?.maxHeight
          ? calcResponsiveHeight({
              maxHeight: section?.desktop?.maxHeight,
              viewport: 1280,
            })
          : 'auto',
      ];
    }, [section?.mobile?.maxHeight, section?.desktop?.maxHeight]);

    return (
      <Section section={section}>
        {/* Regular slider for hero media and contained content */}
        <Container
          data-comp={CollectionHero.displayName}
          sx={{
            height,
            minHeight: [
              `${section?.mobile?.minHeight}px`,
              `${section?.desktop?.minHeight}px`,
            ],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
          }}
        >
          <Image image={image} />

          <Content
            text={text}
            content={content}
            showBreadCrumb={showBreadCrumb}
          />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

CollectionHero.displayName = 'CollectionHero';
CollectionHero.Schema = Schema;
