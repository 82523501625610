import { Container, Box, Flex } from 'theme-ui';

import { Svg, Link } from '@snippets';
import { usePasswordProtected } from '@hooks';

import { themed } from './PasswordProtectedModal.theme';
import { PasswordProtectedForm } from './PasswordProtectedForm';
import { PasswordProtectedContent } from './PasswordProtectedContent';
import { PasswordProtectedImage } from './PasswordProtectedImage';

export const PasswordProtectedModal = themed(({ theme }) => {
  const { gatedPage, setShowErrorMessage, showErrorMessage, submitPassword } =
    usePasswordProtected();
  const { backgroundColor, closeControl, image, password, text } = {
    ...gatedPage,
  };
  const { iconColor, redirectUrlPath } = { ...closeControl };

  return (
    <Container
      data-comp={PasswordProtectedModal.displayName}
      sx={theme.wrapper(backgroundColor)}
    >
      <Box
        data-comp={`${PasswordProtectedModal.displayName}Close`}
        sx={theme.close}
      >
        <Link href={redirectUrlPath}>
          <Svg
            alt="Close modal"
            src="/svgs/close.svg#close"
            viewBox="0 0 48 48"
            sx={theme.icon(iconColor)}
          />
        </Link>
      </Box>
      <Flex variant="flex.row.center" sx={theme.main}>
        <PasswordProtectedImage image={image} />

        <Box
          data-comp={`${PasswordProtectedModal.displayName}Content`}
          sx={theme.content(image?.position)}
        >
          <PasswordProtectedContent text={text} />

          <PasswordProtectedForm
            password={password}
            setShowErrorMessage={setShowErrorMessage}
            showErrorMessage={showErrorMessage}
            submitPassword={submitPassword}
          />
        </Box>
      </Flex>
    </Container>
  );
});

PasswordProtectedModal.displayName = 'PasswordProtectedModal';
