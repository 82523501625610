import { useMemo } from 'react';

export const useByobLineItem = ({ discountCodeApplied, item, updatedAt }) => {
  const { handle } = item.variant.product;

  const isByob = useMemo(() => {
    return (
      item.attributes.some((attr) => attr.key === 'recipe_id') &&
      handle !== 'byob-charge'
    );
  }, [item.id, handle]);

  const byobMessage = useMemo(() => {
    if (handle !== 'byob-charge') return null;

    const totalDiscounts = item.discountAllocations?.reduce(
      (carry, discountAllocation) => {
        const discount = discountAllocation?.discountedAmount?.amount;
        const subtotal = item.estimatedCost?.subtotalAmount?.amount;

        return (
          carry +
          Math.floor((parseFloat(discount) / parseFloat(subtotal)) * 100)
        );
      },
      0
    );

    return totalDiscounts
      ? `${totalDiscounts}% off ${discountCodeApplied ? '' : 'bulk'} discount`
      : null;
  }, [item.id, handle, updatedAt]);

  return { isByob, byobMessage };
};
