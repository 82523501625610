import Script from 'next/script';

export function ArticleSchemaMarkup({ article = {}, pageUrl }) {
  return (
    <Script
      id="schema-markup-article"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          '@id': pageUrl,
          mainEntityOfPage: pageUrl,
          headline: article.seo?.title || '',
          name: article.seo?.title || '',
          description: article.seo?.description || '',
          datePublished: article?.publishedAt || '',
          dateModified: article?.createdAt || '',
          author: [
            {
              '@type': 'Person',
              name: article?.author || '',
            },
          ],
          publisher: {
            '@type': 'Organization',
            '@id': process.env.SITE_URL,
            name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            logo: {
              '@type': 'ImageObject',
              '@id': process.env.NEXT_PUBLIC_SITE_LOGO || '',
              url: process.env.NEXT_PUBLIC_SITE_LOGO || '',
            },
          },
          image: {
            '@type': 'ImageObject',
            '@id': article.seo?.image || '',
            url: article.seo?.image || '',
          },
          url: pageUrl,
          isPartOf: {
            '@type': 'Blog',
            '@id': `${process.env.SITE_URL}/blogs/${article.blog?.handle}`,
            name: article.blog?.title,
            publisher: {
              '@type': 'Organization',
              '@id': process.env.SITE_URL,
              name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            },
          },
        }),
      }}
    />
  );
}

ArticleSchemaMarkup.displayName = 'ArticleSchemaMarkup';
