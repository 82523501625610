import { Flex } from 'theme-ui';

import { themed } from './Sort.theme';
import { SortDropdown } from './SortDropdown';

export const Sort = themed(({ theme }) => {
  return (
    <Flex
      data-comp={Sort.displayName}
      variant="flex.row.end"
      sx={theme.wrapper}
    >
      <SortDropdown />
    </Flex>
  );
});

Sort.displayName = 'Sort';
