import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.row.start.center',
      width: '100%',
      minHeight: '106px',
      px: 14,
    };

    this.picture = {
      width: '72px',
      mr: 8,

      spinner: {
        width: '16px',
        color: 'mediumDarkGray',
        m: 'auto',
      },
    };

    this.details = {
      variant: 'flex.column.start',
    };

    this.title = {
      variant: 'text.h6',
      fontWeight: 500,
      minHeight: '18px',
    };

    this.variant = {
      variant: 'text.sm',
      color: 'mediumDarkGray',
      minHeight: '18px',
    };

    this.priceWrapper = {
      minHeight: '18px',
      mb: 3,
    };

    this.price = {
      p: {
        variant: 'text.sm',
        fontSize: 1,
      },
    };

    this.newPrice = {
      color: 'red',
      mr: 4,
    };

    this.compareAtPrice = {
      textDecoration: 'line-through',
      color: 'mediumDarkGray',
    };

    this.atc = {
      height: '15px',
    };
  })()
);
