import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      position: 'relative',
      display: 'grid',
      gridGap: [0, 0, 3],
    };

    this.media = {
      gridArea: 'media',
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: 460,
      overflow: 'hidden',
    };

    this.content = {
      gridArea: 'content',
      display: 'flex',
      position: 'relative',
      width: '100%',
      height: '100%',
    };
  })()
);
