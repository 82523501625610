import { Container } from 'theme-ui';
import Head from 'next/head';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { themed } from './AccessibilityStatement.theme';
import { Schema } from './AccessibilityStatement.schema';

export const AccessibilityStatement = withInView(
  themed(({ theme, cms }) => {
    return (
      <Section section={cms.section}>
        <Container
          data-comp={AccessibilityStatement.displayName}
          sx={theme.wrapper}
        >
          <Head>
            <script
              src="https://a40.usablenet.com/pt/c/statement/brumate/accessibilitystatement.js?
l=1"
            />
          </Head>
          <div id="a40AccessibilityStatement" />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

AccessibilityStatement.displayName = 'AccessibilityStatement';
AccessibilityStatement.Schema = Schema;
