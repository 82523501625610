import { useEffect } from 'react';
import Script from 'next/script';

export function FueledGTMScript({ withKey: gtmContainerId }) {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    const pageViewListener = (event) => {
      window.dataLayer.push({
        event: 'dl_route_update',
        page: event.detail.page,
        event_id: event.detail.event_id,
        event_time: event.detail.event_time,
        ecommerce: event.detail.ecommerce,
        user_properties: event.detail.user_properties,
      });
    };

    window.addEventListener('dl_route_update', pageViewListener);
    return () => {
      window.removeEventListener('dl_route_update', pageViewListener);
    };
  }, []);

  return (
    <Script
      type="text/javascript"
      id="gtm-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${gtmContainerId}');
        `,
      }}
    />
  );
}
