import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Button, Heading, Paragraph, Container } from 'theme-ui';
import { Svg } from '@snippets';
import { themed } from './GiftWithPurchase.theme';
import { GiftWithPurchaseItem } from './GiftwithPurchaseItem';

export const GiftWithPurchaseSlider = themed(
  ({
    theme,
    cartHasGwp,
    gwpProducts,
    handleAddToCart,
    isLoading,
    modalText,
    openSidebar,
  }) => {
    const {
      heading = 'You qualified for a free gift with purchase!',
      body = "Code 'TREAT' will be applied at checkout",
      ctaLabel = 'Add to Bag',
      ctaSuccessLabel = 'Product Added',
      cancelLabel = 'No Thanks',
    } = { ...modalText };

    return (
      <Container
        data-comp={GiftWithPurchaseSlider.displayName}
        sx={theme.wrapper(gwpProducts?.length)}
      >
        <Box sx={theme.details(gwpProducts?.length)}>
          <Heading variant="text.h2" sx={theme.heading}>
            {heading}
          </Heading>
          <Paragraph variant="text.h5">{body}</Paragraph>
          <Box>
            <Swiper
              slidesPerView={1}
              spaceBetween={24}
              grabCursor
              loop={gwpProducts?.length >= 2}
              modules={[Pagination, Navigation]}
              pagination={{
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                disabledClass: 'cursor-not-allowed opacity-20',
              }}
              breakpoints={{
                768: {
                  enabled: false,
                  slidesPerView:
                    gwpProducts?.length < 3 ? gwpProducts.length : 3,
                  spaceBetween: 24,
                },
                1024: {
                  enabled: false,
                  slidesPerView:
                    gwpProducts?.length < 3 ? gwpProducts.length : 3,
                  spaceBetween: 48,
                },
              }}
              sx={{
                my: [11, 12],
                px: '24px',
                maxWidth: [
                  '100%',
                  gwpProducts?.length >= 2 ? '600px' : '300px',
                ],
              }}
            >
              {gwpProducts.map((product, index) => {
                const { gwpSettings } = product;
                return (
                  <SwiperSlide key={index}>
                    <GiftWithPurchaseItem
                      product={product}
                      gwpSettings={gwpSettings}
                      isLoading={isLoading}
                      cartHasGwp={cartHasGwp}
                      ctaLabel={ctaLabel}
                      ctaSuccessLabel={ctaSuccessLabel}
                      handleAddToCart={handleAddToCart}
                    />
                  </SwiperSlide>
                );
              })}

              {gwpProducts?.length > 1 && (
                <>
                  <div className="swiper-pagination" sx={theme.bullets} />
                  <div
                    className="swiper-button-prev"
                    sx={{ ...theme.arrowBtn, left: 0 }}
                  >
                    <Svg
                      viewBox="0 0 24 24"
                      alt="Arrow Left"
                      src="/svgs/arrow-left.svg#arrow-left"
                    />
                  </div>
                  <div
                    className="swiper-button-next"
                    sx={{ ...theme.arrowBtn, right: 0 }}
                  >
                    <Svg
                      viewBox="0 0 24 24"
                      alt="Arrow Right"
                      src="/svgs/arrow-right.svg#arrow-right"
                    />
                  </div>
                </>
              )}
            </Swiper>
          </Box>
          {cartHasGwp ? null : (
            <Button
              variant="buttons.link.1"
              sx={theme.cancel}
              onClick={() => {
                openSidebar('cart');
              }}
            >
              {cancelLabel}
            </Button>
          )}
        </Box>
      </Container>
    );
  }
);

GiftWithPurchaseSlider.displayName = 'GiftWithPurchaseSlider';
