import { section, color } from '@settings/common';

export function Schema({ page }) {
  if (page?.handle !== 'accessibility-statement-site') return null;

  return {
    label: 'Accessibility Statement',
    key: 'accessibility-statement',
    fields: [
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
}
