import { store } from '@backpackjs/storefront';

export const addingToCartStatus = store.recoil.atom({
  key: 'cart/addingToCartStatus',
  default: {
    started: false,
    finished: false,
    success: false,
    errors: [],
  },
});

export const updatingCartStatus = store.recoil.atom({
  key: 'cart/updatingCartStatus',
  default: {
    started: false,
    finished: false,
    success: false,
    errors: [],
  },
});

const upsellProductsNotInCart = store.recoil.atom({
  key: 'cart/upsellProductsNotInCart',
  default: [],
});

const byobTier = store.recoil.atom({
  key: 'cart/byobTier',
  default: null,
});

const cartIsMaxed = store.recoil.atom({
  key: 'cart/cartIsMaxed',
  default: false,
});

const cartDiscountAmount = store.recoil.atom({
  key: 'cart/discountAmount',
  default: 0,
});

export default {
  addingToCartStatus,
  updatingCartStatus,
  upsellProductsNotInCart,
  byobTier,
  cartIsMaxed,
  cartDiscountAmount,
};
