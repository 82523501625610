import { Box, Spinner, Container } from 'theme-ui';
import { useEffect, useRef } from 'react';

import { isBrowser } from '@utils';

import { SiteReviewsHead } from './SiteReviewsHead';

import { themed } from './SiteReviews.theme';

export const SiteReviews = themed(({ theme }) => {
  const reviewsRef = useRef(null);
  const initCoreWidgets = isBrowser && window.okendoInitApi?.initCoreWidgets;
  const okendoReady = typeof initCoreWidgets === 'function';

  useEffect(() => {
    if (!okendoReady || !reviewsRef.current) return;

    initCoreWidgets();
  }, [okendoReady]);

  return (
    <>
      <SiteReviewsHead />
      <Container data-comp={SiteReviews.displayName} sx={theme.container}>
        {/* Widget */}
        <Box sx={theme.widget}>
          {!okendoReady && <Spinner sx={theme.spinner} />}
          <div
            ref={reviewsRef}
            data-oke-reviews-widget-holder
            sx={theme.holder}
          >
            <div data-oke-reviews-widget />
          </div>
        </Box>
      </Container>
    </>
  );
});

SiteReviews.displayName = 'SiteReviews';
