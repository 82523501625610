import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.dropdown = {};

    this.item = {
      variant: 'flex.row.start.center',
      width: '100%',
      cursor: 'pointer',
      color: 'text',
      height: 'inherit',
      pl: 10,
      pr: 8,
      '&:hover': {
        bg: 'whitesmoke',
      },

      icon: {
        width: 16,
      },

      selectedName: {
        variant: 'text.base',
        pt: 6,
      },

      optionName: {
        variant: 'text.base',
      },

      toggle: {
        width: 16,
        ml: 'auto',
      },
    };
  })()
);
