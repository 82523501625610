// eslint-disable-next-line jsx-a11y/control-has-associated-label
import Script from 'next/script';
import { Container } from 'theme-ui';

export function StoreLocator() {
  return (
    <Container>
      <div
        id="lcly-button-0"
        data-switchlive="true"
        data-switchlive-impression="true"
        data-switchlive-impression-id-pl="1"
      >
        <a
          id="lcly-link-0"
          data-switchlive="true"
          data-switchlive-mode="auto"
          data-switchlive-id-pl="6"
          href="https://join.locally.com/for-brands"
          target="_blank"
          rel="noreferrer"
        />
      </div>
      <Script
        strategy="afterInteractive"
        type="text/javascript"
        id="lcly-script-0"
        async
      />
      <Script
        strategy="afterInteractive"
        type="text/javascript"
        id="lcly-config-0"
        dangerouslySetInnerHTML={{
          __html: `
          var lcly_config_0 = {
            "company_name": "Br\u00fcMate",
            "company_id": "156418",
            "inline": "1",
            "lang": "en-us",
            "no_link": "1"
          };
          var lcly_query_0 = Object.keys(lcly_config_0)
          .reduce(function(a,k){a.push(encodeURIComponent(k) + '='
          + encodeURIComponent(lcly_config_0[k]));return a},[]).join('&');
          var lcly_endpoint_0 = 'https://brumate.locally.com/stores/map.js?' + lcly_query_0;
          document.getElementById('lcly-script-0').src = lcly_endpoint_0;`,
        }}
      />
    </Container>
  );
}
