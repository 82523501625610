import { useState } from 'react';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './PressSlider.schema';
import { themed } from './PressSlider.theme';

import { Slider } from './Slider';

export const PressSlider = withInView(
  themed(({ theme, cms, inView }) => {
    if (cms?.entries.length === 0) return null;

    const { entries, settings, section } = cms;
    const [activeIndex, setActiveIndex] = useState(0);

    return (
      <Section section={section}>
        {inView && (
          <Slider
            entries={entries}
            settings={settings}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            sx={theme.wrapper}
          />
        )}
      </Section>
    );
  }),
  { triggerOnce: true }
);

PressSlider.displayName = 'PressSlider';
PressSlider.Schema = Schema;
