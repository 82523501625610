import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      minWidth: '300px',
      width: ['calc(100% - 32px)', '1000px'],
      '@media only screen and (max-width: 479px)': {
        minWidth: '250px',
      },
      textAlign: 'center',
      pt: 10,
    };
    this.bullets = {
      mt: 10,
      mb: 8,
      display: ['flex'],
      justifyContent: 'center',
      gap: 9,
      width: '100%',
      height: '8px',
      '.swiper-pagination-bullet': {
        cursor: 'pointer',
        bg: 'black',
        opacity: 0.2,
      },
      '.swiper-pagination-bullet-active': {
        opacity: '1 !important',
      },
      position: 'static',
    };
    this.item = {
      variant: 'flex.row.start.center',
      width: '100%',
      cursor: 'pointer',
      color: 'text',
      height: 'inherit',
      pl: 10,
      pr: 8,
      '&:hover': {
        bg: 'whitesmoke',
      },

      icon: {
        width: 16,
      },

      selectedName: {
        variant: 'text.base',
        pt: 6,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },

      optionName: {
        variant: 'text.base',
      },

      toggle: {
        width: 16,
        ml: 'auto',
      },
    };

    this.price = {
      lineThrough: {
        variant: 'text.base',
        textDecoration: 'line-through',
        ml: 4,
        color: 'mediumDarkGray',
      },

      new: {
        variant: 'text.base',
        color: 'red',
      },

      regular: {
        variant: 'text.base',
      },
    };
  })()
);
