import { useRef, useState } from 'react';
import { Box, Flex } from 'theme-ui';

import { Svg } from '@snippets';

const defaultTransSx = {
  transition: 'all 0.3s ease-in-out',
  transform: 'translate(-50%, -50%) scale(1.1)',
  opacity: 0,
};

export function Video({ video, media }) {
  const ref = useRef();
  const videoRef = useRef(null);
  const [paused, setPaused] = useState(false);
  const [transitionSx, setTransitionSx] = useState(defaultTransSx);

  const showHideButton = () => {
    setTransitionSx({
      opacity: 0.5,
      transform: 'translate(-50%, -50%) scale(1.01)',
      transition: 'none',
    });
    setTimeout(() => {
      setTransitionSx(defaultTransSx);
    }, 0);
  };

  const handleVideoClick = () => {
    if (paused) {
      videoRef.current.play();
      setPaused(false);
      showHideButton();
    } else {
      videoRef.current.pause();
      setPaused(true);
      showHideButton();
    }
  };

  return (
    <Box
      data-comp={Video.displayName}
      ref={ref}
      sx={{
        display: 'block',
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        as="video"
        ref={videoRef}
        muted
        playsInline
        loop
        autoPlay
        poster={video?.poster}
        sx={{
          position: 'absolute',
          height: '101%',
          width: '101%',
          objectFit: 'contain',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          objectPosition: [media?.position, media?.positionDt],
        }}
        onClick={handleVideoClick}
      >
        <source key={video?.src} src={video?.src} type="video/mp4" />
      </Box>
      <Flex
        variant="flex.row.center.center"
        sx={{
          position: 'absolute',
          width: '100px',
          height: '100px',
          left: '50%',
          top: '50%',
          borderRadius: '50%',
          bg: 'black',
          ...transitionSx,
        }}
      >
        <Svg
          src={
            !paused ? '/svgs/noprecache/svg/play.svg#play-icon' : '/svgs/noprecache/svg/pause.svg#pause-icon'
          }
          alt={!paused ? 'Play Video' : 'Pause Video'}
          viewBox={!paused ? '0 0 29 32' : '0 0 512 512'}
          sx={{
            color: 'white',
            mr: !paused ? '-4px' : 0,
            width: !paused ? '40px' : '50px',
          }}
        />
      </Flex>
    </Box>
  );
}

Video.displayName = 'Video';
