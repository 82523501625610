import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.mainLayout = {
      variant: 'layout.content',
      display: 'grid',
      gridTemplateColumns: ['1fr', '200px 1fr'],
      gridTemplateAreas: [
        '"collection" "footer"',
        '"title collection" "sidebar collection" "sidebar footer"',
      ],
      gridTemplateRows: 'min-content 1fr',
      columnGap: 6,
      rowGap: 0,
      pt: [0, 14, 15],
      pb: [14, 18, '80px'],
      alignItems: 'flex-start',
    };

    this.mobileHeader = {
      display: ['contents', 'none'],
      mb: 12,
    };

    this.mobileHeading = {
      variant: 'text.h2',
      px: 8,
      my: 10,
    };

    this.desktopHeader = {
      gridArea: 'sidebar',
      display: ['none', 'contents'],
    };

    this.desktopHeading = {
      variant: 'text.h2',
      mb: '28px',
      overflowWrap: 'break-word',
      gridArea: 'title',
    };

    this.grid = {
      gridArea: 'collection',
      gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      pt: [11, 0],
      pb: [14, 18, '80px'],
      display: 'grid',
      columnGap: [10, 12],
      rowGap: [14, 12],
    };

    this.noProducts = {
      variant: 'flex.column',

      text: {
        variant: 'text.lg',
        mt: 20,
        textAlign: 'center',
      },
    };

    this.seoText = {
      variant: 'text.base',
      fontSize: [3, 4],
      lineHeight: [20, 22],
    };
  })()
);
