import { useMemo } from 'react';
import { Flex, Paragraph, Spinner, Heading, Box } from 'theme-ui';

import { Locale, Link, OkendoStars } from '@snippets';

import { themed } from './Details.theme';

export const Details = themed(
  ({
    theme,
    fullProduct,
    product,
    isProductRow = false,
    handleClick,
    enableFullProductAccess = true,
  }) => {
    const productName = isProductRow
      ? fullProduct?.title
      : product.name || product.title;

    const colorCountVerbiage = useMemo(() => {
      const colorsLength = fullProduct?.groupOptionsMap?.handle?.length;
      if (!colorsLength) return null;
      return colorsLength > 1
        ? `${colorsLength} colors available`
        : `${colorsLength} color available`;
    }, [fullProduct?.id]);

    const titles = useMemo(() => {
      const title = isProductRow
        ? productName
        : productName?.replaceAll('&amp;', '&');
      const titleArr = title?.split('|');
      return {
        product: titleArr?.[0]?.trim() || '',
        variant: titleArr?.slice(1)?.join('|').trim() || '',
      };
    }, [productName, isProductRow]);

    return (
      <Flex
        data-comp={Details.displayName}
        sx={isProductRow ? theme.productRow : theme.wrapper}
      >
        {/* Title */}
        <Link
          href={
            enableFullProductAccess ? `/products/${fullProduct?.handle}` : null
          }
          tabIndex="-1"
          aria-hidden="true"
          onClick={handleClick}
        >
          <Heading data-comp="ProductItemTitle" as="h5" sx={theme.title}>
            {titles?.product}
          </Heading>
        </Link>

        {titles.variant && (
          <Paragraph sx={theme.variant}>{titles.variant}</Paragraph>
        )}

        {/* Variant Color Count Display */}
        {colorCountVerbiage && (
          <Paragraph
            sx={{
              ...theme.colorCountVerbiage,
              display: ['none', 'block'],
            }}
          >
            {colorCountVerbiage}
          </Paragraph>
        )}

        <Link
          href={
            enableFullProductAccess ? `/products/${fullProduct?.handle}` : null
          }
          tabIndex="-1"
          aria-hidden="true"
          onClick={handleClick}
        >
          <OkendoStars
            legacyResourceId={fullProduct?.legacyResourceId}
            withScroll={false}
            sx={{ minHeight: '22px' }}
            tabIndex="-1"
            aria-hidden="true"
          />
        </Link>

        {/* Variant price */}
        <Box sx={{ minHeight: '22px', mt: 3 }}>
          {fullProduct?.variants?.[0] && (
            <Locale.Variant variant={fullProduct.variants[0]}>
              <Locale.Price>
                {({ priceV2, compareAtPriceV2, isOnSale }) => {
                  return isOnSale ? (
                    <Flex>
                      <Paragraph sx={theme.newPrice}>
                        {priceV2.locale}
                      </Paragraph>
                      <Paragraph sx={theme.compareAtPrice}>
                        {compareAtPriceV2.locale}
                      </Paragraph>
                    </Flex>
                  ) : (
                    <Paragraph sx={theme.price}>{priceV2.locale}</Paragraph>
                  );
                }}
              </Locale.Price>

              <Locale.Loader>
                <Flex sx={theme.loader}>
                  <Spinner />
                </Flex>
              </Locale.Loader>
            </Locale.Variant>
          )}
        </Box>
      </Flex>
    );
  }
);

Details.displayName = 'Details';
