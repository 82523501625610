import { Flex, Heading, Text, Spinner } from 'theme-ui';

import { Link } from '@snippets';

import { SearchPageSuggestions } from './Suggestions';
import { themed } from './SearchPage.theme';

export const SearchPageHeader = themed(
  ({
    correctedQueryOverridden,
    filterSummary,
    isPageAutocomplete,
    loading,
    pageSpellCorrection,
    pageSuggested,
    query,
    theme,
    totalPageResults,
  }) => {
    return query ? (
      <Flex data-comp={SearchPageHeader.displayName} sx={theme.headingWrapper}>
        {!loading ? (
          <>
            {pageSpellCorrection &&
            !isPageAutocomplete &&
            !correctedQueryOverridden ? (
              <>
                <Heading as="h1" sx={theme.heading}>
                  {totalPageResults} {filterSummary?.length ? 'filtered ' : ''}
                  result{totalPageResults === 1 ? '' : 's'} for "
                  {pageSpellCorrection || ''}"
                </Heading>
                <Heading sx={theme.subheading}>
                  Search for{' '}
                  <Link
                    href="/pages/search"
                    query={{ query, spellcorrect: 'false' }}
                    sx={{
                      ...theme.italicized,
                      textDecoration: 'underline',
                      display: 'inline',
                    }}
                  >
                    {query}
                  </Link>{' '}
                  instead
                </Heading>
              </>
            ) : (
              <>
                <Heading as="h1" sx={theme.heading}>
                  {isPageAutocomplete ? '0' : totalPageResults}{' '}
                  {filterSummary?.length && !isPageAutocomplete
                    ? 'filtered '
                    : ''}
                  result
                  {totalPageResults === 1 ? '' : 's'} for "{query || ''}"
                </Heading>
                {isPageAutocomplete && (
                  <Heading sx={theme.subheading}>
                    Showing {totalPageResults}{' '}
                    {filterSummary?.length ? 'filtered ' : ''} result
                    {totalPageResults === 1 ? '' : 's'} for{' '}
                    <Text sx={theme.italicized}>
                      {pageSuggested.text || ''}
                    </Text>{' '}
                    instead
                  </Heading>
                )}
              </>
            )}

            <SearchPageSuggestions />
          </>
        ) : (
          <Spinner
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '36px',
            }}
          />
        )}
      </Flex>
    ) : null;
  }
);

SearchPageHeader.displayName = 'SearchPageHeader';
