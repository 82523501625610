import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.button = (isPdp) =>
      isPdp
        ? {
            cursor: 'pointer',
            width: '42px',
            height: '42px',
            borderRadius: '50%',
            border: (t) => `1px solid ${t.colors.gray}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 0.15s ease-in-out',
            ':hover': {
              borderColor: 'mediumDarkGray',
              svg: {
                color: 'mediumDarkGray',
              },
            },
          }
        : {
            position: 'absolute',
            top: 2,
            right: 1,
            zIndex: 1,
          };

    this.icon = {
      width: '18px',
      color: 'text',
      transition: 'all 0.15s ease-in-out',
      ':hover': {
        color: 'mediumDarkGray',
      },
    };
  })()
);
