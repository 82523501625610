import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.expandableMenu = (open) => ({
      width: '100%',
      textAlign: 'left',
      position: 'relative',
      gridGap: 0,
      cursor: 'pointer',
      borderTop: (t) => `1px solid ${open ? t.colors.black : 'transparent'}`,
      borderBottom: (t) => `1px solid ${open ? 'transparent' : t.colors.gray}`,
    });

    this.expandableMenuHeading = {
      fontSize: 3,
      fontWeight: 500,
      p: 8,
    };

    this.expandableMenuArrowIcon = (open) => ({
      position: 'absolute',
      right: '23px',
      top: 'calc(50% -  8px)',
      width: '16px',
      height: '16px',
      transition: '0.3s ease-in-out transform',
      transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
      color: (t) => t.colors.black,
    });

    this.epandableMenuHeadingLink = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '15px',
      fontWeight: 500,
      height: '38px',
      width: '100%',
      borderRadius: '6px',
      backgroundColor: (t) => t.colors.mediumLightGray,
      color: (t) => t.colors.black,
    };

    this.expandableMenuLink = {
      images: {
        minHeight: '32px',
        display: 'inline-flex',
        alignItems: 'center',
        px: 8,
        '&:last-child': {
          mb: 8,
        },
      },
      cards: {
        textAlign: 'center',
      },
    };

    this.expandableMenuLinkImage = {
      images: {
        width: '48px',
        height: '48px',
        mr: 6,
      },
      cards: {
        width: '148px',
        height: '148px',
        mx: 'auto',
        mb: 4,
        borderRadius: '100%',
        backgroundColor: (t) => t.colors.mediumLightGray,
      },
    };
  })()
);
