import { Container } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './FeaturedThreeTile.schema';
import { Slider } from './Slider';

export const FeaturedThreeTile = withInView(
  ({ cms }) => {
    const { section, tiles } = cms;

    return (
      <Section section={section}>
        <Container data-comp={FeaturedThreeTile.displayName}>
          {tiles?.length > 0 && <Slider tiles={tiles} />}
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

FeaturedThreeTile.displayName = 'FeaturedThreeTile';
FeaturedThreeTile.Schema = Schema;
