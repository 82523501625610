import { section } from '@settings/common';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Full Page Reviews',
    key: 'full-page-reviews',
    fields: [
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
}
