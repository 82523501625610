import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      height: '30px',
      px: [8, 10, 11, 80],
      alignItems: 'center',
      flexWrap: ['nowrap', 'wrap'],
    };

    this.itemTitle = {
      textAlign: 'left',
      textDecoration: 'unset',
      mr: 4,
      fontSize: ['13px', '15px'],
      '@media screen and (max-width: 479px)': {
        fontSize: '12px',
        mr: 0,
      },
    };

    this.itemTitleLink = {
      textAlign: 'left',
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
      textDecorationThickness: '1px',
      mr: 4,
      fontSize: ['13px', '15px'],
      '@media screen and (max-width: 479px)': {
        fontSize: '12px',
        mr: 2,
      },
    };

    this.icon = {
      width: '12px',
      userSelect: 'none',
      color: 'currentColor',
      mr: 4,
      '@media screen and (max-width: 479px)': {
        mr: 2,
      },
    };
  })()
);
