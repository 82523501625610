import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heroSlider = {
      bg: 'ghostwhite',
      display: 'flex',
      flexDirection: 'column',
    };
  })()
);
