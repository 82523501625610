export const badges = {
  onSale: {
    color: 'background',
    bg: 'dark',
    py: 4,
    px: 6,
    fontSize: 1,
    fontWeight: 400,
  },
  sellingPlan: {
    color: 'background',
    fontSize: '9px',
    width: 'auto',
    lineHeight: 1,
    letterSpacing: '.08em',
    borderRadius: '7px',
    bg: 'text',
    py: 2,
    px: 3,
    fontWeight: 400,
  },
};
