import { useRef, useEffect } from 'react';
import { Input as BaseInput, Box } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import { useSidebar } from '@hooks';
import { Svg } from '@snippets';

import { useSearchInput, useSearch } from '../hooks';
import { themed } from './Input.theme';

export const Input = themed(({ theme, ...props }) => {
  const input = useRef();
  const router = useRouter();
  const [{ isSearch }, { closeSidebarToNavigate }] = useSidebar();
  const [{ rawTerm }, { setRawTerm, resetRawTerm, setSearchIsFocused }] =
    useSearchInput();
  const [{ merchandising }] = useSearch();

  useEffect(() => {
    if (isSearch) {
      input.current.focus();
    }
  }, [isSearch]);

  return (
    <Box
      data-comp={Input.displayName}
      {...props}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      {/* search */}
      <Svg
        alt="Search input"
        viewBox="0 0 48 48"
        src="/svgs/cta/search.svg#search"
        sx={theme.icon}
      />

      <BaseInput
        ref={input}
        placeholder="Search here"
        role="search"
        aria-label="Products and collections"
        onFocus={() => setSearchIsFocused(true)}
        onBlur={() => setSearchIsFocused(false)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !!e.target.value) {
            const redirect = merchandising?.redirect;
            closeSidebarToNavigate();
            router.push({
              pathname: redirect || '/pages/search',
              query: redirect ? {} : { query: e.target.value },
            });
          }
        }}
        onChange={(e) => {
          setRawTerm(e.target.value);
        }}
        value={rawTerm}
        sx={theme.field}
      />

      {/*  clear search icon */}
      <Svg
        src="/svgs/close.svg#close"
        alt="Clear search"
        viewBox="0 0 48 48"
        onClick={resetRawTerm}
        role="button"
        sx={rawTerm ? theme.clear.visible : theme.clear.hidden}
      />
    </Box>
  );
});

Input.displayName = 'Input';
