import { useMemo } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import { Box } from 'theme-ui';

import { Link, Picture } from '@snippets';
import { useMenuSidebar } from '../useMenuSidebar';
import { themed } from './ExpandableMenu.theme';

export const ExpandableMenuLink = themed(({ theme, item, layout }) => {
  const { link } = item;
  const [{}, { closeMenuSidebar }] = useMenuSidebar();
  const { product } = useProductByHandle({
    handle:
      item.product?.handle || 'hopsulator-slim-gold-leopard-12oz-slim-cans',
  });

  const firstImage = useMemo(() => {
    return product?.media?.find((item) => {
      return item.mediaContentType === 'IMAGE';
    })?.image;
  }, [product?.id]);

  return (
    <Box
      as="li"
      sx={theme.expandableMenuLink[layout]}
      data-comp={ExpandableMenuLink.displayName}
    >
      <Link
        href={link?.url}
        newTab={link?.newTab}
        aria-label={link?.text}
        onClick={closeMenuSidebar}
        sx={{
          fontSize: layout === 'images' ? '15px' : '16px',
          color: (t) => t.colors.black,
          ...(layout === 'images'
            ? { display: 'flex', alignItems: 'center' }
            : {}),
        }}
      >
        <Picture
          alt={link?.text}
          images={[
            {
              src: firstImage?.originalSrc,
              width: layout === 'images' ? 96 : 296,
              ratio: 1,
            },
          ]}
          sx={theme.expandableMenuLinkImage[layout]}
        />
        {link?.text}
      </Link>
    </Box>
  );
});

ExpandableMenuLink.displayName = 'ExpandableMenuLink';
