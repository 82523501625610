import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.column.start.between',
      height: '100%',
      width: '100%',
      position: 'relative',
      '[data-comp="AddToCart"]': {
        opacity: [1, 0],
        transition: 'all 0.2s ease-in-out',
        ':focus': {
          opacity: 1,
        },
      },
      ':hover': {
        '[data-comp="AddToCart"]': {
          opacity: 1,
        },
      },
    };

    this.content = {
      width: '100%',
    };

    this.atc = {
      mt: [8, 10],
      width: ['100%', 'unset'],
    };
  })()
);
