import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Container,
  Box,
  Heading,
  Paragraph,
  Input,
  Checkbox,
  Text,
  Label,
} from 'theme-ui';
import { colors } from '@theme/theme.colors';
import { useCustomer, useSettings } from '@backpackjs/storefront';

import { StatefulButton } from '@snippets';
import { useModal, useEmailSignup } from '@hooks';

import { useBackInStock } from './useBackInStock';
import { themed } from './BackInStockModal.theme';

export const BackInStockModal = themed(({ legacyResourceId, title, theme }) => {
  const settings = useSettings();
  const customer = useCustomer();
  const { subscribeToBackInStock, ...status } = useBackInStock();
  const { submitEmailSignup } = useEmailSignup();
  const { closeModal } = useModal()[1];
  const [email, setEmail] = useState(customer?.email || '');
  const [subscribe, setSubscribe] = useState(true);
  const { description, modalButtonText = 'Notify When Available' } =
    settings?.product?.backInStock || {};

  const titles = useMemo(() => {
    const titleArr = title?.replaceAll('&amp;', '&')?.split('|');
    return {
      product: titleArr?.[0]?.trim() || '',
      variant: titleArr?.slice(1)?.join('|').trim() || '',
    };
  }, [title]);

  const emailListId = 'Mrk7yV';

  const handleSubscribe = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email || !legacyResourceId) return;
      if (subscribe) {
        await submitEmailSignup(emailListId, email, 'BackInStockModal');
      }
      await subscribeToBackInStock({
        email,
        variantId: legacyResourceId,
      });
    },
    [email, legacyResourceId, subscribe]
  );

  useEffect(() => {
    if (status.success) {
      setTimeout(() => closeModal(), 2000);
    }
  }, [status.success]);

  return (
    <Container data-comp={BackInStockModal.displayName} sx={theme.wrapper}>
      <Heading sx={theme.title}>{titles.product}</Heading>

      <Heading as="h3" sx={theme.variant}>
        {titles.variant}
      </Heading>

      {description && (
        <Paragraph sx={theme.description}>{description}</Paragraph>
      )}

      <Box as="form" mt={10} onSubmit={handleSubscribe}>
        <Input
          name="email"
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={theme.input}
        />
        <Box sx={theme.checkboxWrapper}>
          <Label
            variant="forms.checkbox"
            sx={{
              alignItems: 'flex-start',
              mb: 0,
              gap: 2,
            }}
          >
            <Checkbox
              type="checkbox"
              id="subscribe_for_newsletter"
              name="subscribe"
              defaultChecked
              onChange={({ target: { checked } }) => setSubscribe(checked)}
              sx={{
                backgroundColor: subscribe ? colors.black : 'transparent',
                color: subscribe ? colors.white : colors.black,
              }}
            />
            <Text sx={{ textAlign: 'left' }}>
              Subscribe to BrüMate mailing list. Must be subscribed to receive
              back-in-stock notifications.
            </Text>
          </Label>
        </Box>

        <StatefulButton
          {...status}
          showErrors={false}
          text={{
            started: modalButtonText,
            success: 'Email Subscribed!',
            error: 'Please Try Again Later',
            default: modalButtonText,
          }}
          sx={theme.button}
        />
      </Box>
    </Container>
  );
});

BackInStockModal.displayName = 'BackInStockModal';
