import { useMemo } from 'react';
import { useCartItems } from '@backpackjs/storefront';
import { Box, Heading, Paragraph } from 'theme-ui';

import { Picture, StatefulButton } from '@snippets';

export function GiftWithPurchaseItem({
  product,
  gwpSettings,
  isLoading,
  cartHasGwp,
  ctaLabel,
  ctaSuccessLabel,
  handleAddToCart,
}) {
  const gwpVariantId = product.variants[0]?.storefrontId;
  const cartItems = useCartItems();
  const isAddedToCart = cartItems.some(
    (item) => item.variant.id === gwpVariantId
  );

  const firstImage = useMemo(() => {
    return product.media?.find((item) => {
      return item.mediaContentType === 'IMAGE';
    })?.image;
  }, [product.id]);

  return (
    <Box sx={{ width: '100%' }}>
      <Picture
        alt={product.title}
        images={[
          {
            src: firstImage?.src,
            ratio: 1,
            width: 240,
          },
        ]}
        imageBgColor="lightGray"
        sx={{
          width: '100%',
        }}
      />
      <Heading
        variant="text.h3"
        sx={{ mt: 8, fontSize: ['24px', '24px', '30px'] }}
      >
        {gwpSettings?.heading || product.title}
      </Heading>
      {gwpSettings?.copy && (
        <Paragraph variant="text.h5" sx={{ mt: 4, fontWeight: 400 }}>
          {gwpSettings?.copy}
        </Paragraph>
      )}
      <Box sx={{ mt: 8 }}>
        <StatefulButton
          started={isLoading}
          finished={isAddedToCart}
          success={isAddedToCart}
          disabled={isLoading || isAddedToCart || cartHasGwp}
          text={{ success: ctaSuccessLabel, default: ctaLabel }}
          onClick={() => handleAddToCart(gwpVariantId)}
          sx={{
            backgroundColor: isAddedToCart ? 'green' : null,
            borderColor: isAddedToCart ? 'green' : null,
            minWidth: '160px',
            fontSize: '15px',
            mx: 'auto',
          }}
        />
      </Box>
    </Box>
  );
}

GiftWithPurchaseItem.displayName = 'GiftWithPurchaseItem';
