import { Heading, Flex, Paragraph, Box } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { EmailSignup as EmailSignupInput } from '@snippets';

import { themed } from './EmailSignup.theme';

export const EmailSignup = themed(({ theme }) => {
  const settings = useSettings();
  const emailSignup = settings?.footer?.emailSignup;

  return (
    <Flex
      data-comp={EmailSignup.displayName}
      variant="flex.column.start.start"
      sx={theme.wrapper}
    >
      {emailSignup?.heading && (
        <Box sx={theme.header}>
          <Heading as="h3" sx={theme.heading}>
            {emailSignup.heading}
          </Heading>

          {emailSignup?.subtext && (
            <Paragraph sx={theme.subtext}>{emailSignup?.subtext}</Paragraph>
          )}
        </Box>
      )}

      <EmailSignupInput sx={theme.input} />
    </Flex>
  );
});

EmailSignup.displayName = 'EmailSignup';
