const defaultInput = {
  variant: 'text.base',
  color: 'text',
  p: 8,
  // border: t => `1px solid ${t.colors.gray}`,
  borderRadius: 0,
  ':focus': {
    outline: 'none !important',
  },
};

export const input = {
  ...defaultInput,

  search: {
    ...defaultInput,
    pl: '50px',
    mb: 10,
    ':focus': {
      outline: 'none !important',
      // border: t => `1px solid ${t.colors.text}`,
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161);',
    },
  },
};
