import { Heading, Flex, Paragraph } from 'theme-ui';

import { Link } from '@snippets';
import { convertAlignToFlex } from '@utils';

import { themed } from './Content.theme';

export const Content = themed(({ theme, settings, article, url }) => {
  const { linkText, linkStyle, linkStyleMobile, align, alignMobile } = {
    ...settings,
  };
  const { author, title, excerpt, _createdAt, createdAt } = { ...article };
  const [, month, day, year] = new Date(Date.parse(_createdAt || createdAt))
    .toString()
    .split(' ');
  const date = `${month} ${day}, ${year}`;

  const textAlign = [alignMobile, align];

  const slicedExcerpt =
    excerpt.length > 80
      ? `${excerpt.slice(0, excerpt.indexOf(' ', 60))} ...`
      : excerpt;

  return (
    <Flex data-comp={Content.displayName} sx={theme.outer}>
      <Flex
        sx={{
          variant: 'flex.column',
          width: '100%',
          alignItems: [
            convertAlignToFlex(alignMobile),
            convertAlignToFlex(align),
          ],
        }}
      >
        <Paragraph sx={{ ...theme.date, textAlign }}>
          {author} | {date}
        </Paragraph>

        <Link
          href={url}
          aria-label={title}
          sx={{ cursor: 'pointer', variant: 'buttons.plain' }}
        >
          <Heading
            as="h4"
            sx={{
              ...theme.title,
              textAlign,
            }}
          >
            {title}
          </Heading>
        </Link>

        <Paragraph
          sx={{
            ...theme.excerpt,
            textAlign,
          }}
        >
          {slicedExcerpt}
        </Paragraph>

        <Link
          variant={`buttons.${linkStyleMobile || 'link.2'}`}
          href={url}
          sx={{
            ...theme.link,
            display: ['block', 'none'],
          }}
        >
          {linkText}
        </Link>

        <Link
          variant={`buttons.${linkStyle || 'link.2'}`}
          href={url}
          sx={{
            ...theme.link,
            display: ['none', 'block'],
          }}
        >
          {linkText}
        </Link>
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
