import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Heading, Box } from 'theme-ui';

import { ProductsRowItem } from './ProductsRowItem';
import { themed } from './ProductsRow.theme';

export const ProductsRow = themed(({ theme, heading, products }) => {
  return (
    <Box data-comp={ProductsRow.displayName} sx={theme.wrapper}>
      <Heading as="h5" sx={theme.heading}>
        {heading}
      </Heading>
      <Swiper
        spaceBetween={16}
        slidesPerView={1.4}
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
      >
        {products.map((item, index) => (
          <SwiperSlide key={item.product?.handle + index}>
            <ProductsRowItem handle={item.product?.handle} image={item.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
});

ProductsRow.displayName = 'MenuSidebarProductsRow';
