import { Box, Flex, Grid, Heading } from 'theme-ui';

import { Link, Svg } from '@snippets';
import { useMenuDrawer } from '../useMenuDrawer';
import { NavMenuLink } from './NavMenuLink';
import { themed } from './NavMenu.theme';
import { NavMenuImage } from './NavMenuImage';

export const NavMenu = themed(({ theme, activeNavMenu, featuredImage }) => {
  const { link, tertiaryMenu } = { ...activeNavMenu };
  const [, { closeMenuDrawer }] = useMenuDrawer();

  return (
    <Box data-comp={NavMenu.displayName}>
      <Grid
        sx={{
          ...theme.navMenu,
          borderLeft: (t) =>
            `1px solid ${tertiaryMenu ? t.colors.gray : 'transparent'}`,
        }}
      >
        <Flex
          variant="flex.column"
          sx={{
            rowGap: '4px',
          }}
        >
          <Heading sx={theme.navMenuHeading}>{link?.text}</Heading>
          <Flex
            variant="flex.column"
            sx={{
              rowGap: '4px',
              maxHeight: '305px',
              overflowY: 'auto',
            }}
          >
            {tertiaryMenu?.map((item, index) => {
              return <NavMenuLink key={index} theme={theme} item={item} />;
            })}
          </Flex>
          {tertiaryMenu && !activeNavMenu?.hideShopAll && (
            <Flex
              variant="flex.row.start.center"
              sx={{
                ...theme.navMenuLink,
                mt: 4,
              }}
            >
              <Link
                href={link?.url}
                newTab={link?.newTab}
                aria-label={link?.text}
                onClick={closeMenuDrawer}
                sx={theme.link}
              >
                Shop All {link?.text}
                <Svg
                  viewBox="0 0 16 16"
                  src="/svgs/chevron/right.svg#right"
                  sx={theme.arrowIcon}
                />
              </Link>
            </Flex>
          )}
        </Flex>
        {featuredImage && (
          <NavMenuImage featuredImage={featuredImage} theme={theme} />
        )}
      </Grid>
    </Box>
  );
});

NavMenu.displayName = 'NavMenu';
