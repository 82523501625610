import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.row.between.center',
      width: '98px',
      height: '32px',
      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      'input[type=number]': {
        MozAppearance: 'textfield',
      },
    };

    this.inputWrapper = {
      variant: 'flex.row.center.center',
      flex: 1,
      mx: 2,
      position: 'relative',
      height: '100%',
    };

    this.input = {
      fontSize: 3,
      fontWeight: 500,
      width: '100%',
      height: '100%',
      textAlign: 'center',
      p: 0,
      bg: 'transparent',
      outline: 'none',
      borderStyle: 'none',
      ':focus, :hover': {
        outline: 'none !important',
      },
      transition: 'opacity 0.1s ease-in-out',
    };

    this.spinner = {
      width: '20px',
      color: 'mediumGray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };

    this.button = (disabled) => ({
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      border: (t) => `1px solid ${t.colors.gray}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.15s ease-in-out',
      cursor: disabled ? 'default' : 'pointer',
      ':hover': {
        borderColor: disabled ? 'gray' : 'mediumGray',
      },
    });

    this.buttonIcon = {
      width: '16px',
      height: '16px',
    };
  })()
);
