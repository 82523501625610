import { useCallback } from 'react';

export const useWishlist = () => {
  const updateWishlist = useCallback(async ({ params }) => {
    try {
      const response = await fetch('/api/swym', {
        method: 'post',
        body: JSON.stringify({
          action: 'updateListProducts',
          ...params,
        }),
      });

      const json = await response.json();

      const { data } = json;
      return data;
    } catch (error) {
      console.log('updateWishlist error:', error);
      return null;
    }
  }, []);

  return { updateWishlist };
};
