import { Container } from 'theme-ui';

import { SuperCollectionProductItem } from './SuperCollectionProductItem';
import { themed } from './SuperCollection.theme';

export const SuperCollectionGrid = themed(({ theme, products }) => {
  return (
    <Container data-comp={SuperCollectionGrid.displayName} sx={theme.grid}>
      {products?.map(({ product, link, description }, index) => {
        const handle = product?.handle;
        if (!handle) return null;
        return (
          <SuperCollectionProductItem
            key={index}
            handle={handle}
            seeAllLink={link}
            description={description}
            index={index}
          />
        );
      })}
    </Container>
  );
});

SuperCollectionGrid.displayName = 'SuperCollectionGrid';
