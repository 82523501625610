import { Picture } from '@snippets';

import { themed } from './Slide.theme';

export const Image = themed(({ theme, slide }) => {
  const { image } = slide;

  return (
    <Picture
      data-comp={Image.displayName}
      alt={image?.alt}
      aboveTheFold
      images={[
        {
          src: image?.image?.src,
          width: 768,
          ratio: 1.1,
        },
        {
          src: image?.imageDt?.src,
          width: 1024,
          ratio: 1.2,
        },
        {
          src: image?.imageDt?.src,
          width: 1280,
          ratio: 1.2,
        },
        {
          src: image?.imageDt?.src,
          width: 1440,
          ratio: 1.2,
        },
      ]}
      sx={theme.image}
      imageSx={{
        objectPosition: [image?.position, image?.positionDt],
      }}
    />
  );
});

Image.displayName = 'Image';
