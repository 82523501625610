import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.outer = {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      py: [12, 0],
      px: [0, 11],
      '@media only screen and (max-width: 479px)': {
        py: 6,
      },
    };

    this.innerLeft = {
      variant: 'flex.column',
      width: '100%',
      alignItems: 'flex-start',
    };

    this.innerRight = {
      ...this.innerLeft,
      alignItems: 'flex-end',
    };

    this.innerCenter = {
      ...this.innerLeft,
      alignItems: 'center',
    };

    this.date = {
      variant: 'text.body',
    };

    this.heading = {
      variant: 'text.articleTitle',
      fontSize: '28px',
      lineHeight: '32px',
      maxWidth: 300,
      overflowWrap: 'break-word',
      mt: 3,
    };

    this.excerpt = {
      variant: 'text.base',
      mt: 3,
    };

    this.readMore = {
      mt: 3,
    };
  })()
);
