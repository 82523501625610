import { Svg, Link } from '@snippets';

import { themed } from '../Menu.theme';

export const CtaWishlist = themed(({ theme, inputRef, ...props }) => {
  return (
    <Link
      data-comp={CtaWishlist.displayName}
      {...props}
      href="/pages/wishlist"
      aria-label="View wishlist"
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      <Svg
        alt="wishlist"
        viewBox="0 0 48 48"
        src="/svgs/cta/heart.svg#heart"
        sx={theme.cta.icon}
      />
    </Link>
  );
});

CtaWishlist.displayName = 'CtaWishlist';
