import { Sidebar } from '@snippets';

import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';

export function CartSearchSidebar() {
  // Cart or Search — sidebar views
  return (
    <Sidebar data-comp={CartSearchSidebar.displayName}>
      <Sidebar.Header>
        <Header />
      </Sidebar.Header>

      <Sidebar.Content>
        <Content />
      </Sidebar.Content>

      <Sidebar.Footer>
        <Footer />
      </Sidebar.Footer>
    </Sidebar>
  );
}

CartSearchSidebar.displayName = 'CartSearchSidebar';
