export const linksList = {
  label: 'Links list (mobile only)',
  name: 'links',
  component: 'group-list',
  description: 'Links at the bottom of the menu sidebar',
  itemProps: {
    label: '{{item.link.text}}',
  },
  defaultItem: {
    link: {
      url: '/account',
      text: 'Account',
    },
  },
  fields: [
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
  ],
};
