import { position, section, align, button } from '@settings/common';

const fields = [
  {
    label: 'Image Settings',
    name: 'image',
    description: 'Image, alt text',
    component: 'group',
    fields: [
      {
        label: 'Image Alt',
        name: 'alt',
        component: 'text',
        description:
          'Provide a brief description of the image for accessibility compliance',
      },
      {
        name: 'imageDt',
        label: 'Image (desktop)',
        component: 'image',
      },
      {
        label: 'Image (mobile)',
        name: 'image',
        component: 'image',
      },
    ],
    defaultValue: {
      alt: `A Woman sitting on a couch with a Hopsulator slim in her hand.`,
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FirstCanCooler.png',
      },
      imageDt: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FirstCanCooler.png',
      },
    },
  },
  {
    label: 'Media Settings',
    name: 'media',
    description: 'Position, placement, aspect ratio',
    component: 'group',
    fields: [
      {
        label: 'Placement (desktop)',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        name: 'locationDt',
        options: [
          {
            value: 'left',
            label: 'Left',
          },
          {
            value: 'right',
            label: 'Right',
          },
        ],
      },
      {
        label: 'Aspect ratio (desktop)',
        name: 'ratioDt',
        component: 'radio-group',
        description:
          'Only applies to images and video files not already set to 1:1 ratio',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          {
            value: '42%',
            label: '42:100',
          },
          {
            value: '80%',
            label: '5:4',
          },
          {
            value: '80%',
            label: '5:4',
          },
          {
            value: '100%',
            label: '1:1',
          },
          {
            value: '120%',
            label: '5:6',
          },
        ],
      },
      {
        label: 'Placement (mobile)',
        name: 'location',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          {
            value: 'top',
            label: 'Top',
          },
          {
            value: 'bottom',
            label: 'Bottom',
          },
        ],
      },
      {
        label: 'Aspect ratio (mobile)',
        component: 'radio-group',
        description:
          'Only applies to images and video files not already set to 1:1 ratio',
        direction: 'horizontal',
        variant: 'radio',
        name: 'ratio',
        options: [
          {
            value: '42%',
            label: '42:100',
          },
          {
            value: '80%',
            label: '5:4',
          },
          {
            value: '100%',
            label: '1:1',
          },
          {
            value: '120%',
            label: '5:6',
          },
        ],
      },
    ],
    defaultValue: {
      location: 'top',
      locationDt: 'left',
      ratio: '100%',
      ratioDt: '100%',
      position: 'center center',
      positionDt: 'center center',
    },
  },
  {
    label: 'Content Settings',
    name: 'content',
    description: 'Heading, superheading body, link, content alignment',
    component: 'group',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        description:
          'use [customer_name], [customer_firstname], [customer_lastname] for real customer name.',
      },
      {
        label: 'Body',
        name: 'body',
        component: 'markdown',
      },
      {
        label: 'Link',
        name: 'cta1',
        component: 'link',
      },
      {
        name: 'cta1Style',
        label: 'Link Style',
        component: 'select',
        options: button.options,
      },
      {
        label: 'Link',
        name: 'cta2',
        component: 'link',
      },
      {
        name: 'cta2Style',
        label: 'Link Style',
        component: 'select',
        options: button.options,
      },
      {
        label: 'Content alignment (mobile)',
        name: 'alignment',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
      },
      {
        label: 'Content alignment (desktop)',
        name: 'alignmentDt',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
      },
    ],
    defaultValue: {
      heading: "THE WORLD'S FIRST SLIM CAN COOLER.",
      body: '',
      ctaStyle: button.value.secondary,
      alignment: align.value.left,
      alignmentDt: align.value.left,
    },
  },
];

export function Schema({ article, blog }) {
  if (article || blog) return null;

  return {
    category: 'LoyaltyLion',
    label: 'LoyaltyLion Welcome',
    key: 'loyaltyllion-welcome',
    fields: [
      {
        label: 'User Settings',
        name: 'userSettings',
        description: 'Settings for Signed In',
        component: 'group',
        fields,
      },
      {
        label: 'Guest Settings',
        name: 'guestSettings',
        description: 'Settings for Signed Out',
        component: 'group',
        fields,
      },
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
}
