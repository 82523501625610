import { Picture } from '@snippets';

import { themed } from './Image.theme';

export const Image = themed(({ theme, image, media }) => {
  return (
    <Picture
      data-comp={Image.displayName}
      alt={image?.alt}
      images={[
        {
          src: image?.image?.src,
          width: 768,
          ratio: 1,
        },
        {
          src: image?.imageDt?.src,
          width: 512,
          ratio: 1,
        },
        {
          src: image?.imageDt?.src,
          width: 640,
          ratio: 1,
        },
        {
          src: image?.imageDt?.src,
          width: 720,
          ratio: 1,
        },
      ]}
      imageSx={{
        objectPosition: [media?.position, media?.positionDt],
      }}
      sx={theme.image}
    />
  );
});

Image.displayName = 'Image';
