import React, { useMemo } from 'react';
import { Button, Alert, Spinner } from 'theme-ui';

export function StatefulButton({
  text = {
    started: 'LOADING',
    success: 'DONE',
    error: 'FAILED',
    default: 'SUBMIT',
  },
  setErrors,
  setSuccess,
  setFinished,
  setStarted,
  started,
  success,
  finished,
  loading,
  errors,
  reset,
  resetTimer,
  showErrors = true,
  autoReset = true,
  resetAfter = 2000,
  children,
  disabled,
  ...props
}) {
  const ref = React.useRef(null);
  const resetTimerRef = resetTimer || ref;

  const buttonText = useMemo(() => {
    if (finished && autoReset) {
      resetTimerRef.current = setTimeout(() => {
        if (reset) reset();
        clearTimeout(resetTimerRef.current);
      }, resetAfter);
    }

    return finished
      ? success
        ? text.success
        : text.error
      : // not finished yet
      started
      ? text.started
      : text.default;
  }, [started, success, finished, Object.values(text).join(',')]);

  const isDisabled = started;

  return (
    <>
      <Button disabled={disabled || started} {...props}>
        {loading || isDisabled ? (
          <Spinner
            aria-label="Loading"
            sx={{
              color: 'currentColor',
              height: '60%',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : (
          <>
            {buttonText}
            {children}
          </>
        )}
      </Button>

      {showErrors && errors?.length
        ? errors.map((err) => (
            <Alert key={err} variant="alerts.error" mt={6}>
              {err}
            </Alert>
          ))
        : null}
    </>
  );
}
