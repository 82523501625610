import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'relative',
      height: '44px',
    };

    this.field = {
      // variant: 'forms.input.search',
      height: '100%',
      borderRadius: '22px',
      fontSize: 4,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'mediumGray',
      py: 0,
      pl: 14,
      pr: 8,
      '::placeholder': {
        variant: 'text.base',
        color: 'text',
      },
    };

    this.icon = {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '24px',
      height: '24px',
      cursor: 'default',
      color: 'text',
      left: 6,
      zIndex: 1,
    };

    this.clear = {
      hidden: {
        ...this.icon,
        right: 8,
        left: 'auto',
        display: 'none',
        width: '16px',
        height: '16px',
      },
      visible: {
        ...this.icon,
        cursor: 'pointer',
        right: 8,
        left: 'auto',
        display: 'block',
        width: '16px',
        height: '16px',
      },
    };
  })()
);
