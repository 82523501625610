import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      mb: 10,
    };

    this.starWrapper = {
      mb: '1rem',
    };
    this.header = {
      variant: 'text.reviewsHeading',
      mt: [15, 80],
    };

    this.percentage = {
      position: 'absolute',
      width: 'fit-content',
      py: 1,
      px: 5,
      backgroundColor: 'black',
      bottom: 'calc(100% - 13px)',
      left: 'calc(50% - 30px)',
      fontWeight: 500,
    };

    this.subtextBox = {
      mt: [10],
      color: 'white',
      alignSelf: 'center',
      border: '1px solid',
      borderColor: 'mediumGray',
      width: [300, 'fit-content'],
      py: [10, 8],
      px: 9,
      position: 'relative',
    };

    this.subtext = {
      color: 'text',
      fontWeight: 500,
    };
  })()
);
