import { color, position, align } from '@settings/common';

export default {
  label: 'Blog Settings',
  name: 'blogSettings',
  component: 'group',
  fields: [
    {
      label: 'Blog page Settings',
      name: 'text',
      description: 'Heading, color, alignment, position',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'markdown',
          description:
            'Tip: You can ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1"',
        },
        {
          component: 'select',
          name: 'colorDt',
          label: 'Text color (tablet & desktop)',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text color (mobile)',
          options: [{ label: 'Default', value: '' }, ...color.options],
        },
        {
          name: 'alignmentDt',
          label: 'Alignment',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: align.options,
        },
        {
          name: 'positionDt',
          component: 'select',
          label: 'Position',
          options: position.flex,
        },
        {
          name: 'alignment',
          label: 'Alignment (mobile)',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: align.options,
        },
        {
          name: 'position',
          component: 'select',
          label: 'Position (mobile)',
          options: position.flex,
        },
      ],
      defaultValue: {
        heading: '# Blog',
        color: '',
        colorDt: '',
        alignmentDt: 'center',
        positionDt: 'flex-end center',
        alignment: 'center',
      },
    },
  ],
};
