import { Flex } from 'theme-ui';
import dynamic from 'next/dynamic';

import { themed } from './Sort.theme';

const SortDropdown = dynamic(
  () => import('./SortDropdown').then((module) => module.SortDropdown),
  { ssr: false, suspense: false }
);

export const Sort = themed(({ theme }) => {
  return (
    <Flex
      data-comp={Sort.displayName}
      variant="flex.row.end"
      sx={theme.wrapper}
    >
      <SortDropdown />
    </Flex>
  );
});

Sort.displayName = 'Sort';
