import { Image as TempImage } from 'theme-ui';

import { themed } from './Image.theme';

export const Image = themed(({ theme, alt, image }) => {
  return (
    <TempImage
      src={image}
      alt="Blog post image"
      sx={{
        ...theme.image,
        objectFit: 'cover',
        width: '100%',
        objectPosition: [image?.position, image?.positionDt],
      }}
    />
    // <Picture
    //   data-comp={Image.displayName}
    //   alt={`${alt} featured image.`}
    //   images={[
    //     {
    //       src: image?.image,
    //       width: 768,
    //       ratio: 1,
    //     },
    //     {
    //       src: image?.image,
    //       width: 512,
    //       ratio: 1.4,
    //     },
    //     {
    //       src: image?.image,
    //       width: 640,
    //       ratio: 1,
    //     },
    //     {
    //       src: image?.image,
    //       width: 1024,
    //       ratio: 1,
    //     },
    //   ]}
    //   imageSx={{
    //     objectPosition: [image?.position, image?.positionDt],
    //   }}
    //   sx={theme.image}
    // />
  );
});

Image.displayName = 'Image';
