import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.content',
      px: [0, 10, 11, 12],
      pt: [14, 16],
      pb: [14, 18, '80px'],
    };

    this.headingWrapper = {
      variant: 'flex.column.center',
      mb: 13,
      maxWidth: '700px',
      px: [8, 0],
      mx: 'auto',
      minHeight: ['36px', '40px'],
      position: 'relative',
    };

    this.heading = {
      variant: 'text.h2',
      textAlign: 'center',
    };

    this.subheading = {
      textAlign: 'center',
      variant: 'text.lg',
      mt: 10,
    };

    this.italicized = {
      fontStyle: 'italic',
      fontWeight: 500,
    };

    this.grid = {
      width: '100%',
      display: 'grid',
      columnGap: [10, 12],
      rowGap: [14, 12],
    };

    this.noProducts = {
      variant: 'flex.column',

      text: {
        variant: 'text.lg',
        mt: 20,
        textAlign: 'center',
      },
    };

    this.banner = {
      width: '100%',
      height: ['100%', 'calc(100% - 60px)'],
      'h2,h3,h4,h5,h6': {
        variant: 'text.h4',
        mt: [10, 11],
        mb: 3,
      },
      p: {
        mt: 0,
        variant: 'text.lg',
      },
      'p:last-of-type': {
        mb: 0,
      },
    };
  })()
);
