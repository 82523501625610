import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      justifyContent: 'center',
      flexWrap: 'wrap',
      mt: 10,
      'p, a, span': { variant: 'text.lg' },
    };

    this.suggestion = (isLast) => ({
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textUnderlineOffset: '1px',
      fontStyle: 'italic',
      mr: 3,
      ':after': {
        content: !isLast ? '","' : '""',
      },
    });
  })()
);
