export const alerts = {
  success: {
    color: 'white',
    bg: 'green',
    py: 4,
    px: 6,
    fontWeight: 400,
    fontSize: 2,
  },
  warning: {
    color: 'red',
    bg: 'yellow',
    py: 4,
    px: 6,
    fontWeight: 400,
    fontSize: 2,
  },
  error: {
    color: 'white',
    bg: 'red',
    py: 4,
    px: 6,
    fontWeight: 400,
    fontSize: 2,
  },
};
