import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      ':hover': {
        pointerEvents: 'none',
        transform: 'scale3d(1.03, 1.03, 1.03)',
      },
      transform: 'scale3d(1.01, 1.01, 1.01)',
      transition: 'transform 0.3s ease-in-out',
    };
  })()
);
