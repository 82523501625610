import { useState } from 'react';
import {
  useCartAddDiscount,
  useCartAddItem,
  useCartItems,
  useSettings,
} from '@backpackjs/storefront';
import { useSidebar } from '@hooks';
import store, { useRecoilValue } from '@store';
import { decodedId } from '@utils';
import { themed } from './GiftWithPurchase.theme';
import { GiftWithPurchaseImage } from './GiftWithPurchaseImage';
import { GiftWithPurchaseSlider } from './GiftWithPurchaseSlider';

export const GiftWithPurchase = themed(({ theme }) => {
  const gwpProducts = useRecoilValue(store.gwpProducts);
  const { cartAddItem } = useCartAddItem();
  const { cartAddDiscount } = useCartAddDiscount();
  const cartItems = useCartItems();
  const [isLoading, setIsLoading] = useState(false);
  const [cartHasGwp, setCartHasGwp] = useState(
    cartItems?.some((item) =>
      gwpProducts?.some(
        (product) =>
          decodedId(product?.variants?.[0]?.storefrontId) ===
            decodedId(item.variant?.id) &&
          parseInt(item.estimatedCost.subtotalAmount.amount, 10) === 0
      )
    )
  );
  const [, { openSidebar }] = useSidebar();
  const settings = useSettings();
  const { code, products, modalText } = { ...settings?.gwp };
  const isImageDisplay = products?.displayType === 'image';

  const handleAddToCart = async (gwpVariantId) => {
    setIsLoading(true);
    const merchandiseId = gwpVariantId;
    if (!merchandiseId) {
      openSidebar('cart');
      return;
    }
    const cart = await cartAddItem({
      merchandiseId,
      quantity: 1,
    });
    setIsLoading(false);
    if (
      code &&
      !cart?.discountCodes?.some((discountCode) => discountCode.code === code)
    ) {
      await cartAddDiscount({ discountCode: code });
    }
    if (cart) {
      setCartHasGwp(true);
      setTimeout(() => {
        openSidebar('cart');
      }, 2000);
    } else {
      openSidebar('cart');
    }
  };

  return isImageDisplay ? (
    <GiftWithPurchaseImage
      cartHasGwp={cartHasGwp}
      gwpProducts={gwpProducts}
      handleAddToCart={handleAddToCart}
      isLoading={isLoading}
      modalText={modalText}
      openSidebar={openSidebar}
      imageGroup={products?.imageGroup}
    />
  ) : (
    <GiftWithPurchaseSlider
      cartHasGwp={cartHasGwp}
      gwpProducts={gwpProducts}
      handleAddToCart={handleAddToCart}
      isLoading={isLoading}
      modalText={modalText}
      openSidebar={openSidebar}
    />
  );
});

GiftWithPurchase.displayName = 'GiftWithPurchase';
