import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };
  })()
);
