import { useMemo } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import { Box, Button } from 'theme-ui';

import { Picture } from '@snippets';

import { themed } from './ColorOptions.theme';

export const ColorOption = themed(
  ({ theme, handle, selectedHandle, setSelectedHandle }) => {
    const { product } = useProductByHandle({ handle });

    const color = useMemo(() => {
      if (!product) return null;
      return product?.tags
        ?.find((tag) => tag.match(new RegExp('color::', 'gi')))
        ?.split('::')[1]
        ?.trim();
    }, [product?.id]);

    const firstImage = useMemo(() => {
      return product?.media?.find((item) => {
        return item.mediaContentType === 'IMAGE';
      })?.image;
    }, [product?.id]);

    const isSelected = handle === selectedHandle;

    return (
      <Box as="li" data-comp={ColorOption.displayName} key={handle}>
        <Button
          aria-label={color}
          variant="buttons.plain"
          onClick={() => {
            setSelectedHandle(handle);
          }}
          sx={{
            ...theme.value,
            ...(isSelected
              ? theme.value.available.selected
              : theme.value.available.unselected),
          }}
        >
          <Picture
            alt={color}
            images={[
              {
                src: firstImage?.originalSrc,
                ratio: 1,
                width: 46,
              },
            ]}
          />
        </Button>
      </Box>
    );
  }
);

ColorOption.displayName = 'ColorOption';
