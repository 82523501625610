import { useMemo } from 'react';

import { Section, Link } from '@snippets';
import { calcResponsiveHeight, withInView } from '@utils';
import { Box, Container } from 'theme-ui';

import { Image } from './Image';
import { Content, ContentBelow } from './Content';
import { themed } from './Hero.theme';
import { Schema } from './Hero.schema';

export const Hero = withInView(
  themed(({ theme, cms }) => {
    const { button, image, text, contentSettings, section } = cms;
    const { placement, placementDt } = { ...contentSettings };

    const content = useMemo(
      () => ({
        image,
        text,
        button,
        settings: contentSettings,
      }),
      [cms]
    );

    const cta = button?.buttons?.[0]?.cta;
    const clickable = button?.clickableHero && !!cta?.url;

    const height = useMemo(() => {
      return [
        calcResponsiveHeight({
          maxHeight: section?.mobile?.maxHeight,
          viewport: 768,
        }),
        calcResponsiveHeight({
          maxHeight: section?.desktop?.maxHeight,
          viewport: 1280,
        }),
      ];
    }, [section?.mobile?.maxHeight, section?.desktop?.maxHeight]);

    return (
      <Section section={section} suppressHydrationWarning>
        <Link
          data-comp={Hero.displayName}
          href={clickable ? cta?.url : null}
          newTab={cta?.newTab}
          aria-label={clickable && button?.hideButtons ? cta?.text : ''}
        >
          <Container
            sx={{
              ...theme.hero,
              height,
              minHeight: [
                `${section?.mobile?.minHeight}px`,
                `${section?.desktop?.minHeight}px`,
              ],
              maxHeight: [
                `${section?.mobile?.maxHeight}px`,
                `${section?.desktop?.maxHeight}px`,
              ],
              cursor: clickable ? 'pointer' : 'auto',
            }}
          >
            <Image image={image} />

            <Box
              sx={{
                display: [
                  placement === 'relative' ? 'none' : 'block',
                  placementDt === 'relative' ? 'none' : 'block',
                ],
              }}
            >
              <Content content={content} clickable={clickable} />
            </Box>
          </Container>
        </Link>
        {/* Show if content is placed below the hero image. */}

        <Box
          sx={{
            display: [
              placement === 'relative' ? 'block' : 'none',
              placementDt === 'relative' ? 'block' : 'none',
            ],
          }}
        >
          <ContentBelow bgColor={section?.bgColor} content={content} />
        </Box>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Hero.displayName = 'Hero';
Hero.Schema = Schema;
