import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.scroll = {
      height: 'calc(var(--viewport-height) - 32px)',
      overflowY: 'scroll',
      webkitOverflowScrolling: 'touch',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    };

    this.grid = {
      gridTemplateColumns: '1fr',
      gridGap: 0,
      pl: 0,
    };

    this.item = (isActive) => ({
      link: {
        display: 'flex',
        px: 11,
        py: 6,
        borderBottom: (t) => `1px solid ${t.colors.gray}`,
        ':hover': {
          p: {
            textDecoration: 'underline',
            textDecorationThickness: '1px',
            textUnderlineOffset: '1px',
          },
        },
      },

      picture: {
        width: '40px',
        mr: 10,
        transition: 'all 0.15s ease-in-out',
      },

      label: {
        variant: 'text.lg',
        pt: 5,
        flex: 1,
        textDecoration: isActive ? 'underline' : 'none',
        textDecorationThickness: '1px',
        textUnderlineOffset: '1px',
        transition: 'all 0.15s ease-in-out',
      },
    });
  })()
);
