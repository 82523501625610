import { useMemo } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import { Box } from 'theme-ui';
import { Link, Picture } from '@snippets';
import { useMenuDrawer } from '../useMenuDrawer';

export function NavMenuLink({ theme, item }) {
  const { link } = item;
  const [, { closeMenuDrawer }] = useMenuDrawer();
  const { product } = useProductByHandle({
    handle:
      item.product?.handle || 'hopsulator-slim-gold-leopard-12oz-slim-cans',
  });

  const firstImage = useMemo(() => {
    return product?.media?.find((item) => {
      return item.mediaContentType === 'IMAGE';
    })?.image;
  }, [product?.id]);

  return (
    <Box sx={theme.navMenuLink} data-comp={NavMenuLink.displayName}>
      <Picture
        alt={link?.text}
        images={[
          {
            src: firstImage?.originalSrc,
            width: 64,
            ratio: 1,
          },
        ]}
        sx={theme.navMenuLinkImage}
      />
      <Link
        href={link?.url}
        newTab={link?.newTab}
        aria-label={link?.text}
        onClick={closeMenuDrawer}
        sx={theme.link}
      >
        {link?.text}
      </Link>
    </Box>
  );
}

NavMenuLink.displayName = 'NavMenuLink';
