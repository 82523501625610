import { Flex } from 'theme-ui';

import { Link, Markdown } from '@snippets';

import { useSettings } from '@backpackjs/storefront';
import { convertAlignToFlex } from '@utils';
import { themed } from './ReferLink.theme';
import { useMenuSidebar } from '../useMenuSidebar';

export const ReferLink = themed(({ theme }) => {
  const [{ }, { closeMenuSidebar }] = useMenuSidebar();
  const settings = useSettings();
  const mobileSettings = settings?.header?.refer?.mobile;
  const { linkText, background, color, link, textAlign, enabled } = {
    ...mobileSettings,
  };

  if (!enabled) return null;

  return (
    <Flex
      data-comp={ReferLink.displayName}
      sx={{
        ...theme.wrapper,
        bg: background,
        justifyContent: convertAlignToFlex(textAlign),
      }}
    >
      <Link
        href={link.url}
        newTab={link.newTab}
        onClick={closeMenuSidebar}
        sx={theme.link}
      >
        <Markdown
          text={linkText || link.text}
          textSx={{ ...theme.text, color }}
        />
      </Link>
    </Flex>
  );
});

ReferLink.displayName = 'MenuSidebarReferLink';
