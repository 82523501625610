import { useState, forwardRef } from 'react';
import { Grid } from 'theme-ui';

import { OkendoStarsIcon } from './OkendoStarsIcon';

export const OkendoStarsIcons = forwardRef(
  (
    {
      averageScore,
      emptyColor = 'beige',
      fullColor = 'red',
      onClickedStar = () => {},
      totalReviews,
      width = '16px',
      ...props
    },
    ref
  ) => {
    if (typeof averageScore === 'undefined') return null;

    return (
      <Grid
        data-comp={OkendoStarsIcons.displayName}
        ref={ref}
        variant="flex.row.start.center"
        {...props}
        sx={{
          gridTemplateColumns: 'repeat(5, 10px)',
          columnGap: '3px',
          ...props.sx,
        }}
      >
        {/* render each star icon */}
        {[...new Array(5)].map((_, index) => (
          <OkendoStarsIcon
            averageScore={averageScore}
            emptyColor={emptyColor}
            fullColor={fullColor}
            index={index}
            key={averageScore + index}
            onClickedStar={onClickedStar}
            totalReviews={totalReviews}
            width={width}
          />
        ))}
      </Grid>
    );
  }
);

OkendoStarsIcons.displayName = 'OkendoStarsIcons';
