import { useRef, useState, useEffect, useMemo } from 'react';
import { Button, Heading } from 'theme-ui';

import { Markdown, Svg } from '@snippets';

export const Accordion = ({ accordion, theme, forceActiveState }) => {
  const [headingHeight, setHeadingHeight] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [markdownLoaded, setMarkdownLoaded] = useState(false);
  const [isActive, setIsActive] = useState(forceActiveState);
  const headingRef = useRef();
  const bodyRef = useRef();

  const getMaxHeightValue = useMemo(() => {
    if (isActive) {
      return headingHeight + bodyHeight;
    } else {
      return headingHeight;
    }
  }, [headingHeight, bodyHeight, isActive]);

  const getMarginBottomValue = (node) => {
    if (!window) return 0;
    return parseInt(
      window.getComputedStyle(node)?.marginBottom.replace('px', '')
    );
  };

  const setHeightValues = () => {
    setHeadingHeight(
      headingRef.current?.clientHeight +
        getMarginBottomValue(headingRef.current)
    );
    setBodyHeight(bodyRef.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', setHeightValues);
    return () => {
      window.removeEventListener('resize', setHeightValues);
    };
  }, []);

  useEffect(() => {
    setHeightValues();
  }, [isActive, markdownLoaded]);

  return (
    <Button
      variant="plain"
      onClick={() => setIsActive(!isActive)}
      sx={{
        ...theme.accordion(isActive),
        maxHeight: getMaxHeightValue,
      }}
    >
      <Heading
        ref={headingRef}
        variant="h5"
        sx={{
          ...theme.accordionHeading(isActive),
          ...(() => {
            if (isActive) return {};
            return {
              backgroundColor: accordion.headingBgColor || 'mediumLightGray',
              color: accordion.headingTextColor || 'black',
            };
          })(),
        }}
      >
        {accordion.heading}
        <Svg
          alt="chevron"
          viewBox="0 0 16 16"
          src="/svgs/chevron/down.svg#down"
          sx={{
            ...theme.accordionIcon(isActive),
            color: accordion.headingTextColor || isActive ? 'white' : 'black',
          }}
        />
      </Heading>
      <Markdown
        ref={bodyRef}
        text={accordion.body}
        textSx={{
          m: 0,
        }}
        sx={{
          ...theme.accordionBody(isActive),
        }}
        setIsLoaded={setMarkdownLoaded}
      />
    </Button>
  );
};

Accordion.displayName = 'Accordion';
