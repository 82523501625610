import { useMemo } from 'react';
import { Box, Flex, Heading, Grid } from 'theme-ui';

import { Markdown } from '@snippets';
import { convertAlignToFlex, parseContentPosition } from '@utils/common';
import { space } from '@theme/theme.space';

import { themed } from './Content.theme';
import { CTA } from './CTA';

export const Content = themed(
  ({ theme, content, isContentBelow, clickable, ...props }) => {
    const { text, button, settings } = content;

    const color = [
      text?.color || 'currentColor',
      text?.colorDt || 'currentColor',
    ];

    const textAlign = [settings?.alignment, settings?.alignmentDt];
    const flexAlign = textAlign.map((align) => convertAlignToFlex(align));

    // Mobile content position
    const posMb = useMemo(
      () => parseContentPosition(settings?.position),
      [settings?.position]
    );

    // Desktop content position
    const posDt = useMemo(
      () => parseContentPosition(settings?.positionDt),
      [settings?.positionDt]
    );

    const calculatedMaxWidth = useMemo(() => {
      return settings?.maxWidthDt
        ? [
            settings.maxWidth + space[12] * 2,
            settings.maxWidthDt + space[15] * 2,
          ]
        : 'unset';
    }, [settings]);

    return (
      <Flex
        data-comp={Content.displayName}
        sx={{
          ...theme.contentWrapper,
          alignItems: [posMb.alignItems, posDt.alignItems],
          justifyContent: [posMb.justifyContent, posDt.justifyContent],
          ...props.sx,
        }}
      >
        <Box
          sx={{
            ...theme.content,
            maxWidth: calculatedMaxWidth,
            mx: isContentBelow ? 0 : [settings?.margin, settings?.marginDt],
            py: isContentBelow ? [10, 12] : [15, 17],
          }}
        >
          {text?.superheading && (
            <Heading
              as="h6"
              sx={{
                ...theme.superheading,
                textAlign,
                color,
              }}
            >
              {text?.superheading}
            </Heading>
          )}

          <Markdown
            text={text?.heading}
            textSx={{
              ...theme.heading(text?.headingOption),
              textAlign,
              color,
            }}
            sx={{
              mb: text?.subheading ? [4, 6] : 0,
            }}
          />

          {text?.subheading && (
            <Markdown
              text={text?.subheading}
              textSx={{
                ...theme.subheading,
                textAlign,
                color,
              }}
            />
          )}

          {!button?.hideButtons && button?.buttons?.length > 0 && (
            <Grid
              sx={{
                ...theme.buttons,
                justifyContent: flexAlign,
                '@media only screen and (max-width: 479px)': {
                  alignItems: flexAlign,
                },
              }}
            >
              {button?.buttons?.slice(0, 2).map((item, index) => {
                return (
                  <CTA
                    key={index}
                    index={index}
                    desktop={item?.styleDt}
                    mobile={item?.style}
                    newTab={item?.cta?.newTab}
                    text={item?.cta?.text}
                    url={item?.cta?.url}
                    clickable={clickable && !isContentBelow}
                  />
                );
              })}
            </Grid>
          )}
        </Box>
      </Flex>
    );
  }
);

Content.displayName = 'Content';
