import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.row.between.center',
      flexWrap: 'wrap',
      p: 10,
      width: '100%',
      minHeight: '146px',
      position: 'relative',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'gray',
    };

    this.image = {
      width: '105px',
      height: '105px',
    };

    this.details = {
      variant: 'flex.column.start.between',
      flex: 1,
      ml: 10,
      minHeight: '98px',
    };

    this.top = {
      variant: 'flex.row.between',
      width: '100%',
    };

    this.titleWrapper = {
      variant: 'flex.column.start',
      mb: 4,
    };

    this.title = {
      variant: 'text.h5',
      fontSize: 6,
      mb: 2,
    };

    this.variantTitle = {
      variant: 'text.sm',
      fontSize: 1,
      color: 'mediumDarkGray',
    };

    this.closeButton = {
      mt: '3px',
      ml: 4,
    };

    this.closeIcon = {
      width: '14px',
      height: '14px',
    };

    this.bottom = {
      variant: 'flex.row.between.center',
      width: '100%',
    };

    this.deleting = {
      variant: 'flex.row.between.center',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bg: 'rgba(255,255,255,0.5)',

      spinner: {
        width: '30px',
        color: 'mediumDarkGray',
        m: 'auto',
      },
    };
  })()
);
