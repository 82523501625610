import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      '.swiper-container': {
        width: '100%',
      },
    };

    this.heading = {
      variant: 'text.label.1',
      px: 8,
      mb: 4,
    };

    this.product = {
      cursor: 'pointer',
      img: {
        transform: 'scale(1.001)',
        transition: 'transform 0.2s ease-in-out',
      },
      ':hover': {
        img: {
          transform: 'scale(1.04)',
        },
      },

      picture: {
        width: '100%',
      },

      title: {
        variant: 'text.h5',
        mt: 4,
      },
    };

    this.skeleton = {
      variant: 'flex.row.center.center',
      width: '100%',
      height: '100%',
      bg: 'lightGray',

      spinner: {
        width: '30px',
        color: 'gray',
      },
    };
  })()
);
