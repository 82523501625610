import { useMemo } from 'react';
import { useSelectedVariant } from '@hooks';
import { ProductUpsellsList, Section } from '@snippets';
import { withInView } from '@utils';
import { Schema } from './ProductUpsells.schema';

export const ProductUpsells = withInView(
  ({ cms }) => {
    const { heading, products, section } = cms;
    const [selectedVariant] = useSelectedVariant();

    const upsellProducts = useMemo(() => {
      const tags = selectedVariant?.product?.tags;
      const newUpsellHandles = tags?.reduce((carry, tag) => {
        if (tag.indexOf('upsell::') > -1) {
          const [, handle, order] = tag.split('::');

          return [
            ...carry,
            {
              product: {
                handle,
                order,
              },
            },
          ].sort((a, b) => {
            return a?.product?.order - b?.product?.order;
          });
        }
        return carry;
      }, []);

      if (newUpsellHandles?.length) {
        return newUpsellHandles;
      }
      return products;
    }, [selectedVariant?.product?.tags]);

    if (!upsellProducts?.length) return null;

    return (
      <Section section={section}>
        <ProductUpsellsList heading={heading} products={upsellProducts} />
      </Section>
    );
  },
  { triggerOnce: true }
);

ProductUpsells.displayName = 'ProductUpsells';
ProductUpsells.Schema = Schema;
