import { Container, Box } from 'theme-ui';

import { ProductSwiper } from '@snippets';
import { themed } from './Slider.theme';

export const Slider = themed(
  ({ theme, products, loop = true, swiper, setSwiper, onItemClick }) => {
    const count = products?.length >= 4;

    return (
      <Container data-comp={Slider.displayName} sx={theme.wrapper}>
        <Box sx={theme.swiper}>
          <ProductSwiper
            isProductRow
            isProductRecs
            hideAddToCart={false}
            products={products}
            count={count}
            loop={loop}
            swiper={swiper}
            setSwiper={setSwiper}
            sx={theme.slides}
            onItemClick={onItemClick}
          />
        </Box>
      </Container>
    );
  }
);

Slider.displayName = 'Slider';
