import { useGotoRecoilSnapshot } from 'recoil'; // todo expose on @backpackjs/storefront
import store, { useRecoilCallback, useRecoilValue } from '@store';

export const useModal = () => {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const modal = useRecoilValue(store.modal);

  const closeModal = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.modal, null))
        .map(({ set }) => set(store.overlay, false));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  const openModal = useRecoilCallback(
    ({ snapshot }) =>
      async (modalChildren, fromSidebar) => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot.map(({ set }) => {
            set(store.modal, modalChildren);
            set(store.overlay, true);
            if (fromSidebar) {
              set(store.sidebar, false);
            }
          });

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  return [
    // state
    {
      modal,
    },
    // actions
    {
      closeModal,
      openModal,
    },
  ];
};
