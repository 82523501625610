import { useMemo, useEffect, useState } from 'react';
import { useCart, useCartRemoveDiscount } from '@backpackjs/storefront';

export const useCartRejectDiscount = () => {
  const cart = useCart();
  const { cartRemoveDiscount } = useCartRemoveDiscount();
  const [showDiscountInput, setShowDiscountInput] = useState(true);

  const discountCodes = useMemo(() => {
    return (cart?.discountCodes || []).reduce((acc, discount) => {
      if (
        discount?.applicable &&
        discount?.code?.toLowerCase()?.startsWith('app')
      ) {
        return [...acc, discount.code];
      }
      return acc;
    }, []);
  }, [cart?.updatedAt]);

  const removeDiscounts = async () => {
    setShowDiscountInput(false);
    await cartRemoveDiscount();
    setShowDiscountInput(true);
  };

  useEffect(() => {
    if (discountCodes?.length) {
      removeDiscounts();
    }
  }, [discountCodes]);

  return { showDiscountInput };
};
