import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.itemLink = {
      width: '100%',
    };

    this.picture = {};

    this.itemTitle = {
      fontSize: '13px',
      lineHeight: '20px',
      textAlign: 'center',
      mt: 2,
      textUnderlineOffset: '2px',
      textDecorationThickness: '1px',
    };

    this.button = {
      width: '100%',
      textAlign: 'left',
      position: 'relative',
      height: '52px',
      p: 8,
      borderTop: (t) => `1px solid ${t.colors.gray}`,
      display: 'flex',
      alignItems: 'center',
      borderBottomStyle: 'none',
      borderBottomWidth: '1px',
      borderBottomColor: 'gray',
    };

    this.title = {
      variant: 'text.label.1',
      display: 'inline-block',
      mr: 4,
    };

    this.selected = {
      color: 'mediumDarkGray',
      variant: 'text.base',
      fontSize: [3, 4],
      whiteSpace: 'nowrap',
    };

    this.icon = {
      position: 'absolute',
      right: 8,
      top: '50%',
      width: '24px',
      height: '24px',
      transform: 'translateY(-50%)',
    };

    this.sticky = {
      top: ['var(--header-height)'],
      position: 'sticky',
      bg: 'white',
      zIndex: 2,
      gridArea: 'sidebar',
    };
  })()
);
