import { Box, Flex, Heading, Paragraph } from 'theme-ui';

import { Accordions, Accordion } from '@snippets';

import { CollectionCategoriesDesktopItem } from './CollectionCategoriesDesktopItem';
import { themed } from './CollectionCategoriesDesktop.theme';

export const CollectionCategoriesDesktop = themed(
  ({
    categories,
    name,
    collectionHandle,
    isSuperCollection,
    activeCategory,
    collapsed,
    theme,
  }) => {
    return (
      <Accordions
        data-comp={CollectionCategoriesDesktop.displayName}
        textColor="text"
        borderColor="gray"
        iconColor="text"
        minHeight="52px"
        maxHeight="800px"
        oneOpenAtATime={false}
        sx={{ ...theme.sticky, display: ['none', 'grid'] }}
      >
        <Accordion
          collapsed={collapsed}
          sx={{ borderBottomStyle: isSuperCollection ? 'solid' : 'none' }}
        >
          <Accordion.Header>
            <Flex variant="flex.row.start.center">
              <Heading as="h5" sx={theme.accordionHeader.heading}>
                {name}
              </Heading>

              <Paragraph sx={theme.accordionHeader.selected}>
                {activeCategory}
              </Paragraph>
            </Flex>
          </Accordion.Header>

          <Accordion.Body>
            {({ isOpen }) =>
              categories.map((item, index) => {
                return (
                  <Box key={index} as="li">
                    <CollectionCategoriesDesktopItem
                      item={item}
                      collectionHandle={collectionHandle}
                      tabIndex={isOpen ? '0' : '-1'}
                      aria-hidden={isOpen ? 'false' : 'true'}
                    />
                  </Box>
                );
              })
            }
          </Accordion.Body>
        </Accordion>
      </Accordions>
    );
  }
);

CollectionCategoriesDesktop.displayName = 'CollectionCategoriesDesktop';
