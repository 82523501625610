/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useState, useEffect } from 'react';
import { Flex } from 'theme-ui';

import { Link } from '@snippets';
import { isBrowser } from '@utils';

import { themed } from './AccessibilityBar.theme';

export const AccessibilityBar = themed(({ theme }) => {
  const [mounted, setMounted] = useState(false);
  const [injected, setInjected] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const cookies = isBrowser && document.cookie;

  const enableUsableNetAssistiveFn =
    isBrowser && window.enableUsableNetAssistive;
  const usableNetReady = typeof enableUsableNetAssistiveFn === 'function';

  const enableUsableNetAssistive = useCallback(() => {
    try {
      if (typeof window.enableUsableNetAssistive === 'function') {
        window.enableUsableNetAssistive();
      } else {
        console.error('UsableNet script is not injected');
      }
    } catch (error) {
      console.error(error.message);
    }
  }, []);

  const disableUsableNetAssistive = useCallback(() => {
    document.cookie = 'UsableNetAssistive=';
  }, []);

  const injectScriptOnMount = useCallback(async () => {
    try {
      if (!mounted || injected || usableNetReady) return;

      const script = document.createElement('script');
      script.src = 'https://a40.usablenet.com/pt/c/brumate/cookiestorestart';
      script.async = true;
      document.head.appendChild(script);
      setInjected(true);
    } catch (error) {
      console.error(error.message);
    }
  }, [injected, mounted, usableNetReady]);

  const setEnabledOnCookieChange = useCallback(() => {
    setEnabled(usableNetReady && cookies?.indexOf('UsableNetAssistive=1') > -1);
  }, [cookies, usableNetReady]);

  // Set mounted on window load
  useEffect(() => {
    const onLoad = () => setMounted(true);
    window.addEventListener('load', () => {
      onLoad();
      window.removeEventListener('load', onLoad);
    });
    return () => {
      window.removeEventListener('load', onLoad);
    };
  }, []);

  useEffect(() => {
    injectScriptOnMount();
  }, [mounted]);

  useEffect(() => {
    setEnabledOnCookieChange();
  }, [cookies, usableNetReady]);

  return (
    <Flex data-comp={AccessibilityBar.displayName} sx={theme.bar}>
      {usableNetReady && (
        <Link
          href="#"
          className="UsableNetAssistive"
          sx={theme.link}
          onClick={
            enabled ? disableUsableNetAssistive : enableUsableNetAssistive
          }
        >
          {enabled ? 'Exit Accessibility Mode' : 'Enable Accessibility'}
        </Link>
      )}
    </Flex>
  );
});

AccessibilityBar.displayName = 'AccessibilityBar';
