import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.navMenu = {
      px: 14,
      pr: [0, null, null, 14],
      gridTemplateColumns: '275px 1fr',
      columnGap: 14,
    };

    this.navMenuHeading = {
      fontSize: 3,
      fontWeight: 500,
    };

    this.navMenuLink = {
      minHeight: '32px',
      display: 'inline-flex',
      alignItems: 'center',
    };

    this.navMenuLinkImage = {
      width: '32px',
      height: '32px',
      mr: 6,
    };

    this.link = {
      fontSize: 3,
      position: 'relative',
      display: 'inline-flex',
      alignItems: 'center',
      mt: 1,
      pb: 1,
      backgroundImage: 'linear-gradient(currentColor, currentColor)',
      backgroundPosition: '0% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 1px',
      transition: 'background-size 0.2s ease-in-out',
      ':hover, :focus': {
        backgroundSize: '100% 1px',
      },
    };

    this.arrowIcon = {
      width: '14px',
      height: '14px',
      ml: 4,
    };
  })()
);
