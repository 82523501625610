import { useMemo } from 'react';
import { Box } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import { CollectionCategoriesMobile } from './Mobile';
import { CollectionCategoriesDesktop } from './Desktop';
import { themed } from './CollectionCategories.theme';

export const CollectionCategories = themed(
  ({
    categories,
    name = 'Size',
    collapsed = true,
    isSuperCollection = false,
  }) => {
    const router = useRouter();
    const collectionHandle = router.query?.handle;

    const activeCategory = useMemo(() => {
      if (!collectionHandle) return null;
      const active = categories?.find(
        (category) => category.link?.url.split('/').pop() === collectionHandle
      );
      return active?.link?.text || null;
    }, [collectionHandle, categories]);

    return categories?.length ? (
      <Box
        data-comp={CollectionCategories.displayName}
        sx={{ display: 'contents' }}
      >
        <CollectionCategoriesMobile
          categories={categories}
          name={name}
          collectionHandle={collectionHandle}
          activeCategory={activeCategory}
          isSuperCollection={isSuperCollection}
        />

        <CollectionCategoriesDesktop
          categories={categories}
          name={name}
          collectionHandle={collectionHandle}
          activeCategory={activeCategory}
          isSuperCollection={isSuperCollection}
          collapsed={collapsed}
        />
      </Box>
    ) : null;
  }
);

CollectionCategories.displayName = 'CollectionCategories';
