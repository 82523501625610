import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      variant: 'text.h2',
      mb: [12, 14],
    };

    this.form = {
      width: '100%',
      gridTemplateColumns: 'repeat(2, 1fr)',
      color: 'text',
      gap: 5,
    };

    this.submitWrapper = {
      flexDirection: 'column',
      gap: 8,
      gridColumn: '1 / span 2',
    };

    this.recaptcha = {
      my: 6,
      width: '100%',
    };

    this.submit = {
      width: 'auto',
      maxWidth: '200px',
      mt: 10,
    };

    this.errors = {
      flexDirection: 'column',
      gap: 2,
    };

    this.error = {
      color: 'red',
    };
  })()
);
