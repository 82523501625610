import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      position: 'relative',
    };

    this.imageLink = {
      ...this.image,
      ':hover': {
        img: {
          pointerEvents: 'none',
          transform: 'scale3d(1.03, 1.03, 1.03)',
        },
      },
      img: {
        transform: 'scale3d(1.01, 1.01, 1.01)',
        transition: 'transform 0.3s ease-in-out',
      },
    };

    this.button = {
      position: 'absolute',
      width: 'fit-content',
      bottom: [10, 30],
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
      pointerEvents: 'auto',
    };
  })()
);
