import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      gridArea: 'social',
      width: ['100%', '175px'],
      pb: [12, 0],
      px: [8, 0],
      borderBottomStyle: ['solid', 'none'],
      borderBottomWidth: '1px',
      borderBottomColor: 'mediumDarkGray',
    };

    this.logo = {
      width: '175px',
      color: 'background',
      mb: 10,
      display: ['none', 'block'],
    };

    this.heading = {
      variant: 'text.label.1',
      mb: 8,
    };

    this.socialLinks = {
      mb: [11, '28px'],
    };
  })()
);
