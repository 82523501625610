import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.contentBelow = {
      position: 'relative',
    };

    this.contentWrapper = {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      pointerEvents: 'none',
      position: 'relative',
    };

    this.content = {
      height: 'fit-content',
      width: '100%',
      px: 12,
      pointerEvents: 'auto',
      '@media only screen and (max-width: 479px)': {
        px: 0,
      },
    };

    this.superheading = {
      variant: 'text.label.1',
      display: ['none', 'block'],
    };

    this.heading = (fontFamily) => ({
      mt: 5,
      variant: 'text.h2',
      fontFamily: fontFamily === 'text.h1.2' ? 'heading' : 'Reckless Neue',
    });

    this.subheading = {
      variant: 'text.lg',
    };

    this.buttons = {
      mt: [11, 12],
      gridAutoFlow: 'column',
      gridGap: 9,
      '@media only screen and (max-width: 479px)': {
        gridAutoFlow: 'row',
        gridGap: 6,
      },
    };
  })()
);
