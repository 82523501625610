import { useEffect, useState } from 'react';
import Script from 'next/script';
import { Box, Container, Flex, Heading } from 'theme-ui';

import { Section } from '@snippets';
import { Content } from './Content';
import { Image } from './Image';

import { themed } from './MalomoTracking.theme';
import { Schema } from './MalomoTracking.schema';

export const MalomoTracking = themed(({ cms, theme }) => {
  const { section, content, image, media } = cms;
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [init, setInit] = useState(false);
  const [orderFound, setOrderFound] = useState(false);

  const mediaLeft = media?.locationDt === 'left'; // mobile
  const mediaTop = media?.location === 'top'; // desktop

  useEffect(() => {
    const checkForMalomoScript = setInterval(() => {
      if (typeof window.Malomo === 'function') {
        setScriptLoaded(true);
        clearInterval(checkForMalomoScript);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (!scriptLoaded || init) return;

    const { Malomo } = window;
    const apiKey = `${process.env.NEXT_PUBLIC_MALOMO_API_KEY}`;
    const mountPoint = '#shipment-tracker';
    const malomo = Malomo(apiKey);
    const elements = malomo.elements();

    window.addEventListener('malomo-order.found', () => {
      setOrderFound(true);
    });

    setInit(true);

    malomo
      .fetchOrder()
      .then((order) => {
        if (order) {
          window.dispatchEvent(new Event('malomo-order.found'));
        }
        return elements
          .create('shipmentTracker', {
            order,
            carrierInfo: {
              linkToCarrier: true,
            },
          })
          .mount(mountPoint);
      })
      .catch(() => {
        return elements
          .create('orderLookup', {
            callback: (resp) => {
              const url = new URL(document.URL);

              url.searchParams.append('_m_id', resp.body.id);

              window.location.href = url;
            },
          })
          .mount(mountPoint);
      });
  }, [scriptLoaded]);

  return (
    <Section section={section}>
      <Script
        id="malomo-script"
        type="text/javascript"
        src="https://js.gomalomo.com/v2/"
      />
      <Container
        sx={{
          ...theme.grid,
          gridTemplateAreas: [
            mediaTop ? '"media" "content"' : '"content" "media"',
            mediaLeft ? '"media content"' : '"content media"',
          ],
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: ['center', 'flex-start'],
            width: ['100%', null, '500px'],
            mx: 'auto',
            height: '100%',
            py: orderFound ? [12, 0] : [12, 40],
            px: [10, 14],
          }}
        >
          {!orderFound && (
            <Heading
              as="h3"
              variant="text.h3"
              sx={{
                mb: '1.5rem',
              }}
            >
              Track Your Order
            </Heading>
          )}

          {scriptLoaded && (
            <Box
              id="shipment-tracker"
              sx={{
                '.malomo-order-lookup-error-message': {
                  color: 'red',
                },
                '.malomo-shipment-tracker-latest-activity, .malomo-shipment-tracker-most-recent-event':
                  {
                    variant: 'text.label.1',
                  },
                '.malomo-shipment-tracker-event': {
                  py: 6,
                },
                '.malomo-shipment-tracker-event-location-city + .malomo-shipment-tracker-event-location-state::before':
                  {
                    content: '", "',
                  },
                '.malomo-shipment-tracker-event-location': {
                  variant: 'text.label.1',
                  textTransform: 'capitalize',
                  ml: 6,
                },
                '.malomo-shipment-tracker-header': {
                  textAlign: ['center', 'left'],
                },
                '.malomo-shipment-tracker-status-message': {
                  variant: 'text.h2',
                  fontFamily: 'heading',
                  fontWeight: 600,
                  textTransform: 'capitalize',
                  mb: [8, 4],
                },
                '.malomo-order-lookup-number-label': {
                  display: 'block',
                  variant: 'text.label.2',
                  position: 'absolute',
                  transformOrigin: 'top left',
                  transform: 'translate(0px, 4px) ',
                  width: 'auto',
                  px: 10,
                  color: 'text',
                  pointerEvents: 'none',
                },
                '.malomo-order-lookup-number-input': {
                  display: 'block',
                  variant: 'text.lg',
                  borderRadius: 50,
                  height: '46px',
                  border: (t) => `1px solid ${t.colors.mediumGray}`,
                  width: '100%',
                  minWidth: ['250px', '350px'],
                  px: 10,
                  mt: 4,
                  pt: 8,
                  pb: 2,
                  color: 'text',
                },

                '.malomo-order-lookup-customer-email-label': {
                  display: 'block',
                  variant: 'text.label.2',
                  position: 'absolute',
                  transformOrigin: 'top left',
                  transform: 'translate(0px, 4px) ',
                  width: 'auto',
                  px: 10,
                  color: 'text',
                  pointerEvents: 'none',
                },
                '.malomo-order-lookup-customer-email-input': {
                  display: 'block',
                  variant: 'text.lg',
                  borderRadius: 50,
                  height: '46px',
                  border: (t) => `1px solid ${t.colors.mediumGray}`,
                  width: '100%',
                  minWidth: ['250px', '350px'],
                  px: 10,
                  mt: 4,
                  pt: 8,
                  pb: 2,
                  color: 'text',
                },

                '.malomo-order-lookup-section-separator': {
                  display: 'flex',
                  variant: 'text.h5',
                  mx: 'auto',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  my: 8,
                },

                '.malomo-order-lookup-tracking-number-label': {
                  display: 'block',
                  variant: 'text.label.2',
                  position: 'absolute',
                  transformOrigin: 'top left',
                  transform: 'translate(0px, 4px) ',
                  width: 'auto',
                  px: 10,
                  color: 'text',
                  pointerEvents: 'none',
                },
                '.malomo-order-lookup-tracking-number-input': {
                  display: 'block',
                  variant: 'text.lg',
                  borderRadius: 50,
                  height: '46px',
                  border: (t) => `1px solid ${t.colors.mediumGray}`,
                  width: '100%',
                  minWidth: ['250px', '350px'],
                  px: 10,
                  mt: 4,
                  pt: 8,
                  pb: 2,
                  color: 'text',
                },

                '.malomo-order-lookup-submit-button': {
                  variant: 'buttons.primary',
                  textTransform: 'capitalize',
                  mt: 12,
                  width: '100%',
                  mx: 'auto',
                  px: 10,
                },
                '.malomo-shipment-tracker-delivered-message': {
                  variant: 'text.label.2',
                },
                '.malomo-shipment-tracker-delivered-message a': {
                  textDecoration: 'underline',
                },
                '.malomo-shipment-tracker-delivered-message-close': {
                  cursor: 'pointer',
                },

                '.malomo-shipment-tracker-delivered-message-header': {
                  alignItems: 'center',
                  display: 'flex',
                },

                ' .malomo-shipment-tracker-delivered-message-title': {
                  flex: 1,
                },

                '.malomo-shipment-tracker-events': {
                  height: '400px',
                  overflow: 'hidden',
                  overflowY: 'scroll',
                },

                '.malomo-shipment-tracker-events-backdrop': {
                  alignItems: 'center',
                  backgroundColor: ' rgba(0, 0, 0, 0.65)',
                  display: 'flex',
                  height: '100vh',
                  justifyContent: 'center',
                  position: 'fixed',
                  zIndex: 1,
                  left: 0,
                  top: 0,
                  width: '100%',
                },

                '.malomo-shipment-tracker-events-header': {
                  display: 'flex',
                  pt: 11,
                  pb: 6,
                },

                '.malomo-shipment-tracker-events-header-label': {
                  variant: 'text.h5',
                },

                ' .malomo-shipment-tracker-events-header-close': {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                },

                '.malomo-shipment-tracker-events-header-close-icon': {
                  cursor: 'pointer',
                  width: '20px',
                },

                '.malomo-shipment-tracker-events-modal': {
                  backgroundColor: ' #fff',
                  width: '600px',
                  borderRadius: '3px',
                  px: 11,
                  overflow: 'auto',
                },

                '.malomo-shipment-tracker-history-control': {
                  variant: 'buttons.primary',
                  textTransform: ['uppercase', 'capitalize'],
                  textDecoration: [null, 'underline'],
                  justifyContent: 'center',
                  mt: 6,
                  cursor: 'pointer',
                  width: '100%',
                  '@media only screen and (min-width: 767px)': {
                    fontSize: 1,
                    display: 'inline',
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'black',
                    p: 0,
                    width: 'auto',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                },

                '.malomo-shipment-tracker-carrier-info': {
                  display: 'flex',
                  justifyContent: ['center', 'flex-start'],
                  borderTop: [null, '1px solid #e3ebf6'],
                  pt: 4,
                  mx: '-24px',
                  px: 11,
                  mt: 8,
                  mb: '-12px',
                },

                '.malomo-shipment-tracker-carrier-info-tracking-code, .malomo-shipment-tracker-carrier-info-tracking-code-link':
                  {
                    variant: 'text.label.1',
                    textDecoration: 'underline',
                    letterSpacing: '0.5px',
                  },

                '.malomo-shipment-tracker-carrier-info-image': {
                  maxHeight: '20px',
                  maxWidth: '64px',
                  mr: '4px',
                },

                '.malomo-shipment-tracker-modal-open': {
                  overflow: 'hidden',
                },

                '.malomo-shipment-tracker-selector-items': {
                  justifyContent: ['center', 'flex-start'],
                  mb: '5px',
                  mt: 0,
                },

                '.malomo-shipment-tracker-selector-counter': {
                  variant: 'text.label.1',
                  mb: '5px',
                },

                '.malomo-shipment-tracker-return-actions': {
                  mt: '16px',
                },

                '.malomo-shipment-tracker-event-timestamp-date, .malomo-shipment-tracker-event-message':
                  {
                    variant: 'text.label.1',
                  },

                '.malomo-shipment-tracker-event-timestamp-time': {
                  variant: 'text.label.1',
                  color: 'rgba(18, 38, 63, .7)',
                  ml: 2,
                },
              }}
            />
          )}
        </Flex>
        <Box
          sx={{
            ...theme.media,
            minHeight: orderFound ? [null, '500px'] : null,
          }}
        >
          <Image image={image} />
          <Box
            sx={{
              ...theme.content,
              justifyContent: mediaLeft ? 'flex-start' : 'flex-end',
              ':before': {
                content: '""',
                float: 'left',
              },
            }}
          >
            <Content content={content} />
          </Box>
        </Box>
      </Container>
    </Section>
  );
});

MalomoTracking.displayName = 'MalomoTracking';
MalomoTracking.Schema = Schema;
