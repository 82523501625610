import { useCallback } from 'react';
import { useRouter } from '@backpackjs/storefront';
import store, {
  useGotoRecoilSnapshot,
  useRecoilValue,
  useRecoilCallback,
} from '@store';

export const useSidebar = () => {
  const router = useRouter();
  const sidebar = useRecoilValue(store.sidebar);
  const gotoSnapshot = useGotoRecoilSnapshot();

  const closeSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.sidebar, null))
        .map(({ set }) => set(store.overlay, false));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  const openSidebar = useRecoilCallback(({ snapshot }) => async (type) => {
    const release = snapshot.retain();

    if (!type) {
      console.warn(
        'A sidebar type not provided. Must be "cart", "search" or "colors"'
      );
      release();
      return;
    }

    try {
      const updatedState = snapshot.map(({ set }) => {
        set(store.sidebar, type || null);
        set(store.overlay, true);
        set(store.modal, null);
      });

      // update state
      gotoSnapshot(updatedState);

      // animateIn
      // eg — await animations.revealDrawer();
    } finally {
      release();
    }
  });

  const openCartSidebar = useCallback(() => {
    openSidebar('cart');
  }, []);

  const openSearchSidebar = useCallback(() => {
    openSidebar('search');
  }, []);

  const openColorsSidebar = useCallback(() => {
    openSidebar('colors');
  }, []);

  const closeSidebarToNavigate = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot.map(({ set }) => {
            set(store.sidebar, null);
            set(store.overlay, false);
            set(store.selectedItem, false);
            set(store.menuSidebar, false);
            set(store.modal, null);
          });

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  return [
    // state
    {
      sidebar,
      isCart: sidebar === 'cart',
      isSearch: sidebar === 'search',
      isColors: sidebar === 'colors',
    },
    // actions
    {
      closeSidebar,
      openSidebar,
      openCartSidebar,
      openSearchSidebar,
      openColorsSidebar,
      closeSidebarToNavigate,
    },
  ];
};
