import { color, section, button, align } from '@settings/common';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Two Tile',
    key: 'two-tile',
    fields: [
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 2 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'New Tile',
          description: '',
          alt: 'Tile image',
          image: {
            src: 'https://images.unsplash.com/photo-1518050227004-c4cb7104d79a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
          },
          cta: {
            text: '',
            url: '',
          },
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
          },
          {
            component: 'select',
            name: 'color',
            label: 'Text color',
            options: [{ label: 'Default', value: '' }, ...color.options],
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description:
              'Provide a brief description of the image for accessibility compliance',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            heading: 'New Releases',
            description: '',
            color: '',
            cta: {
              text: 'Bundles',
              url: '/collections/all',
            },
            alt: 'Tile image',
            image: {
              src: 'https://images.unsplash.com/photo-1518050227004-c4cb7104d79a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
            },
          },
          {
            heading: 'Best Sellers',
            description: '',
            alt: 'Tile image',
            color: '',
            image: {
              src: 'https://images.unsplash.com/photo-1485965120184-e220f721d03e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
            },
            cta: {
              text: 'Shop Now',
              url: '/collections/all',
            },
          },
        ],
      },
      {
        label: 'Heading Settings',
        name: 'header',
        description: 'Heading, text align, heading button',
        component: 'group',
        fields: [
          {
            name: 'heading',
            component: 'text',
            label: 'Heading',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop if button is in header",
            label: 'Heading alignment',
            options: align.options,
          },
          {
            name: 'ctas',
            label: 'Heading Button',
            component: 'group-list',
            description: 'Max of 1 button',
            itemProps: {
              label: '{{item.cta.text}}',
            },
            validate: {
              maxItems: 1,
            },
            defaultItem: {
              cta: {
                text: 'Shop All',
                url: '/collections/all',
              },
              style: button.value.secondary,
            },
            fields: [
              {
                name: 'cta',
                component: 'link',
                label: 'Button',
              },
              {
                name: 'style',
                label: 'Button style',
                component: 'select',
                options: button.options,
              },
            ],
          },
        ],
        defaultValue: {
          heading: '',
          textAlign: align.value.center,
          ctas: [],
        },
      },
      {
        label: 'Tile Settings',
        name: 'tile',
        description: 'Image ratio, text position, text align, link style',
        component: 'group',
        fields: [
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'imageRatio',
            label: 'Image aspect ratio (mobile)',
            options: [
              { label: '3:4', value: 'portrait' },
              { label: '1:1', value: 'square' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'imageRatioDt',
            label: 'Image aspect ratio (desktop)',
            options: [
              { label: '3:4', value: 'portrait' },
              { label: '1:1', value: 'square' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'overlay',
            label: 'Image overlay',
            options: [
              { label: 'None', value: 'none' },
              { label: 'Dark', value: 'dark' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textPosition',
            label: 'Text position',
            options: [
              { label: 'Contained', value: 'absolute' },
              { label: 'Below', value: 'relative' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            label: 'Text alignment',
            options: align.options,
          },
          {
            name: 'ctaStyle',
            label: 'Link style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          imageRatio: 'square',
          imageRatioDt: 'square',
          overlay: 'none',
          textPosition: 'absolute',
          textAlign: align.value.left,
          ctaStyle: button.value.secondary,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.white,
          mobile: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
}
