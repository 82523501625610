import Script from 'next/script';

export function GorgiasChatWidget() {
  return (
    <>
      <style jsx>{`
        iframe#chat-button,
        iframe#chat-window,
        iframe#chat-campaigns {
          z-index: 40 !important;
        }
      `}</style>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            var HIDE_POWERED_BY_GORGIAS = true;
            // var HIDE_WIDGET_OUTSIDE_BUSINESS_HOURS = true; // Uncomment if needed
            !function(_) {
              _.GORGIAS_CHAT_APP_ID = "338";
              _.GORGIAS_CHAT_BASE_URL = "us-east1-898b.production.gorgias.chat";
              _.GORGIAS_API_BASE_URL = "config.gorgias.chat";
              var e = new XMLHttpRequest();
              e.open("GET", "https://config.gorgias.chat/applications/338", true);
              e.onload = function(t) {
                if (e.readyState === 4)
                  if (e.status === 200) {
                    var n = JSON.parse(e.responseText);
                    if (!n.application || !n.bundleVersion) throw new Error("Missing fields in the response body - https://config.gorgias.chat/applications/338");
                    _.GORGIAS_CHAT_APP = n.application;
                    _.GORGIAS_CHAT_BUNDLE_VERSION = n.bundleVersion;
                    if (n && n.texts) _.GORGIAS_CHAT_TEXTS = n.texts;
                    if (n && n.sspTexts) _.GORGIAS_CHAT_SELF_SERVICE_PORTAL_TEXTS = n.sspTexts;
                    if (!document.getElementById("gorgias-chat-container")) {
                      var o = document.createElement("div");
                      o.id = "gorgias-chat-container";
                      o.style = 'z-index:99;';
                      document.body.appendChild(o);
                      var r = document.createElement("script");
                      r.setAttribute("defer", true);
                      r.src = "https://client-builds.production.gorgias.chat/" + n.bundleVersion + "/static/js/main.js";
                      document.body.appendChild(r);
                    }
                  } else {
                    console.error("Failed request GET - https://config.gorgias.chat/applications/338");
                  }
              };
              e.onerror = function(_) {
                console.error(_);
              };
              e.send();
            }(window || {});
          `,
        }}
      />
    </>
  );
}
