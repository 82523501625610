import { Picture } from '@snippets';

import { themed } from './Image.theme';

export const Image = themed(({ theme, image }) => {
  if (!image?.imageDt?.src) return;

  return (
    <Picture
      data-comp={Image.displayName}
      alt={image?.alt}
      aboveTheFold={false}
      images={[
        {
          src: image?.image?.src,
          width: 768,
          ratio: 1.2,
        },
        {
          src: image?.imageDt?.src,
          width: 1024,
          ratio: 1.2,
        },
        {
          src: image?.imageDt?.src,
          width: 1280,
          ratio: 1.2,
        },
        {
          src: image?.imageDt?.src,
          width: 1440,
          ratio: 1.2,
        },
      ]}
      sx={theme.image}
      imageSx={{
        objectPosition: [image?.position, image?.positionDt],
      }}
    />
  );
});

Image.displayName = 'Image';
