import { useCustomerAuth } from '@backpackjs/storefront';
import { useEffect, useState, useMemo } from 'react';
import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './LoyaltyLionWelcome.schema';
import { Content } from './Content';
import { Image } from './Image';

export const LoyaltyLionWelcome = withInView(
  ({ cms }) => {
    const { authenticated, customer, customerInit } = useCustomerAuth();
    const [secSettings, setSecSettings] = useState(null);
    const { image, media, content } = useMemo(() => {
      return {
        image: secSettings?.image,
        media: secSettings?.media,
        content: secSettings?.content,
      };
    }, [customer?.id, secSettings]);
    const section = cms?.section;
    const mediaLeft = media?.locationDt === 'left'; // mobile
    const mediaTop = media?.location === 'top'; // desktop

    useEffect(() => {
      if (customerInit) {
        if (customer?.id) {
          setSecSettings(cms?.userSettings);
        } else {
          setSecSettings(cms?.guestSettings);
        }
      }
    }, [customerInit, authenticated]);

    if (!secSettings) return null;

    return (
      <Section data-comp={LoyaltyLionWelcome.displayName} section={section}>
        <div
          className={`flex items-center ${
            mediaTop ? 'flex-col' : 'flex-col-reverse'
          } ${mediaLeft ? 'md:flex-row' : 'md:flex-row-reverse'}`}
        >
          <div className="w-full md:w-[52%]">
            <Image image={image} media={media} />
          </div>
          <div className="w-full md:w-[48%]">
            <Content content={content} customer={customer} />
          </div>
        </div>
      </Section>
    );
  },
  { triggerOnce: true }
);

LoyaltyLionWelcome.displayName = 'LoyaltyLion Welcome';
LoyaltyLionWelcome.Schema = Schema;
