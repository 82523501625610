import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      variant: 'text.h2',
      fontSize: [10, 12],
      mb: [8, 11],
    };

    this.paragraph = {
      fontSize: ['13px', 6],
      lineHeight: ['normal', '24px'],
      fontWeight: 'body',
      mt: [-4, -7],
    };

    this.bodyWithoutHeading = {
      variant: 'text.base',
      fontSize: ['13px', 6], // [13px, 18px]
      lineHeight: ['normal', '24px'],
      mt: 0,
      py: 10,
      ul: {
        ml: 0,
      },
      'p:last-of-type': {
        mb: 0,
      },
    };

    this.bodyWithHeading = {
      ...this.bodyWithoutHeading,
    };

    this.onlyproductDiscount = {
      '.lion-reward-item--cart-discount-voucher': {
        display: 'none!important',
      },
    };

    this.onlycartDiscount = {
      '.lion-reward-item--product-discount-voucher,.lion-reward-item--product-cart':
        {
          display: 'none!important',
        },
    };

    this.slides = {
      '.swiper-slide': {
        opacity: 0.25,
      },
      '.swiper-slide-active, .swiper-slide-next, .swiper-slide-prev': {
        opacity: 1,
      },
    };
  })()
);
