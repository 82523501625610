import { section, align } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Markdown',
    key: 'markdown',
    fields: [
      {
        name: 'body',
        component: 'markdown',
        label: 'Body',
        defaultValue:
          'I am a text field with *italic* __bold__ and other powers...',
      },
      {
        label: 'Text Settings',
        name: 'text',
        description: 'Text align',
        component: 'group',
        fields: [
          {
            name: 'textAlign',
            label: 'Text align',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
            defaultValue: align.value.left,
          },
        ],
      },
      section,
    ],
  };
};
