import { Heading, Flex, Button } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './NestedSidebar.theme';

export const NestedSidebarHeader = themed(
  ({ theme, selectedItem, toggleNestedSidebar }) => {
    return (
      <Flex data-comp={NestedSidebarHeader.displayName} sx={theme.header}>
        <Button
          variant="buttons.plain"
          onClick={toggleNestedSidebar}
          sx={theme.header.backButton}
        >
          <Svg
            viewBox="0 0 24 24"
            src="/svgs/arrow-left.svg#arrow-left"
            sx={theme.header.icon}
          />
        </Button>

        <Heading as="h5" sx={theme.header.heading}>
          {selectedItem?.link?.text}
        </Heading>
      </Flex>
    );
  }
);

NestedSidebarHeader.displayName = 'NestedSidebarHeader';
