import { useState, useCallback } from 'react';
import { Box, Button } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';

import { Svg } from '@snippets';

import { themed } from './Navigation.theme';
import { useSliderParams } from './useSliderParams';

SwiperCore.use([A11y]);

export const Navigation = themed(
  ({
    theme,
    slider,
    entries,
    activeIndex,
    isLogo = true,
    setActiveIndex,
    customParams = {},
  }) => {
    if (entries.length == 0) return null;

    const logoCount = entries?.length;
    const defaultParams = useSliderParams({ isLogo, logoCount, customParams });

    const [navigationSwiper, setNavigationSwiper] = useState();

    const changeSlide = useCallback(
      (index) => {
        if (!slider) return;

        setActiveIndex(index);
        return slider.slideTo(activeIndex);
      },
      [slider]
    );

    return (
      <Box sx={theme.wrapper}>
        <Swiper onSwiper={setNavigationSwiper} {...defaultParams}>
          {entries.map((entry, index) => {
            if (!entry?.logo?.src) return null;
            const isActive = index === activeIndex;
            return (
              <SwiperSlide key={entry?.name}>
                <Box
                  aria-selected={isActive}
                  sx={{
                    ...theme.slide,
                    opacity: isActive ? 1 : 0.5,
                  }}
                >
                  <Button
                    variant="buttons.plain"
                    onClick={() => {
                      changeSlide(index);
                    }}
                  >
                    <Svg
                      src={entry?.logo?.src}
                      alt={entry?.name}
                      sx={{
                        ...theme.logo,
                        borderBottom: isActive ? '1px solid black' : 'none',
                      }}
                    />
                  </Button>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    );
  }
);

Navigation.displayName = 'Navigation';
