import { useCallback } from 'react';
import store, { useRecoilCallback, useGotoRecoilSnapshot } from '@store';

import {
  useCartAddItem,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';

import { useSelectedPlan } from '@hooks';
import { getEncodedId, isEncoded } from '@utils';

export const useAddToCart = ({ selectedVariant, product, rebuyWidget }) => {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const [selectedPlan] = useSelectedPlan({ selectedVariant });

  const { cartAddItem, ...addItemStatus } = useCartAddItem();
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: product?.handle,
    withQuantity: true,
  });

  const variantStorefrontId = isEncoded(inventory?.id)
    ? getEncodedId(selectedVariant?.storefrontId)
    : selectedVariant?.storefrontId;
  const variantInventory = inventory
    ? inventory?.variants?.[variantStorefrontId]
    : null;

  const isGiftCard =
    variantInventory?.product?.handle === 'brumate-e-gift-card' ||
    product?.handle === 'brumate-e-gift-card';

  const isSoldOut =
    !variantInventory ||
    (variantInventory.quantityAvailable <= 0 && !isGiftCard);

  const isPreOrder =
    isSoldOut && selectedVariant?.inventoryPolicy === 'CONTINUE';

  const toggleCartSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.modal, null))
        .map(({ set }) => set(store.overlay, true))
        .map(({ set }) => set(store.sidebar, 'cart'));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  // add oneTime and subs
  const addToCart = useCallback(
    async ({ callback, openCart } = { openCart: true }) => {
      if (!selectedVariant || (isSoldOut && !isPreOrder)) return;

      const item = {
        merchandiseId: selectedVariant.id,
        quantity: 1,
      };

      // adds shipscout attribute to collect data for abtest
      if (typeof window.ShipScoutLineItemProperty !== 'undefined') {
        item.attributes = [
          { key: '_sc', value: window.ShipScoutLineItemProperty },
        ];
      }

      // adds tracking attributes if rebuyWidget is present
      if (rebuyWidget) {
        const rebuyAttributes = [
          { key: '_attribution', value: 'PDP' },
          { key: '_source', value: 'Rebuy' },
          { key: '_widget', value: rebuyWidget },
        ];

        if (item.attributes) {
          item.attributes = [...item.attributes, ...rebuyAttributes];
        } else {
          item.attributes = rebuyAttributes;
        }
      }

      if (typeof window.ShipScoutTrackAddToCart === 'function') {
        window.ShipScoutTrackAddToCart();
      }
      // if selling plan is selected we the subscription
      if (selectedPlan) {
        item.sellingPlanId = selectedPlan.id;
      }

      const cart = await cartAddItem(item);

      if (typeof callback === 'function') {
        callback(cart);
      }

      if (openCart) {
        toggleCartSidebar();
      }
    },
    [selectedVariant?.id, isSoldOut, isPreOrder, selectedPlan?.id]
  );

  return [
    {
      isPreOrder,
      isSoldOut: isSoldOut && !isPreOrder,
      selectedPlan,
      status: {
        addItem: addItemStatus,
        inventory,
        inventoryStatus,
      },
    },
    {
      addToCart,
      toggleCartSidebar,
    },
  ];
};
