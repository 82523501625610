import { section, color } from '@settings/common';
import camelCase from 'lodash.camelcase';

const inputField = (type) => ({
  label: type,
  key: camelCase(type),
  defaultItem: {
    label: `${type}`,
  },
  itemProps: (item) => ({
    label: `${type}${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Placeholder',
      name: 'placeholder',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    ...(type === 'Email'
      ? [
        {
          label: 'Email For Autoresponse',
          name: 'isAutoresponseEmail',
          component: 'toggle',
          description:
            'Toggle on for only one email field per form. Field will automatically be named "email" in the form submission',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ]
      : []),
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
});

const multiChoiceField = (type) => ({
  label: type,
  key: camelCase(type),
  defaultItem: {
    label: `${type}`,
  },
  itemProps: (item) => ({
    label: `${type}${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    ...(type === 'Select'
      ? [
        {
          label: 'Placeholder',
          name: 'placeholder',
          component: 'text',
        },
      ]
      : []),
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Options',
      name: 'options',
      component: 'list',
      field: { component: 'text' },
    },
    ...(type === 'Radio' || type === 'Multiple Checkbox'
      ? [
        {
          label: 'Direction',
          name: 'direction',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            { label: 'Horizontal', value: 'horizontal' },
            { label: 'Vertical', value: 'vertical' },
          ],
          defaultValue: 'horizontal',
        },
      ]
      : []),
    ...(type !== 'Multiple Checkbox'
      ? [
        {
          label: 'Required',
          name: 'required',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ]
      : []),
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    ...(type === 'Multiple Checkbox'
      ? [
        {
          label: 'Hide Label',
          name: 'hideLabel',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ]
      : []),
  ],
});

const countryField = {
  label: 'Country',
  key: 'country',
  defaultItem: {
    label: 'Country',
  },
  itemProps: (item) => ({
    label: `Country${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Placeholder',
      name: 'placeholder',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

const labelField = {
  label: 'Label',
  key: 'label',
  defaultItem: {
    label: 'Label Text',
  },
  itemProps: (item) => ({
    label: `Label${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
  ],
};

const checkboxField = {
  label: 'Checkbox',
  key: 'checkbox',
  defaultItem: {
    label: 'Checkbox',
  },
  itemProps: (item) => ({
    label: `Checkbox${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

const fileField = {
  label: 'File',
  key: 'file',
  defaultItem: {
    label: 'File Upload',
  },
  itemProps: (item) => ({
    label: `File${item.label ? `: ${item.label}` : ''}`,
  }),
  fields: [
    {
      label: 'Label',
      name: 'label',
      component: 'text',
    },
    {
      label: 'Info Message',
      name: 'infoMessage',
      component: 'text',
    },
    {
      label: 'Required',
      name: 'required',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Half Width',
      name: 'halfWidth',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

export const Schema = () => {
  return {
    label: 'Form Builder',
    key: 'form-builder',
    fields: [
      {
        label: 'Form Endpoint',
        name: 'endpoint',
        component: 'text',
        description:
          'Submit button will be disabled until this is entered.\nE.g. https://getform.io/f/1234-5678-90abcdef',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Form',
      },
      {
        label: 'Fields',
        name: 'fields',
        description: `Ensure each field's label is unique to avoid conflicts`,
        component: 'blocks',
        templates: {
          checkbox: checkboxField,
          country: countryField,
          date: inputField('Date'),
          email: inputField('Email'),
          file: fileField,
          label: labelField,
          multipleCheckbox: multiChoiceField('Multiple Checkbox'),
          number: inputField('Number'),
          phone: inputField('Phone'),
          radio: multiChoiceField('Radio'),
          select: multiChoiceField('Select'),
          text: inputField('Text'),
          textArea: inputField('Text Area'),
          time: inputField('Time'),
          url: inputField('Url'),
        },
      },
      {
        label: 'Submit Button Text',
        name: 'submitText',
        component: 'text',
        defaultValue: 'Submit',
      },
      {
        label: 'reCAPTCHA Enabled',
        name: 'recaptchaEnabled',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'contentNarrow',
            mt: 'none',
            py: 's',
          },
          mobile: {
            container: 'contentNarrow',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
