import { useState } from 'react';
import { Container } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';

import { themed } from './Slider.theme';
import { useSliderParams } from './useSliderParams';
import { Tile } from '../Tile';

export const Slider = themed(({ theme, tiles, settings }) => {
  const [swiper, setSwiper] = useState();
  const defaultParams = useSliderParams();

  return (
    <Container data-comp={Slider.displayName} sx={theme.swiper}>
      <Swiper onSwiper={setSwiper} {...defaultParams}>
        {tiles?.slice(0, 12).map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <Tile item={item} settings={settings} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Container>
  );
});

Slider.displayName = 'Slider';
