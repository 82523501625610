import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      mt: [11, 16],
      px: [0, 10, 17],
      pb: [15, 0],
    };

    this.swiper = {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      cursor: 'grab',
    };

    this.bullets = {
      mt: 6,
      display: ['flex', 'none'],
      justifyContent: 'center',
      gap: 9,
      width: '100%',
      height: '8px',
      '.swiper-pagination-bullet': {
        cursor: 'pointer',
        bg: 'white',
        opacity: 0.2,
      },
      '.swiper-pagination-bullet-active': {
        opacity: '1 !important',
      },
      position: 'absolute',
      bottom: 0,
      left: 0,
    };

    this.buttonPrev = {
      position: 'absolute',
      display: ['none', 'flex'],
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      left: [8, 0],
      right: 'auto',
      transition: 'opacity 0.2s ease-in-out',
      width: ['44px', null, null, '56px'],
      height: ['44px', null, null, '56px'],
      borderRadius: '50%',
      bg: 'background',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'gray',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 3px 25px rgba(0,0,0,0.2)',
    };

    this.buttonNext = {
      ...this.buttonPrev,
      left: 'auto',
      right: [8, 0],
    };

    this.icon = {
      width: '24px',
      userSelect: 'none',
      ':hover': {
        color: 'text',
      },
    };

    this.slideWrapper = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      px: [0, '50px', '60px'],
    };

    this.starWrapper = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      minWidth: 122,
      height: '18px',
      mx: 'auto',
    };

    this.stars = {
      mx: 'auto',
    };

    this.title = {
      variant: 'text.h3',
      fontWeight: 500,
      fontSize: [7, 9],
      mt: [8],
      maxWidth: '225px',
    };

    this.review = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: [44, 48],
      mt: [4, 3],
      maxWidth: '225px',
      mx: 'auto',
    };

    this.paragraph = {
      variant: 'text.base',
    };

    this.displayName = {
      mt: [4, '22px'],
      textTransform: 'capitalize',
    };
  })()
);
