import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.row.start.center',
      border: [null, null, '1px solid'],
      borderColor: [null, null, 'gray'],
      borderRadius: [null, null, 4],
      width: '100%',
      px: [14, null, 12],
      py: [6],
    };

    this.picture = {
      width: '72px',
      mr: 8,

      spinner: {
        width: '16px',
        color: 'mediumDarkGray',
        m: 'auto',
      },
    };

    this.details = {
      variant: 'flex.column.start',
    };

    this.title = {
      variant: 'text.h6',
      minHeight: ['18px', null, '22px'],
      fontSize: [1, null, 5],
      lineHeight: [18, null, 22],
    };

    this.variant = {
      variant: 'text.base',
      color: 'mediumDarkGray',
      minHeight: ['18px', null, '22px'],
      fontSize: [1, null, 4],
      lineHeight: [18, null, 22],
    };

    this.price = {
      minHeight: ['18px', null, '22px'],
      mb: 3,
      p: {
        variant: 'text.base',
        minHeight: ['18px', null, '22px'],
        fontSize: [1, null, 4],
        lineHeight: [18, null, 22],
      },
    };

    this.newPrice = {
      color: 'red',
      mr: 4,
    };

    this.compareAtPrice = {
      textDecoration: 'line-through',
      color: 'mediumDarkGray',
    };

    this.atc = {
      height: '15px',
    };

    this.mobileAtc = {
      display: [null, null, 'none'],
    };

    this.desktopAtc = {
      variant: 'flex.column.center.center',
      display: ['none', null, 'flex'],
      ml: 'auto',
      minWidth: '67px',
    };

    this.dropdown = {
      minWidth: 'min-content',
      maxWidth: 'fit-content',
      width: 'auto',
      my: '8px',
    };

    this.option = {
      variant: 'flex.row.between.center',
      px: 6,
      width: '100%',
      top: '0px',

      name: {
        variant: 'text.base',
        mr: 7,
      },

      image: {
        ml: 'auto',
      },
    };
  })()
);
