import { create } from '@theme/create';

const SELECTED_HEIGHT = 44;
const OPTION_HEIGHT = 30;
const MIN_WIDTH = '200px';
const COUNTRIES_MIN_WIDTH = '160px';

const wrapper = {
  minWidth: MIN_WIDTH,
  position: 'relative',
  zIndex: 2,
};

const drawerWrapper = {
  position: 'absolute',
  width: '100%',
  left: 0,
  top: '56px',
  overflow: 'hidden',
};

const drawer = {
  bg: 'background',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'gray',
  borderRadius: '2px',
  flexDirection: 'column',
  height: 'auto',
  maxHeight: '100%',
  minHeight: '100%',
  overflow: 'hidden',
  transition: 'max-height 0.2s ease-in-out',
  width: '100%',
  boxShadow:
    '0px 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 4px 8px -2px rgba(0, 0, 0, 0.28)',
  mt: '-2px',
};

const scroll = {
  '::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  webkitOverflowScrolling: 'touch',
  height: 'auto',
  maxHeight: '100%',
  overflowY: 'auto',
  position: 'relative',
  width: '100%',
};

const item = {
  alignItems: 'center',
  bg: 'background',
  color: 'text',
  display: 'flex',
  flexDirection: 'row',
  height: `${SELECTED_HEIGHT}px`,
  justifyContent: 'space-between',
  lineHeight: `${SELECTED_HEIGHT - 2}px`,
  overflow: 'hidden',
  px: 0,
  py: 0,
  textAlign: 'left',
  textOverflow: 'ellipsis',
  transition: 'background-color 0.1s ease-in-out',
  whiteSpace: 'nowrap',
  width: '100%',
  zIndex: 1,
};

const selected = {
  ...item,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'mediumDarkGray',
  borderRadius: '30px',
};

const option = {
  ...item,
  height: `${OPTION_HEIGHT}px`,
  lineHeight: `${OPTION_HEIGHT}px`,
  border: 'none',
  '&:first-of-type': {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: 'gray',
    mt: '2px',
  },
  // '&:last-of-type': {
  //   mb: 2
  // }
};

const label = {
  position: 'relative',
  m: 0,
  width: '100%',

  text: {
    variant: 'text.label.2',
    position: 'absolute',
    top: 2,
    left: '21px',
    zIndex: 2,
    pointerEvents: 'none',
    color: 'dark',
  },
};

export const themed = create(
  new (function () {
    this.default = {
      drawerWrapper,
      wrapper,
      drawer,
      scroll,
      selected, // selected option
      option, // each dropdown option
      label,
    };

    this.countries = {
      drawerWrapper,
      wrapper: {
        ...wrapper,
        minWidth: COUNTRIES_MIN_WIDTH,
      },

      // Drawer wrapper
      drawer,

      // defining a px maxHeight enables scrolling
      scroll: {
        ...scroll,
      },

      // top selected item
      selected: {
        ...selected,
      },

      // each dropdown option
      option: {
        ...option,
        // '&:first-of-type': {
        //   mt: 4
        // },
        // '&:last-of-type': {
        //   mb: 4
        // }
      },

      label,
    };

    this.subscription = {
      drawerWrapper,
      wrapper,
      drawer,
      scroll,
      selected,
      option: {
        ...option,
        '&:hover': {
          bg: 'lightGray',
        },
      },
      label,
    };

    this.sizes = {
      wrapper,
      drawerWrapper: {
        ...drawerWrapper,
        top: '42px',
      },
      drawer,
      scroll,
      selected: {
        ...selected,
        height: '34px',
        p: {
          variant: 'text.base',
          minHeight: ['18px', null, '22px'],
          fontSize: [1, null, 4],
          lineHeight: [18, null, 22],
        },
      },
      option: {
        ...option,
        p: {
          variant: 'text.base',
          minHeight: ['18px', null, '22px'],
          fontSize: [1, null, 4],
          lineHeight: [18, null, 22],
        },
        '&:hover': {
          bg: 'lightGray',
        },
      },
      label,
    };

    this.colors = {
      wrapper,
      drawerWrapper: {
        ...drawerWrapper,
        top: '42px',
        minWidth: '150px',
      },
      drawer,
      scroll: {
        ...scroll,
        maxHeight: ['125px', null, '175px'],
      },
      selected: {
        ...selected,
        height: '34px',
        p: {
          variant: 'text.base',
          minHeight: ['18px', null, '22px'],
          fontSize: [1, null, 4],
          lineHeight: [18, null, 22],
        },
      },
      option: {
        ...option,
        p: {
          variant: 'text.base',
          minHeight: ['18px', null, '22px'],
          fontSize: [1, null, 4],
          lineHeight: [18, null, 22],
        },
        '&:hover': {
          bg: 'lightGray',
        },
      },
      label,
    };
  })()
);
