import { Button, Paragraph, Flex } from 'theme-ui';

import { Svg } from '@snippets';
import { useCartCount } from '@snippets/Cart';
import { useSidebar } from '@hooks';

import { themed } from '../Menu.theme';

export const CtaCart = themed(({ theme, ...props }) => {
  const cartCount = useCartCount();
  const { openCartSidebar } = useSidebar()[1];
  const length = cartCount?.toFixed(0)?.length;

  return (
    <Flex
      sx={{
        ...theme.cartWrapper,
        ...props.sx,
      }}
    >
      <Button
        data-comp={CtaCart.displayName}
        variant="buttons.plain"
        onClick={openCartSidebar}
        aria-label="Open cart drawer"
        sx={theme.cta}
      >
        <Svg
          alt="cart"
          viewBox="0 0 48 48"
          src="/svgs/cta/cart.svg#cart"
          sx={theme.cta.icon}
        />
      </Button>

      <Paragraph
        sx={{
          variant: 'text.label.1',
          fontSize: [0, null, length > 2 ? 0 : 2],
          ml: [0, null, 2],
          userSelect: 'none',
        }}
      >
        ({cartCount})
      </Paragraph>
    </Flex>
  );
});

CtaCart.displayName = 'CtaCart';
