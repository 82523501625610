import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      variant: 'flex.column.center.center',
      height: '100%',
      px: 10,

      text: {
        variant: 'text.h4',
        fontSize: 8,
        lineHeight: 32,
        textAlign: 'center',
        maxWidth: '250px',
      },

      grid: {
        justifyItems: 'center',
        gridGap: 8,
        mt: 12,
      },

      link: {},
    };

    // cart empty — rendered in /cart
    this.page = {
      ...this.sidebar,
      border: (t) => [`1px solid ${t.colors.gray}`, null, 0],
      mr: [0, null, '130px'],

      text: {
        variant: 'text.h4',
        fontSize: 8,
        lineHeight: 32,
      },

      grid: {
        justifyItems: 'center',
        gridGap: 8,
        mt: 12,
      },

      link: {},
    };
  })()
);
