import { Box, Paragraph, Flex, Heading } from 'theme-ui';
import PropTypes from 'prop-types';

import { themed } from './Prices.theme';

export const Prices = themed(
  ({ theme: themes, estimatedCost, isDiscounted, inSidebar }) => {
    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Box data-comp={Prices.displayName}>
        {/* Price after savings and discounts */}
        <Flex sx={theme.price}>
          <Heading as="h5" sx={theme.price.title} className="hidden">
            Subtotal
          </Heading>
          <Flex sx={theme.price.container}>
            <Paragraph sx={theme.price.amount}>
              {estimatedCost?.subtotalAmount.locale}
            </Paragraph>
            {isDiscounted && (
              <Paragraph sx={theme.price.strike}>
                {estimatedCost.totalCompareAtAmount.locale}
              </Paragraph>
            )}
          </Flex>
        </Flex>
      </Box>
    );
  }
);

Prices.displayName = 'Prices';
Prices.propTypes = {
  estimatedCost: PropTypes.object,
  isDiscounted: PropTypes.bool,
  isOnSale: PropTypes.bool,
  inSidebar: PropTypes.bool,
};
