import {
  Flex,
  Input,
  Label,
  Text,
  Checkbox,
  Radio,
  Select,
  Paragraph,
  Box,
  Textarea,
} from 'theme-ui';
import { pascalCase } from 'change-case';

import { CountryField } from './CountryField';
import { themed } from './FormBuilder.theme';

export const FormField = themed(({ field, index }) => {
  const {
    _template: type,
    column,
    direction,
    halfWidth,
    hideLabel,
    infoMessage,
    isAutoresponseEmail,
    label,
    link,
    options,
    placeholder,
    required,
  } = field;
  const isTextarea = type === 'textArea';
  const isRadio = type === 'radio';
  const isSelect = type === 'select';
  const isCountry = type === 'country';

  // Non inputs
  const isLabel = type === 'label';
  const hasLabel = !isLabel;

  // Uses <checkbox>
  const isCheckbox = type === 'checkbox';
  const isMultiCheckbox = type === 'multipleCheckbox';
  const isCheckboxType = isCheckbox || isMultiCheckbox;

  // Uses <input type="file">
  const isFile = type === 'file';
  const isFileType = isFile;

  // Uses <input>
  const isText = type === 'text';
  const isEmail = type === 'email';
  const isPhone = type === 'phone';
  const isDate = type === 'date';
  const isNumber = type === 'number';
  const isUrl = type === 'url';
  const isTime = type === 'time';
  const isAddress = type === 'address';
  const isInput =
    isText ||
    isEmail ||
    isPhone ||
    isDate ||
    isNumber ||
    isUrl ||
    isTime ||
    isAddress;

  const isNotFullWidth = isCheckbox || isRadio || isFileType || isCheckboxType;

  return (
    <Flex
      data-comp={FormField.displayName}
      key={index}
      sx={{
        flexDirection: 'column',
        width: '100%',
        gridColumn: halfWidth ? column : `1 / span 2`,
        alignItems: isNotFullWidth ? 'flex-start' : 'stretch',
        label: { mb: 0, fontWeight: 400 },
        input: { p: 4, borderColor: 'mediumGray' },
        'select, textarea': {
          p: 4,
          borderRadius: 0,
          borderColor: 'mediumGray',
        },
      }}
    >
      <Label
        sx={{
          width: isNotFullWidth ? 'auto' : '100%',
          display: 'flex',
          flexDirection: isCheckbox ? 'row' : 'column-reverse',
          py: isCheckbox || isLabel ? 2 : 0,
          columnGap: 2,
          rowGap: 3,
          fontWeight: 400,
          justifySelf:
            isCheckbox || isRadio || isFileType ? 'flex-start' : 'stretch',
        }}
      >
        {isLabel && label}

        {isFileType && (
          <Input
            data-comp={pascalCase(type)}
            type="file"
            required={required}
            name={label}
          />
        )}

        {isInput && (
          <Input
            data-comp={pascalCase(type)}
            type={isPhone ? 'tel' : type}
            required={required}
            placeholder={placeholder}
            name={isAutoresponseEmail ? 'email' : label}
          />
        )}

        {isTextarea && (
          <Textarea
            data-comp={pascalCase(type)}
            type="textarea"
            required={required}
            name={label}
            placeholder={placeholder}
            sx={{ resize: 'none', p: 3 }}
          />
        )}

        {isCheckbox && (
          <>
            <Checkbox
              data-comp={pascalCase(type)}
              required={required}
              name={label}
              value="yes"
            />
            <Box type="hidden" name={label} value="no" />
          </>
        )}

        {isMultiCheckbox && (
          <Flex
            data-comp={pascalCase(type)}
            sx={{
              flexDirection: direction === 'vertical' ? 'column' : 'row',
              columnGap: 4,
              rowGap: 2,
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {options?.map((value) => (
              <Label key={value}>
                <Checkbox name={value} value="yes" />
                <Box type="hidden" name={value} value="no" />
                {value}
              </Label>
            ))}
          </Flex>
        )}

        {isRadio && (
          <Flex
            data-comp={pascalCase(type)}
            sx={{
              flexDirection: direction === 'vertical' ? 'column' : 'row',
              columnGap: 4,
              rowGap: 2,
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
            }}
          >
            {options?.map((value) => (
              <Label key={value}>
                <Radio value={value} name={label} />
                <Text>{value}</Text>
              </Label>
            ))}
          </Flex>
        )}

        {isSelect && (
          <Select data-comp={pascalCase(type)} required={required} name={label}>
            {[placeholder || '', ...(options || [])].map((value, index) => (
              <option
                key={value}
                value={value}
                disabled={index === 0}
                selected={index === 0}
              >
                {value}
              </option>
            ))}
          </Select>
        )}

        {isCountry && (
          <CountryField
            label={label}
            required={required}
            placeholder={placeholder}
          />
        )}

        {hasLabel && !hideLabel && (
          <Text
            dangerouslySetInnerHTML={{
              __html: `${label}${
                link?.text
                  ? `&nbsp;<a href="${link.url}" onclick="event.preventDefault" target="_blank" style"position:inline;">${link.text}</a>`
                  : ''
              }${required ? ' *' : ''}`,
            }}
            sx={{ a: { textDecoration: 'underline' } }}
          />
        )}
      </Label>

      {infoMessage && (
        <Paragraph
          sx={{
            variant: 'text.sm',
            color: 'mediumDarkGray',
            mt: 2,
          }}
        >
          {infoMessage}
        </Paragraph>
      )}
    </Flex>
  );
});

FormField.displayName = 'FormField';
