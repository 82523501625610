import { useCallback, useMemo } from 'react';
import { Box, Flex } from 'theme-ui';
import { router } from 'next/router';

import { Link } from '@snippets';
import { useModal } from '@hooks';

import { HalfMedia } from '../../HalfMedia';
import { themed } from './PromoBar.theme';

const textHtmlTags = `p, h1, h2, h3, h4, h5, h6, span, label, div, strong, em, blockquote, pre, code, i, b, ul, ol, li, modal`;

export const PromoBarMessage = themed(({ theme, link, align, html, index }) => {
  const [{ modal }, { openModal, closeModal }] = useModal();

  const hasLinkInMarkdown = useMemo(() => {
    return html?.includes('<a') && html?.includes('</a>');
  }, [html]);

  const toggleModal = useCallback(
    (id) => {
      if (modal) {
        closeModal();
      }
      openModal(<HalfMedia passedId={id} />);
    },
    [modal, HalfMedia]
  );

  const parsedHtml = useMemo(() => {
    const openModalTagIndex = html?.indexOf('<modal');
    const closeModalTagIndex = html?.indexOf('</modal>');
    if (openModalTagIndex >= 0 && closeModalTagIndex >= 0) {
      if (typeof window !== 'undefined') {
        window[`__brumate_modal_click_${index}`] = toggleModal;
      }
      const openModalTagClosingBracketIndex = html?.indexOf(
        '>',
        openModalTagIndex
      );
      const idAttr = html
        .slice(openModalTagIndex, openModalTagClosingBracketIndex || 0)
        .match(/id="(.*)"/)?.[0];
      const id = idAttr?.replace('id="', '').replace('"', '');
      return html
        .replace(
          '<modal',
          `<span onClick="window.__brumate_modal_click_${index}(${id})" class="markdown-modal" `
        )
        .replace('</modal>', '</span>');
    }
    return html;
  }, [html, toggleModal, index]);

  return (
    <Flex data-comp={PromoBarMessage.displayName} sx={theme.message}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        href={hasLinkInMarkdown ? undefined : link?.url}
        newTab={link?.newTab}
        prefetch={false}
        sx={theme.message.link}
        onClick={(e) => {
          if (e.target.className === 'markdown-modal') e.preventDefault();
          if (!hasLinkInMarkdown || !link?.url) return;
          router.push(link.url);
        }}
      >
        <Box
          dangerouslySetInnerHTML={{ __html: parsedHtml }}
          sx={{
            [textHtmlTags]: {
              textAlign: align || 'center',
              mt: 0,
              '&:last-of-type': { mb: 0 },
              ...theme.message.text,
              fontWeight: 'body !important',
            },
            a: {
              textDecoration: 'underline',
            },
            '.markdown-modal': {
              textDecoration: 'underline',
              fontFamily: 'body',
              cursor: 'pointer',
              zIndex: 10,
            },
          }}
        />
      </Link>
    </Flex>
  );
});

PromoBarMessage.displayName = 'PromoBarMessage';
