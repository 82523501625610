import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: ['flex', 'none'],
      justifyContent: 'center',
      overflow: 'hidden',
      minHeight: '56px',
      py: 6,
    };

    this.scroll = {
      height: '100%',
      overflowY: 'hidden',
      overflowX: 'auto',
      webkitOverflowScrolling: 'touch',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
      px: 8,
    };

    this.link = {
      variant: 'text.label.1',
      bg: 'gray',
      height: '32px',
      lineHeight: '32px',
      px: 6,
      borderRadius: '16px',
      minWidth: 'auto',
      whiteSpace: 'nowrap',
      transition: 'background-color 0.2s ease-in-out',
      ':hover': {
        bg: 'mediumGray',
      },
    };
  })()
);
