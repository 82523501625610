import { color, section, button, align } from '@settings/common';

const media = () => ({
  label: 'Media Settings',
  name: 'media',
  description: 'Media settings(image, mp4, gif)',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      label: 'Media Alt',
      description:
        'Provide a brief description of the image for accessibility compliance',
    },
    {
      name: 'image',
      label: 'Image',
      component: 'image',
    },
    {
      name: 'video',
      label: 'Video URL',
      component: 'text',
      description: `Direct video links usually end in or contain '.mp4'`,
    },
    {
      name: 'imageHover',
      label: 'Media Hover',
      component: 'image',
    },
    {
      name: 'videoHover',
      label: 'Video Hover URL',
      component: 'text',
      description: `Direct video links usually end in or contain '.mp4'`,
    },
  ],
  defaultValue: {
    alt: 'Shop By Category Image',
    image: {
      src: 'https://cdn.shopify.com/s/files/1/0807/0011/3185/files/2024_05_29_Brumate_Q3_Backyard_1024_201.jpg?v=1726502488',
    },
    imageHover: {
      src: 'https://cdn.shopify.com/s/files/1/0807/0011/3185/files/2024_05_29_Brumate_Q3_Backyard_1024_201.jpg?v=1726502488',
    },
    video: '',
    videoHover: '',
  },
});

const content = () => ({
  label: 'Content Settings',
  name: 'content',
  description: 'Heading, Description, CTA',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'text',
      label: 'Heading (desktop)',
    },
    {
      name: 'headingMobile',
      component: 'text',
      label: 'Heading (mobile)',
    },
    {
      name: 'description',
      component: 'text',
      label: 'Description (desktop)',
    },
    {
      name: 'descriptionMobile',
      component: 'text',
      label: 'Description (mobile)',
    },
    {
      name: 'alignment',
      label: 'Content alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
      ],
    },
    {
      name: 'alignmentMobile',
      label: 'Content alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
      ],
    },
    {
      label: 'Link (desktop)',
      name: 'cta',
      component: 'link',
    },
    {
      name: 'style',
      label: 'Link style (desktop)',
      component: 'select',
      options: button.options,
    },
  ],
  defaultValue: {
    heading: 'Gear Up For Summer',
    headingMobile: 'Gear Up For Summer',
    description: 'We got all the leakproof essentials.',
    descriptionMobile: 'We got all the leakproof essentials.',
    alignment: 'left',
    alignmentMobile: 'left',
    cta: {
      text: 'Shop',
      url: '/collections/all',
    },
    style: button.value.primary,
  },
});

const defaultTile = () => ({
  media: {
    alt: 'Shop By Category Image',
    image: {
      src: 'https://cdn.shopify.com/s/files/1/0807/0011/3185/files/2024_05_29_Brumate_Q3_Backyard_1024_201.jpg?v=1726502488',
    },
    imageHover: {
      src: 'https://cdn.shopify.com/s/files/1/0807/0011/3185/files/2024_05_29_Brumate_Q3_Backyard_1024_201.jpg?v=1726502488',
    },
    video: '',
    videoHover: '',
  },
  content: {
    heading: 'Gear Up For Summer',
    headingMobile: 'Gear Up For Summer',
    description: 'We got all the leakproof essentials.',
    descriptionMobile: 'We got all the leakproof essentials.',
    alignment: 'left',
    alignmentMobile: 'left',
    cta: {
      text: 'Shop',
      url: '/collections/all',
    },
    style: button.value.primary
  },
});

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Shop By Category',
    key: 'shop-by-category',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, text align',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'textAlign',
            label: 'Heading alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'left' },
              { label: 'Center', value: 'center' },
            ],
          },
        ],
        defaultValue: {
          heading: 'Shop By Category',
          textAlign: align.value.left,
        },
      },
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 12 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.content.heading}}',
        },
        defaultItem: defaultTile(),
        validate: {
          maxItems: 12,
        },
        fields: [
          media(),
          content(),
        ],
        defaultValue: [defaultTile()],
      },
      {
        label: 'Media Settings',
        name: 'media',
        description: 'Aspect ratio',
        component: 'group',
        fields: [
          {
            label: 'Aspect ratio (desktop)',
            name: 'ratioDt',
            component: 'radio-group',
            description:
              'Only applies to images and video files not already set to 1:1 ratio',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: '1',
                label: '1:1',
              },
              {
                value: '0.75',
                label: '3:4',
              },
            ],
          },
          {
            label: 'Aspect ratio (mobile)',
            component: 'radio-group',
            description:
              'Only applies to images and video files not already set to 1:1 ratio',
            direction: 'horizontal',
            variant: 'radio',
            name: 'ratio',
            options: [
              {
                value: '1',
                label: '1:1',
              },
              {
                value: '0.75',
                label: '3:4',
              },
            ],
          },
          {
            label: 'Enable Contained',
            name: 'enableContained',
            description: 'Show Content on top of media',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          ratio: '0.75',
          ratioDt: '0.75',
          enableContained: false,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'xs',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'xs',
          },
        },
      },
    ],
  };
}
