import { Flex, Paragraph, Text, Button } from 'theme-ui';

import store, { useSetRecoilState } from '@store';

import { useSearch } from './hooks';
import { themed } from './Search.theme';

export const SuggestionsHeader = themed(({ theme }) => {
  const [{ suggested, suggestions, isAutocomplete }] = useSearch();
  const setRawTerm = useSetRecoilState(store.rawTerm);

  return suggestions?.length > 0 || isAutocomplete ? (
    <Flex
      data-comp={SuggestionsHeader.displayName}
      sx={theme.suggestionsWrapper}
    >
      {isAutocomplete && (
        <Paragraph>
          Showing results for{' '}
          <Text sx={theme.suggested}>{suggested?.text}</Text>
        </Paragraph>
      )}

      {suggestions?.length > 0 && (
        <Flex sx={{ flexWrap: 'wrap' }}>
          <Paragraph sx={{ mr: 2 }}>Suggestions:</Paragraph>
          {suggestions.map((suggestion, index, arr) => {
            const isLast = index === arr.length - 1;
            return (
              <Button
                key={suggestion}
                variant="buttons.plain"
                sx={theme.suggestion(isLast)}
                onClick={() => setRawTerm(suggestion)}
                aria-label={`Search for ${suggestion}`}
              >
                {suggestion}
              </Button>
            );
          })}
        </Flex>
      )}
    </Flex>
  ) : null;
});

SuggestionsHeader.displayName = 'SuggestionsHeader';
