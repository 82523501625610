import { useState } from 'react';
import { Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';

import { Navigation } from './Navigation';
import { UpsellSliderItem } from './SliderItem';
import { themed } from './Upsell.theme';

SwiperCore.use([A11y]);

export const UpsellSlider = themed(({ theme, products, swiper, setSwiper }) => {
  return (
    <Box data-comp={UpsellSlider.displayName} sx={theme.slider}>
      <Swiper
        onSwiper={(_swiper) => {
          setSwiper(_swiper);
        }}
        spaceBetween={0}
        slidesPerView={1}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        a11y={{ enabled: true }}
        loop={products?.length > 1}
      >
        {products?.map(({ handle }, index) => {
          if (!handle) return null;
          return (
            <SwiperSlide key={index}>
              <UpsellSliderItem handle={handle} />
            </SwiperSlide>
          );
        })}
      </Swiper>

      {products?.length > 1 && <Navigation swiper={swiper} />}
    </Box>
  );
});

UpsellSlider.displayName = 'CartUpsellSlider';
