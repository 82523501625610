import { useRef } from 'react';

import { Link } from '@snippets';

import { useMenuDrawer } from '../MenuDrawer';
import { themed } from './Menu.theme';

export const NavItem = themed(({ theme, item, index, ...props }) => {
  const ref = useRef();
  const [{ hovered }, { hoverItem, unHoverItem }] = useMenuDrawer();
  const textColor = item?.color;

  const isHovered = hovered?.link?.text === item?.link?.text;

  return (
    // Level 1 Menu nav item
    <Link
      ref={ref}
      data-comp={NavItem.displayName}
      href={item.link?.url}
      newTab={item.link?.newTab}
      aria-label={item.link?.text}
      onClick={unHoverItem}
      onMouseLeave={unHoverItem}
      onMouseEnter={() => hoverItem(item)}
      sx={{
        ...theme.item,
        color: textColor || 'inherit',
        ':after': {
          ...theme.item.underline,
          transform: isHovered ? 'scaleX(1)' : 'scaleX(0)',
        },
      }}
    >
      {item.link?.text}
    </Link>
  );
});

NavItem.displayName = 'NavItem';
