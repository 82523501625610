import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.content = {
      variant: 'flex.column',
      height: '100%',
    };
  })()
);
