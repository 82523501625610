import { Flex, Container } from 'theme-ui';

import { Link, Section } from '@snippets';
import { withInView } from '@utils';

import { themed } from './QuickSelectCollections.theme';
import { Schema } from './QuickSelectCollections.schema';

export const QuickSelectCollections = withInView(
  themed(({ theme, cms }) => {
    const { links, section } = cms;

    return (
      <Section section={section}>
        <Container
          data-comp={QuickSelectCollections.displayName}
          sx={theme.wrapper}
        >
          <Flex sx={theme.scroll}>
            {links?.map((item, index, arr) => {
              return (
                <Link
                  key={index}
                  href={item.cta?.url}
                  newTab={item.cta?.newTab}
                  sx={{
                    ...theme.link,
                    mr: index !== arr.length - 1 ? 3 : 0,
                  }}
                >
                  {item.cta?.text}
                </Link>
              );
            })}
          </Flex>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

QuickSelectCollections.displayName = 'QuickSelectCollections';
QuickSelectCollections.Schema = Schema;
