import { useCallback } from 'react';
import { nanoid } from 'nanoid';

export const useSearchspring = () => {
  // Check if specific ss id exists from cookies, if not generate new one
  const getClientIp = useCallback(async () => {
    try {
      const response = await fetch('/api/client-ip');
      const data = await response.json();
      return data?.query || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);

  const getIdOrGenerateId = useCallback(async (name) => {
    try {
      if (!name) return;

      let id = null;
      document.cookie?.split(/\s*;\s*/).find((item) => {
        const [key, value] = item.split(/\s*=\s*/);
        if (key !== name) return false;
        id = value;
        return true;
      });

      if (id) {
        return id;
      }
      const newId = nanoid();
      document.cookie = `${name}=${newId}`;
      return newId;
    } catch (error) {
      console.error('Error with getIdOrGenerateId', error.message);
      throw new Error(error.message);
    }
  }, []);

  // Request searchspring api
  const requestSearchspring = useCallback(async ({ action, params }) => {
    try {
      if (!action) throw new Error('No Searchspring action provided');

      const hasUserTracking = ['getCollection', 'getSearchResults'].includes(
        action
      );
      const ssUserId = hasUserTracking
        ? await getIdOrGenerateId('ssUserId')
        : null;
      const sessionId = hasUserTracking
        ? await getIdOrGenerateId('ssSessionIdNamespace')
        : null;

      const ip = await getClientIp();

      const url = `/api/searchspring`;
      const options = {
        method: 'POST',
        body: JSON.stringify({
          action,
          ...params,
          ...(hasUserTracking ? { userId: ssUserId } : null),
          ...(hasUserTracking ? { sessionId } : null),
          ...(hasUserTracking
            ? {
                header: {
                  'searchspring-session-id': sessionId,
                  'searchspring-user-id': ssUserId,
                  'searchspring-page-load-id': nanoid(),
                  'User-Agent': navigator.userAgent || '',
                  'X-Forwarded-For': ip || '',
                },
              }
            : null),
        }),
      };

      const response = await fetch(url, options);
      const data = await response.json();

      return data?.data;
    } catch (error) {
      console.error(`Error with requestSearchspring: ${error.message}`);
      throw error;
    }
  }, []);

  return {
    requestSearchspring,
  };
};
