import { useMemo, useState, useEffect, useCallback } from 'react';
import { Box, Container } from 'theme-ui';

import { Section } from '@snippets';
import { calcResponsiveHeight, withInView } from '@utils';

import { Schema } from './HeroSlider.schema';
import { Slide } from './Slide';
import { Slider, ContentSlider } from './Slider';
import { themed } from './HeroSlider.theme';

export const HeroSlider = withInView(
  themed(({ theme, cms }) => {
    const { slider, section, slides } = cms;

    const [swiper, setSwiper] = useState();
    const [contentSwiper, setContentSwiper] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const showContentSliderMobile = slider?.contentPosition === 'relative';
    const showContentSliderDesktop = slider?.contentPositionDt === 'relative';

    const height = useMemo(() => {
      return [
        calcResponsiveHeight({
          maxHeight: section?.mobile?.maxHeight,
          viewport: 768,
        }),
        calcResponsiveHeight({
          maxHeight: section?.desktop?.maxHeight,
          viewport: 1280,
        }),
      ];
    }, [section?.mobile?.maxHeight, section?.desktop?.maxHeight]);

    const mobileMinHeight = section?.mobile?.minHeight
      ? section.mobile.minHeight
      : 375;
    const desktopMinHeight = section?.desktop?.minHeight
      ? section.desktop.minHeight
      : 460;

    const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
      if (!swiper || slides?.length < 2) return;
      swiper?.on('slideChange', () => {
        setActiveIndex(swiper?.realIndex);
        contentSwiper?.slideTo(swiper?.realIndex);
      });
    }, [swiper, contentSwiper, slides?.length]);

    useEffect(() => {
      setActiveIndexAndSlideToOnSlideChange();
    }, [swiper, contentSwiper, slides?.length]);

    return (
      <Section section={section}>
        {/* Regular slider for HeroSlider media and contained content */}
        <Container
          data-comp={HeroSlider.displayName}
          sx={{
            ...theme.heroSlider,
            height,
            minHeight: [`${mobileMinHeight}px`, `${desktopMinHeight}px`],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
          }}
        >
          {slides?.length > 1 && (
            <Slider
              slides={slides}
              slider={slider}
              swiper={swiper}
              setSwiper={setSwiper}
              showContentSliderMobile={showContentSliderMobile}
              showContentSliderDesktop={showContentSliderDesktop}
              activeIndex={activeIndex}
            />
          )}

          {slides?.length === 1 && (
            <Slide
              slide={slides[0]}
              isActive
              showContentSliderMobile={showContentSliderMobile}
              showContentSliderDesktop={showContentSliderDesktop}
              isFirstSlide
              isVisibleContent
            />
          )}
        </Container>

        {/* Slider for content if displayed below HeroSlider image */}
        {(showContentSliderMobile || showContentSliderDesktop) && (
          <Box
            sx={{
              display: [
                showContentSliderMobile ? 'block' : 'none',
                showContentSliderDesktop ? 'block' : 'none',
              ],
            }}
          >
            <ContentSlider
              activeIndex={activeIndex}
              bgColor={section?.bgColor}
              contentSwiper={contentSwiper}
              setContentSwiper={setContentSwiper}
              showContentSliderMobile={showContentSliderMobile}
              showContentSliderDesktop={showContentSliderDesktop}
              slider={slider}
              slides={slides}
            />
          </Box>
        )}
      </Section>
    );
  }),
  { triggerOnce: true }
);

HeroSlider.displayName = 'HeroSlider';
HeroSlider.Schema = Schema;
