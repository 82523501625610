import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.buttonPrev = {
      position: 'absolute',
      transform: 'translateY(-50%)',
      zIndex: 1,
      top: [19, 21],
      left: [8, 10, 17],
      right: 'auto',
      transition: 'opacity 0.2s ease-in-out',
      width: ['44px', null, null, '56px'],
      height: ['44px', null, null, '56px'],
      borderRadius: '50%',
      bg: 'background',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'gray',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 3px 25px rgba(0,0,0,0.2)',
    };

    this.buttonNext = {
      ...this.buttonPrev,
      left: 'auto',
      right: [8, 10, 17],
    };

    this.icon = {
      width: '24px',
      userSelect: 'none',
      pointerEvents: 'none',
    };
  })()
);
