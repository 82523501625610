import { useEffect, useRef } from 'react';
import Script from 'next/script';
import { useCart } from '@backpackjs/storefront';

export function FueledFondueCashbackScript() {
  const listenersAttached = useRef(false); // Ref to track if listeners are already attached
  const cart = useCart();

  useEffect(() => {
    let i = 0;
    const intervalId = setInterval(() => {
      if (i > 15) {
        clearInterval(intervalId);
      }
      if (typeof window.Fondue === 'object' && cart?.id) {
        clearInterval(intervalId);
        window.Fondue.cart.getCartId = function () {
          return cart?.id;
        };
        window.getCartId = function () {
          return cart?.id;
        };
        window.Fondue.cart.registerCartId(cart.id);
      } else {
        i += 1;
        console.log('Waiting for fondueCashback SDK...');
      }
    }, 300); // Check every 300ms
    return () => {
      clearInterval(intervalId);
    };
  }, [cart?.id]);

  useEffect(() => {
    let sdkReady = false;

    const setupListeners = () => {
      if (!sdkReady || listenersAttached.current) return; // Ensure listeners are not attached more than once

      const updateCartListener = (event) => {
        // Delay to ensure dl_add_to_cart is fully processed
        setTimeout(() => {
          if (window.Fondue.status === 'ready') {
            window.Fondue.cart.dispatchUpdatedCartEvent();
          }
          console.log('fondueCashback update Cart Event Fired');
        }, 0); // Delay to ensure dl_add_to_cart is processed
      };

      window.addEventListener('dl_add_to_cart', updateCartListener);
      window.addEventListener('dl_remove_from_cart', updateCartListener);

      listenersAttached.current = true; // Mark listeners as attached

      return () => {
        window.removeEventListener('dl_add_to_cart', updateCartListener);
        window.removeEventListener('dl_remove_from_cart', updateCartListener);
        listenersAttached.current = false; // Reset listeners attached flag on cleanup
      };
    };

    const handleFondueCashbackReady = () => {
      sdkReady = true;
      setupListeners();
    };

    window.addEventListener('fondueCashbackReady', handleFondueCashbackReady);

    return () => {
      window.removeEventListener(
        'fondueCashbackReady',
        handleFondueCashbackReady
      );
    };
  }, []);

  return (
    <Script
      type="text/javascript"
      id="fondue-cashback"
      onLoad={() => {
        console.log('Checking fondueCashback SDK:', typeof window.Fondue);
        let i = 0;
        const intervalId = setInterval(() => {
          if (i > 15) {
            clearInterval(intervalId);
          }
          if (
            typeof window.Fondue === 'object' &&
            window.Fondue.status === 'ready'
          ) {
            clearInterval(intervalId);
            console.log('fondueCashback SDK loaded:', typeof window.Fondue);
            const event = new Event('fondueCashbackReady');
            window.dispatchEvent(event);
          } else {
            i += 1;
            console.log('Waiting for fondueCashback SDK...');
          }
        }, 300); // Check every 300ms
      }}
      src="https://public.getfondue.com/cashback-extension/cart/fundle.js"
      shop="kryo-gear.myshopify.com"
      api="STOREFRONT"
    />
  );
}
