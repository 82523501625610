import { Flex } from 'theme-ui';

import { NavItem } from './NavItem';
import { useMenu } from './useMenu';
import { themed } from './Menu.theme';

export const Nav = themed(({ theme }) => {
  const { desktop } = useMenu();

  if (!desktop?.menu?.length) return null;

  return (
    <Flex
      data-comp={Nav.displayName}
      as="nav"
      role="navigation"
      aria-label="Main menu"
      sx={theme.nav}
    >
      {desktop?.menu?.map((item, index) => (
        <NavItem key={index} item={item} index={index} />
      ))}
    </Flex>
  );
});

Nav.displayName = 'Nav';
