import { Container, Box } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './TwoThirdsMedia.schema';
import { themed } from './TwoThirdsMedia.theme';
import { Content } from './Content';
import { Image } from './Image';

export const TwoThirdsMedia = withInView(
  themed(({ theme, cms }) => {
    const { image, content, section } = cms;
    const imageLeft = image?.locationDt === 'left'; // Desktop
    const imageTop = image?.location === 'top'; // Mobile
    const gridTemplateAreas = imageLeft
      ? ['1fr', '2fr 1fr']
      : ['1fr', '1fr 2fr'];

    return (
      <Section section={section}>
        <Container
          data-comp={TwoThirdsMedia.displayName}
          sx={{
            ...theme.grid,
            gridTemplateAreas: [
              imageTop ? '"media" "content"' : '"content" "media"',
              imageLeft ? '"media content"' : '"content media"',
            ],
            gridTemplateColumns: gridTemplateAreas,
          }}
        >
          <Box sx={theme.media}>
            <Image image={image} />
          </Box>

          <Box
            sx={{
              ...theme.content,
              justifyContent: imageLeft ? 'flex-start' : 'flex-end',
              ':before': {
                content: '""',
                float: 'left',
              },
            }}
          >
            <Content content={content} />
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

TwoThirdsMedia.displayName = 'TwoThirdsMedia';
TwoThirdsMedia.Schema = Schema;
