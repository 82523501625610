import { Container, Box } from 'theme-ui';

import { Content } from './Content';
import { themed } from './Content.theme';

export const ContentBelow = themed(({ theme, bgColor, content }) => {
  return (
    <Container data-comp={ContentBelow.displayName}>
      {content?.image?.imageDt?.src && (
        <Box
          sx={{
            ...theme.content,
            bg: bgColor || 'background',
          }}
        >
          <Content content={content} isContentBelow />
        </Box>
      )}
    </Container>
  );
});

ContentBelow.displayName = 'ContentBelow';
