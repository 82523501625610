import {
  Fragment,
  useState,
  useEffect,
  useMemo,
  Children,
  cloneElement,
} from 'react';
import { Box } from 'theme-ui';

import { isBrowser } from '@utils';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { themed } from './Sidebar.theme';

export const Sidebar = themed(({ theme, open, children, ...props }) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [sidebarHeight, setSidebarHeight] = useState('100vh');

  // sorts drawer children into Header, Content and Footer slots
  const SidebarSlots = useMemo(() => {
    let Header = null;
    let Content = null;
    let Footer = null;

    const _footerHeight = footerHeight ? `${footerHeight}px` : '0px';
    const _headerHeight = headerHeight ? `${headerHeight}px` : '0px';

    // scrollHeight is (header + content) - footer
    const scrollHeight = `calc(var(--viewport-height) - ${_headerHeight} - ${_footerHeight})`;

    Children.forEach(children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'SidebarHeader':
          Header = cloneElement(child, {
            key: 'Header',
            open,
            setHeight: setHeaderHeight,
            ...child.props,
          });
          break;

        case 'SidebarContent':
          Content = cloneElement(child, {
            key: 'Content',
            open,
            ...child.props,
          });
          break;

        case 'SidebarFooter':
          Footer = cloneElement(child, {
            key: 'Footer',
            open,
            setHeight: setFooterHeight,
            ...child.props,
          });
          break;

        default:
          break;
      }
    });

    return (
      <>
        {Header}
        <Box
          data-comp={`${Sidebar.displayName}Scroll`}
          sx={{
            ...theme.inner.scroll,
            height: scrollHeight,
            flexGrow: 1,
          }}
        >
          {Content}
        </Box>
        {Footer}
      </>
    );
  }, [Children.count(children), children, open, footerHeight, headerHeight]);

  const getBrowserHeight = () => {
    if (isBrowser) {
      setSidebarHeight(window.innerHeight);
    }
  };

  useEffect(() => {
    ['resize', 'scroll'].forEach((event) => {
      window.addEventListener(event, getBrowserHeight);
    });
    return () => {
      ['resize', 'scroll'].forEach((event) => {
        window.removeEventListener(event, getBrowserHeight);
      });
    };
  }, []);

  useEffect(() => {
    getBrowserHeight();
  }, [footerHeight]);

  return (
    <Box
      data-comp={Sidebar.displayName}
      sx={{
        ...theme.inner,
        height: sidebarHeight,
      }}
    >
      {SidebarSlots}
    </Box>
  );
});

Sidebar.displayName = 'Sidebar';

Sidebar.Header = Header;
Sidebar.Content = Content;
Sidebar.Footer = Footer;
