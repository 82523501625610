import { Box, Flex } from 'theme-ui';

import { Link, Picture } from '@snippets';
import { themed } from './NestedSidebar.theme';
import { ExpandableMenu } from '../ExpandableMenu';

export const NestedSidebarMenu = themed(
  ({
    theme,
    secondaryMenu,
    featuredImage,
    index,
    closeMenuSidebar,
    ...props
  }) => {
    const { image, link } = { ...featuredImage };

    return secondaryMenu?.length ? (
      <Flex
        data-comp={NestedSidebarMenu.displayName}
        {...props}
        as="ul"
        variant="flex.column"
        sx={{
          gridGap: 0,
          pb: 19,
        }}
      >
        {/* Each secondaryMenu link item */}
        {secondaryMenu.map((item, index) => {
          const { link, color: textColor, tertiaryMenu } = item;
          return (
            <Box as="li" index={index}>
              {tertiaryMenu ? (
                <ExpandableMenu item={item} />
              ) : (
                <Box sx={theme.secondaryMenuLinkWrapper}>
                  <Link
                    href={link.url}
                    newTab={link.newTab}
                    aria-label={link.text}
                    onClick={closeMenuSidebar}
                    sx={{
                      ...theme.secondaryMenuLink,
                      color: textColor || 'black',
                    }}
                  >
                    {link.text}
                  </Link>
                </Box>
              )}
            </Box>
          );
        })}

        <Box
          sx={{
            px: 8,
            my: 12,
          }}
        >
          {image?.src && (
            <Link
              href={link?.url}
              newTab={link?.newTab}
              aria-label={link?.text}
              onClick={closeMenuSidebar}
            >
              <Picture
                alt={link?.text}
                images={[
                  {
                    src: image.src,
                    width: 1160,
                    ratio: 1,
                  },
                ]}
                sx={{
                  width: '100%',
                  minHeight: '260px',
                  maxHeight: '260px',
                  maxWidth: '580px',
                  ml: 'auto',
                  position: 'relative',
                }}
              />
            </Link>
          )}
          <Link
            href={link?.url}
            newTab={link?.newTab}
            aria-label={link?.text}
            onClick={closeMenuSidebar}
            sx={{
              color: 'black',
              fontSize: 4,
              mt: 4,
            }}
          >
            {link?.text}
          </Link>
        </Box>
      </Flex>
    ) : null;
  }
);

NestedSidebarMenu.displayName = 'NestedSidebarMenu';
