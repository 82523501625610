import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.content',
      pt: [0, 14, 15],
      pb: [14, 18, '80px'],
    };
    this.grid = {
      display: 'grid',
      columnGap: [10, 12],
      rowGap: [14, 12],
      gridTemplateColumns: [
        'repeat(2, 1fr)',
        'repeat(3, 1fr)',
        'repeat(4, 1fr)',
      ],
    };
    this.heading = {
      variant: 'text.h1',
      mb: '28px',
    };
  })()
);
