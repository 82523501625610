import Script from 'next/script';
import { useEffect } from 'react';

export function FueledKeynesScript({ withKey: { upixelId, advertiserId } }) {
  useEffect(() => {
    const pageViewListener = () => {
      if (
        window?.ttd_dom_ready &&
        typeof window?.TTDUniversalPixelApi === 'function'
      ) {
        window?.ttd_dom_ready(() => {
          const universalPixelApi = new window.TTDUniversalPixelApi();
          universalPixelApi.init(
            advertiserId,
            [upixelId],
            'https://insight.adsrvr.org/track/up'
          );
        });
      }
    };

    window.addEventListener('dl_route_update', pageViewListener);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('dl_route_update', pageViewListener);
    };
  }, []);

  return (
    <>
      <Script
        strategy="beforeInteractive"
        id="fueled-keynes-script"
        src="https://js.adsrvr.org/up_loader.1.1.0.js"
      />

      <img
        width="1"
        height="1"
        alt=""
        src="//action.dstillery.com/orbserv/nspix?adv=cl172306849900104&ns=8766&nc=BruMate-Site-Visitor&ncv=71"
      />
    </>
  );
}
