import { Heading, Flex, Paragraph } from 'theme-ui';
import store, { useRecoilState } from '@store';

import { convertAlignToFlex } from '@utils/common';
import { Markdown, Link } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, content, customer }) => {
  const textAlign = [content?.alignment, content?.alignmentDt];
  const flexAlign = textAlign.map((align) => convertAlignToFlex(align));
  const [_, setRewardsRedirect] = useRecoilState(store.rewardsRedirect);

  return (
    <Flex data-comp={Content.displayName} sx={theme.outer}>
      <Flex
        sx={{
          variant: 'flex.column',
          width: '100%',
          maxWidth: ['600px', '450px'],
          alignItems: flexAlign,
          gap: '11',
        }}
      >
        <Heading
          sx={{
            ...theme.heading,
            textAlign,
          }}
        >
          {content?.heading
            ?.replace(/\[customer_name\]/gi, customer?.displayName)
            ?.replace(/\[customer_firstname\]/gi, customer?.firstName)
            ?.replace(/\[customer_lastname\]/gi, customer?.lastName)}
        </Heading>

        {content?.body && (
          <Markdown
            text={content.body}
            sx={{
              ...(content?.heading
                ? theme.bodyWithHeading
                : theme.bodyWithoutHeading),
            }}
            textSx={{
              textAlign,
            }}
          />
        )}

        {(content?.cta1?.text || content?.cta2?.text) && (
          <div className="flex gap-5 md:gap-6">
            {content?.cta1?.text && (
              <Link
                variant={`buttons.${content?.cta1Style || 'secondary'}`}
                href={content?.cta1?.url}
                newTab={content?.cta1?.newTab}
                onClick={() => {
                  setRewardsRedirect(true);
                }}
              >
                {content?.cta1?.text}
              </Link>
            )}
            {content?.cta2?.text && (
              <Link
                variant={`buttons.${content?.cta2Style || 'secondary'}`}
                href={content?.cta2?.url}
                newTab={content?.cta2?.newTab}
                onClick={() => {
                  setRewardsRedirect(true);
                }}
              >
                {content?.cta2?.text}
              </Link>
            )}
          </div>
        )}
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
