import { Container } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './FeaturedIcons.schema';
import { themed } from './FeaturedIcons.theme';
import { Content } from './Content';

export const FeaturedIcons = withInView(
  themed(({ theme, cms }) => {
    const { section } = cms;
    return (
      <Section section={section}>
        <Container
          data-comp={FeaturedIcons.displayName}
          sx={{
            ...theme.grid,
          }}
        >
          <Content content={cms} />
        </Container>
      </Section>
    );
  }),
  { renderOnce: true }
);

FeaturedIcons.displayName = 'FeaturedIcons';
FeaturedIcons.Schema = Schema;
