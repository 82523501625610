import { Grid, Box } from 'theme-ui';

import { SizeSidebarItem } from './SizeSidebarItem';
import { themed } from './SizeSidebar.theme';

export const SizeSidebar = themed(
  ({ theme, toggleFiltersSidebar, options }) => {
    return (
      <Box data-comp={SizeSidebar.displayName} sx={theme.scroll}>
        <Grid as="ul" sx={theme.grid}>
          {options?.map((option) => {
            return (
              <Box as="li" key={option.label}>
                <SizeSidebarItem
                  item={option}
                  toggleFiltersSidebar={toggleFiltersSidebar}
                />
              </Box>
            );
          })}
        </Grid>
      </Box>
    );
  }
);

SizeSidebar.displayName = 'SizeSidebar';
