import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      pb: [12, 15],
      pt: [12, 18],
      color: 'white',
      bg: 'dark',
      minHeight: '400px',
    };

    this.content = {
      variant: 'layout.content',
      px: [0, 12, 14],
      maxWidth: '1360px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: [15, 18],
    };

    this.grid = {
      width: '100%',
      gridTemplateColumns: ['1fr', 'min-content auto', 'auto 1fr auto'],
      gridTemplateAreas: [
        '"social" "email" "menu"',
        '"social email" "menu menu"',
        '"social menu email"',
      ],
      rowGap: [0, 16],
      columnGap: [0, 19, 15, 19],
    };
  })()
);
