import { Box, Container, Flex } from 'theme-ui';

import { Ctas, CtaMenu } from './Ctas';
import { Logo } from './Logo';
import { Nav } from './Nav';
import { themed } from './Menu.theme';

export const Menu = themed(({ theme, ...props }) => {
  return (
    <Container data-comp={Menu.displayName} sx={theme.wrapper}>
      {/* 🍔 🔍  — mobile only */}
      <Box sx={theme.toggle}>
        <CtaMenu />
      </Box>

      <Flex variant="flex.row.center">
        <Logo />
      </Flex>

      {/* Top level nav */}
      <Nav />

      {/* 🔍 👩‍💻  🛒 */}
      <Ctas />
    </Container>
  );
});

Menu.displayName = 'Menu';
