import {
  position,
  sectionWithHeight,
  color,
  align,
  button,
} from '@settings/common';

const image = ({ position }) => ({
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      description:
        'Provide a brief description of the image for accessibility compliance',
      label: 'Alt',
    },
    {
      name: 'imageDt',
      label: 'Image (desktop)',
      component: 'image',
    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Image position (desktop)',
      options: position.object,
    },
    {
      name: 'image',
      label: 'Image (mobile)',
      component: 'image',
    },
    {
      name: 'position',
      component: 'select',
      label: 'Image position (mobile)',
      options: position.object,
    },
  ],
  defaultValue: {
    alt: 'Hero image',
    imageDt: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HeroBg.png',
    },
    positionDt: 'left center',
    image: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Screen_Shot_2022-04-27_at_2.33.14_PM.png',
    },
    position: 'left center',
  },
});

const text = ({ color }) => ({
  label: 'Text Settings',
  name: 'text',
  description: 'Superheading, heading, subheading, text color',
  component: 'group',
  fields: [
    {
      name: 'superheading',
      component: 'text',
      label: 'Superheading',
    },
    {
      name: 'heading',
      component: 'markdown',
      label: 'Heading',
      description:
        'Tip: You can ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1"',
    },
    {
      name: 'headingOption',
      label: 'Font Family Heading Option',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Reckless Neue', value: 'text.h1' },
        { label: 'Fellix', value: 'text.h1.2' },
      ],
      defaultValue: 'text.h1',
    },
    {
      name: 'subheading',
      component: 'markdown',
      label: 'Subheading / Body',
      description:
        'Tip: You can add in-line links by highlighting the section of text and clicking the link button in the menu options',
    },
    {
      component: 'select',
      name: 'colorDt',
      label: 'Text color (tablet & desktop)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      component: 'select',
      name: 'color',
      label: 'Text color (mobile)',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
  ],
  defaultValue: {
    superheading: 'our commitment',
    heading: '## See what everybody is talking about',
    subheading:
      'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nulla vitae elit libero, a pharetra augue. Aenean Cursus Curabitur blandit tempus. Link here',
    color: '',
    colorDt: '',
  },
});

const buttons = ({ button }) => ({
  label: 'Button Settings',
  name: 'button',
  description: 'Buttons, clickable hero',
  component: 'group',
  fields: [
    {
      label: 'Clickable hero',
      description:
        'Allows entire hero to be clickable, using the first button link as url. Note: You MUST provide a valid URL in a button before this option will activate',
      name: 'clickableHero',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Hide buttons',
      name: 'hideButtons',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      name: 'buttons',
      label: 'Buttons',
      component: 'group-list',
      description: 'Max of 2 buttons',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      validate: {
        maxItems: 2,
      },
      defaultItem: {
        cta: {
          text: 'Learn More',
          url: '/collections/all',
        },
        style: 'inverse',
      },
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
        {
          name: 'styleDt',
          label: 'Button style',
          component: 'select',
          options: button.options,
        },
        {
          name: 'style',
          label: 'Button style (mobile)',
          component: 'select',
          description: 'Optional',
          options: button.options,
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Learn More',
            url: '/collections/all',
          },
          styleDt: 'inverseSecondary',
          style: 'primary',
        },
      ],
    },
  ],
});

const contentSettings = ({ position }) => ({
  name: 'contentSettings',
  label: 'Content Settings',
  description: 'Content alignment, content position',
  component: 'group',
  fields: [
    {
      name: 'placementDt',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      label: 'Placement',
      options: [
        { label: 'Contained', value: 'absolute' },
        { label: 'Below', value: 'relative' },
      ],
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'placement',
      label: 'Placement (mobile)',
      options: [
        { label: 'Contained', value: 'absolute' },
        { label: 'Below', value: 'relative' },
      ],
    },
    {
      name: 'marginDt',
      component: 'number',
      label: 'Contained margin (px)',
      description: 'Optional: Add horizontal margin to the contained placement',
    },
    {
      name: 'margin',
      component: 'number',
      label: 'Contained margin (mobile)',
    },
    {
      name: 'maxWidthDt',
      component: 'number',
      label: 'Max content pixel width',
      description:
        'Note: Leaving this blank will default to the max width of the hero',
    },
    {
      name: 'maxWidth',
      component: 'number',
      label: 'Max content pixel width (mobile)',
    },
    {
      name: 'alignmentDt',
      label: 'Alignment',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Position',
      options: position.flex,
    },
    {
      name: 'alignment',
      label: 'Alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'position',
      component: 'select',
      label: 'Position (mobile)',
      options: position.flex,
    },
  ],
  defaultValue: {
    placementDt: 'absolute',
    placement: 'relative',
    maxWidthDt: 420,
    marginDt: 100,
    alignmentDt: 'left',
    positionDt: 'flex-end center',
    alignment: 'left',
  },
});

const section = ({ sectionWithHeight }) => ({
  ...sectionWithHeight,
  defaultValue: {
    ...sectionWithHeight.desktop,
    desktop: {
      container: 'content',
      maxHeight: 540,
      minHeight: 540,
    },
    ...sectionWithHeight.mobile,
    mobile: {
      container: 'content',
      maxHeight: 240,
      minHeight: 240,
    },
  },
});

const defaultHero = ({ button }) => ({
  image: {
    alt: 'A drink being raised in the air.',
    imageDt: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HeroBg.png',
    },
    positionDt: 'center bottom',
    image: {
      src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/Screen_Shot_2022-04-27_at_2.33.14_PM.png',
    },
    position: 'left center',
  },
  text: {
    superheading: 'Our commitment',
    heading: '## See what everybody is talking about',
    subheading:
      'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nulla vitae elit libero, a pharetra augue. Aenean Cursus Curabitur blandit tempus. [Link here](./)',
  },
  button: {
    buttons: [
      {
        cta: {
          text: 'learn more',
          url: '/collections/all',
        },
        styleDt: button.value.inverseSecondary,
        style: button.value.primary,
      },
    ],
  },
  contentSettings: {
    placementDt: 'absolute',
    placement: 'relative',
    maxWidthDt: 420,
    marginDt: 100,
    alignmentDt: 'left',
    positionDt: 'flex-end center',
    alignment: 'left',
  },
});

export const Schema = () => {
  return {
    label: 'Hero',
    key: 'hero',
    fields: [
      image({ position }),
      text({ color }),
      buttons({ button }),
      contentSettings({ position }),
      section({ sectionWithHeight }),
    ],
    defaultValue: defaultHero({ button }),
  };
};
