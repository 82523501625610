import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      alignItems: 'center',
      mb: [13, 14],
      flex: 1,
    };

    this.heading = {
      variant: 'text.h2',
    };

    this.footer = {
      display: ['flex', 'none'],
      mt: 14,
      px: 10,
    };
  })()
);
