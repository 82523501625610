import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.outer = {
      width: '100%',
      justifyContent: 'center',
      alignItems: ['flex-start', 'center'],
      px: 12,
      py: [18, 16],
      '@media only screen and (max-width: 479px)': {
        py: 15,
      },
    };

    this.superheading = {
      variant: 'text.label.1',
      mb: 2,
    };

    this.heading = {
      variant: 'text.h2',
    };

    this.bodyWithoutHeading = {
      variant: 'text.base',
      mt: 0,
      ul: {
        ml: 0,
      },
      'p:last-of-type': {
        mb: 0,
      },
    };

    this.bodyWithHeading = {
      ...this.bodyWithoutHeading,
    };
  })()
);
