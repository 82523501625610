import { WishlistPage } from '@snippets';
import { withInView } from '@utils';
import { Schema } from './Wishlist.schema';

export const Wishlist = withInView(
  ({ cms }) => {
    return <WishlistPage cms={cms} />;
  },
  { triggerOnce: true }
);

Wishlist.Schema = Schema;
