import { useState, useCallback, useEffect, useMemo } from 'react';
import { useCartItems } from '@backpackjs/storefront';

import { useSearchspringBeacon } from '@hooks';

export const useUpsellBeacon = ({
  profileData,
  profileTag,
  visibleProduct,
}) => {
  const cartItems = useCartItems();
  const { sendEventsToBeacon } = useSearchspringBeacon();

  const [mounted, setMounted] = useState(false);

  const cartSkus = useMemo(
    () => cartItems?.map((item) => ({ sku: item.variant.sku })) || [],
    [cartItems]
  );

  const sendBeaconEventsOnCartOpen = useCallback(async () => {
    try {
      if (mounted || !profileData?.results || !visibleProduct || !profileTag)
        return;

      await sendEventsToBeacon({
        profileTypes: ['profile.render', 'profile.impression'],
        productTypes: ['profile.product.render', 'profile.product.impression'],
        tag: profileTag,
        placement: 'basket-page',
        products: profileData.results,
        visibleProducts: [visibleProduct],
        seed: cartSkus,
      });
      setMounted(true);
    } catch (error) {
      console.error(error.message);
    }
  }, [profileData?.results, visibleProduct, profileTag, mounted, cartSkus]);

  const sendBeaconEventsOnClick = useCallback(
    async (event) => {
      try {
        if (!profileTag) return;
        const dataComp = [...(event.target.attributes || [])].find(
          (attr) => attr.name === 'data-comp'
        );
        const productClicked =
          dataComp?.value === 'UpsellAddToCart' ||
          dataComp?.value === 'UpsellSliderItemHeading' ||
          dataComp?.value === 'PictureImage';

        await sendEventsToBeacon({
          profileTypes: ['profile.click'],
          productTypes: productClicked ? ['profile.product.click'] : [],
          tag: profileTag,
          placement: 'basket-page',
          clickedProduct: productClicked ? visibleProduct : null,
          seed: cartSkus,
        });
      } catch (error) {
        console.error(error.message);
      }
    },
    [profileTag, visibleProduct, cartSkus]
  );

  const sendBeaconEventsOnProductsInView = useCallback(async () => {
    try {
      if (!mounted || !visibleProduct || !profileTag) return;

      await sendEventsToBeacon({
        productTypes: ['profile.product.impression'],
        tag: profileTag,
        placement: 'basket-page',
        visibleProducts: [visibleProduct],
        seed: cartSkus,
      });
    } catch (error) {
      console.error(error.message);
    }
  }, [visibleProduct, profileTag, mounted, cartSkus]);

  useEffect(() => {
    sendBeaconEventsOnCartOpen();
  }, [profileData?.results, visibleProduct, profileTag]);

  useEffect(() => {
    sendBeaconEventsOnProductsInView();
  }, [visibleProduct?.id]);

  return { sendBeaconEventsOnClick };
};
