import { useCallback } from 'react';
import _xorBy from 'lodash.xorby';
import store, { useRecoilState, useRecoilValue } from '@store';
import { Button } from 'theme-ui';
import { Svg } from '@snippets';
import { useWishlist } from '@hooks';
import { themed } from './Favorite.theme';

export const Favorite = themed(({ theme, selectedVariant, isPdp }) => {
  const { updateWishlist } = useWishlist();
  const [wishlist, setWishlist] = useRecoilState(store.wishlist);
  const lid = useRecoilValue(store.wishlistId);
  const wishlistEmail = useRecoilValue(store.wishlistEmail);
  const isWishlisted = useRecoilValue(
    store.variantIsWishlisted(selectedVariant)
  );

  const toggleWishlist = useCallback(
    async (event) => {
      event.preventDefault();

      const item = {
        epi: parseInt(selectedVariant.legacyResourceId, 10),
        empi: parseInt(selectedVariant.product.legacyResourceId, 10),
        du: `https://${process.env.SHOPIFY_DOMAIN}/products/${selectedVariant.product.handle}`,
      };

      const updatedWishlist = _xorBy(wishlist, [item], 'epi');
      setWishlist(updatedWishlist);

      if (wishlistEmail) {
        await updateWishlist({
          params: {
            lid,
            email: wishlistEmail,
            add: isWishlisted ? [] : [item],
            remove: isWishlisted ? [item] : [],
          },
        });
      }
    },
    [selectedVariant, wishlist, isWishlisted]
  );

  return (
    <Button
      data-comp={Favorite.displayName}
      variant="buttons.plain"
      aria-label={
        isWishlisted
          ? `Add ${selectedVariant?.product?.title || ''} to favorites.`
          : `Remove ${selectedVariant?.product?.title || ''} from favorites.`
      }
      onClick={toggleWishlist}
      sx={theme.button(isPdp)}
    >
      {isWishlisted ? (
        <Svg
          viewBox="0 0 18 15"
          alt="Favorite"
          src="/svgs/heart-filled.svg#heart"
          sx={theme.icon}
        />
      ) : (
        <Svg
          viewBox="0 0 18 15"
          alt="Favorite"
          src="/svgs/heart.svg#heart"
          sx={theme.icon}
        />
      )}
    </Button>
  );
});

Favorite.displayName = 'Favorite';
