/* eslint-disable import/no-unresolved */
import { useMemo } from 'react';
import { Box, Paragraph } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Slider.theme';

export const Slide = themed(({ theme, stars, title, review, name }) => {
  if (!review.length) return null;
  const reviewBody = useMemo(() => {
    if (review.length === 0) return null;

    const reviewCharLimit = 70;
    const slicedReview = review.slice(0, reviewCharLimit);
    const lastSpaceIndex = slicedReview.lastIndexOf(' ');
    const lastPeriodIndex = slicedReview.lastIndexOf('.');
    const exclaimationIndex = slicedReview.lastIndexOf('!');

    if (review.length < reviewCharLimit) return review;

    if (lastPeriodIndex !== -1 || exclaimationIndex !== -1) {
      return slicedReview.slice(
        0,
        lastPeriodIndex + 1 || exclaimationIndex + 1
      );
    }
    return `${slicedReview.slice(0, lastSpaceIndex + 1)}...`;
  }, [review]);

  const starArray = stars ? [...Array(stars)] : [];

  return (
    <Box data-comp={Slide.displayName} sx={theme.slideWrapper}>
      <Box sx={theme.starWrapper}>
        {starArray &&
          starArray.map((star, index) => {
            return (
              <Svg
                alt={`${index} ${index === 0 ? 'star' : 'stars'}`}
                viewBox="0 0 18 18"
                key={index}
                src="/svgs/star.svg"
                sx={theme.stars}
              />
            );
          })}
      </Box>
      <Paragraph as="h3" variant="text.h3" sx={theme.title}>
        {title.length < 20 ? title : `${stars} Stars!`}
      </Paragraph>
      <Box sx={theme.review}>
        <Paragraph sx={theme.paragraph}>{reviewBody}</Paragraph>
      </Box>
      <Paragraph sx={theme.displayName}>{`- ${name}`}</Paragraph>
    </Box>
  );
});

Slide.displayName = 'Slide';
