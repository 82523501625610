/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMemo } from 'react';
import { Container } from 'theme-ui';

import { Link } from '@snippets';

import { Image } from './Image';
import { Content } from './Content';
import { themed } from './Tile.theme';

export const Tile = themed(({ theme, article, tileSettings }) => {
  const { image, content } = tileSettings;

  const articleImage = useMemo(() => {
    if (!article?.excerpt) return null;
    if (article.seo?.image) return article.seo.image;
    if (!article.bodyHtml) return null;
    const jpg = article.bodyHtml?.slice(
      article?.bodyHtml.indexOf('cdn'),
      article?.bodyHtml.indexOf('.jpg')
    );
    const png = article?.bodyHtml?.slice(
      article?.bodyHtml.indexOf('cdn'),
      article?.bodyHtml.indexOf('.png')
    );
    const srcUrl = jpg?.length < 200 ? jpg : png;
    const extension = jpg?.length < 200 ? '.jpg' : '.png';
    return jpg || png
      ? `https://${srcUrl}${extension}`
      : 'https://cdn.shopify.com/s/files/1/1114/2308/files/brumate-blog-placeholder.jpg';
  }, [article]);

  const imageTop = image?.location === 'top';
  const url = `/articles/${article?.handle}`;

  return article.excerpt ? (
    <Container
      data-comp={Tile.displayName}
      sx={{
        ...theme.grid,
        flexDirection: imageTop ? 'column' : 'column-reverse',
      }}
    >
      <Link href={url} aria-label={article?.title} sx={{ cursor: 'pointer' }}>
        <Image
          articleImage={articleImage}
          alt={article?.title}
          sx={theme.image}
        />
      </Link>

      <Content article={article} settings={content} url={url} />
    </Container>
  ) : null;
});

Tile.displayName = 'Tile';
