import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.button = {
      display: 'flex',
    };

    this.swatchOuter = (isActive) => ({
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      position: 'relative',
      overflow: 'hidden',
      bg: 'white',
      borderStyle: !isActive ? 'none' : 'solid',
      borderWidth: '1px',
      borderColor: 'text',
      mr: 7,
    });

    this.swatchInner = ({
      isActive,
      isWhite,
      isImage,
      bgColor,
      borderColor,
    }) => ({
      width: isActive ? 'calc(100% - 4px)' : '100%',
      height: isActive ? 'calc(100% - 4px)' : '100%',
      borderRadius: '50%',
      borderWidth: '1px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      bg: isActive && isWhite ? 'lightGray' : bgColor,
      borderStyle: isImage || isActive ? 'none' : 'solid',
      borderColor,
    });

    this.image = (isActive) => ({
      position: 'absolute',
      width: isActive ? 'calc(100% + 4px)' : '100%',
      height: isActive ? 'calc(100% + 4px)' : '100%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    });

    this.checkmark = (isWhite) => ({
      color: isWhite ? 'text' : 'white',
      width: '8px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    });

    this.label = (isActive) => ({
      variant: 'text.lg',
      color: isActive ? 'text' : 'mediumDarkGray',
      textDecoration: isActive ? 'underline' : 'unset',
      textUnderlineOffset: '2px',
      textDecorationThickness: '1px',
      textAlign: 'left',
      flex: 1,
    });
  })()
);
