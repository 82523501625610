import { Heading, Flex, Button, Paragraph, Box } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { FreeShippingMeter, useCartCount } from '@snippets/Cart';
import { Svg } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from './Header.theme';

export const Header = themed(({ theme, inputRef, ...props }) => {
  const cartCount = useCartCount();
  const [{ isCart, isColors }, { closeSidebar, openCartSidebar }] =
    useSidebar();
  const settings = useSettings();
  const cartHeading = settings?.cart?.heading || 'Cart';
  const cartSearchHeading = isCart ? cartHeading : 'Search';

  // Cart or Search — side bars
  return (
    <Box data-comp={Header.displayName} ref={inputRef} {...props}>
      <Flex sx={theme.wrapper}>
        <Button
          variant="buttons.plain"
          onClick={closeSidebar}
          aria-label={
            isColors
              ? 'Close Colors Sidebar'
              : `Close ${isCart ? 'Cart' : 'Search'}`
          }
          sx={{
            position: 'absolute',
            left: 10,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <Svg
            viewBox="0 0 24 24"
            alt="Close"
            src="/svgs/arrow-left.svg#arrow-left"
            sx={theme.back}
          />
        </Button>

        <Heading as="h5" sx={theme.heading}>
          {isColors ? 'Color' : cartSearchHeading}
        </Heading>

        {/* Cart Toggle icon */}
        {!isColors && (
          <Flex
            sx={{
              variant: 'flex.row.start.center',
              height: '100%',
              pl: 10,
              width: '80px',
              // borderLeft: t => `1px solid ${t.colors.gray}`,
              position: 'absolute',
              right: 0,
              top: 0,
              bg: 'dark',
            }}
          >
            <Button
              variant="buttons.plain"
              onClick={openCartSidebar}
              aria-label="Open cart"
            >
              <Svg
                alt="cart"
                viewBox="0 0 48 48"
                src="/svgs/cta/cart.svg#cart"
                sx={{
                  width: '20px',
                  color: 'white',
                }}
              />
            </Button>

            <Paragraph
              sx={{
                variant:
                  cartCount?.toFixed(0)?.length > 2
                    ? 'text.label.2'
                    : 'text.label.1',
                ml: 2,
                userSelect: 'none',
                color: 'white',
              }}
            >
              ({cartCount})
            </Paragraph>
          </Flex>
        )}
      </Flex>

      {isCart && <FreeShippingMeter />}
    </Box>
  );
});

Header.displayName = 'CartSearchSidebarHeader';
