import {
  Flex,
  Input,
  Label,
  Text,
  Checkbox,
  Radio,
  Select,
  Paragraph,
  Box,
  Textarea,
} from 'theme-ui';
import { pascalCase } from 'change-case';

import { Link } from '@snippets';

import { themed } from './Form.theme';

export const FormField = themed(({ element, onInputChange, formResponse }) => {
  const { type, label, column, position, placeholder } = element;
  const isTextarea = type === 'textarea';
  const isRadio = type === 'radio';
  const isSelect = type === 'select';
  const isHidden = type === 'hidden';

  const isRatings = type === 'ratings';

  // Non inputs
  const isLabel = type === 'label';
  const isSpace = type === 'space';
  const isLineBreak = type === 'lineBreak';
  const isRawHtml = type === 'raw_html';
  const isHeadings = type === 'headings';
  const isParagraph = type === 'paragraph';
  const isHtml = isRawHtml || isHeadings || isParagraph;
  const hasLabel = !isHtml && !isSpace && !isLineBreak && !isHidden && !isLabel;

  // Uses <checkbox>
  const isCheckbox = type === 'checkbox';
  const isMultiCheckbox = type === 'multipleCheckbox';
  const isTerms = type === 'terms_conditions';
  const isCheckboxType = isCheckbox || isMultiCheckbox || isTerms;

  // Uses <input type="file">
  const isFile = type === 'file';
  const isImage = type === 'image';
  const isSignature = type === 'signature';
  const isFileType = isFile || isImage || isSignature;

  // Uses <input>
  const isText = type === 'text';
  const isEmail = type === 'email';
  const isPhone = type === 'phone';
  const isDate = type === 'newdate';
  const isNumber = type === 'number';
  const isUrl = type === 'url';
  const isTime = type === 'time';
  const isAddress = type === 'address';
  const isInput =
    isText ||
    isEmail ||
    isPhone ||
    isDate ||
    isNumber ||
    isUrl ||
    isTime ||
    isAddress;

  const isNotFullWidth = isCheckbox || isRadio || isFileType || isCheckboxType;

  // settings
  const isHalfWidth = element.halfwidth === 'yes';
  const isRequired = element.required === 'yes';
  let hideElement = false;
  const conditionValue = element.Conditions.SelectedValue;
  const conditionElement = element.Conditions.SelectedElemenet;
  if (conditionValue) {
    let conditionMet;
    if (typeof formResponse?.[conditionElement] === 'string') {
      conditionMet =
        formResponse[conditionElement].toLowerCase() ===
        conditionValue?.toLowerCase();
    } else {
      conditionMet = formResponse?.[conditionElement] === conditionValue;
    }
    hideElement = !conditionMet;
  }

  const values = element.values
    ?.split('\n')
    ?.map((value) => value.trim())
    .filter(Boolean);

  return !hideElement ? (
    <Flex
      data-comp={FormField.displayName}
      key={position}
      sx={{
        flexDirection: 'column',
        width: '100%',
        gridColumn: isHalfWidth ? column : `1 / span 2`,
        alignItems: isNotFullWidth ? 'flex-start' : 'stretch',
        label: { mb: 0 },
        input: { p: 3 },
      }}
    >
      <Label
        onChange={({ target }) => onInputChange({ target, element })}
        className={isLabel ? element.customClass || null : null}
        id={isLabel ? element.customID || null : null}
        sx={{
          width: isNotFullWidth ? 'auto' : '100%',
          display: 'flex',
          flexDirection: isCheckbox || isTerms ? 'row' : 'column-reverse',
          columnGap: 2,
          rowGap: 3,
          justifySelf:
            isCheckbox || isRadio || isFileType ? 'flex-start' : 'stretch',
        }}
      >
        {isLabel && label}

        {isFileType && (
          <Input
            data-comp={pascalCase(type)}
            type="file"
            required={isRequired}
            name={label}
            className={element.customClass || null}
            id={element.customID || null}
            onChange={({ target }) => onInputChange({ target, element })}
          />
        )}

        {isInput && (
          <Input
            data-comp={pascalCase(type)}
            // eslint-disable-next-line no-nested-ternary
            type={isDate ? 'date' : isPhone ? 'tel' : type}
            required={isRequired}
            placeholder={placeholder}
            name={label}
            className={element.customClass || null}
            id={element.customID || null}
            onChange={({ target }) => onInputChange({ target, element })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(isNumber
              ? { min: element.minNumber || '', max: element.maxNumber || '' }
              : null)}
          />
        )}

        {isHtml && (
          <Box
            data-comp={pascalCase(type)}
            dangerouslySetInnerHTML={{
              __html: element.rowHtmlCode || element[type],
            }}
          />
        )}

        {isTextarea && (
          <Textarea
            data-comp={pascalCase(type)}
            type={type}
            required={isRequired}
            name={label}
            className={element.customClass || null}
            id={element.customID || null}
            onChange={({ target }) => onInputChange({ target, element })}
            placeholder={placeholder}
            sx={{ resize: 'none', p: 3 }}
          />
        )}

        {isTerms && (
          <Checkbox
            data-comp={pascalCase(type)}
            required={isRequired}
            name={label}
            checked={formResponse?.[label] === 'on'}
            className={element.customClass || null}
            id={element.customID || null}
            onClick={({ target }) =>
              onInputChange({
                target,
                element,
              })
            }
          />
        )}

        {isCheckbox && (
          <Checkbox
            data-comp={pascalCase(type)}
            required={isRequired}
            name={label}
            checked={formResponse?.[label] === 'on'}
            className={element.customClass || null}
            id={element.customID || null}
            onClick={({ target }) =>
              onInputChange({
                target,
                element,
              })
            }
          />
        )}

        {isMultiCheckbox && (
          <Flex
            data-comp={pascalCase(type)}
            sx={{
              flexDirection: element.align === 'vertical' ? 'column' : 'row',
              columnGap: 4,
              rowGap: 2,
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
            }}
          >
            {values?.map((value) => (
              <Label key={value}>
                <Checkbox
                  required={isRequired}
                  name={label}
                  value={value}
                  className={element.customClass || null}
                  id={element.customID || null}
                  onClick={({ target }) => {
                    onInputChange({
                      target,
                      element,
                    });
                  }}
                />
                <Link href={element.redirect || ''} newTab>
                  {value}
                </Link>
              </Label>
            ))}
          </Flex>
        )}

        {isRadio && (
          <Flex
            data-comp={pascalCase(type)}
            sx={{
              flexDirection: element.align === 'vertical' ? 'column' : 'row',
              columnGap: 4,
              rowGap: 2,
              justifyContent: 'flex-start',
              alignSelf: 'flex-start',
            }}
          >
            {values?.map((value) => (
              <Label key={value}>
                <Radio
                  value={value}
                  name={label}
                  className={element.customClass || null}
                  id={element.customID || null}
                  onClick={({ target }) => onInputChange({ target, element })}
                />
                <Text>{value}</Text>
              </Label>
            ))}
          </Flex>
        )}

        {isSelect && (
          <Select
            data-comp={pascalCase(type)}
            required={isRequired}
            name={label}
            className={element.customClass || null}
            id={element.customID || null}
            onChange={({ target }) => onInputChange({ target, element })}
          >
            {[placeholder || '', ...(values || [])].map((value, index) => (
              <option
                key={value}
                value={value}
                disabled={index === 0}
                selected={index === 0}
              >
                {value}
              </option>
            ))}
          </Select>
        )}

        {isSpace && (
          <Box
            data-comp={pascalCase(type)}
            sx={{ height: '16px', bg: 'transparent' }}
          />
        )}

        {isLineBreak && (
          <Box
            data-comp={pascalCase(type)}
            sx={{ height: '1px', bg: 'gray', my: '15px' }}
          />
        )}

        {isHidden && (
          <Input
            data-comp={pascalCase(type)}
            name={label}
            type="hidden"
            value={element.fieldvalue}
          />
        )}

        {isRatings && (
          <Paragraph>Ratings field is not yet implemented.</Paragraph>
        )}

        {hasLabel && (
          <Text
            dangerouslySetInnerHTML={{
              __html: label + (isRequired ? ' *' : ''),
            }}
            sx={{}}
          />
        )}
      </Label>

      {element.infoMessage && (
        <Paragraph
          sx={{
            variant: 'text.sm',
            color: 'mediumDarkGray',
            mt: 2,
          }}
        >
          {element.infoMessage}
        </Paragraph>
      )}
    </Flex>
  ) : null;
});

FormField.displayName = 'FormField';
