import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.badge = (isPdp) => ({
      variant: 'text.label.1',
      fontSize: '11px',
      textTransform: 'capitalize',
      position: 'absolute',
      top: isPdp ? [0, 10] : 0,
      left: isPdp ? [0, 10] : 0,
      zIndex: 1,
      height: '24px',
      lineHeight: '24px',
      bg: 'red',
      color: 'white',
      px: '11px',
      pointerEvents: 'none',
    });
  })()
);
