import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.tile = {
      position: 'relative',
    };

    this.tileLink = {
      ...this.tile,
      img: {
        transform: 'scale(1.01)',
        transition: 'transform 0.3s ease-in-out',
      },
      ':hover': {
        img: {
          transform: 'scale(1.03)',
        },
      },
    };

    this.contentRelative = {
      flexDirection: 'column',
      position: 'relative',
      py: [4, 8],
    };

    this.contentAbsolute = {
      ...this.contentRelative,
      position: 'absolute',
      pointerEvents: 'none',
      width: '100%',
      bottom: 0,
      left: 0,
      mt: 0,
      p: [10, 10, 14],
    };

    this.heading = {
      variant: 'text.h3',
    };

    this.body = {
      variant: 'text.lg',
      py: [0, 0],
    };

    this.flexed = {
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        svg: {
          transform: 'translate3d(5px, 0, 0)',
          transition: 'transform 0.3s ease-in-out',
        },
      },
    };

    this.icon = {
      ml: 7,
      width: '24px',
    };

    this.button = {
      mt: 8,
      pointerEvents: 'auto',
    };
  })()
);
