import { useEffect, useCallback, useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { GiftWithPurchase, StatefulButton, BackInStockModal } from '@snippets';
import {
  useCartStatus,
  useModal,
  useSidebar,
  useGiftWithPurchase,
} from '@hooks';
import { useAddToCart } from '@snippets/AddToCart/useAddToCart';
import store, { useRecoilValue } from '@store';

import { themed } from './SliderItem.theme';

export const UpsellAddToCart = themed(
  ({ theme, product, selectedVariant, style }) => {
    const settings = useSettings();
    const [{ status, isPreOrder, isSoldOut, selectedPlan }, { addToCart }] =
      useAddToCart({ product, selectedVariant });
    const { started, finished, success, errors } = status.addItem;

    const [, { openSidebar }] = useSidebar();
    const gwp = useGiftWithPurchase();
    const [, { openModal }] = useModal();
    const [{ addingToCartStatus }, { setAddingToCartStatus }] = useCartStatus();

    const cartIsMaxed = useRecoilValue(store.cartIsMaxed);

    const isAddingToCart =
      addingToCartStatus.started && !addingToCartStatus.finished;
    const ignoredProducts = settings?.cart?.bulkOrdering?.ignoredProducts;

    const noBackInStockOption =
      isSoldOut && !isPreOrder && product?.tags?.includes('no-bis');

    const text =
      status?.inventoryStatus?.success &&
      status?.inventoryStatus?.finished &&
      product
        ? noBackInStockOption
          ? 'Sold Out'
          : isPreOrder
          ? 'Pre-order'
          : isSoldOut
          ? 'Notify Me'
          : selectedPlan
          ? 'Subscribe'
          : 'Add to Bag'
        : 'LOADING...';

    const ignoredFromMaxQty = useMemo(
      () =>
        ignoredProducts?.some(
          ({ product: ignoredProduct }) =>
            ignoredProduct.handle === product.handle
        ),
      [product.handle, ignoredProducts]
    );

    const setGlobalCartStatusOnAdding = useCallback(() => {
      setAddingToCartStatus({ started, finished, success, errors });
      return () => {
        setAddingToCartStatus({
          started: false,
          finished: false,
          success: false,
          errors: [],
        });
      };
    }, [started, finished, success]);

    const handleOutOfStock = useCallback(() => {
      openModal(
        <BackInStockModal
          legacyResourceId={selectedVariant?.legacyResourceId}
          title={product.title || ''}
        />,
        true
      );
    }, [selectedVariant?.id, product.title]);

    useEffect(() => {
      setGlobalCartStatusOnAdding();
    }, [started, finished, success]);

    const isNotifyMe = isSoldOut && !isPreOrder;
    const disabled =
      noBackInStockOption ||
      isAddingToCart ||
      (cartIsMaxed && !isNotifyMe && !ignoredFromMaxQty);

    return (
      <StatefulButton
        data-comp={UpsellAddToCart.displayName}
        variant="buttons.link.1"
        disabled={disabled}
        loading={!status?.inventoryStatus?.success}
        sx={{
          ...theme.atc,
          cursor: disabled ? 'default' : 'pointer',
          color: disabled ? 'gray' : 'text',
          ':hover': disabled
            ? {
                ':after': { transform: 'translateX(0)' },
                ':before': { transform: 'translateX(0)' },
                color: 'gray',
              }
            : {},
          ...style,
        }}
        {...status.addItem}
        text={{
          default: text,
          started: text,
          success: text,
          error: text,
        }}
        onClick={async () => {
          if (isNotifyMe) {
            handleOutOfStock();
          } else {
            await addToCart({
              callback: (_cart) => {
                if (gwp.isEligible(_cart)) {
                  openModal(<GiftWithPurchase />);
                } else {
                  openSidebar('cart');
                }
              },
              openCart: false,
            });
          }
        }}
      />
    );
  }
);

UpsellAddToCart.displayName = 'UpsellAddToCart';
