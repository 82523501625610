import { Button } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Quantity.theme';

export const QuantityButton = themed(
  ({ theme, type = 'Decrease', quantity, disabled, isStatic, ...props }) => {
    return (
      <Button
        data-comp={`QuantityButton${type}`}
        variant="buttons.plain"
        disabled={disabled}
        aria-label={`${type} line item quantity`}
        {...props}
        sx={{
          ...theme.button(disabled),
          visibility: isStatic ? 'hidden' : 'visible',
          ...props.sx,
        }}
      >
        <Svg
          viewBox="0 0 48 48"
          alt={type}
          src={
            type === 'Decrease'
              ? '/svgs/minus.svg#minus'
              : '/svgs/plus.svg#plus'
          }
          sx={{
            ...theme.buttonIcon,
            color: disabled ? 'mediumDarkGray' : 'dark',
          }}
        />
      </Button>
    );
  }
);

QuantityButton.displayName = 'QuantityButton';
