import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: 8,
      rowGap: 12,
      px: 8,
      py: 11,
    };

    this.option = {
      display: 'flex',
      flexDirection: 'column',

      available: {
        selected: {
          // border: (t) => `1px solid ${t.colors.text}`,
          bg: 'background',
          color: 'text',
        },
        unselected: {
          // border: (t) => `1px solid ${t.colors.gray}`,
          bg: 'background',
          color: 'text',
        },
      },
    };

    this.color = {
      color: 'mediumDarkGray',
      mt: 4,
      mb: 8,
    };
  })()
);
