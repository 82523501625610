import { store } from '@backpackjs/storefront';

const gwpVariantIds = store.recoil.atom({
  key: 'giftWithPurchase/variantIds',
  default: null,
});

const gwpProducts = store.recoil.atom({
  key: 'giftWithPurchase/products',
  default: null,
});

export default {
  gwpVariantIds,
  gwpProducts,
};
