import { create } from '@theme/create';

const MENU_HEIGHT = ['56px'];

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.header',
      display: 'grid',
      alignItems: 'center',
      bg: 'background',
      columnGap: [4, 8, 12],
      gridTemplateColumns: ['60px 1fr 60px', null, '140px 1fr auto'],
      justifyContent: 'space-between',
      position: 'relative',
      height: MENU_HEIGHT,
      width: '100%',
      zIndex: 2,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'gray',
    };

    // burger + search — mobile
    this.toggle = {
      display: ['flex', null, 'none'],
      width: 100,
    };
    this.logo = {
      cursor: 'pointer',
      mx: 'auto',
      width: '140px',
      color: 'dark',
    };
    this.nav = {
      variant: 'flex.row.start.center',
      display: ['none', null, 'flex'],
      height: 'inherit',
      flex: 1,
    };
    this.item = {
      variant: 'text.navItem',
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      mr: 10,
      opacity: 1,
      position: 'relative',

      underline: {
        content: '""',
        bg: 'text',
        bottom: 0,
        display: 'block',
        height: '1px',
        left: 0,
        position: 'absolute',
        right: 0,
        transition: 'transform .15s ease-in-out',
        transformOrigin: 'bottom center',
        width: '100%',
      },
    };
    this.refer = {
      mr: [5, 7],
      fontSize: 2, // 14px
      lineHeight: 18,
      fontWeight: 500,
      letterSpacing: '0.02em',
      fontFamily: 'body',
      cursor: 'pointer',
    };
    this.ctas = {
      variant: 'flex.row.end.center',
      columnGap: [5, 7],
      gridTemplateColumns: ['1fr', null, '1fr 1fr max-content'],
      ml: 'auto',
      width: ['auto'],
    };
    this.cta = {
      icon: {
        width: [24, null, 20],
        cursor: 'pointer',
      },
    };

    this.cartWrapper = {
      variant: 'flex.row.start.center',
      height: '100%',
      ml: [5, null, 10],
      pl: [0, null, 10],
      width: ['auto', null, '56px', '48px'],
      borderLeftStyle: ['none', null, 'solid'],
      borderLeftWidth: '1px',
      borderLeftColor: 'gray',
      position: 'relative',
    };
  })()
);
