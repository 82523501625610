import { useMemo } from 'react';
import { Select } from 'theme-ui';
import { useCountries } from '@backpackjs/storefront';

import { themed } from './FormBuilder.theme';

const firstCountries = [
  'United States',
  'Canada',
  'United Kingdom',
  'France',
  'Australia',
  'New Zealand',
  '--',
];

export const CountryField = themed(({ label, required, placeholder }) => {
  const countries = useCountries();

  const orderedCountries = useMemo(() => {
    if (!countries?.length) return null;
    return [...countries].sort((a, b) => {
      const aName = a.locale.name;
      const bName = b.locale.name;
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }, [countries?.length]);

  const countryOptions = useMemo(() => {
    if (!orderedCountries?.length) return null;
    const localize = (country) => ({
      locale: { name: country },
    });
    return [
      localize(placeholder || ''),
      ...firstCountries.map(localize),
      ...orderedCountries,
    ];
  }, [orderedCountries?.length, firstCountries, placeholder]);

  return (
    <Select data-comp="Country" required={required} name={label}>
      {countryOptions?.map(({ locale }, index) => (
        <option
          key={locale.name}
          value={locale.name}
          disabled={index === 0 || locale.name === '--'}
          selected={index === 0}
        >
          {locale.name}
        </option>
      ))}
    </Select>
  );
});

CountryField.displayName = 'CountryField';
