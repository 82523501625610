export function Schema({ collection }) {
  if (!collection) return null;

  return {
    label: 'Collection Categories',
    key: 'collection-categories',
    fields: [
      {
        label: 'Name',
        name: 'name',
        component: 'text',
        defaultValue: 'Size',
      },
      {
        name: 'collapsed',
        label: 'Dropdown Collapsed (Desktop)',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: true,
      },
      {
        label: 'Categories',
        name: 'categories',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        defaultItem: {
          link: {
            text: 'Slim (12oz Slim Cans)',
            url: '/collections/hopsulator-slim',
          },
          mobileImage: {
            product: { handle: 'hopsulator-slim-onyx-leopard-12oz-slim-cans' },
          },
        },
        fields: [
          {
            label: 'Collection Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Image',
            name: 'mobileImage',
            description:
              'Use default image of specific product or manual upload of image',
            component: 'group',
            fields: [
              {
                name: 'image',
                label: 'Manual Image',
                component: 'image',
                description:
                  'If provided, this image will override the default image of the product',
              },
              {
                name: 'product',
                component: 'productSearch',
                label: 'Default Image of Specific Product',
              },
            ],
            defaultValue: {},
          },
        ],
        defaultValue: [
          {
            link: {
              text: 'All Can Holders',
              url: '/collections/can-holders',
            },
            mobileImage: {
              product: {
                handle: 'hopsulator-slim-gold-leopard-12oz-slim-cans',
              },
            },
          },
          {
            link: {
              text: 'Slim (12oz Slim Cans)',
              url: '/collections/hopsulator-slim',
            },
            mobileImage: {
              product: {
                handle: 'hopsulator-slim-onyx-leopard-12oz-slim-cans',
              },
            },
          },
          {
            link: {
              text: 'Trio (12/16oz Cans)',
              url: '/collections/hopsulator-trio',
            },
            mobileImage: {
              product: {
                handle: 'hopsulator-trio-v2-0-by-brumate-matte-navy',
              },
            },
          },
        ],
      },
    ],
  };
}
