import { Box, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Accordions, Accordion, Link } from '@snippets';

import { themed } from './MobileMenu.theme';

export const MobileMenu = themed(({ theme, inputRef, ...props }) => {
  const settings = useSettings();
  const menu = settings?.footer?.menu;

  return (
    <Box data-comp={MobileMenu.displayName} {...props} sx={theme.grid}>
      <Accordions
        textColor="white"
        borderColor="mediumDarkGray"
        iconColor="white"
        minHeight="52px"
      >
        {Array.isArray(menu)
          ? menu.map((menuItem, index) => (
              <Accordion key={index}>
                <Accordion.Header sx={theme.header}>
                  {menuItem.name}
                </Accordion.Header>

                <Accordion.Body sx={theme.body}>
                  {({ isOpen }) =>
                    menuItem.subMenuItems?.map((subMenuItem, subIndex) => {
                      return (
                        <Flex key={subIndex} as="li" sx={theme.item}>
                          <Link
                            href={subMenuItem.cta?.url}
                            newTab={subMenuItem.cta?.newTab}
                            tabIndex={isOpen ? '0' : '-1'}
                            aria-hidden={isOpen ? 'false' : 'true'}
                            sx={theme.link}
                          >
                            {subMenuItem.cta?.text}
                          </Link>
                        </Flex>
                      );
                    })
                  }
                </Accordion.Body>
              </Accordion>
            ))
          : []}
      </Accordions>
    </Box>
  );
});

MobileMenu.displayName = 'MobileMenu';
