import { useState } from 'react';
import { Box, Flex, Grid, Heading } from 'theme-ui';
import Expand from 'react-expand-animated';

import { Svg, Link } from '@snippets';
import { useMenuSidebar } from '../useMenuSidebar';
import { ExpandableMenuLink } from './ExpandableMenuLink';
import { themed } from './ExpandableMenu.theme';

export const ExpandableMenu = themed(({ theme, item }) => {
  const [{}, { closeMenuSidebar }] = useMenuSidebar();
  const [open, setOpen] = useState(false);
  const {
    link,
    color: textColor,
    tertiaryMenu,
    mobileLayout = 'cards',
    hideShopAll,
  } = item;

  return (
    <>
      <Box
        data-comp={ExpandableMenu.displayName}
        sx={theme.expandableMenu(open)}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Heading
          sx={{ ...theme.expandableMenuHeading, color: textColor || 'black' }}
        >
          {link.text}
        </Heading>
        <Svg
          viewBox="0 0 16 16"
          src="/svgs/chevron/down.svg#down"
          sx={theme.expandableMenuArrowIcon(open)}
        />
      </Box>
      <Expand
        open={open}
        duration={300}
        sx={{
          ...(open
            ? {
                borderBottom: (t) => `1px solid ${t.colors.black}`,
              }
            : ''),
        }}
      >
        {!hideShopAll && (
          <Box
            sx={{
              px: 8,
              mb: mobileLayout === 'images' ? 4 : 10,
            }}
          >
            <Link
              href={link?.url}
              newTab={link?.newTab}
              aria-label={link?.text}
              onClick={closeMenuSidebar}
              sx={theme.epandableMenuHeadingLink}
            >
              All {link?.text}
            </Link>
          </Box>
        )}
        {mobileLayout === 'images' ? (
          <Flex variant="flex.column" as="ul">
            {tertiaryMenu?.map((item) => {
              return <ExpandableMenuLink item={item} layout={mobileLayout} />;
            })}
          </Flex>
        ) : (
          <Grid
            sx={{
              gridTemplateColumns: '1fr 1fr',
              gridGap: '20px 47px',
              px: 8,
              mb: 12,
            }}
          >
            {tertiaryMenu?.map((item) => {
              return <ExpandableMenuLink item={item} layout={mobileLayout} />;
            })}
          </Grid>
        )}
      </Expand>
    </>
  );
});

ExpandableMenu.displayName = 'ExpandableMenu';
