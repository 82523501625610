import { useEffect } from 'react';
import { Flex, Box } from 'theme-ui';

import store, { useRecoilValue } from '@store';
import { useDataLayerActions } from '@hooks';

import { useSearch } from '../hooks';
import { Product } from './Product';

import { themed } from './Results.theme';

export const Results = themed(({ theme, ...props }) => {
  const [{ results, totalResults }] = useSearch();
  const { sendViewSearchResultsEvent } = useDataLayerActions();
  const searchTerm = useRecoilValue(store.term);

  useEffect(() => {
    sendViewSearchResultsEvent({
      products: results,
      searchTerm,
    });
  }, [results, sendViewSearchResultsEvent]);

  return (
    <Flex
      data-comp={Results.displayName}
      sx={{
        ...props.sx,
        ...(totalResults ? theme.found : theme.notFound),
      }}
    >
      {/* Product Results */}
      {totalResults ? (
        <Flex sx={theme.products}>
          <Flex sx={theme.list} as="ul">
            {results.map((product, index, arr) => {
              if (product.isBanner) return null;
              return (
                <Box key={product.id} as="li">
                  <Product
                    product={product}
                    isLast={index === arr.length - 1}
                    index={index}
                    searchTerm={searchTerm}
                  />
                </Box>
              );
            })}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
});

Results.displayName = 'Results';
