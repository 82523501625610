import { promoBar } from './promoBar';
import { menu } from './menu';
import { bestSellers } from './bestSellers';
import { linksList } from './linksList';
import { refer } from './refer';

// header
export default {
  label: 'Header Settings',
  name: 'header',
  component: 'group',
  fields: [promoBar, menu, bestSellers, linksList, refer],
};
