import { useEffect, useState, useCallback, useRef } from 'react';

export const useForm = ({ formId }) => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const [formResponse, setFormResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [message, setMessage] = useState(null);

  const details = formData?.form_details;
  const successMessage = details?.after_submit_msg;

  const getForm = useCallback(async () => {
    try {
      if (!formId) {
        return console.error('No formId provided');
      }

      const url = '/api/hulkapps';
      const options = {
        method: 'POST',
        body: JSON.stringify({
          action: 'getForm',
          formId,
        }),
      };

      const response = await fetch(url, options);
      const data = await response.json();

      // Generate default form response on form mount
      const _formResponse = data?.data?.form_details?.formElements?.reduce(
        (obj, { type, label, checked, values }) => {
          if (
            [
              'label',
              'raw_html',
              'space',
              'lineBreak',
              'headings',
              'paragraph',
            ].includes(type)
          )
            return obj;

          const isCheckbox = type === 'checkbox' || type === 'terms_conditions';
          const isChecked = isCheckbox && checked === 'yes';
          const value = isChecked ? 'on' : '';

          const isMultiCheckbox = type === 'multipleCheckbox';
          if (isMultiCheckbox) {
            const maxChoices = values?.split('\n').length;
            obj[label] = Array(maxChoices).fill('');
          }

          // If there are more than one field with same label name, generate array of answers
          if (Array.isArray(obj[label])) {
            obj[label].push(value);
          } else if (obj[label] !== undefined) {
            obj[label] = [obj[label], value];
          } else {
            obj[label] = value;
          }
          return obj;
        },
        {}
      );

      // Determine the intended column number for the form field
      let columnCounter = 0;
      const mapWithIndexes = {};
      const formElements = data?.data?.form_details?.formElements?.map(
        (element) => {
          const isHalfWidth = element.halfwidth === 'yes';
          let column = 1;
          if (columnCounter % 2 !== 0 && !isHalfWidth) {
            columnCounter = 0;
            column = 1;
          } else if (columnCounter % 2 === 0 && !isHalfWidth) {
            columnCounter += 2;
            column = 2;
          } else if (columnCounter % 2 !== 0 && isHalfWidth) {
            columnCounter += 1;
            column = 2;
          } else if (columnCounter % 2 === 0 && isHalfWidth) {
            columnCounter += 1;
            column = 1;
          }

          if (mapWithIndexes[element.label] !== undefined) {
            mapWithIndexes[element.label] += 1;
          } else {
            mapWithIndexes[element.label] = 0;
          }

          return {
            ...element,
            column,
            index: mapWithIndexes[element.label],
          };
        }
      );

      const _formData = data?.data
        ? {
            ...data?.data,
            form_details: {
              ...data?.data?.form_details,
              formElements,
            },
          }
        : null;
      setFormData(_formData);
      setFormResponse(_formResponse);
      return _formData;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [formId]);

  const submitForm = useCallback(
    async ({ event, captchaLoaded }) => {
      try {
        event.preventDefault();

        if (!formId) {
          return console.error('No formId provided');
        }
        if (!formResponse) {
          return console.error('No formResponse provided');
        }

        setIsSubmitting(true);
        setErrors(null);
        setMessage(null);

        // Check if captcha is verified if captcha was originally rendered
        if (captchaLoaded) {
          const captchaResponse = await window.grecaptcha?.getResponse();
          if (!captchaResponse) {
            setIsSubmitting(false);
            return setErrors(['Please verify that you are not a robot']);
          }
        }

        const parsedFormReponse = Object.entries(formResponse).reduce(
          (obj, [key, value]) => {
            if (Array.isArray(value) && value[0]?.isMultiCheckbox) {
              obj[key] = value.slice(1).filter(Boolean).join('\n');
            } else {
              obj[key] = value;
            }
            return obj;
          },
          {}
        );

        const url = '/api/hulkapps';
        const options = {
          method: 'POST',
          body: JSON.stringify({
            action: 'submitForm',
            formId,
            formResponse: parsedFormReponse,
          }),
        };

        const response = await fetch(url, options);
        const data = await response.json();
        console.log('submitForm data', data);

        const error = data?.data?.error;
        const hasErrors = error?.length > 0;

        if (data?.ok && !hasErrors) {
          setMessage(successMessage);
          formRef.current.reset();
        }

        if (hasErrors) {
          setErrors(error);
        }

        setIsSubmitting(false);
        return data || null;
      } catch (error) {
        setIsSubmitting(false);
        console.error(error);
        return null;
      }
    },
    [formId, formResponse, successMessage]
  );

  const onInputChange = useCallback(
    ({ target, element }) => {
      const { label, type, values } = element;
      if (!target || !label) return;
      let { value } = target;
      const { checked } = target;
      const isCheckbox = type === 'checkbox' || type === 'terms_conditions';
      const isMultiCheckbox = type === 'multipleCheckbox';

      if (isCheckbox) value = checked ? 'on' : '';

      let finalValue = value;

      // If form response value is an array, add new value to array
      if (Array.isArray(formResponse[label])) {
        const map = formResponse[label].reduce((obj, arrValue, index) => {
          obj[index] = arrValue;
          return obj;
        }, {});
        map[element.index] = value;
        finalValue = Object.values(map);
      }

      // If form response value is multiple choice, add or remove values from string
      if (isMultiCheckbox) {
        const optionsArr = values?.split('\n');
        const maxOptions = optionsArr?.length;
        const emptyArr = [
          { isMultiCheckbox: true },
          ...Array(maxOptions).fill(''),
        ];

        const initArr = Array.isArray(formResponse[label])
          ? formResponse[label]
          : emptyArr;

        const map = initArr.reduce((obj, arrValue, index) => {
          obj[index] = arrValue;
          return obj;
        }, {});

        const valueIndex = optionsArr.indexOf(value) + 1;

        if (checked) {
          map[valueIndex] = value;
        } else {
          map[valueIndex] = '';
        }

        finalValue = Object.values(map);
      }

      setFormResponse({
        ...formResponse,
        [label]: finalValue,
      });
    },
    [formResponse]
  );

  useEffect(() => {
    getForm();
  }, [formId]);

  return [
    {
      formData,
      ref: formRef,
      isSubmitting,
      errors,
      message,
      formResponse,
    },
    {
      submitForm,
      onInputChange,
    },
  ];
};

useForm.displayName = 'useForm';
