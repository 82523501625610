import { useEffect, useRef } from 'react';
import { Box } from 'theme-ui';

import { Link } from '@snippets';

import { ContentSlide } from './ContentSlide';
import { Image } from './Image';
import { Video } from './Video';
import { themed } from './Slide.theme';

export const Slide = themed(
  ({
    theme,
    slide,
    isSlider,
    showContentSliderMobile,
    showContentSliderDesktop,
    isActive,
    isFirstSlide,
    isVisibleContent,
  }) => {
    const { button, video, content } = slide;
    const cta = button?.buttons?.[0]?.cta;
    const clickable = button?.clickableHero && !!cta?.url;
    const sideRef = useRef(null);

    useEffect(() => {
      if (!content?.enableMedia && sideRef?.current) {
        if (!showContentSliderDesktop) {
          sideRef?.current.parentElement.classList.add('!h-auto');
          sideRef?.current.parentElement.classList.add('!min-h-0');
        } else {
          sideRef?.current.parentElement.classList.add('!hidden');
        }
      }
    }, [content?.enableMedia, sideRef]);

    return (
      <Link
        data-comp={Slide.displayName}
        href={clickable ? cta?.url : null}
        newTab={cta?.newTab}
        tabIndex={clickable && button?.hideButtons ? '0' : '-1'}
        aria-label={clickable && button?.hideButtons ? cta?.text : ''}
        sx={{
          ...theme.slide,
          cursor: clickable ? 'pointer' : isSlider ? 'grab' : 'auto',
        }}
        ref={sideRef}
      >
        {!!content?.enableMedia && (
          <>
            <Box
              sx={{
                display: ['block', 'none'],
                width: '100%',
                height: '100%',
                position: 'absolute',
                inset: 0,
              }}
            >
              {video?.src ? (
                <Video
                  video={video}
                  src={video?.src}
                  poster={video?.poster}
                  isActive={isActive}
                />
              ) : (
                <Image slide={slide} />
              )}
            </Box>

            <Box
              sx={{
                display: ['none', 'block'],
                width: '100%',
                height: '100%',
                position: 'absolute',
                inset: 0,
              }}
            >
              {video?.srcDt ? (
                <Video
                  video={video}
                  src={video?.srcDt}
                  poster={video?.posterDt}
                  isActive={isActive}
                />
              ) : (
                <Image slide={slide} />
              )}
            </Box>
          </>
        )}

        <ContentSlide
          slide={slide}
          showContentSliderMobile={showContentSliderMobile}
          showContentSliderDesktop={showContentSliderDesktop}
          isVisibleContent={isVisibleContent}
          isFirstSlide={isFirstSlide}
        />
      </Link>
    );
  }
);

Slide.displayName = 'Slide';
