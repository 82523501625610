import { useCallback } from 'react';
import { useStartFinishErrors } from '@backpackjs/storefront';

export const useRebuy = () => {
  const { setStarted, setFinished, setErrors, reset, ...status } =
    useStartFinishErrors();

  const getRebuyProducts = useCallback(
    async ({ shopify_product_ids, cart_item_count }) => {
      try {
        reset();
        setStarted(true);
        const url = `/api/rebuy`;
        const options = {
          method: 'POST',
          body: JSON.stringify({
            shopify_product_ids: shopify_product_ids || '',
            cart_item_count,
          }),
        };
        const options2 = {
          method: 'POST',
          body: JSON.stringify({
            shopify_product_ids: shopify_product_ids || '',
            cart_item_count,
            isUpsell: true,
          }),
        };
        const response = await fetch(url, options);
        const response2 = await fetch(url, options2);

        if (!response.ok) {
          setErrors(['Error with rebuy.']);
          console.error(`Response back from API not successful:`, response);
          return null;
        }

        if (!response2.ok) {
          setErrors(['Error with rebuy.']);
          console.error(`Response2 back from API not successful:`, response2);
          return null;
        }

        const crossSells = await response.json();
        const upSells = await response2.json();

        if (!crossSells?.data || !upSells?.data) {
          setErrors(['Error with rebuy.']);
          return null;
        }

        setFinished(true);
        return {
          crossSells,
          upSells,
        };
      } catch (error) {
        setErrors(['Error with rebuy.']);
        console.error(`Error with rebuy: ${error}`);
      }
    },
    []
  );

  return {
    getRebuyProducts,
    setErrors,
    reset,
    ...status,
  };
};
