import { useMemo } from 'react';
import {
  useCart,
  useCartCount as useDefaultCartCount,
} from '@backpackjs/storefront';

export const useCartCount = () => {
  const cartCount = useDefaultCartCount();
  const cart = useCart();

  const cartCountWithoutCharge = useMemo(() => {
    return cart?.lines?.reduce((acc, lineItem) => {
      const jtbAttr = (lineItem?.attributes || []).find(
        (attr) => attr?.key === 'recipe_id' && attr?.value?.startsWith('uc-')
      );
      if (jtbAttr) {
        return acc - lineItem.quantity;
      }
      return acc;
    }, cartCount);
  }, [cart?.lines, cartCount]);

  return cartCountWithoutCharge;
};

useCartCount.displayName = 'useCartCount';
