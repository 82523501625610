import { useMemo } from 'react';
import { theme } from '../theme';

import { useBreakpoint } from './useBreakpoint';

export const useBreakpointValue = (values = []) => {

  if (!values?.length) {
    console.warn(
      'No values provided. Please provide an array of responsive values'
    );
    return null;
  }

  // refresh result memo on value change
  const refresh = values?.map((value) => JSON.stringify(value)).join(',');

  const { s, m_, l_, xl_, xxl } = useBreakpoint();

  return useMemo(() => {
    const [valueS, valueM, valueL, valueXl, valueXxl] = new Array(
      theme.breakpoints.length + 1
    )
      .fill()
      .reduce((_values, _, index) => {
        const initialValue = values[index];
        const altValue =
          initialValue === null || initialValue === undefined
            ? _values[index - 1]
            : initialValue;
        const finalValue =
          altValue === null || altValue === undefined ? null : altValue;
        return [..._values, finalValue];
      }, []);

    return values?.length
      ? xxl
        ? valueXxl
        : xl_
          ? valueXl
          : l_
            ? valueL
            : m_
              ? valueM
              : s
                ? valueS
                : null
      : null; // no values provided
  }, [s, m_, l_, xl_, xxl, refresh]);
};
