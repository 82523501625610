import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: ['block', 'none'],
    };

    this.grid = {
      gridTemplateColumns: '1fr',
      gridGap: 0,
      borderTop: (t) => `1px solid ${t.colors.gray}`,
    };

    this.button = {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      position: 'relative',
      height: '52px',
      p: 8,
      borderTop: (t) => `1px solid ${t.colors.gray}`,
      borderBottom: (t) => `1px solid ${t.colors.gray}`,
    };

    this.title = {
      variant: 'text.label.1',
      display: 'inline-block',
    };

    this.selectedSort = {
      fontSize: 3,
      color: 'mediumDarkGray',
      ml: 4,
    };

    this.icon = {
      position: 'absolute',
      right: 8,
      top: '50%',
      width: '24px',
      height: '24px',
      transform: 'translateY(-50%)',
    };
  })()
);
