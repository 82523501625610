import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: [null, 'grid'],
      gridTemplateColumns: '1fr 1fr',
      maxWidth: [null, '64em'],
      mx: ['-20px', null, '-32px'],
      my: ['-32px', null, '-48px'],
      width: ['auto', '90vw'],
    };
    this.image = {
      height: '100%',
    };
    this.details = {
      alignSelf: 'center',
      p: [11, 17],
    };
    this.heading = {
      mb: [4, 8],
    };
    this.buttonWrapper = {
      mt: [11, '50px'],
    };
    this.cancel = {
      ml: 11,
    };
  })()
);
