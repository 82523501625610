import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      minWidth: '300px',
      maxWidth: '500px',
      '@media only screen and (max-width: 479px)': {
        minWidth: '250px',
      },
      textAlign: 'center',
      p: 10,
    };

    this.title = {
      variant: 'text.h3',
    };

    this.variant = {
      fontSize: 6,
    };

    this.description = {
      fontSize: 3,
      mt: 10,
    };

    this.input = {
      mb: 11,
      px: 11,
    };

    this.checkboxWrapper = {
      display: 'flex',
      gap: 4,
      mb: 11,
    };

    this.button = {
      width: '100%',
    };
  })()
);
