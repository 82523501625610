import { Container } from 'theme-ui';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade } from 'swiper';
import { ContentSlide } from '../Slide';
import { themed } from './Slider.theme';

SwiperCore.use([EffectFade]);

export const ContentSlider = themed(
  ({
    theme,
    activeIndex,
    bgColor,
    setContentSwiper,
    slider,
    slides,
    showContentSliderMobile,
    showContentSliderDesktop,
  }) => {
    return slides?.length > 0 ? (
      <Container data-comp={ContentSlider.displayName}>
        {slides.length > 1 ? (
          <Swiper
            {...defaultParams}
            autoplay={
              slider?.autoplay
                ? {
                    delay: slider.delay || 5000,
                    disableOnInteraction: false,
                  }
                : false
            }
            effect={slider?.effect === 'fade' ? 'fade' : null}
            onSwiper={setContentSwiper}
          >
            {slides.map((slide, index) => {
              return (
                <SwiperSlide key={index}>
                  <ContentSlide
                    bgColor={bgColor}
                    slide={slide}
                    showContentSliderMobile={showContentSliderMobile}
                    showContentSliderDesktop={showContentSliderDesktop}
                    isBelowHero
                    isVisibleContent={
                      slider?.effect !== 'fade' || index === activeIndex
                    }
                    isFirstSlide={index === 0}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <ContentSlide
            bgColor={bgColor}
            slide={slides[0]}
            showContentSliderMobile={showContentSliderMobile}
            showContentSliderDesktop={showContentSliderDesktop}
            isBelowHero
            isVisibleContent
            isFirstSlide
          />
        )}
      </Container>
    ) : null;
  }
);

ContentSlider.displayName = 'ContentSlider';

const defaultParams = {
  allowSlideNext: false,
  allowSlidePrev: false,
  allowTouchMove: false,
  centeredSlides: false,
  fadeEffect: { crossFade: true },
  freeMode: false,
  loop: false,
  navigation: false,
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  watchOverflow: true,
};
