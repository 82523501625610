import { Box, Button, Heading, Paragraph } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Svg } from '@snippets';
import { useFiltersSidebar } from '@hooks';

import { CollectionCategoriesItem } from './CollectionCategoriesItem';
import { themed } from './CollectionCategoriesMobile.theme';

export const CollectionCategoriesMobile = themed(
  ({
    categories,
    name,
    collectionHandle,
    activeCategory,
    isSuperCollection,
    theme,
  }) => {
    const [, { toggleFiltersSidebar }] = useFiltersSidebar();

    return (
      <Box
        data-comp={CollectionCategoriesMobile.displayName}
        sx={{ display: ['contents', 'none'] }}
      >
        <Swiper
          spaceBetween={8}
          slidesPerView={2.5}
          slidesOffsetBefore={10}
          slidesOffsetAfter={10}
          breakpoints={{
            600: {
              slidesPerView: 3.5,
              spaceBetween: 16,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
            },
          }}
          sx={{ mb: 10, cursor: 'grab' }}
        >
          {categories.map((item, index) => (
            <SwiperSlide key={index}>
              <CollectionCategoriesItem
                item={item}
                collectionHandle={collectionHandle}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Box sx={theme.sticky}>
          <Button
            variant="buttons.plain"
            onClick={() =>
              toggleFiltersSidebar({
                values: categories,
                label: name,
                isSize: true,
              })
            }
            sx={{
              ...theme.button,
              borderBottomStyle: isSuperCollection ? 'solid' : 'none',
            }}
          >
            <Heading as="h5" sx={theme.title}>
              {name}
            </Heading>

            <Paragraph sx={theme.selected}>{activeCategory}</Paragraph>

            <Svg
              viewBox="0 0 24 24"
              src="/svgs/arrow-right.svg#arrow-right"
              sx={theme.icon}
            />
          </Button>
        </Box>
      </Box>
    );
  }
);

CollectionCategoriesMobile.displayName = 'CollectionCategoriesMobile';
