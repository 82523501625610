import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      variant: 'layout.content',
      py: [15, 16, 17, 18],
      div: {
        fontFamily:
          '"Fellix", system-ui, -apple-system, BlinkMacSystemFont, sans-serif !important',
      },
    };

    this.spinnerWrapper = {
      width: '100%',
      height: '400px',
      position: 'relative',
    };

    this.spinner = {
      width: '30px',
      color: 'mediumDarkGray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  })()
);
