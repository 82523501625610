import { useMemo } from 'react';

export const useGatedProduct = ({ gatedPage, product, currentUrl = null }) => {
  const enableFullProductAccess = useMemo(() => {
    const { pages } = { ...gatedPage?.page };
    const gatedProduct = pages?.reduce(
      (carry, { products, enableFullProductAccess }) => {
        if (carry) return carry;
        const foundProduct = {
          product: products?.find(
            (_product) => _product?.product?.handle === product?.handle
          ),
          enableFullProductAccess,
        };
        if (foundProduct?.product) return foundProduct;
        return carry;
      },
      null
    );

    // if gatedProduct, check to see whether product is in Phase 1 or 2
    // Phase 1 - No access
    // Phase 2 - Access (ATC, Links)
    if (gatedProduct?.product) {
      return gatedProduct?.enableFullProductAccess;
    }

    return true;
  }, [gatedPage, product]);

  // returns list of gated products
  const gatedProducts = useMemo(() => {
    const { pages } = { ...gatedPage?.page };
    const gatedCollection = pages?.find(
      (_page) => _page?.pageUrl?.indexOf(currentUrl) > -1
    );

    const _gatedProducts = gatedCollection?.products?.map(({ product }) => {
      if (product?.data) {
        return { ...product, ...product?.data };
      }
      return product;
    });
    return _gatedProducts;
  }, [gatedPage, currentUrl]);

  return { enableFullProductAccess, gatedProducts };
};
