import Script from 'next/script';

// Development only head or scripts
export function DevelopmentHead() {
  return process.env.NODE_ENV === 'development' ? (
    <Script
      strategy="afterInteractive"
      id="override-error-overlay"
      dangerouslySetInnerHTML={{
        __html: `
    const rebindEditorOpen = () => {
      setTimeout(() => {
        console.log('overriding error overlay...');
        const root = document.querySelector('nextjs-portal').shadowRoot;
        const link = root.querySelector('p[role="link"]');
        const file = '../' + link.innerText.split(' ')[0];
        const [line, col] = link.innerText
          .split(' ')[1]
          .replace(/[()]/g, '')
          .split(':');
        const method = link.innerText.split(' ')[3];
        const params = 'file=' + file + '&lineNumber=' + line + '&column=' + col + '&methodName=' + method;
        const url = '/__nextjs_launch-editor?' + params;
        const clone = link.cloneNode(true);
        const open = () => fetch(url);
        clone.addEventListener('click', open);
        link.replaceWith(clone);
      }, 1000);
    };

    window.addEventListener('uncaughtexception', rebindEditorOpen);
    window.addEventListener('unhandledrejection', rebindEditorOpen);
    window.addEventListener('error', rebindEditorOpen);
  `,
      }}
    />
  ) : null;
}
