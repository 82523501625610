import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.row.center.center',
      bg: 'background',
      color: 'text',
      position: 'relative',
      borderBottom: (t) => `1px solid ${t.colors.gray}`,
      height: '52px',
    };

    this.cart = {
      width: 24,
      zIndex: 1,
    };

    (this.back = {
      width: 24,
      zIndex: 1,
    }),
      (this.heading = {
        variant: 'text.h5',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      });
  })()
);
