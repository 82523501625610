import { Container } from 'theme-ui';

import { useFiltersSidebar } from '@hooks';
import { useCollectionFilters } from '@templates/collection/hooks';
import { useSearchFilters } from '@snippets/Search/SearchPage/Filters';

import { themed } from './FiltersSidebar.theme';
import { SizeSidebar } from './SizeSidebar';
import { FiltersSidebar } from './FiltersSidebar';
import { FiltersSidebarHeader } from './FiltersSidebarHeader';

export const FiltersSidebarShell = themed(({ theme, inputRef, ...props }) => {
  const [
    { filtersSidebar, isSize, isFiltersAndSort, isSearchPage },
    { toggleFiltersSidebar },
  ] = useFiltersSidebar();
  const [collectionFilterState, collectionFilterActions] =
    useCollectionFilters();
  const [searchFilterState, searchFilterActions] = useSearchFilters();

  const addToSelectedFilters = isSearchPage
    ? searchFilterActions.addToSelectedFilters
    : collectionFilterActions.addToSelectedFilters;
  const removeFromSelectedFilters = isSearchPage
    ? searchFilterActions.removeFromSelectedFilters
    : collectionFilterActions.removeFromSelectedFilters;
  const filters = isSearchPage
    ? searchFilterState.filters
    : collectionFilterState.filters;

  return (
    <Container
      data-comp={FiltersSidebarShell.displayName}
      {...props}
      sx={{
        ...theme.shell,
        transform: filtersSidebar ? 'translateX(0)' : 'translateX(100%)',
        ...props.sx,
      }}
    >
      {filtersSidebar && (
        <>
          <FiltersSidebarHeader
            toggleFiltersSidebar={toggleFiltersSidebar}
            title={filtersSidebar?.label}
            isSearchPage={isSearchPage}
          />

          {isSize && (
            <SizeSidebar
              options={filtersSidebar?.values}
              toggleFiltersSidebar={toggleFiltersSidebar}
            />
          )}

          {isFiltersAndSort && (
            <FiltersSidebar
              filters={filters}
              addToSelectedFilters={addToSelectedFilters}
              removeFromSelectedFilters={removeFromSelectedFilters}
              isSearchPage={isSearchPage}
              toggleFiltersSidebar={toggleFiltersSidebar}
            />
          )}
        </>
      )}
    </Container>
  );
});

FiltersSidebarShell.displayName = 'FiltersSidebarShell';
