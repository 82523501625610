import { useEffect, useCallback, useMemo, useState } from 'react';
import { Box } from 'theme-ui';
import { useProductByHandle, useSettings } from '@backpackjs/storefront';

import { GiftWithPurchase, StatefulButton, BackInStockModal } from '@snippets';
import {
  useCartStatus,
  useModal,
  useSidebar,
  useGiftWithPurchase,
  useGatedProduct,
  useMatchMedia,
} from '@hooks';
import store, { useRecoilValue } from '@store';

import { useAddToCart } from './useAddToCart';
import { themed } from './AddToCart.theme';

export const AddToCart = themed(
  ({
    action = () => {},
    theme,
    product,
    selectedVariant,
    handleUpsellRemove,
    rebuyWidget,
    style,
    isProductItem,
    addToTapCart,
    isInitialized,
    ...props
  }) => {
    const settings = useSettings();

    const gatedPage = useRecoilValue(store.gatedPage);

    const { enableFullProductAccess } = useGatedProduct({ product, gatedPage });

    const productByHandle = useProductByHandle({
      handle: selectedVariant?.product?.handle,
    });
    const isDesktop = useMatchMedia('(min-width: 1024px)');

    const [productItemNotifyMeText, setProductItemNotifyMeText] =
      useState('Notify Me');

    const [{ status, isSoldOut, isPreOrder, selectedPlan }, { addToCart }] =
      useAddToCart({
        product: productByHandle.product,
        selectedVariant,
        rebuyWidget,
      });

    const { started, finished, success, errors } = status.addItem;
    const [, { openModal }] = useModal();
    const [, { openSidebar }] = useSidebar();
    const soldOutText = isProductItem
      ? productItemNotifyMeText
      : 'Notify Me When Available';
    const [, { setAddingToCartStatus, setUpdatingCartStatus }] =
      useCartStatus();
    const gwp = useGiftWithPurchase();

    const cartIsMaxed = useRecoilValue(store.cartIsMaxed);

    const ignoredProducts = settings?.cart?.bulkOrdering?.ignoredProducts;

    const noBackInStockOption =
      isSoldOut &&
      !isPreOrder &&
      productByHandle?.product?.tags?.includes('no-bis');

    const text =
      status?.inventoryStatus?.finished && product
        ? noBackInStockOption
          ? 'Sold Out'
          : !isSoldOut && isPreOrder
          ? 'Pre-order'
          : isSoldOut
          ? soldOutText
          : selectedPlan
          ? 'Subscribe'
          : 'Add to Bag'
        : 'LOADING...';

    const ignoredFromMaxQty = useMemo(
      () =>
        ignoredProducts?.some(
          ({ product: ignoredProduct }) =>
            ignoredProduct.handle === product.handle
        ),
      [product.handle, ignoredProducts]
    );

    const setGlobalCartStatusOnAdding = useCallback(() => {
      setAddingToCartStatus({ started, finished, success, errors });
      setUpdatingCartStatus({ started, finished, success, errors });
      const defaultStatus = {
        started: false,
        finished: false,
        success: false,
        errors: [],
      };
      return () => {
        setAddingToCartStatus(defaultStatus);
        setUpdatingCartStatus(defaultStatus);
      };
    }, [started, finished, success]);

    const handleOutOfStock = useCallback(() => {
      // need timeout for delay if a modal is already open
      setTimeout(() => {
        openModal(
          <BackInStockModal
            legacyResourceId={
              selectedVariant?.legacyResourceId || product?.variants?.[0]?.id
            }
            title={product.title || product.name || ''}
          />
        );
      }, 250);
    }, [selectedVariant?.id, product.title]);

    useEffect(() => {
      setGlobalCartStatusOnAdding();
    }, [started, finished, success]);

    useEffect(() => {
      if (!isProductItem || !isSoldOut) return;
      setProductItemNotifyMeText(
        isDesktop ? 'Notify Me When Available' : 'Notify Me'
      );
    }, [isProductItem, isDesktop, isSoldOut]);

    const isNotifyMe = isSoldOut && !isPreOrder;
    const overMaxDisabled = cartIsMaxed && !isNotifyMe && !ignoredFromMaxQty;

    if (!enableFullProductAccess) return null;

    return (
      <Box
        sx={{
          ...theme.wrapper,
          ...style,
        }}
      >
        <StatefulButton
          disabled={overMaxDisabled}
          data-comp={AddToCart.displayName}
          sx={{
            ...theme.button,
            ...(isNotifyMe || overMaxDisabled || noBackInStockOption
              ? {
                  ...theme.button.unavailable,
                  ...(selectedPlan
                    ? theme.button.unavailable.subscribe
                    : {
                        ...theme.button.unavailable.oneTime,
                        borderColor:
                          overMaxDisabled || noBackInStockOption
                            ? 'gray'
                            : 'text',
                        color:
                          overMaxDisabled || noBackInStockOption
                            ? 'mediumGray'
                            : 'mediumDarkGray',
                        ':hover': {
                          borderColor:
                            overMaxDisabled || noBackInStockOption
                              ? 'gray'
                              : 'text',
                          color:
                            overMaxDisabled || noBackInStockOption
                              ? 'mediumGray'
                              : 'text',
                          bg: 'background',
                        },
                      }),
                }
              : {
                  ...theme.button.available,
                  ...(selectedPlan
                    ? theme.button.available.subscribe
                    : theme.button.available.oneTime),
                }),
            cursor:
              overMaxDisabled || noBackInStockOption ? 'default' : 'pointer',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '100%',
          }}
          {...status.addItem}
          loading={!status?.inventoryStatus?.finished}
          text={{
            default: text,
            started: text,
            success: text,
            error: text,
          }}
          onClick={async () => {
            if (isNotifyMe) {
              handleOutOfStock();
            } else {
              if (isInitialized) {
                addToTapCart({
                  lineItems: [
                    {
                      variantId: selectedVariant?.legacyResourceId,
                      quantity: 1,
                    },
                  ],
                });
                return;
              }
              action();
              await addToCart({
                callback: (_cart) => {
                  if (handleUpsellRemove) handleUpsellRemove();

                  if (gwp.isEligible(_cart)) {
                    openModal(<GiftWithPurchase />);
                  } else {
                    openSidebar('cart');
                  }
                },
                openCart: false,
              });
            }
          }}
        />
      </Box>
    );
  }
);

AddToCart.displayName = 'AddToCart';
