import { useEffect } from 'react';
import store, { useSetRecoilState } from '@store';
import { useSettings } from '@backpackjs/storefront';

export const useTrackSettings = () => {
  const settings = useSettings();
  const setRecoilSettings = useSetRecoilState(store.siteSettings);

  useEffect(() => {
    // on siteSettings change, update store.updateSettings
    setRecoilSettings(settings);
  });
};
