import { useEffect } from 'react';
import { useRouter } from '@backpackjs/storefront';

import { useSidebar } from '@hooks';

export const useCartRedirect = () => {
  const [{ isCart }, { openCartSidebar }] = useSidebar();
  const router = useRouter();
  const { route, query } = router;
  const isOpenCart = query?.cart === 'true';

  useEffect(() => {
    if (route !== '/cart') return;
    router.push('/?cart=true');
  }, [route]);

  useEffect(() => {
    if (!isOpenCart) return;
    openCartSidebar();
  }, [isOpenCart]);

  useEffect(() => {
    if (!isCart || !isOpenCart) return;
    const newQuery = { ...query };
    delete newQuery.cart;
    router.replace({ pathname: '/', query: newQuery });
  }, [isCart, isOpenCart]);

  return null;
};
