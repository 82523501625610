import { useEffect } from 'react';
import { Box } from 'theme-ui';

import { useBodyScrollLock } from '@hooks';

import { themed } from './Overlay.theme';
import { useMenuSidebar } from '../useMenuSidebar';

export const Overlay = themed(({ theme }) => {
  const [{ menuSidebar }, { toggleSidebar }] = useMenuSidebar();
  const { lockScroll, unlockScroll } = useBodyScrollLock();

  useEffect(() => {
    menuSidebar ? lockScroll() : unlockScroll();
  }, [menuSidebar]);

  return (
    <Box
      data-comp={Overlay.displayName}
      onClick={toggleSidebar}
      sx={{
        ...(menuSidebar ? theme.open : theme.closed),
      }}
    />
  );
});

Overlay.displayName = 'Overlay';
