import Script from 'next/script';
import { useEffect, useState } from 'react';

const determinePageType = () => {
  switch (true) {
    case window.location.pathname.includes('/collections'):
      return 'collection';
    case window.location.pathname.includes('/products'):
      return 'product';
    case window.location.pathname.includes('/pages/search'):
      return 'search';
    case window.location.pathname.includes('/pages'):
      return 'page';
    case window.location.pathname.includes('/articles'):
      return 'article';
    case window.location.pathname.includes('/blogs'):
      return 'blog';
    default:
      return 'home';
  }
};

export function FueledPostscriptScript({ withKey: postScriptShopId }) {
  const [events, setEvents] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const handlePostscriptReady = () => {
      setIsReady(true);
    };

    if (typeof window?.postscript === 'object') {
      handlePostscriptReady();
    }

    const collectEvent = (event) => {
      setEvents((prevEvents) => [...prevEvents, event]);
    };

    window.addEventListener('dl_view_item', collectEvent);
    window.addEventListener('dl_add_to_cart', collectEvent);
    window.addEventListener('postscriptReady', handlePostscriptReady);

    return () => {
      window.removeEventListener('dl_view_item', collectEvent);
      window.removeEventListener('dl_add_to_cart', collectEvent);
      window.removeEventListener('postscriptReady', handlePostscriptReady);
    };
  }, []);

  useEffect(() => {
    if (!isReady || !events.length) return undefined;

    const identify = async (event) => {
      if (event.detail.user_properties?.customer_phone) {
        return window.postscript?.identify({
          phone: event.detail.user_properties.customer_phone,
        });
      }

      return null;
    };

    const viewItemListener = async (event) => {
      await identify(event);
      const [product] = event.detail.ecommerce.detail.products;

      window.postscript?.event('page_view', {
        shop_id: postScriptShopId,
        url: `${process.env.SITE_URL}${window.location.pathname}`,
        search_params: { variant: Number(product.variant_id) },
        page_type: determinePageType(),
        referrer: document.referrer,
        resource: {
          category: product.category,
          name: product.name,
          price_in_cents: 100 * parseFloat(product.price),
          resource_id: Number(product.product_id),
          resource_type: 'product',
          sku: product.sku,
          variant_id: Number(product.variant_id),
          vendor: product.vendor || '',
        },
      });

      console.log('Postscript Page View Event Fired:', product);
    };

    const addToCartListener = async (event) => {
      await identify(event);
      const [product] = event.detail.ecommerce.add.products;

      window.postscript.event('add_to_cart', {
        shop_id: postScriptShopId,
        url: `${process.env.SITE_URL}${window.location.pathname}`,
        search_params: { variant: Number(product.variant_id) },
        page_type: determinePageType(),
        referrer: document.referrer,
        resource: {
          category: product.category,
          name: product.name,
          price_in_cents: 100 * parseFloat(product.price),
          resource_id: Number(product.product_id),
          resource_type: 'product',
          sku: product.sku,
          variant_id: Number(product.variant_id),
          vendor: product.vendor || '',
        },
      });

      console.log('Postscript Add to Cart Event Fired:', product);
    };

    events.forEach((event) => {
      if (event.type === 'dl_view_item') {
        viewItemListener(event);
      } else if (event.type === 'dl_add_to_cart') {
        addToCartListener(event);
      }
    });

    setEvents([]);

    return undefined;
  }, [isReady, events.length]);

  return (
    <Script
      strategy="afterInteractive"
      type="text/javascript"
      id={`postscript-${postScriptShopId}`}
      src={`https://sdk.postscript.io/sdk.bundle.js?shopId=${postScriptShopId}&rand=${Date.now()}`}
    />
  );
}
