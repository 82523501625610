export function BuyWithPrime() {
  return (
    <div
      id="amzn-bwp-cart"
      data-site-id={process.env.NEXT_PUBLIC_AMAZON_BWP_SITE_ID || 'gwfxnozf54'}
      data-widget-id={
        process.env.NEXT_PUBLIC_AMAZON_BWP_WIDGET_ID ||
        'w-XRZsc7Ov0c7a7UehqIg5N0'
      }
    />
  );
}
