import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      justifyContent: 'center',
      minHeight: '114px',
      py: 12,
    };

    this.grid = {
      variant: 'layout.content',
      display: 'grid',
      width: '100%',
      gridTemplateColumns: ['repeat(2, 1fr)', 'repeat(4, 1fr)'],
      columnGap: 6,
      rowGap: 10,
    };

    this.item = {
      variant: 'flex.row.start.center',

      icon: {
        width: ['45px', '50px'],
        height: ['45px', '50px'],
        color: 'currentColor',
        mr: 8,
      },

      label: {
        flex: 1,
        variant: 'text.label.1',
      },
    };
  })()
);
