import { useCustomer } from '@backpackjs/storefront';
import { Heading, Paragraph } from 'theme-ui';
import { Section } from '@snippets';
import { themed } from './EmbedContent.theme';
import { Schema } from './EmbedContent.schema';

export const EmbedContent = themed(({ theme, cms }) => {
  const customer = useCustomer();
  const { state, heading, headingColor, subHeading, section } = cms;
  const textAlign = [cms?.alignment, cms?.alignmentDt];

  const enable =
    state === 'enable' ||
    (state === 'guest' && !customer?.id) ||
    (state === 'user' && customer?.id);

  if (!enable) return null;

  return (
    <Section data-comp={EmbedContent.displayName} section={section}>
      <div className="py-10">
        {heading && (
          <Heading
            sx={{
              ...theme.heading,
              textAlign,
              color: headingColor || 'text',
            }}
          >
            {heading}
          </Heading>
        )}

        {subHeading && (
          <Paragraph
            sx={{
              ...theme.paragraph,
              textAlign,
              color: headingColor || 'text',
            }}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        )}

        {cms?.snippet && (
          <div
            className="m-auto block"
            dangerouslySetInnerHTML={{ __html: cms.snippet }}
          />
        )}
      </div>
    </Section>
  );
});

EmbedContent.displayName = 'EmbedContent';
EmbedContent.Schema = Schema;
