import { Paragraph, Flex, Text } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '@snippets';

import { themed } from './Legal.theme';

export const Legal = themed(({ theme }) => {
  const settings = useSettings();

  return (
    <Flex data-comp={Legal.displayName} sx={theme.wrapper}>
      <Flex as="ul" sx={theme.row}>
        {settings?.footer?.legalLinks?.map((legalLink, index, arr) => {
          return (
            <Flex
              as="li"
              key={index}
              sx={{
                ml: index === 0 ? [0, 4] : 4,
                mr: index === arr.length - 1 ? [0, 4] : 4,
              }}
            >
              <Text
                sx={{
                  ...theme.text,
                  ':before': {
                    ...theme.text.before,
                    display: index === 0 ? ['none', 'block'] : 'block',
                  },
                }}
              >
                <Link
                  href={legalLink.cta.url}
                  newTab={legalLink.cta.newTab}
                  sx={{
                    ...theme.link,
                  }}
                >
                  {legalLink.cta.text}
                </Link>
              </Text>
            </Flex>
          );
        })}
      </Flex>

      <Paragraph sx={theme.copyright}>
        {'Copyright © '}
        {new Date().getFullYear() > 2010 && new Date().getFullYear()}{' '}
        {settings?.footer?.copyrightNotice}
      </Paragraph>
    </Flex>
  );
});

Legal.displayName = 'Legal';
