import { Heading } from 'theme-ui';

import { Link } from '@snippets';

import { themed } from './SubNav.theme';

export const SubNavLinkItem = themed(({ theme, item, closeMenuSidebar }) => {
  const textColor = item?.color;
  return (
    <Link
      data-comp={SubNavLinkItem.displayName}
      href={item.link.url}
      newTab={item.link.newTab}
      sx={theme.item.button}
      onClick={closeMenuSidebar}
    >
      <Heading
        as="h5"
        sx={{ ...theme.item.text, color: textColor || 'inherit' }}
      >
        {item.link.text}
      </Heading>
    </Link>
  );
});

SubNavLinkItem.displayName = 'SubNavLinkItem';
