import { Button, Heading } from 'theme-ui';
import { Svg } from '@snippets';

import { themed } from './SubNav.theme';

export const SubNavMenuItem = themed(({ theme, item, toggleNestedSidebar }) => {
  const textColor = item?.color;
  return (
    <Button
      data-comp={`${SubNavMenuItem.displayName}`}
      variant="buttons.plain"
      onClick={() => {
        toggleNestedSidebar({ item });
      }}
      sx={theme.item.button}
    >
      <Heading
        as="h5"
        data-comp="SubNavAccordionItemHeading"
        sx={{ ...theme.item.text, color: textColor || 'inherit' }}
      >
        {item.link.text}
      </Heading>

      <Svg
        data-comp="SubNavAccordionItemNextArrow"
        viewBox="0 0 24 24"
        src="/svgs/arrow-right.svg#arrow-right"
        sx={theme.item.icon}
      />
    </Button>
  );
});

SubNavMenuItem.displayName = 'SubNavMenuItem';
