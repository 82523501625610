import { useState, useCallback } from 'react';
import { Container, Box, Spinner } from 'theme-ui';
import { useProduct } from '@backpackjs/storefront';

import { withInView } from '@utils';
import { Section } from '@snippets';
import { useProductRecommendations } from '@hooks';

import { useProductRecsBeacon } from './useProductRecsBeacon';
import { useVisibleProductRecItems } from './useVisibleProductRecItems';
import { Schema } from './ProductRecs.schema';
import { themed } from './ProductRecs.theme';
import { Header } from './Header';
import { Slider } from './Slider';

export const ProductRecs = withInView(
  themed(({ theme, cms, inView }) => {
    const { content, loop, section, tag, limit } = cms;

    const product = useProduct();
    const { recommendations, profileData, profileTag } =
      useProductRecommendations({
        tag: tag || 'cross-sell',
        limit,
        productId: product?.legacyResourceId || '',
      });

    const [swiper, setSwiper] = useState(null);

    const { visibleProducts } = useVisibleProductRecItems({
      profileData,
      swiper,
      loop,
    });

    const { sendBeaconEventsOnClick } = useProductRecsBeacon({
      profileData,
      profileTag,
      visibleProducts,
      inView,
    });

    const handleProfileClick = useCallback(
      (event) => {
        if (
          event.target.dataset.comp !== 'NavigationButton' &&
          !event.target.swiper
        )
          return;
        sendBeaconEventsOnClick(event);
      },
      [profileTag]
    );

    const handleProfileProductClick = useCallback(
      (event, index) => {
        sendBeaconEventsOnClick(event, profileData?.results?.[index] || null);
      },
      [profileData?.results, profileTag]
    );

    return (
      <Section section={section}>
        <Container data-comp={ProductRecs.displayName} sx={theme.wrapper}>
          <Box sx={theme.header}>
            <Header content={content} />
          </Box>

          <Box sx={theme.slider} onClick={handleProfileClick}>
            {inView && recommendations?.length ? (
              <Slider
                products={recommendations}
                ctaStyle={content?.ctaStyle}
                loop={loop}
                swiper={swiper}
                setSwiper={setSwiper}
                onItemClick={handleProfileProductClick}
              />
            ) : (
              <Spinner sx={theme.spinner} />
            )}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductRecs.displayName = 'ProductRecs';
ProductRecs.Schema = Schema;
