const TEXT = '#222222';
const BACKGROUND = '#FFF';

export const colors = {
  // Required
  black: '#010101',
  white: '#FFFFFF',

  text: TEXT,
  background: BACKGROUND,

  lightGreen: '#E1FF01',

  // Grays
  darkGray: '#444444',
  mediumDarkGray: '#8C8C8D',
  mediumGray: '#C6C6C7',
  gray: '#f3f3f3',
  lightGray: '#F3F3F3',
  mediumLightGray: '#F1F2F4',

  // Primary
  dark: '#222222',

  // Accents
  red: '#AE2807',
  green: '#83B784',
  chiliPepper: '#9B1B30',
  chartreuseYellow: '#E1FF05',
  holidayGreen: '#2E4847',
  redSalePrice: '#FF0000',
  tundora: '#574F4C',
};
