import { position, section, align, button } from '@settings/common';

export function Schema({ article, collection }) {
  if (article || collection) return null;


  return {
    label: 'Two Thirds Media',
    key: 'two-thirds-media',
    fields: [
      {
        label: 'Image Settings',
        name: 'image',
        description: 'Image, position, placement, aspect ratio',
        component: 'group',
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description:
              'Provide a brief description of the image for accessibility compliance',
          },
          {
            name: 'imageDt',
            label: 'Image (desktop)',
            component: 'image',

          },
          {
            name: 'positionDt',
            component: 'select',
            label: 'Image position (desktop)',
            options: position.object,
          },
          {
            label: 'Placement (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationDt',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
          },
          {
            label: 'Image (mobile)',
            name: 'image',
            component: 'image',

          },
          {
            name: 'position',
            component: 'select',
            label: 'Image position (mobile)',
            options: position.object,
          },
          {
            name: 'location',
            label: 'Placement (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
          },
        ],
        defaultValue: {
          alt: `A Woman sitting on a couch with a Hopsulator slim in her hand.`,
          imageDt: {
            src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FirstCanCooler.png',
          },
          positionDt: 'center center',
          locationDt: 'right',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FirstCanCooler.png',
          },
          location: 'top',
          position: 'center center',

        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, superheading body, link, content alignment',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Superheading',
            name: 'superheading',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            name: 'ctaStyle',
            label: 'Link Style',
            component: 'select',
            options: button.options,
          },
          {
            label: 'Content alignment (desktop)',
            name: 'alignmentDt',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content alignment (mobile)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
        defaultValue: {
          heading: 'The Worlds First Slim Can Cooler',
          body: 'The Hopsulator Slim keeps all of your favorite 12oz slim canned beverages icy-cold, no matter how hot the party gets.',
          alignmentDt: align.value.left,
          alignment: align.value.left,
        },
      },
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'content',
            mt: 'l',
          },
          mobile: {
            container: 'content',
            mt: 's',
          },
        },
      },
    ],
  };
}
