import { Container, Box } from 'theme-ui';

import { themed } from './GridDisplay.theme';
import { Tile } from '../Tile';

export const GridDisplay = themed(({ theme, tiles, tile }) => {
  return (
    <Container data-comp={GridDisplay.displayName} sx={theme.grid}>
      {tiles?.slice(0, 2).map((item, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              ':before': {
                content: '""',
                pb: [
                  tile?.imageRatio === 'portrait'
                    ? `${(1 / 0.75) * 100}%`
                    : '100%',
                  tile?.imageRatioDt === 'portrait'
                    ? `${(1 / 0.75) * 100}%`
                    : '100%',
                ],
                float: 'left',
              },
            }}
          >
            <Tile item={item} tile={tile} />
          </Box>
        );
      })}
    </Container>
  );
});

GridDisplay.displayName = 'GridDisplay';
