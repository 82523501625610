import Head from 'next/head';

export function ProductReviewsHead() {
  return (
    <Head>
      <meta
        name="oke:subscriber_id"
        content="64fdab67-9789-4d89-bfa4-42a4cef5e5d8"
      />
      <script
        async
        src="https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js"
      />
    </Head>
  );
}
