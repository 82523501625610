import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.closed = {
      zIndex: 40,
      position: 'fixed',
      p: [10, null, 12],
      py: [12, null, 15],
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      bg: 'background',
      opacity: 0,
      pointerEvents: 'none',
      minWidth: ['90%', 640],
      maxWidth: ['90%', 'unset'],
      maxHeight: '90%',
      overflowY: 'auto',
    };

    this.open = {
      ...this.closed,
      opacity: 1,
      pointerEvents: 'auto',
    };

    this.close = {
      position: 'absolute',
      cursor: 'pointer',
      right: ['20px', '32px'],
      top: ['20px', '32px'],
      zIndex: 1,
    };

    this.icon = {
      width: [24, null, 22],
      cursor: 'pointer',
    };

    this.contentHidden = {
      opacity: 0,
      willChange: 'opacity',
      transition: 'opacity .3s ease-in-out',
    };

    this.contentVisible = {
      ...this.contentHidden,
      opacity: 1,
    };
  })()
);
