import { color, align } from '@settings/common';

export const refer = {
  label: 'Refer Settings',
  name: 'refer',
  component: 'group',
  fields: [
    {
      label: 'Desktop Settings',
      name: 'desktop',
      component: 'group',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Desktop Link',
          name: 'link',
          component: 'link',
          defaultValue: {
            url: '/pages/refer',
            text: 'Refer A Friend',
          },
        },
      ],
    },
    {
      label: 'Mobile Settings',
      name: 'mobile',
      component: 'group',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Mobile Link',
          name: 'link',
          component: 'link',
          defaultValue: {
            url: '/pages/refer',
            text: 'Refer A Friend',
          },
        },
        {
          name: 'linkText',
          component: 'markdown',
          label: 'Link Text Override',
          defaultValue: '**Get 20% Off** - <u>LEARN MORE</u>',
        },
        {
          label: 'Text Alignment',
          name: 'textAlign',
          component: 'radio-group',
          variant: 'radio',
          direction: 'horizontal',
          options: align.options,
          defaultValue: 'center',
        },
        {
          label: 'Background color',
          name: 'background',
          component: 'color',
          colors: color.list,
          widget: 'block',
          defaultValue: color.value.text,
        },
        {
          label: 'Text color',
          name: 'color',
          component: 'color',
          colors: color.list,
          widget: 'block',
          defaultValue: color.value.background,
        },
      ],
    },
  ],
};
