import { useRouter } from 'next/router';
import { Container } from 'theme-ui';
import store, { useRecoilValue } from '@store';

import { useGatedProduct } from '@hooks';

import { CollectionGrid as Grid } from '@templates/collection/CollectionGrid';
import { Schema } from './CollectionGrid.schema';

export function CollectionGrid({ cms }) {
  const { asPath } = useRouter();
  let { products } = cms;

  const gatedPage = useRecoilValue(store.gatedPage);

  const { gatedProducts } = useGatedProduct({
    gatedPage,
    currentUrl: asPath?.split('?')[0],
  });

  products = products?.map(({ product }) => product);

  return (
    <Container
      data-comp={CollectionGrid.displayName}
      sx={{
        variant: 'layout.content',
      }}
    >
      <Grid
        products={gatedProducts || products}
        hasInitialProducts={products}
        gatedPage={gatedPage}
      />
    </Container>
  );
}

CollectionGrid.displayName = 'CollectionGrid';
CollectionGrid.Schema = Schema;
