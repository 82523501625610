import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      position: 'relative',
    };

    this.video = {
      display: 'block',
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      translate3d: '0, 0, 0',
    };
  })()
);
