import { useMemo } from 'react';

export const useSliderParams = (
  { isLogo, logoCount, customParams } = { isLogo: false }
) => {
  const quoteParams = useMemo(
    () => ({
      allowTouchMove: false,
      navigation: false,
      slidesPerView: 1,
      effect: 'slide',
      loop: false,
    }),
    []
  );

  const logoParams = useMemo(
    () => ({
      speed: customParams?.speed || 300,
      loop: customParams?.loop || false,
      slidesPerView: 2.7,
      spaceBetween: 30,
      effect: 'slide',
      freeMode: false,
      autoplay: false,
      breakpoints: {
        370: {
          slidesPerView: 2.7,
          spaceBetween: 30,
          freeMode: true,
          autoplay: false,
        },
        768: {
          slidesPerView: 3.7,
          spaceBetween: 30,
          autoplay: false,
          freeMode: true,
        },
        1024: {
          slidesPerView: logoCount,
          allowTouchMove: false,
          allowSlideNext: false,
          allowSlidePrev: false,
          spaceBetween: 30,
          freeMode: false,
          autoplay: false,
        },
        1280: {
          slidesPerView: logoCount,
          allowTouchMove: false,
          allowSlideNext: false,
          allowSlidePrev: false,
          spaceBetween: 30,
          freeMode: false,
          autoplay: false,
        },
      },
    }),
    []
  );

  return isLogo ? logoParams : quoteParams;
};
