import { useCallback, useState } from 'react';
import { Flex, Box, Paragraph } from 'theme-ui';
import {
  useCart,
  useProductByHandle,
  useCartRemoveItem,
} from '@backpackjs/storefront';

import { AddToCart, Link, Locale, Svg, Dropdown } from '@snippets';

import { RebuyItemImage } from './RebuyItemImage';
import { themed } from './RebuyModal.theme';

export const RebuyItem = themed(
  ({
    theme,
    isUpsell,
    product,
    productHandle,
    onItemClick = () => {},
    index,
    ...props
  }) => {
    if (!product) return null;

    const { product: fullProduct } = useProductByHandle({
      handle: product.handle,
    });

    const cart = useCart();
    const { cartRemoveItem } = useCartRemoveItem();

    const handleUpsellRemove = () => {
      const removeItem = cart?.lines?.find(
        (item) => item?.variant?.product?.handle == productHandle
      );
      if (isUpsell && removeItem) {
        cartRemoveItem({ lineId: removeItem.id });
      }
    };

    const [selectedVariant, setSelectedVariant] = useState(null);
    const updateSelectedVariant = (option) => {
      setSelectedVariant(option);
    };

    const titleArray = fullProduct?.title?.split(' | ');
    const title = titleArray?.[0];
    let subTitle = titleArray?.[1];
    const size = titleArray?.[2];
    if (size) subTitle += ` | ${size}`;

    return (
      <Flex
        data-comp={RebuyItem.displayName}
        {...props}
        onClick={(event) => onItemClick(event, index)}
        sx={{
          ...props.sx,
          flexDirection: 'column',
          width: '100%',
          maxWidth: '200px',
          mx: 'auto',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '200px', mx: 'auto' }}>
          <Box sx={{ position: 'relative' }}>
            <Link
              href={`/products/${product.handle}`}
              aria-label={fullProduct?.title}
              sx={{
                mb: 4,
                position: 'relative',
              }}
            >
              <RebuyItemImage
                selectedVariant={selectedVariant || fullProduct?.variants?.[0]}
                fullProduct={fullProduct}
              />
            </Link>
          </Box>

          <Paragraph sx={{ fontSize: ['15px', '17px'], fontWeight: 'bold' }}>
            {title}
          </Paragraph>

          <Paragraph sx={{ variant: 'text.base', color: '#8C8C8D' }}>
            {subTitle}
          </Paragraph>

          {/* price */}
          <Box sx={{ minHeight: '22px', mt: [0, 3], mb: 3 }}>
            {fullProduct?.variants?.[0]?.product && (
              <Locale.Variant
                variant={selectedVariant || fullProduct?.variants?.[0]}
              >
                <Locale.Price>
                  {({ priceV2, compareAtPriceV2, isOnSale }) => {
                    return isOnSale ? (
                      <Flex sx={{ justifyContent: 'center' }}>
                        <Paragraph sx={theme.price.new}>
                          {priceV2.locale}
                        </Paragraph>
                        <Paragraph sx={theme.price.lineThrough}>
                          {compareAtPriceV2.locale}
                        </Paragraph>
                      </Flex>
                    ) : (
                      <Paragraph sx={theme.price.regular}>
                        {priceV2.locale}
                      </Paragraph>
                    );
                  }}
                </Locale.Price>
              </Locale.Variant>
            )}
          </Box>

          {/* dropdown */}
          {fullProduct?.variants?.[0] &&
            fullProduct?.variants.length > 1 &&
            fullProduct?.handle?.includes('ice-pack') && (
              <Box>
                <Dropdown
                  label="Options"
                  initial={fullProduct?.variants?.[0]}
                  onSelected={updateSelectedVariant}
                  sx={{
                    position: 'relative',
                    zIndex: 69,
                  }}
                >
                  <Dropdown.Selected>
                    {({ selecting, selected }) => (
                      <Flex
                        sx={{
                          ...theme.item,
                          bg: selecting ? 'lightGray' : 'background',
                        }}
                      >
                        <Paragraph sx={theme.item.selectedName}>
                          {selected?.title}
                        </Paragraph>

                        <Svg
                          alt="Select a variant"
                          viewBox="0 0 16 16"
                          src="/svgs/chevron/up.svg#up"
                          sx={{
                            ...theme.item.toggle,
                            transition: 'all 0.2s ease-in-out',
                            transform: selecting
                              ? 'rotate(0deg)'
                              : 'rotate(-180deg)',
                          }}
                        />
                      </Flex>
                    )}
                  </Dropdown.Selected>

                  {fullProduct?.variants.map((variant) => (
                    <Dropdown.Option key={variant.title} option={variant}>
                      {({ selecting, isSelected, option }) =>
                        isSelected ? null : (
                          <Flex
                            sx={{
                              ...theme.item,
                              bg: isSelected ? 'lightGray' : 'background',
                            }}
                          >
                            <Paragraph sx={theme.item.optionName}>
                              {variant?.title}
                            </Paragraph>
                          </Flex>
                        )
                      }
                    </Dropdown.Option>
                  ))}
                </Dropdown>
              </Box>
            )}
        </Box>

        <Box sx={{ textAlign: 'center', maxWidth: 250, mx: 'auto' }}>
          <AddToCart
            product={product}
            selectedVariant={selectedVariant || fullProduct?.variants?.[0]}
            sx={theme.atc}
            handleUpsellRemove={handleUpsellRemove}
            rebuyWidget={
              isUpsell ? 'Pack Rebuy Upsell' : 'Pack Rebuy Cross-Sell'
            }
          />
        </Box>
      </Flex>
    );
  }
);

RebuyItem.displayName = 'RebuyItem';
