const brand = process.env.NEXT_PUBLIC_SITE_TITLE || 'BruMate';

export const mapProductItemProduct =
  (list = '') =>
  (product, index = 0) => {
    try {
      if (!product) return null;
      const firstVariant = product.variants?.[0];

      return {
        sku: firstVariant?.sku || '',
        name: product.title || '',
        brand: product.vendor || brand,
        category: product.productType || '',
        variant: firstVariant?.title || '',
        price: `${firstVariant?.priceV2?.amount || ''}`,
        list,
        product_id: product.legacyResourceId || '',
        variant_id: firstVariant?.legacyResourceId || '',
        compare_at_price: `${firstVariant?.compareAtPriceV2?.amount || ''}`,
        image: product.featuredImage?.src || '',
        collections: product.collections || [],
        tags: product.tags || [],
        position: index + 1,
        url: `/products/${product.handle}`,
      };
    } catch (error) {
      console.error('DataLayer:mapProductItemProduct error', error.message);
      console.error('DataLayer:mapProductItemProduct product', product);
      return null;
    }
  };

export const mapProductItemVariant =
  (list = '') =>
  (variant, index = 0) => {
    try {
      if (!variant) return null;

      return {
        sku: variant.sku || '',
        name: variant.product.title || '',
        brand: variant.product.vendor || brand,
        category: variant.product.productType || '',
        variant: variant.title || '',
        price: `${variant.priceV2?.amount || ''}`,
        list,
        product_id: variant.product.legacyResourceId || '',
        variant_id: variant.legacyResourceId || '',
        compare_at_price: `${variant.compareAtPriceV2?.amount || ''}`,
        image: variant.image?.src || '',
        collections: variant.product.collections || [],
        tags: variant.product.tags || [],
        position: (variant.index ?? index) + 1,
        url: `/products/${variant.product.handle}`,
      };
    } catch (error) {
      console.error('DataLayer:mapProductItemVariant error', error.message);
      console.error('DataLayer:mapProductItemVariant variant', variant);
      return null;
    }
  };

export const mapProductPageVariant =
  (list = '') =>
  (variant) => {
    try {
      if (!variant) return null;

      return {
        sku: variant.sku || '',
        name: variant.product.title || '',
        brand: variant.product.vendor || brand,
        category: variant.product.productType || '',
        variant: variant.title || '',
        price: `${variant.priceV2?.amount || ''}`,
        list,
        product_id: variant.product.id?.split('/').pop() || '',
        variant_id: variant.legacyResourceId || '',
        compare_at_price: `${variant.compareAtPriceV2?.amount || ''}`,
        image: variant.image?.src || '',
        collections: variant.product.collections || [],
        tags: variant.product.tags || [],
        url: `/products/${variant.product.handle}?variant=${variant.legacyResourceId}`,
      };
    } catch (error) {
      console.error('DataLayer:mapProductPageVariant error', error.message);
      console.error('DataLayer:mapProductPageVariant variant', variant);
      return null;
    }
  };

const decodedId = (id) => {
  try {
    if (!id) return undefined;
    return window.atob(id);
  } catch (error) {
    return id;
  }
};

export const mapLineItem =
  (list = '') =>
  (item, index = 0) => {
    try {
      const { quantity, variant } = { ...item };
      if (!variant) return null;

      return {
        sku: variant.sku || '',
        name: variant.product?.title || '',
        brand,
        category: variant.product?.productType || '',
        variant: variant.title || '',
        price: `${variant.priceV2?.amount || ''}`,
        quantity: `${quantity || ''}`,
        list,
        product_id: decodedId(variant.product?.id)?.split('/').pop() || '',
        variant_id: decodedId(variant.id)?.split('/').pop() || '',
        compare_at_price: `${variant.compareAtPriceV2?.amount || ''}`,
        image: variant.image?.src || '',
        collections: variant.product?.collections?.nodes || [],
        tags: variant.product?.tags || [],
        position: (item.index || index) + 1,
        url: `/products/${variant.product.handle}?variant=${variant.id
          .split('/')
          .pop()}`,
      };
    } catch (error) {
      console.error('DataLayer:mapLineItem error', error.message);
      console.error('DataLayer:mapLineItem item', item);
      return null;
    }
  };

export const fetchCustomerOrders = async ({ accessToken }) => {
  try {
    if (!accessToken) return null;

    const url = `/api/customer/orders`;
    const request = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        first: 100,
        country: 'US',
        accessToken,
      }),
    });

    if (!request?.ok) {
      return null;
    }

    const data = await request.json();
    const { ok, response } = data;

    if (!ok) {
      return null;
    }
    return response?.orders;
  } catch (error) {
    console.error('DataLayer:fetchCustomerOrders error', error.message);
    return null;
  }
};
