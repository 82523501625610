import Head from 'next/head';

function HeadFonts() {
  return (
    <Head>
      <link
        key="MrEavesXLModOT-Bold.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/MrEavesXLModOT-Bold.woff2"
      />
      <link
        key="MrEavesXLModOT-Heavy.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/MrEavesXLModOT-Heavy.woff2"
      />
      <link
        key="MrEavesXLModOT-Reg.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/MrEavesXLModOT-Reg.woff2"
      />
    </Head>
  );
}

export default HeadFonts;
