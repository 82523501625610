import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.column.start',
      width: '100%',
    };

    this.productRow = {
      ...this.wrapper,
      minHeight: ['113px', 20],
      overflow: 'hidden',
    };

    this.title = {
      variant: 'text.h4',
      minHeight: ['24px', '32px'],
    };

    this.variantStarsWrapper = {
      variant: 'flex.row.start.center',
      flexWrap: 'wrap',
      columnGap: 6,
      mt: 2,
    };

    this.variant = {
      variant: 'text.base',
      color: 'mediumDarkGray',
      minHeight: '22px',
    };

    this.description = {
      variant: 'text.base',
      mt: 2,
      minHeight: '22px',
    };

    this.priceWrapper = {
      mt: 2,
      minHeight: '22px',
    };

    this.newPrice = {
      variant: 'text.base',
      color: 'red',
      mr: 4,
    };

    this.compareAtPrice = {
      variant: 'text.base',
      textDecoration: 'line-through',
      color: 'mediumDarkGray',
    };

    this.price = {
      variant: 'text.base',
    };

    this.loader = {
      height: '22px',
      svg: {
        height: '100%',
        color: 'gray',
      },
    };
  })()
);
