import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    this.contentWrapper = {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      pointerEvents: 'none',
      position: 'relative',
      width: '100%',
    };

    this.content = {
      px: [12, 15],
      py: [15, 17],
      pointerEvents: 'auto',
      '@media only screen and (max-width: 479px)': {
        px: 10,
      },
    };

    this.heading = {
      variant: 'text.h2',
      m: 0,
    };

    this.subheading = {
      variant: 'text.lg',
    };
  })()
);
