import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as LinkUI, Box } from 'theme-ui';
import LinkNext from 'next/link';

export const Link = forwardRef(
  (
    {
      href = '',
      newTab = false,
      hiddenForSeo = false,
      prefetch = false,
      children,
      query, // next/link query object
      sx,
      ...props
    },
    ref
  ) => {
    return hiddenForSeo ? (
      <LinkUI
        data-comp="Link-Hidden"
        href={href}
        ref={ref}
        sx={{ display: 'none' }}
      />
    ) : href ? (
      newTab ? (
        <LinkUI
          data-comp="Link-External"
          href={href}
          ref={ref}
          target="_blank"
          sx={{
            display: 'block',
            ...sx,
          }}
          {...props}
        >
          {children}
        </LinkUI>
      ) : (
        <LinkNext
          href={typeof query === 'object' ? { pathname: href, query } : href}
          prefetch={prefetch}
          passHref
        >
          <LinkUI
            data-comp="Link-Internal"
            ref={ref}
            sx={{
              display: 'block',
              ...sx,
            }}
            {...props}
          >
            {children}
          </LinkUI>
        </LinkNext>
      )
    ) : (
      <Box
        data-comp="Link-NoHref"
        ref={ref}
        sx={{ cursor: 'default', ...sx }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

Link.propTypes = {
  href: PropTypes.string,
  newTab: PropTypes.bool,
  hiddenForSeo: PropTypes.bool,
  prefetch: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  query: PropTypes.object,
  sx: PropTypes.object,
};
