import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.fourSixty = {
      '.fs-has-links::after':{
        p: '5px 7.5px',
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.8)',
        content: '"Shop Now"',
      },

      '.fs-showcase_v2_5.fs-desktop .fs-entry-container': {
        width: '25% !important',
        pt: '25% !important'
      },

      '.fs-showcase_v2_5.fs-mobile .fs-entry-container': {
        width: '50% !important',
        pt: '50% !important',
      },

      '.fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title':
        {
          fontFamily: 'Times New Roman, serif',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
        },

      'div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text':
        {
          fontFamily: 'Helvetica Nueue, Helvetica, Arial, sans-serif',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
        },

      '.fs-wrapper div.fs-text-container *': {
        color: '#ffffff',
      },
      '.fs-wrapper div.fs-text-container': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        margin: '0px',
      },

      'div.fs-entry-date': {
        display: 'none',
      },

      'div.fs-entry-title': {
        display: 'none',
      },

      '.fs-showcase_v2_5.fs-wrapper div.fs-timeline-entry': {
        margin: '6px',
      },

    };
  })()
);