import { store } from '@backpackjs/storefront';

const collection = store.recoil.atom({
  key: 'collection',
  default: null,
});

const selectedSort = store.recoil.atom({
  key: 'collection/selectedSort',
  default: null,
});

const selectedFilters = store.recoil.atom({
  key: 'collection/selectedFilters',
  default: [],
});

const selectedResultsPerPage = store.recoil.atom({
  key: 'collection/selectedResultsPerPage',
  default:
    parseInt(process.env.NEXT_PUBLIC_COLLECTIONS_RESOLVE_FIRST, 10) || 30,
});

const currentResultsPage = store.recoil.atom({
  key: 'collection/currentResultsPage',
  default: 1,
});

const filtersSidebar = store.recoil.atom({
  key: 'collection/filtersSidebar',
  default: null,
});

const swatchesMap = store.recoil.atom({
  key: 'collection/swatchesMap',
  default: {},
});

export default {
  // atoms
  collection,
  selectedSort,
  selectedFilters,
  selectedResultsPerPage,
  currentResultsPage,
  filtersSidebar,
  swatchesMap,
};
