export const bestSellers = {
  label: 'Best sellers (mobile only)',
  name: 'bestSellers',
  component: 'group',
  fields: [
    {
      label: 'Products heading',
      name: 'heading',
      component: 'text',
    },
    {
      name: 'products',
      label: 'Products',
      component: 'group-list',
      itemProps: {
        label: '{{item.product.title}}' || '{{item.product.handle}}' || 'Product',
      },
      defaultItem: {
        product: {
          handle: 'hopsulator-slim-sundaisy-12oz-slim-cans',
          title: 'slim'
        },
      },
      fields: [
        {
          name: 'image',
          label: 'Image',
          description: 'Overrides default Shopify product image',
          component: 'image',
        },
        {
          name: 'product',
          component: 'productSearch',
          label: 'Product',
        },
      ],
    },
  ],
  defaultValue: {
    heading: 'Best Sellers',
    products: [
      {
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/1_a80439a0-e419-4e80-9e57-f902cae2bf58.jpg?v=1649376837',
        },
        product: { handle: 'hopsulator-slim-sundaisy-12oz-slim-cans' },
      },
    ],
  },
};
