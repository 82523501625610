import { color, section, button } from '@settings/common';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  const defaultTiles = [
    {
      alt: 'Woman in a red dress sitting on a couch.',
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/ValentinesTile.png',
      },
      cta: {
        text: 'Valentines day gift guide',
        url: '/collections/all',
      },
      style: button.value.inverse,
    },
    {
      alt: 'Woman holding a brumate MUV.',
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/MuvTile_d1b8fb12-b0f2-43b5-a0d2-7f8536c976be.png',
      },
      cta: {
        text: 'MÜV Collection',
        url: '/collections/all',
      },
      style: button.value.inverse,
    },
    {
      alt: 'A canned beverage sitting inside of a Brumate Hopsulator.',
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsTile.png',
      },
      cta: {
        text: 'Deals & Steals',
        url: '/collections/all',
      },
      style: button.value.inverse,
    },
  ];

  return {
    label: 'Featured Three Tile',
    key: 'featured-three-tile',
    fields: [
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 3 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.cta.text}}',
        },
        defaultItem: {
          alt: 'Woman in a red dress sitting on a couch.',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/RedVelvetTile.png',
          },
          overlay: true,
          cta: {
            text: 'valentines day gift guide',
            url: '/collections/all',
          },
          style: button.value.inverse,
        },
        validate: {
          maxItems: 3,
        },
        fields: [
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
            description:
              'Provide a brief description of the image for accessibility compliance',
          },
          {
            name: 'image',
            label: 'Image',
            component: 'image',
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            name: 'style',
            label: 'Link style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: defaultTiles,
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'xs',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'xs',
          },
        },
      },
    ],
  };
}
