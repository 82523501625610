import React from 'react';
import { Box, Container, Flex, Heading } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Accordion } from './Accordion';
import { Schema } from './Accordions.schema';
import { themed } from './Accordions.theme';
import { FAQPageSeo } from './FAQPageSeo';

export const Accordions = withInView(
  themed(({ theme, cms }) => {
    return (
      <Section>
        {cms?.accordionsGroup && (
          <FAQPageSeo
            accordions={cms.accordionsGroup
              .map((accordionGroup) => {
                return [...accordionGroup.accordions] || [];
              })
              .flat()}
          />
        )}
        <Container data-comp={Accordions.displayName} sx={theme.wrapper}>
          <Heading variant="h2" sx={theme.heading}>
            {cms?.heading}
          </Heading>
          <Box>
            {cms?.accordionsGroup?.map(
              (accordionGroup, accordionGroupIndex) => {
                return (
                  <Flex
                    key={accordionGroupIndex}
                    variant="flex.column"
                    sx={theme.accordionGroup}
                  >
                    <Heading variant="h3" sx={theme.accordionGroupHeading}>
                      {accordionGroup.heading}
                    </Heading>
                    <Box>
                      <Flex variant="flex.column">
                        {accordionGroup.accordions?.map((accordion, index) => {
                          const forceActiveState =
                            accordionGroupIndex === 0 && index === 0;
                          return (
                            <Accordion
                              key={`${accordionGroup.heading}-accordion-${index}`}
                              accordion={accordion}
                              theme={theme}
                              forceActiveState={forceActiveState}
                            />
                          );
                        })}
                      </Flex>
                    </Box>
                  </Flex>
                );
              }
            )}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Accordions.displayName = 'Accordions';
Accordions.Schema = Schema;
