import { Container, Box } from 'theme-ui';

import { ProductItem } from '@snippets';

import { Sort, useSearchSort } from './Sort';
import { Pagination } from './Pagination';
import { useSearch } from '../hooks';
import { themed } from './SearchPage.theme';

export const SearchGrid = themed(({ theme, merchandising, searchTerm }) => {
  const [{ pageResults, totalPageResults }] = useSearch();
  const [{ sortOptions }] = useSearchSort();

  const header = merchandising?.content?.header?.[0];
  const footer = merchandising?.content?.footer?.[0];
  const banner = merchandising?.content?.banner?.[0];

  return (
    <Container data-comp={SearchGrid.displayName} sx={{ px: [8, 0] }}>
      {/* Header and banner merchandising banner */}
      {(header || banner) && (
        <Box sx={{ mb: 12, display: ['none', 'block'] }}>
          {[header, banner].map((item, index) =>
            item ? (
              <Box
                key={index}
                dangerouslySetInnerHTML={{
                  __html: item,
                }}
              />
            ) : null
          )}
        </Box>
      )}

      {sortOptions && totalPageResults > 0 && <Sort />}

      {/* Results */}
      <Container
        sx={{
          ...theme.grid,
          gridTemplateColumns: totalPageResults
            ? ['repeat(2, 1fr)', 'repeat(3, 1fr)']
            : ['1fr'],
        }}
      >
        {totalPageResults
          ? pageResults.map((product, index) => {
              if (!product) return null;
              return !product.isBanner ? (
                <ProductItem
                  key={product.handle}
                  product={product}
                  index={index}
                  isSearchPage
                  searchTerm={searchTerm}
                />
              ) : (
                // Inline merchandising banner
                <Box
                  key={product.id}
                  sx={theme.banner}
                  dangerouslySetInnerHTML={{
                    __html: product.value,
                  }}
                />
              );
            })
          : null}
      </Container>

      <Pagination />

      {/* Footer merchandising banner */}
      {footer && (
        <Box
          dangerouslySetInnerHTML={{
            __html: footer,
          }}
          sx={{ mt: 12, display: ['none', 'block'] }}
        />
      )}
    </Container>
  );
});

SearchGrid.displayName = 'SearchGrid';
