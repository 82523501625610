import { useMemo } from 'react';
import { Flex, Paragraph, Spinner, AspectRatio, Heading } from 'theme-ui';
import { useProductByHandle } from '@backpackjs/storefront';

import { Picture, Link } from '@snippets';

import { useMenuSidebar } from '../useMenuSidebar';
import { themed } from './ProductsRow.theme';

export const ProductsRowItem = themed(({ theme, handle, image }) => {
  if (!handle) return null;

  const { product, ...status } = useProductByHandle({ handle });
  const [{}, { closeMenuSidebar }] = useMenuSidebar();

  const ratio = image?.ratio || product?.images?.[0]?.ratio;
  const url = `/products/${product?.handle}`;

  const firstImage = useMemo(() => {
    return product?.media?.find((item) => {
      return item.mediaContentType === 'IMAGE';
    })?.image;
  }, [product?.id]);
  const src = image?.src || firstImage?.originalSrc;

  const title = useMemo(() => {
    const fullTitle = product?.title;
    const splitIndex = fullTitle?.indexOf('|') || -1;
    return {
      product:
        splitIndex >= 0 ? fullTitle?.slice(0, splitIndex).trim() : fullTitle,
      variant: splitIndex >= 0 ? fullTitle?.slice(splitIndex + 1).trim() : '',
    };
  }, [product?.title]);

  return status?.finished && product ? (
    <Link
      data-comp={ProductsRowItem.displayName}
      href={url}
      onClick={closeMenuSidebar}
      sx={theme.product}
    >
      <Picture
        alt={product.title}
        images={[
          {
            src,
            ratio,
            width: 253,
          },
        ]}
        sx={theme.product.picture}
      />

      <Heading sx={theme.product.title} as="h5">
        {title.product}
      </Heading>

      <Paragraph
        sx={{
          color: 'mediumDarkGray',
          mt: 1,
        }}
      >
        {title.variant}
      </Paragraph>
    </Link>
  ) : (
    <AspectRatio ratio={1}>
      <Flex sx={theme.skeleton}>
        <Spinner sx={theme.skeleton.spinner} />
      </Flex>
    </AspectRatio>
  );
});

ProductsRowItem.displayName = 'MenuSidebarProductsRowItem';
