import { section, color, align } from '@settings/common';

export function Schema() {
  return {
    label: 'Product Recs',
    key: 'product-recs',
    fields: [
      {
        name: 'tag',
        label: 'Searchspring Product Recs Tag',
        component: 'text',
        description:
          'The product recommendations profile tag taken from Searchspring admin, e.g. "cross-sell", "similar", "home", "recently-viewed"',
        defaultValue: 'cross-sell',
      },
      {
        name: 'limit',
        label: 'Number of recommendations',
        component: 'number',
        defaultValue: 8,
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
        ],
        defaultValue: {
          heading: 'Complete The Fam',
        },
      },
      {
        name: 'loop',
        label: 'Loop',
        component: 'toggle',
        variant: 'horizontal',
        description:
          'Loop products when they reach the end. Note: Requires 5 or more products to be set',
        defaultValue: true,
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
