import { useMemo, useState, useCallback } from 'react';
import { Box, Flex, Heading, Paragraph, Spinner, Image } from 'theme-ui';
import { Locale, Picture, Dropdown } from '@snippets';
import { UpsellAddToCart } from '@snippets/Cart/Upsell/SliderItem/UpsellAddToCart';
import { themed } from './ProductUpsellsListItem.theme';

export const ProductUpsellsListItem = themed(
  ({ theme, handle, fullProduct, zIndex }) => {
    const sizeVariants = useMemo(() => {
      const variantArray = fullProduct?.variants
        ?.filter(
          (variant) =>
            variant.product.handle === handle &&
            variant.selectedOptionsMap.Size !== undefined
        )
        .sort((a, b) => a.position - b.position);
      return variantArray;
    }, [fullProduct?.variants]);

    const colorVariants = useMemo(() => {
      const variantArray = fullProduct?.variants
        ?.filter(
          (variant) =>
            variant.product.handle === handle &&
            variant.selectedOptionsMap.Color !== undefined
        )
        .sort((a, b) => a.position - b.position);
      return variantArray;
    }, [fullProduct?.variants]);

    const initialVariant = useMemo(() => {
      if (sizeVariants?.length > 1) {
        return sizeVariants?.find((variant) => variant.inventoryQuantity > 0);
      }
      if (colorVariants?.length > 1) {
        return colorVariants?.find((variant) => variant.inventoryQuantity > 0);
      }

      return fullProduct?.variants?.[0];
    }, [sizeVariants, colorVariants, fullProduct?.variants?.[0]]);

    const [selectedVariant, setSelectedVariant] = useState(initialVariant);

    const handleSelect = useCallback(
      (option) => {
        const selectedSizeVariant = sizeVariants?.find(
          (variant) => variant?.selectedOptionsMap?.Size === option
        );
        const selectedColorVariant = colorVariants?.find(
          (variant) => variant?.selectedOptionsMap?.Color === option
        );
        setSelectedVariant(selectedSizeVariant || selectedColorVariant);
      },
      [sizeVariants, colorVariants]
    );

    const productVariant = useMemo(() => {
      if (
        (sizeVariants?.length > 1 && selectedVariant) ||
        (colorVariants?.length > 1 && selectedVariant)
      ) {
        return selectedVariant;
      }
      if (
        (sizeVariants?.length > 1 && !selectedVariant) ||
        (colorVariants?.length > 1 && !selectedVariant)
      ) {
        return initialVariant;
      }
      return fullProduct?.variants?.[0];
    }, [
      sizeVariants,
      colorVariants,
      initialVariant,
      fullProduct?.variants?.[0],
      selectedVariant,
    ]);

    const title = useMemo(() => {
      const fullTitle = fullProduct?.title;
      const splitIndex = fullTitle?.indexOf('|') || -1;
      return {
        product:
          splitIndex >= 0 ? fullTitle?.slice(0, splitIndex).trim() : fullTitle,
        variant: splitIndex >= 0 ? fullTitle?.slice(splitIndex + 1).trim() : '',
      };
    }, [fullProduct?.title]);

    const image =
      (sizeVariants?.length > 1 && selectedVariant) ||
      (colorVariants?.length > 1 && selectedVariant)
        ? selectedVariant?.image?.originalSrc
        : fullProduct?.media?.find((item) => {
            return item.mediaContentType === 'IMAGE';
          })?.image?.originalSrc;

    return (
      <Flex
        data-comp={ProductUpsellsListItem.displayName}
        sx={{ ...theme.wrapper, zIndex }}
      >
        <Picture
          alt={fullProduct?.title}
          images={[
            {
              src: image,
              width: 350,
              ratio: 1,
            },
          ]}
          sx={theme.picture}
        >
          {!image && <Spinner sx={theme.picture.spinner} />}
        </Picture>

        <Flex sx={theme.details}>
          <Heading as="h6" sx={theme.title}>
            {title?.product}
          </Heading>

          {sizeVariants?.length > 1 && (
            <Flex>
              <Dropdown
                variant="sizes"
                initial={sizeVariants?.[0]?.selectedOptionsMap.Size}
                onSelected={async (optionSelected) => {
                  handleSelect(optionSelected);
                }}
                sx={theme.dropdown}
              >
                <Dropdown.Selected>
                  {({ selected, selecting }) => (
                    <Flex sx={theme.option}>
                      <Paragraph sx={theme.option.name}>{selected}</Paragraph>

                      {sizeVariants?.length ? (
                        <Image
                          alt="Toggle dropdown"
                          src={
                            selecting
                              ? '/svgs/chevron/up.svg#up'
                              : '/svgs/chevron/down.svg#down'
                          }
                          width="12px"
                          sx={theme.option.image}
                        />
                      ) : null}
                    </Flex>
                  )}
                </Dropdown.Selected>

                {sizeVariants?.map((variant) => {
                  const size = variant.selectedOptionsMap.Size;
                  return (
                    <Dropdown.Option option={size} key={size}>
                      {({ option }) => (
                        <Flex sx={theme.option}>
                          <Paragraph sx={theme.option.name}>{option}</Paragraph>
                        </Flex>
                      )}
                    </Dropdown.Option>
                  );
                })}
              </Dropdown>
            </Flex>
          )}

          {colorVariants?.length > 1 && !sizeVariants?.length && (
            <Flex>
              <Dropdown
                variant="colors"
                initial={colorVariants?.[0]?.selectedOptionsMap.Color}
                onSelected={async (optionSelected) => {
                  handleSelect(optionSelected);
                }}
                sx={theme.dropdown}
              >
                <Dropdown.Selected>
                  {({ selected, selecting }) => (
                    <Flex sx={theme.option}>
                      <Paragraph sx={theme.option.name}>{selected}</Paragraph>

                      {colorVariants?.length ? (
                        <Image
                          alt="Toggle dropdown"
                          src={
                            selecting
                              ? '/svgs/chevron/up.svg#up'
                              : '/svgs/chevron/down.svg#down'
                          }
                          width="12px"
                          sx={theme.option.image}
                        />
                      ) : null}
                    </Flex>
                  )}
                </Dropdown.Selected>

                {colorVariants?.map((variant) => {
                  const color = variant.selectedOptionsMap.Color;
                  return (
                    <Dropdown.Option option={color} key={color}>
                      {({ option }) => (
                        <Flex sx={theme.option}>
                          <Paragraph sx={theme.option.name}>{option}</Paragraph>
                        </Flex>
                      )}
                    </Dropdown.Option>
                  );
                })}
              </Dropdown>
            </Flex>
          )}

          {sizeVariants?.length <= 1 && colorVariants?.length <= 1 ? (
            <Paragraph sx={theme.variant}>{title?.variant}</Paragraph>
          ) : null}

          {productVariant && (
            <Locale.Variant variant={productVariant} sx={theme.price}>
              <Locale.Price>
                {({ priceV2, compareAtPriceV2, isOnSale }) => {
                  return isOnSale ? (
                    <Flex>
                      <Paragraph sx={theme.newPrice}>
                        {priceV2.locale}
                      </Paragraph>
                      <Paragraph sx={theme.compareAtPrice}>
                        {compareAtPriceV2.locale}
                      </Paragraph>
                    </Flex>
                  ) : (
                    <Paragraph>{priceV2.locale}</Paragraph>
                  );
                }}
              </Locale.Price>
            </Locale.Variant>
          )}

          <Box sx={theme.mobileAtc}>
            {fullProduct && (
              <UpsellAddToCart
                product={fullProduct}
                selectedVariant={productVariant}
              />
            )}
          </Box>
        </Flex>
        <Flex sx={theme.desktopAtc}>
          {fullProduct && (
            <UpsellAddToCart
              product={fullProduct}
              selectedVariant={productVariant}
            />
          )}
        </Flex>
      </Flex>
    );
  }
);
