import { useCallback } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import atob from 'atob';
import { nanoid } from 'nanoid';

import { useSearchspring } from '@hooks';

// https://searchspring.zendesk.com/hc/en-us/articles/360052114591-Beacon-API-Recommendations

export const useSearchspringBeacon = () => {
  const { requestSearchspring } = useSearchspring();
  // const customer = useCustomer();
  // const customerId = customer?.id || '';

  const getIdOrGenerateId = useCallback(async (name) => {
    try {
      if (!name) return;

      let id = null;
      document.cookie?.split(/\s*;\s*/).find((item) => {
        const [key, value] = item.split(/\s*=\s*/);
        if (key !== name) return false;
        id = value;
        return true;
      });

      if (id) {
        return id;
      }
      const newId = nanoid();
      document.cookie = `${name}=${newId}`;
      return newId;
    } catch (error) {
      console.error('Error with getIdOrGenerateId', error.message);
      throw new Error(error.message);
    }
  }, []);

  const sendEventsToBeacon = useCallback(
    async ({
      profileTypes = [], // 'profile.render', 'profile.impression', 'profile.click'
      productTypes = [], // 'profile.product.render', 'profile.product.impression', 'profile.product.click'
      tag, // e.g. 'view-cart', 'product-page'
      placement, // e.g. 'basket-page', 'product-page'
      products = [], // loaded products on mount; required w/ 'profile.product.render'
      visibleProducts = [], // products visible in the viewport; required w/ 'profile.product.impression'
      clickedProduct, // product clicked; required w/ 'profile.product.click'
      seed, // e.g. [{ sku: '123456' }]
    }) => {
      try {
        if (!tag) {
          throw new Error('No tag provided to sendEventsToBeacon');
        }
        if (!placement) {
          throw new Error('No placement provided to sendEventsToBeacon');
        }
        if (!profileTypes.length && !productTypes.length) {
          throw new Error(
            'No profileTypes or productTypes provided to sendEventsToBeacon'
          );
        }
        if (
          productTypes.includes('profile.product.render') &&
          !products.length
        ) {
          throw new Error('products are required for profile.product.render');
        }
        if (
          productTypes.includes('profile.product.impression') &&
          !visibleProducts.length
        ) {
          throw new Error(
            'visibleProducts are required for profile.product.impression'
          );
        }
        if (productTypes.includes('profile.product.click') && !clickedProduct) {
          throw new Error(
            'clickedProduct is required for profile.product.click'
          );
        }

        // const shopper = atob(customerId)?.split('/').pop() || null;
        const userId = await getIdOrGenerateId('ssUserId');
        const sessionId = await getIdOrGenerateId('ssSessionIdNamespace');
        const pageLoadId = nanoid();

        const pids = {};

        const context = {
          website: {
            trackingCode: process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID,
          },
          userId,
          sessionId,
          pageLoadId,
        };

        const profileEvents = profileTypes.map((type) => {
          const id = nanoid();
          pids[type] = id;

          return {
            type,
            category: 'searchspring.recommendations.user-interactions',
            id,
            pid: null,
            event: {
              profile: {
                tag,
                placement,
                seed,
              },
              context: {
                type: 'product-recommendation',
                tag,
                placement,
              },
            },
            context,
          };
        });

        const productEvents = productTypes.reduce((acc, type) => {
          let _products;
          if (type === 'profile.product.render') _products = products;
          else if (type === 'profile.product.impression')
            _products = visibleProducts;
          else if (type === 'profile.product.click')
            _products = [clickedProduct];
          else _products = [];

          const events = _products.map((product) => {
            return {
              type,
              category: 'searchspring.recommendations.user-interactions',
              id: nanoid(),
              pid: pids[type.replace('.product.', '.')] || null,
              event: {
                product: {
                  id: product.id,
                  seed,
                  mappings: product.mappings,
                },
                context: {
                  type: 'product-recommendation',
                  tag,
                  placement,
                },
              },
              context,
            };
          });
          return [...acc, ...events];
        }, []);

        const events = [...profileEvents, ...productEvents];

        const data = await requestSearchspring({
          action: 'sendToBeacon',
          params: { events },
        });

        return data;
      } catch (error) {
        console.error(`Error with useSearchspringBeacon: ${error.message}`);
        throw error;
      }
    },
    []
  );

  return { sendEventsToBeacon };
};
