import { useMemo, useCallback } from 'react';
import { Spinner, Flex, Paragraph, Box, Heading } from 'theme-ui';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';

import { useDataLayerActions, useSidebar } from '@hooks';
import { Picture, Locale, Link } from '@snippets';
import { themed } from './Product.theme';

export const Product = themed(
  ({ theme, product, isLast, index, searchTerm }) => {
    const { sendClickProductItemEvent } = useDataLayerActions();
    const [, { closeSidebar }] = useSidebar();
    const { product: fullProduct } = useProductByHandle({
      handle: product.handle,
    });
    const productUrl = fullProduct ? `/products/${fullProduct.handle}` : '';
    const firstImage = useMemo(() => {
      return fullProduct?.media?.find((item) => {
        return item.mediaContentType === 'IMAGE';
      })?.image;
    }, [fullProduct?.id]);
    const { localized } = useLocalizedVariant({
      variant: fullProduct?.variants?.[0],
    });

    const titles = useMemo(() => {
      const titleArr = fullProduct?.title?.split('|');
      return {
        product: titleArr?.[0]?.trim() || '',
        variant: titleArr?.slice(1)?.join('|').trim() || '',
      };
    }, [fullProduct?.title]);

    const intellisuggestTrackClick = useCallback(() => {
      if (document.images) {
        const apiUrl = `${window.location.protocol || 'http:'}//${
          process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID
        }.a.searchspring.io/api/`;

        const imgTag = new Image();
        imgTag.src = `${apiUrl}track/track.json?d=${
          product.intellisuggestData
        }&s=${product.intellisuggestSignature}&u=${encodeURIComponent(
          `${process.env.SITE_URL}/products/${product.handle}`
        )}&r=${encodeURIComponent(
          document.referrer
        )}&bgfilter.ss_mobile_only=0`;
      }

      return true;
    }, [product.id]);

    const handleClick = useCallback(async () => {
      closeSidebar();
      intellisuggestTrackClick();

      sendClickProductItemEvent({
        isSearchResult: true,
        listIndex: index,
        localized,
        product: fullProduct,
        selectedVariant: fullProduct?.variants?.[0],
        searchTerm,
      });
    }, [localized, fullProduct?.id, index]);

    return (
      <Link
        data-comp={Product.displayName}
        href={productUrl}
        onClick={handleClick}
        sx={theme.grid(isLast)}
      >
        <Picture
          alt={product.title}
          images={[
            {
              src: firstImage?.originalSrc,
              ratio: 1,
              width: 84,
            },
          ]}
          sx={theme.picture}
        />

        <Flex sx={theme.details}>
          <Flex sx={{ variant: 'flex.row.between.start' }}>
            <Box sx={{ flex: 1, mr: 6 }}>
              <Heading
                as="h5"
                sx={{ variant: 'text.base', fontWeight: 500, fontSize: 3 }}
              >
                {titles.product}
              </Heading>

              <Paragraph sx={theme.variant}>{titles.variant}</Paragraph>
            </Box>

            {/* Variant price */}
            <Locale.Variant
              variant={fullProduct?.variants?.[0]}
              sx={{
                p: {
                  fontSize: '13px',
                },
              }}
            >
              <Locale.Price>
                {({ priceV2, compareAtPriceV2, isOnSale }) => {
                  return isOnSale ? (
                    <Flex sx={{ variant: 'flex.column.end.start' }}>
                      <Paragraph sx={theme.newPrice}>
                        {priceV2.locale}
                      </Paragraph>
                      <Paragraph sx={theme.compareAtPrice}>
                        {compareAtPriceV2.locale}
                      </Paragraph>
                    </Flex>
                  ) : (
                    <Paragraph sx={theme.price}>{priceV2.locale}</Paragraph>
                  );
                }}
              </Locale.Price>

              <Locale.Loader>
                <Flex sx={theme.loader}>
                  <Spinner />
                </Flex>
              </Locale.Loader>
            </Locale.Variant>
          </Flex>
        </Flex>
      </Link>
    );
  }
);

Product.displayName = 'Product';
