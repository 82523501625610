import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: ['100%', 940],
      mt: [8, 11],
      '.swiper-wrapper': {
        flexDirection: ['unset', 'row'],
        justifyContent: ['unset', 'center'],
        alignContent: ['unset', 'center'],
        alignItems: ['unset', 'center'],
      },
    };

    this.slide = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      overflow: 'hidden',
      ':hover': {
        opacity: ['unset', 1],
        transition: ['unset', 'opacity .4s ease-in-out'],
      },
    };

    this.logo = {
      height: 40,
      pb: 8,
      ':hover': {
        borderBottom: ['unset', '1px solid black'],
        transition: ['unset', 'border-bottom .4s linear'],
      },
    };
  })()
);
