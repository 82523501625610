import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import { Box } from 'theme-ui';

import { Slide } from '../Slide';
import { themed } from './Slider.theme';

SwiperCore.use([Navigation, Pagination, EffectFade]);

export const Slider = themed(
  ({
    theme,
    activeIndex,
    setSwiper,
    showContentSliderMobile,
    showContentSliderDesktop,
    slider,
    slides,
    swiper,
  }) => {
    return (
      <Box
        data-comp={Slider.displayName}
        sx={{
          ...theme.slider,
          '.swiper-pagination-bullet-active': {
            bg: slider?.activeBulletColor || 'text',
          },
        }}
      >
        <Swiper
          {...defaultParams}
          autoplay={
            slider?.autoplay
              ? {
                  delay: slider.delay || 5000,
                  disableOnInteraction: false,
                }
              : false
          }
          effect={slider?.effect === 'fade' ? 'fade' : null}
          onSwiper={setSwiper}
        >
          {slides?.map((slide, index) => (
            <SwiperSlide key={index}>
              <Slide
                slide={slide}
                isSlider
                showContentSliderMobile={showContentSliderMobile}
                showContentSliderDesktop={showContentSliderDesktop}
                isVisibleContent={
                  slider?.effect !== 'fade' || index === activeIndex
                }
                isActive={index === activeIndex}
                isFirstSlide={index === 0}
              />
            </SwiperSlide>
          ))}

          {/* Bullets nav */}
          {slider?.pagination && (
            <div className="swiper-pagination" sx={theme.bullets} />
          )}
        </Swiper>
      </Box>
    );
  }
);

Slider.displayName = 'Slider';

const defaultParams = {
  centeredSlides: false,
  fadeEffect: { crossFade: true },
  freeMode: false,
  loop: false,
  navigation: false,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  slidesPerGroup: 1,
  slidesPerView: 1,
  spaceBetween: 0,
  watchOverflow: true,
};
