import { align, color } from '../common';

export const promoBar = {
  label: 'Promobar',
  name: 'promoBar',
  component: 'group',
  fields: [
    {
      name: 'enabled',
      label: 'Enabled',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
    {
      component: 'number',
      name: 'duration',
      label: 'Autoplay duration',
      description: 'Time on each message. (sec)',
      step: 1,
      defaultValue: 3,
    },
    {
      component: 'number',
      name: 'speed',
      label: 'Autoplay speed',
      description: 'Time between message transitions. (sec)',
      step: 0.25,
      defaultValue: 0.75,
    },
    {
      label: 'Messages',
      name: 'messages',
      component: 'group-list',
      itemProps: {
        label: '{{item.copy}}' || '{{item.link.text}}' || 'Message',
      },
      defaultItem: {
        copy: 'Your promotional message',
        color: color.value.background,
        background: color.value.text,
        align: 'center',
        link: {
          url: '',
          text: '',
        },
      },
      // each message
      fields: [
        {
          label: 'Background color',
          name: 'background',
          component: 'select',
          options: color.options,
          defaultValue: color.value.text,
        },
        {
          label: 'Text color',
          name: 'color',
          component: 'select',
          options: color.options,
          defaultValue: color.value.background,
        },
        {
          label: 'Text alignment',
          name: 'align',
          component: 'select',
          options: align.options,
          defaultValue: align.value.center,
        },
        {
          label: 'Message (HTML)',
          description:
            'Primary text: <p></p>\nLink: <a href="/pages/example"></a>\nModal link: <modal id="123"></modal>\nBold: <strong></strong>\nItalic: <em></em>\nUnderline: <span style="text-decoration:underline;"></span>',
          name: 'html',
          component: 'html',
        },
        {
          label: 'Message link (optional)',
          name: 'link',
          component: 'link',
          description:
            'Wraps entire message with a link. If message html contains a hyperlink, i.e. "<a></a>", this link will not be used',
          defaultValue: {
            url: '',
            text: '',
          },
        },
      ],
    },
  ],
  defaultValue: {
    enabled: true,
    closeable: false,
    duration: 5,
    speed: 0.75,
    messages: [
      {
        color: '#FFFFFF',
        background: '#222222',
        copy: 'Free shipping on U.S. orders of $50+',
        link: {
          text: '',
          url: '',
        },
      },
    ],
  },
};
