import { useEffect } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';

export function ProductUpselItem({ handle, uProducts, setUProducts }) {
  const { product } = useProductByHandle({
    handle,
  });

  useEffect(() => {
    if (
      product?.handle &&
      product?.status !== 'DRAFT' &&
      (!uProducts || uProducts?.length < 5)
    ) {
      if (!uProducts?.includes(product?.handle)) {
        setUProducts((prev) => [...prev, product]);
      }
    }
  }, [product?.status, product?.handle]);

  return null;
}
