import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.outer = {
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    };

    this.innerLeft = {
      variant: 'flex.column',
      width: '100%',
      alignItems: 'flex-start',
    };

    this.innerRight = {
      ...this.innerLeft,
      alignItems: 'flex-end',
    };

    this.innerCenter = {
      ...this.innerLeft,
      alignItems: 'center',
    };

    this.date = {
      mt: 0,
    };

    this.title = {
      variant: 'text.articleTitle',
      fontSize: '28px',
      lineHeight: '32px',
      overflowWrap: 'break-word',
      maxWidth: '324px',
      my: 3,
      cursor: 'pointer',
    };

    this.excerpt = {};

    this.link = {
      mt: [3, 6],
      cursor: 'pointer',
    };
  })()
);
