import { section, align } from '@settings/common';

export function Schema() {
  return {
    label: 'Html',
    key: 'html',
    fields: [
      {
        label: 'HTML',
        name: 'html',
        component: 'html',
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Content alignment, text alignment',
        component: 'group',
        fields: [
          {
            name: 'contentAlign',
            label: 'Content Alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
            defaultValue: align.value.left,
          },
          {
            name: 'textAlign',
            label: 'Text Alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
            defaultValue: align.value.left,
          },
        ],
      },
      section,
    ],
  };
}
