import PropTypes from 'prop-types';
import { Paragraph, Flex } from 'theme-ui';

import { themed } from './Price.theme';

export const Price = themed(
  ({ theme, estimatedCost, isDiscounted, isOnSale }) => {
    const savingsPercent =
      typeof estimatedCost?.totalAmount?.amount === 'number' &&
      typeof estimatedCost?.compareAtPriceAmount?.amount === 'number'
        ? 100 -
          (estimatedCost?.totalAmount?.amount /
            estimatedCost?.compareAtPriceAmount?.amount) *
            100
        : 0;
    return (
      <Flex data-comp={Price.displayName} sx={theme.price}>
        <>
          {/* the total including savings and any applied discounts */}
          <Paragraph
            sx={{
              ...theme.price.regular,
              color:
                isOnSale ||
                isDiscounted ||
                estimatedCost.totalAmount.amount === 0
                  ? 'redSalePrice'
                  : 'text',
            }}
          >
            {estimatedCost.totalAmount.amount === 0
              ? estimatedCost.totalAmount.locale
              : estimatedCost.totalAmount.locale}
          </Paragraph>

          {/* The full price before any savings or discounts */}
          {isOnSale || isDiscounted ? (
            <Paragraph sx={theme.price.strike}>
              {estimatedCost.compareAtPriceAmount.locale}
            </Paragraph>
          ) : null}
          {(isOnSale || isDiscounted) && savingsPercent >= 1 && (
            <Paragraph
              sx={{
                width: '100%',
                textAlign: 'right',
                fontSize: '9px',
                color: 'redSalePrice',
                textTransform: 'uppercase',
              }}
            >
              {savingsPercent === 100
                ? 'Free'
                : `${savingsPercent?.toFixed(0)}% Off`}
            </Paragraph>
          )}
        </>
      </Flex>
    );
  }
);

Price.displayName = 'Price';
Price.propTypes = {
  estimatedCost: PropTypes.object,
  isOnSale: PropTypes.bool,
  isDiscounted: PropTypes.bool,
};
