import { color, section } from '@settings/common';

export function Schema({ article, blog }) {
  if (article || blog) return null;

  const defaultTiles = [
    {
      cta: {
        text: 'Shop All',
        url: '/collections/all',
      },
      alt: '',
      image: {
        src: '',
      },
    },
  ];

  return {
    label: 'Link Farm',
    key: 'link-farm',
    fields: [
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 6 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.cta.text}}',
        },
        defaultItem: {
          cta: {
            text: 'Shop All',
            url: '/collections/all',
          },
          alt: '',
          image: {
            src: '',
          },
        },
        validate:{
          maxItems: 6,
        },
        fields: [
          {
            name: 'borderColor',
            label: 'Border color',
            component: 'color',
            colors: color.list,
            widget: 'block',
            defaultValue: color.value.text,
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            name: 'alt',
            label: 'Image Alt',
            component: 'text',
            description:
              'Provide a brief description of the image for accessibility compliance',
          },
          {
            name: 'image',
            label: 'Image',
            component: 'image',
            parse: (image) => image,
          },
          {
            name: 'hoverImage',
            label: 'Hover Image',
            component: 'image',
            parse: (image) => image,
          },
        ],
        defaultValue: defaultTiles,
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
}
