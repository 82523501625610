import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      gridArea: 'email',
      width: ['100%', '300px'],
      py: [12, 0],
      px: [8, 0],
      '@media only screen and (min-width: 1024px) and (max-width: 1060px)': {
        width: '210px',
      },
      '@media only screen and (min-width: 1061px) and (max-width: 1140px)': {
        width: '250px',
      },
    };

    this.header = {
      mb: [8, 11],
    };

    this.heading = {
      variant: 'text.label.1',
    };

    this.subtext = {
      variant: 'text.base',
      mt: 8,
    };

    this.input = {
      width: '100%',
    };
  })()
);
