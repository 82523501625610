export const styles = {
  root: {
    fontFamily: 'body',
    lineHeight: '1.35',
    fontWeight: 400,
  },
  h1: {
    variant: 'text.h1',
  },
  h2: {
    variant: 'text.h2',
  },
  h3: {
    variant: 'text.h3',
  },
  h4: {
    variant: 'text.h4',
  },
  h5: {
    variant: 'text.h5',
  },
  h6: {
    variant: 'text.h6',
  },
  articleTitle: {
    variant: 'text.articleTitle',
  },
  pre: {
    fontFamily: 'monospace',
    overflowX: 'auto',
    code: {
      color: 'inherit',
    },
  },
  p: {
    display: 'none',
    variant: 'text.base',
  },
  code: {
    fontFamily: 'monospace',
    fontSize: 'inherit',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  th: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  td: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  a: {
    variant: 'text.base',
  },
  'input::placeholder': {
    variant: 'text.base',
  },
  span: {
    variant: 'text.base',
  },
  div: {
    variant: 'text.base',
  },
};
