import { useState } from 'react';
import { Container, Box, Heading, Paragraph } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';

import { Navigation } from '@snippets/ProductSwiper';
import { useModal, useSidebar } from '@hooks';

import { RebuyItem } from './RebuyItem';
import { themed } from './RebuyModal.theme';

SwiperCore.use([A11y]);

export const RebuyModal = themed(({ rebuyProducts, productHandle, theme }) => {
  const { closeModal } = useModal()[1];
  const [, { openSidebar }] = useSidebar();

  const crossSells = rebuyProducts?.crossSells?.products;
  const upSells = rebuyProducts?.upSells?.products;
  const isUpsell = upSells?.length > 0;
  const products = isUpsell ? upSells : crossSells;
  const language = isUpsell
    ? rebuyProducts?.upSells?.language
    : rebuyProducts?.crossSells?.language;

  const [localSwiper, setLocalSwiper] = useState(null);
  const swiper = localSwiper;
  const setSwiper = setLocalSwiper;

  if (!products || products?.length == 0) return null;

  return (
    <Container data-comp={RebuyModal.displayName} sx={theme.wrapper}>
      <Heading
        sx={{
          color: 'dark',
          fontSize: ['22px', '40px'],
          lineHeight: ['28px', '43px'],
          fontWeight: 'bold',
          position: 'relative',
          top: ['-10px', -40],
        }}
      >
        {language?.title || 'You’ll also love'}
      </Heading>

      <Paragraph
        sx={{
          color: 'dark',
          fontSize: ['13px', '15px'],
          position: 'relative',
          top: [0, -30],
        }}
      >
        {language?.description || 'Don’t forget these customer favorites'}
      </Paragraph>

      <Box sx={{ position: 'relative', top: [0, '-20px'] }}>
        <Box
          mt={8}
          mb={3}
          sx={{
            maxWidth: 600,
            mx: 'auto',
            '& .swiper-container': { overflowY: 'visible' },
            '& button > div > p': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <Swiper
            onSwiper={setSwiper}
            a11y={{ enabled: true }}
            pagination={{
              el: '.swiper-pagination-rebuy',
              type: 'bullets',
              clickable: true,
            }}
            init
            spaceBetween={48}
            loop={products?.length > 1}
            breakpoints={{
              480: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: products?.length > 1 ? 2 : 1,
              },
            }}
          >
            {products?.map((product, index) => {
              if (!product?.handle) return null;
              return (
                <SwiperSlide key={product.handle + index}>
                  <RebuyItem
                    product={product}
                    index={index}
                    isUpsell={isUpsell}
                    productHandle={productHandle}
                    onItemClick={() => {
                      closeModal();
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {products?.length > 1 && (
            <Navigation
              swiper={swiper}
              loop
              count={products?.length}
              leftSx={{ top: [20, 21], left: [-8, 20] }}
              rightSx={{ top: [20, 21], right: [-8, 20] }}
            />
          )}

          <div className="swiper-pagination-rebuy" sx={theme.bullets} />
        </Box>
      </Box>

      <Paragraph
        sx={{
          color: 'dark',
          fontSize: ['15px', '17px'],
          fontWeight: 'bold',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={() => {
          closeModal();
          openSidebar('cart');
        }}
      >
        No Thanks
      </Paragraph>
    </Container>
  );
});

RebuyModal.displayName = 'RebuyModal';
