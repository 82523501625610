import { Heading, Flex, Paragraph, Box } from 'theme-ui';
import { useCollection } from '@backpackjs/storefront';
import store, { useRecoilValue } from '@store';

import { convertAlignToFlex } from '@utils/common';
import { Markdown, Link, Breadcrumbs } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(
  ({ theme, content, hasMedia, showBreadCrumb }) => {
    const textAlign = [content?.alignment, content?.alignmentDt];
    const flexAlign = textAlign.map((align) => convertAlignToFlex(align));
    const collection = useCollection();
    const subCollections = useRecoilValue(store.collectionsMap);
    const superCollectionTitle = useRecoilValue(store.superCollectionTitle);

    return (
      <Flex data-comp={Content.displayName} sx={theme.outer}>
        <Flex
          sx={{
            variant: 'flex.column',
            width: '100%',
            maxWidth: hasMedia ? ['600px', '450px'] : '100%',
            alignItems: flexAlign,
          }}
        >
          {subCollections?.length ? (
            <Box
              sx={{
                mx: [-8, -10, -11, -80],
              }}
            >
              <Breadcrumbs
                isSuperCollection
                superCollectionTitle={superCollectionTitle || ''}
              />
            </Box>
          ) : (
            collection?.handle &&
            showBreadCrumb && (
              <Box
                sx={{
                  mx: [-8, -10, -11, -80],
                }}
              >
                <Breadcrumbs
                  isCollection
                  collectionTitle={collection?.title || ''}
                />
              </Box>
            )
          )}

          {content?.superheading && (
            <Paragraph
              sx={{
                ...theme.superheading,
                textAlign,
              }}
            >
              {content.superheading}
            </Paragraph>
          )}

          <Heading
            sx={{
              ...theme.heading,
              textAlign,
              color: content?.headingColor || 'inherit',
            }}
          >
            {content?.heading}
          </Heading>

          {content?.body && (
            <Markdown
              text={content.body}
              sx={{
                ...(content?.heading
                  ? theme.bodyWithHeading
                  : theme.bodyWithoutHeading),
              }}
              textSx={{
                textAlign,
              }}
            />
          )}

          {content?.cta?.text && (
            <Link
              variant={`buttons.${content?.ctaStyle || 'secondary'}`}
              href={content?.cta?.url}
              newTab={content?.cta?.newTab}
              sx={{
                mt: content?.heading || content?.body ? [10, 11] : 0,
                alignSelf: flexAlign,
              }}
            >
              {content?.cta?.text}
            </Link>
          )}
        </Flex>
      </Flex>
    );
  }
);

Content.displayName = 'Content';
