import { Heading, Flex, Paragraph } from 'theme-ui';

import { convertAlignToFlex } from '@utils/common';
import { Markdown, Link } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, content, customer }) => {
  const textAlign = [content?.alignment, content?.alignmentDt];
  const flexAlign = textAlign.map((align) => convertAlignToFlex(align));
  return (
    <Flex data-comp={Content.displayName} sx={theme.outer}>
      <Flex
        sx={{
          variant: 'flex.column',
          width: '100%',
          maxWidth: ['600px', '450px'],
          alignItems: flexAlign,
          gap: '11',
        }}
      >
        <Heading
          sx={{
            ...theme.heading,
            textAlign,
          }}
        >
          {content?.heading?.replace(
            /\[customer_name\]/gi,
            customer?.displayName
          )}
        </Heading>

        {content?.body && (
          <Markdown
            text={content.body}
            sx={{
              ...(content?.heading
                ? theme.bodyWithHeading
                : theme.bodyWithoutHeading),
            }}
            textSx={{
              textAlign,
            }}
          />
        )}
        {content?.list && (
          <ol
            className="list-none font-medium flex flex-col gap-4 md:gap-6"
            sx={{
              ...(content?.listStyle === 'Number'
                ? theme.listNumber
                : theme.listCirlce),
            }}
          >
            {(content?.list || []).map((item, index) => (
              <li
                key={`list-${index}`}
                sx={{
                  fontSize: [4, 6],
                }}
              >
                {item?.heading}
                <p
                  className="mt-1 md:mt-0"
                  sx={{
                    fontSize: ['13px', 4],
                  }}
                >
                  {item?.text}
                </p>
              </li>
            ))}
          </ol>
        )}
        {content?.cta?.text && (
          <div className="flex gap-5 md:gap-6">
            {content?.cta?.text &&
              (content?.ctaStyle === 'lionRefer' ? (
                <Link
                  variant={`buttons.${content?.ctaStyle || 'secondary'}`}
                  href={content?.cta?.url}
                  newTab={content?.cta?.newTab}
                  data-lion-refer
                >
                  {content?.cta?.text}
                </Link>
              ) : (
                <Link
                  variant={`buttons.${content?.ctaStyle || 'secondary'}`}
                  href={content?.cta?.url}
                  newTab={content?.cta?.newTab}
                >
                  {content?.cta?.text}
                </Link>
              ))}
          </div>
        )}
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
