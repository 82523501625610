import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      height: [
        'var(--viewport-height)',
        `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height, 0px))`,
      ],
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      bg: 'background',
      zIndex: 2,
      pointerEvents: 'auto',
      transition: 'transform .3s ease-in-out',
      borderStyle: 'solid',
      borderColor: 'gray',
      borderWidth: '0 1px 0 0',
    };

    this.scroll = {
      top: 0,
      height: '100%',
      mt: 0,
      pointerEvents: 'auto',
      bg: 'background',
      overflowY: 'scroll',
      webkitOverflowScrolling: 'touch',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    };

    this.header = {
      variant: 'flex.row.center.center',
      height: '52px',
      borderBottom: (t) => `1px solid ${t.colors.gray}`,
      position: 'sticky',
      top: 0,
      bg: 'background',
      zIndex: 1,

      backButton: {
        position: 'absolute',
        top: '50%',
        left: 8,
        transform: 'translateY(-50%)',
      },

      icon: {
        width: '24px',
        height: '24px',
        color: 'dark',
      },

      heading: {
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: 24,
        textAlign: 'center',
        letterSpacing: '-0.005em',
      },
    };

    this.secondaryMenuLinkWrapper = {
      px: 8,
      mt: 8,
    };

    this.secondaryMenuLink = {
      fontSize: 3,
      display: 'inline',
      textAlign: 'left',
      position: 'relative',
    };

    this.secondaryMenuItem = {
      fontSize: 3,
      fontWeight: 500,
      p: 8,
    };

    this.footer = {
      position: 'relative',

      link: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        variant: 'text.buttonText',
        fontSize: 3,
        width: 'calc(100% + 1px)',
        height: '52px',
        bg: 'dark',
        textAlign: 'center',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    };
  })()
);
