import { Heading, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { SocialLinks, Svg, CountrySelector } from '@snippets';

import { themed } from './SocialAndCountrySelect.theme';

export const SocialAndCountrySelect = themed(({ theme }) => {
  const settings = useSettings();

  return (
    <Flex
      data-comp={SocialAndCountrySelect.displayName}
      variant="flex.column.start.start"
      sx={theme.wrapper}
    >
      <Svg
        viewBox="0 0 1588 375"
        src="/svgs/brumate-logo.svg#brumate-logo"
        alt="Brumate logo"
        sx={theme.logo}
      />

      <Heading as="h3" sx={theme.heading}>
        {settings?.footer?.social?.heading}
      </Heading>

      <SocialLinks sx={theme.socialLinks} />

      <CountrySelector />
    </Flex>
  );
});

SocialAndCountrySelect.displayName = 'SocialAndCountrySelect';
