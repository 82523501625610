import { useEffect } from 'react';
import _uniqBy from 'lodash.uniqby';
import store, { useRecoilState } from '@store';
import { useCustomer } from '@backpackjs/storefront';

const fetchUserWishlist =
  ({ email, cachedWishlist }) =>
    async () => {
      const endpoint = '/api/swym';
      try {
        const response = await fetch(endpoint, {
          method: 'post',
          body: JSON.stringify({
            action: 'getLists',
            email,
          }),
        });

        if (response.ok) {
          const data = await response.json();

          let wishlist = data?.data?.find((list) => {
            return list.lname === 'wishlist';
          });

          if (!wishlist) {
            const createResponse = await fetch(endpoint, {
              method: 'post',
              body: JSON.stringify({
                action: 'createList',
                email,
              }),
            });

            const createData = await createResponse.json();
            wishlist = createData.data;
          }

          const { lid } = wishlist;

          const remoteWishlist = wishlist.listcontents
            ? wishlist.listcontents.reduce(
              (itemsObj, item) => {
                itemsObj.items.push({
                  empi: item.empi,
                  epi: item.epi,
                  du: item.du,
                });
                return itemsObj;
              },
              { items: [] }
            )
            : { items: [] };

          // merge local storage plus remote items
          const mergedWishlistItems = cachedWishlist?.items
            ? _uniqBy([...cachedWishlist.items, ...remoteWishlist.items], 'epi')
            : remoteWishlist.items;

          return { items: mergedWishlistItems, lid };
        }
        return { items: cachedWishlist?.items || [] };
      } catch (error) {
        console.error('fetchUserWishlist:', error.message);
        return null;
      }
    };

export const useInitWishlist = () => {
  const [wishlistInit, setWishlistInit] = useRecoilState(store.wishlistInit);
  const [wishlist, setWishlist] = useRecoilState(store.wishlist);
  const [wishlistEmail, setWishlistEmail] = useRecoilState(store.wishlistEmail);
  const [wishlistId, setWishlistId] = useRecoilState(store.wishlistId);
  const customer = useCustomer();

  useEffect(() => {
    if (wishlistInit) return;

    setWishlistInit(true);

    let getWishlistedProducts;

    const _wishlistEmail = customer?.email || wishlistEmail?.email || null;

    const anonymousWishlist = (list) => () =>
      new Promise((resolve) => {
        resolve(list);
      });

    if (_wishlistEmail) {
      getWishlistedProducts = fetchUserWishlist({
        email: _wishlistEmail,
        cachedWishlist: wishlist,
      });
    } else if (wishlist) {
      getWishlistedProducts = anonymousWishlist(wishlist);
    } else {
      getWishlistedProducts = anonymousWishlist({ items: [] });
    }

    (async () => {
      const data = await getWishlistedProducts();

      if (Array.isArray(data?.items)) {
        if (setWishlist) {
          setWishlistEmail(_wishlistEmail);
          setWishlist(data.items);
          setWishlistId(data.lid);
        }
      } else {
        console.warn('Invalid getWishlist data', data);
      }
    })();
  }, [customer]);
};
