import { useMemo, useEffect } from 'react';
import { useCart } from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';

export const useCartDiscountPrices = () => {
  const cart = useCart();

  const setDiscountAmount = useSetRecoilState(store.cartDiscountAmount);

  // discountAmount used for cart level discount such as discount
  // code that applies to the whole order
  const discountAmount = useMemo(() => {
    if (!cart?.discountAllocations?.length) return 0;
    const _discountAmount = cart.discountAllocations.reduce(
      (carry, discount) => {
        if (discount?.discountedAmount?.amount) {
          return carry + Number(discount.discountedAmount.amount);
        }
        return carry;
      },
      0
    );
    return Number(_discountAmount || 0);
  }, [cart?.updatedAt]);

  useEffect(() => {
    setDiscountAmount(discountAmount);
  }, [discountAmount]);
};

useCartDiscountPrices.displayName = 'useCartDiscountPrices';
