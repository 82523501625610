import { Box } from 'theme-ui';
import { useCartCount } from '@backpackjs/storefront';

import { Totals, Upsell } from '@snippets/Cart';
import { SearchFooter } from '@snippets/Search';
import { useSidebar } from '@hooks';

import { themed } from './Footer.theme';

export const Footer = themed(({ theme, inputRef }) => {
  const cartCount = useCartCount();
  const [{ isCart, isSearch }] = useSidebar();

  return (
    <Box data-comp={Footer.displayName} ref={inputRef}>
      {isCart ? (
        <>
          {!!cartCount && <Totals inSidebar />}
          <Upsell inSidebar />
        </>
      ) : null}
      {isSearch ? <SearchFooter /> : null}
    </Box>
  );
});

Footer.displayName = 'CartSearchSidebarFooter';
