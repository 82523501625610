import { useState, useEffect } from 'react';
import { requestOkendo } from './requestOkendo';

export const useOkendo = ({ orderBy, count, inView }) => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    if (!inView) return;
    const paramsAndData = {
      action: 'getReviews',
      params: {
        limit: JSON.stringify(count),
        orderBy,
      },
    };

    requestOkendo(paramsAndData).then((res) => {
      return setReviews([...res.data.reviews] || []);
    });
  }, [count, orderBy, inView]);

  return {
    requestOkendo,
    reviews,
  };
};
