import { useCallback } from 'react';
import store, { useRecoilState } from '@store';

export const useFiltersSidebar = () => {
  const [filtersSidebar, setFiltersSidebar] = useRecoilState(
    store.filtersSidebar
  );
  const [overlay, setOverlay] = useRecoilState(store.overlay);

  const toggleFiltersSidebar = useCallback(
    (content) => {
      setFiltersSidebar(filtersSidebar ? null : content || null);
      setOverlay(filtersSidebar ? false : true);
    },
    [filtersSidebar]
  );

  const closeFiltersSidebar = useCallback(
    () => {
      setFiltersSidebar(null);
    },
    [filtersSidebar]
  );

  return [
    // state
    {
      filtersSidebar,
      isSize: filtersSidebar?.isSize || false,
      isFiltersAndSort: filtersSidebar?.isFiltersAndSort || false,
      isSearchPage: filtersSidebar?.isSearchPage || false,
    },
    // actions
    {
      toggleFiltersSidebar,
      closeFiltersSidebar,
    },
  ];
};
