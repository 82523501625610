import { useRef } from 'react';
import { Box, Flex, Paragraph, Button, Input } from 'theme-ui';

import { Svg } from '@snippets';
import { themed } from './PasswordProtectedModal.theme';

export const PasswordProtectedForm = themed(
  ({
    password,
    setShowErrorMessage,
    showErrorMessage,
    submitPassword,
    theme,
  }) => {
    const { errorMessage, errorMessageColor, passwordColor } = { ...password };
    const passwordInputRef = useRef();
    return (
      <Flex
        variant="flex.column.center"
        sx={{
          mt: 11,
        }}
      >
        <Box
          as="form"
          sx={{
            position: 'relative',
          }}
          onSubmit={(e) => {
            e.preventDefault();
            submitPassword(passwordInputRef?.current?.value);
          }}
        >
          <Button variant="plain" sx={theme.passwordSubmitBtn}>
            <Svg
              viewBox="0 0 24 24"
              src="/svgs/arrow-right.svg#arrow-right"
              sx={theme.passwordInputIcon(
                showErrorMessage ? errorMessageColor : passwordColor
              )}
            />
          </Button>
          <Input
            placeholder="Enter Password"
            type="text"
            required
            ref={passwordInputRef}
            onChange={() => setShowErrorMessage(false)}
            sx={{
              ...theme.passwordInput(passwordColor),
              ...(showErrorMessage
                ? theme.animationError(errorMessageColor)
                : ''),
            }}
          />
        </Box>
        {showErrorMessage && (
          <Paragraph
            sx={{
              mt: 10,
              color: errorMessageColor,
            }}
          >
            {errorMessage}
          </Paragraph>
        )}
      </Flex>
    );
  }
);

PasswordProtectedForm.displayName = 'PasswordProtectedForm';
