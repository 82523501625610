import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      mt: 6,
      px: [8, 0],
    };

    this.filtersRow = {
      flexWrap: 'wrap',
      gap: 4,
    };

    this.filter = {
      height: '32px',
      position: 'relative',
      borderRadius: '16px',
      py: 2,
      pr: 6,
      pl: 4,
      columnGap: 4,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      maxWidth: '200px',
      backgroundColor: (t) => `${t.colors.gray}`,
      border: (t) => `1px solid ${t.colors.black}`,
      transition: 'all 0.15s ease-in-out',
      ':hover': {
        borderColor: 'text',
        '[data-comp="Close"]': { opacity: 1 },
      },
    };

    this.close = {
      transition: 'all 0.15s ease-in-out',
      color: 'text',
      opacity: 1,
      width: '16px',
      mr: 1,
    };

    this.filterValue = {
      textAlign: 'left',
      fontSize: 3,
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };

    this.clearAll = {
      mt: 10,
    };
  })()
);
