import { useState, useEffect } from 'react';
import { Flex, Box } from 'theme-ui';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AccessibilityBar } from '../AccessibilityBar';
import { Navigation } from './Navigation';
import { PromoBarMessage } from './PromoBarMessage';
import { usePromobar } from './usePromobar';
import { useMenuSidebar } from '../MenuSidebar';
import { themed } from './PromoBar.theme';

SwiperCore.use([EffectFade]);
SwiperCore.use([Autoplay]);

export const PromoBar = themed(({ theme }) => {
  const [
    {
      promoBarRef,
      swiper,
      swiperRef,
      closed,
      currentColor,
      bgColor,
      isDisabled,
      singleMessage,
      promoBar,
    },
    { updateActiveIndex, setSwiper },
  ] = usePromobar();
  const [{ menuSidebar }] = useMenuSidebar();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
    return () => {
      setLoaded(false);
    };
  }, []);

  return (
    <Box
      data-comp={PromoBar.displayName}
      ref={promoBarRef}
      sx={{
        ...theme.wrapper,
        minHeight: [menuSidebar ? 0 : '20px', '20px'],
        maxHeight: [menuSidebar ? 0 : '102px', '102px'],
        overflow: 'hidden',
      }}
    >
      <AccessibilityBar />

      {!isDisabled && loaded && (
        <Flex
          sx={{
            ...theme.promobarWrapper,
            color: currentColor,
            bg: bgColor,
          }}
        >
          {/* Single message (no swiper), multiple swiper */}
          <Flex
            sx={{
              ...theme.swiperWrapper,
              visibility: [menuSidebar ? 'hidden' : 'visible', 'visible'],
            }}
          >
            {singleMessage ? (
              <PromoBarMessage {...promoBar.messages[0]} isActive={!closed} />
            ) : (
              <Swiper
                data-comp="PromoBarMessagesSlider"
                init
                autoplay={{
                  enabled: true,
                  delay: promoBar.duration * 1000,
                  disableOnInteraction: false,
                }}
                ref={swiperRef}
                watchSlidesProgress
                fadeEffect={{ crossFade: true }}
                speed={promoBar.speed * 1000}
                effect="fade"
                onSwiper={setSwiper}
                onSlideChange={updateActiveIndex}
                loop
                sx={theme.swiper}
              >
                {promoBar.messages.map((message, index) => (
                  <SwiperSlide key={index + message?.copy?.slice(0, 10)}>
                    {({ isActive }) => {
                      return (
                        <PromoBarMessage
                          {...message}
                          isActive={!closed && isActive}
                          index={index}
                        />
                      );
                    }}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}

            {/* Navigation arrows */}
            {!singleMessage && <Navigation swiper={swiper} />}
          </Flex>
        </Flex>
      )}
    </Box>
  );
});

PromoBar.displayName = 'PromoBar';
