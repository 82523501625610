export const fontWeights = {
  body: 400,
  regular: 400,
  bold: 700,
  heavy: 800,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
};
