import { SearchPage } from '@snippets/Search';
import { withInView } from '@utils';

import { Schema } from './SearchResults.schema';

export const SearchResults = withInView(
  ({ cms }) => {
    return <SearchPage cms={cms} />;
  },
  { triggerOnce: true }
);

SearchResults.displayName = 'SearchResults';
SearchResults.Schema = Schema;
