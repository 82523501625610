import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.navList = {
      rowGap: 8,
      width: '225px',
    };

    this.linkHasMenu = {
      display: 'inline',
      fontSize: 4,
      fontWeight: 500,
    };

    this.link = {
      fontSize: 4,
      fontWeight: 400,
      position: 'relative',
      display: 'inline',
      pb: 1,
      backgroundImage: 'linear-gradient(currentColor, currentColor)',
      backgroundPosition: '0% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 1px',
      transition: 'background-size 0.2s ease-in-out',
      ':hover, :focus': {
        backgroundSize: '100% 1px',
      },
    };

    this.arrowIcon = (isActive) => ({
      position: 'absolute',
      right: '35px',
      top: 'calc(50% -  8px)',
      width: '16px',
      height: '16px',
      opacity: isActive ? '1' : '0.25',
    });
  })()
);
