import { useMemo } from 'react';

export const useSliderParams = ({ loop } = { loop: false, speed: 1500 }) => {
  const params = useMemo(
    () => ({
      slidesPerView: 1,
      slidesPerGroup: 1,
      centeredSlides: true,
      centeredSlidesBounds: true,
      loop,
      speed: 400,
      breakpoints: {
        768: {
          slidesPerView: 2,
          slidesPerGroup: 1,
          speed: 400,
          centeredSlides: false,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 1,
          speed: 400,
          centeredSlides: false,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
        },
      },
    }),
    [loop]
  );

  return params;
};
