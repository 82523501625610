import { Box } from 'theme-ui';

import { Markdown } from '@snippets';

import { themed } from './SuperCollection.theme';

export const SuperCollectionSeoFooter = themed(({ body, theme }) => {
  return body ? (
    <Box
      data-comp={SuperCollectionSeoFooter.displayName}
      sx={{ gridArea: 'footer' }}
    >
      <Markdown text={body} textSx={theme.seoText} />
    </Box>
  ) : null;
});

SuperCollectionSeoFooter.displayName = 'SuperCollectionSeoFooter';
