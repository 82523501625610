import { store } from '@backpackjs/storefront';
import effects from './effects';

const sellingPlans = store.recoil.atomFamily({
  key: 'sellingPlan/sellingPlans',
  default: null,
});

const selectedPlan = store.recoil.atomFamily({
  key: 'sellingPlan/selectedPlan',
  default: null,
  effects_UNSTABLE: [
    effects.logStateChange('selectedPlan'),
    // effects.onSetUpdateVariantUrlParam(), // TODO: add &selling_plan=
    effects.onChange({
      key: 'selectedPlan',
      topic: 'SELLING_PLAN_UPDATE',
      actions: ['publishGtmEvent', 'publishBusEvent'],
    }),
  ],
});

export default {
  sellingPlans,
  selectedPlan,
};
