import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: 8,
    };

    this.imageLink = {
      ...this.image,
      ':hover': {
        img: {
          transform: 'scale(1.03)',
        },
      },
      img: {
        transform: 'scale(1.01)',
        transition: 'transform 0.3s ease-in-out',
      },
    };

    this.content = {
      flexDirection: 'column',
    };

    this.heading = {
      mb: 4,
      variant: 'text.h5',
    };

    this.body = {
      mt: 0,
      variant: 'text.base',
    };

    this.button = {
      mt: 4,
    };
  })()
);
