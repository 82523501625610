import { isBrowser } from '@utils';
import { useState, useEffect } from 'react';

export const useLocalStorage = (key, defaultValue = null) => {
  const [value, setValue] = useState(() => {
    if (!isBrowser) return defaultValue;
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
