import { useState, useEffect, useCallback } from 'react';

import { NavigationButton } from './NavigationButton';
import { themed } from './Slider.theme';

export const Navigation = themed(({ theme, loop, swiper }) => {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const setStartAndEndOnSwiperChange = useCallback(() => {
    if (!swiper) return;

    if (loop) {
      setIsStart(false);
      setIsEnd(false);
    }

    if (!loop) {
      setIsStart(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
      swiper.on('slideChange', () => {
        setIsStart(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      });
    }
  }, [swiper, loop]);

  useEffect(() => {
    setStartAndEndOnSwiperChange();
  }, [swiper]);

  return (
    <>
      <NavigationButton onClick={() => swiper.slidePrev()} inactive={isStart} />

      <NavigationButton
        onClick={() => swiper.slideNext()}
        src="/svgs/arrow-right.svg#arrow-right"
        alt="Next Review"
        inactive={isEnd}
        isNext
      />
    </>
  );
});

Navigation.displayName = 'Navigation';
