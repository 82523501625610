import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.swiper = {
      '.swiper-container': {
        cursor: ['grab', 'auto'],
        pl: ['16px', 'unset'],
      },
      '.swiper-container, .swiper-wrapper': {
        height: 'auto !important',
        width: '100%',
      },
      '.swiper-slide': {
        height: 'auto !important',
        width: '100%',
        ':before': {
          content: '""',
          pb: `${(1 / 0.75) * 100}%`,
          float: 'left',
        },
      },
    };
  })()
);
