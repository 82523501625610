import { Flex, Paragraph } from 'theme-ui';

import { Link } from '@snippets';

import { Schema } from './FunctionalSchemaDemo.schema';

// Displays a simple call to action linking back to a collection this product belongs to
export function FunctionalSchemaDemo({ cms }) {
  return cms?.backToCollection ? (
    <Flex data-comp={FunctionalSchemaDemo.displayName}>
      <Paragraph sx={{ mr: 3 }}>Go back to</Paragraph>
      <Link href={`/collections/${cms.backToCollection}`}>
        {`${cms?.backToCollection} collection`}
      </Link>
    </Flex>
  ) : null;
}

FunctionalSchemaDemo.displayName = 'FunctionalSchemaDemo';
FunctionalSchemaDemo.Schema = Schema;
