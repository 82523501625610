import { Grid } from 'theme-ui';
import PropTypes from 'prop-types';

import { themed } from './Body.theme';

export const Body = themed(
  ({ theme, index, isOpen, inputRef, children, ...props }) => {
    return (
      <Grid
        data-comp={Body.displayName}
        as="ul"
        {...props}
        sx={{
          ...theme.body,
          ...props.sx,
        }}
      >
        {children({ isOpen })}
      </Grid>
    );
  }
);

Body.displayName = 'Body';

Body.propTypes = {
  children: PropTypes.func,
};
