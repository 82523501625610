import {
  Container,
  Flex,
  Grid,
  Button,
  Spinner,
  Paragraph,
  Box,
} from 'theme-ui';
import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { FormField } from './FormField';
import { useForm } from './useForm';
import { themed } from './Form.theme';
import { Schema } from './Form.schema';

export const Form = withInView(
  themed(({ theme, cms }) => {
    const { formId, section } = cms;
    const captchaRef = useRef(null);

    const [
      { formData, ref, isSubmitting, errors, message, formResponse },
      { submitForm, onInputChange },
    ] = useForm({ formId });

    const [captchaLoaded, setCaptchaLoaded] = useState(false);

    const details = formData?.form_details;
    const submitText = details?.form_submit;
    const captchaAlign = details?.captcha_align;
    const hasCaptcha = details?.captcha_enable === 'yes';
    const renderCaptcha = typeof window !== 'undefined' ? window?.grecaptcha?.render : null;
    const captchaReady = typeof renderCaptcha === 'function';

    useEffect(() => {
      try {
        if (!captchaReady || !hasCaptcha) return;
        renderCaptcha('form-captcha-widget', {
          sitekey: '6LfvrGshAAAAAB3e0obWMRxvWnpoyX7NvtXDiqVS',
        });
      } catch (error) {
        console.error(error);
      }
    }, [captchaReady, hasCaptcha]);

    useEffect(() => {
      if (!captchaRef.current || !hasCaptcha) return;
      const observer = new MutationObserver(() => {
        setCaptchaLoaded(true);
        observer.disconnect();
      });
      observer.observe(captchaRef.current, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }, [hasCaptcha]);

    return (
      <Section section={section}>
        <Head>
          <script src="https://www.google.com/recaptcha/api.js" async defer />
        </Head>

        <Container data-comp={Form.displayName} sx={{ minHeight: '400px' }}>
          <Grid
            as="form"
            ref={ref}
            sx={{
              width: '100%',
              gridTemplateColumns: 'repeat(2, 1fr)',
              color: 'text',
              gap: 5,
            }}
            action={`https://formbuilder.hulkapps.com/forms/create_response/${formId}`}
            method="post"
            enctype="multipart/form-data"
            onSubmit={(event) => submitForm({ event, captchaLoaded })}
          >
            {formData?.form_details?.formElements?.map((element) => (
              <FormField
                element={element}
                onInputChange={onInputChange}
                formResponse={formResponse}
              />
            ))}

            <Flex
              sx={{ flexDirection: 'column', gap: 8, gridColumn: '1 / span 2' }}
            >
              {hasCaptcha && (
                <Flex
                  ref={captchaRef}
                  sx={{
                    my: 6,
                    width: '100%',
                    justifyContent:
                      // eslint-disable-next-line no-nested-ternary
                      captchaAlign === 'right'
                        ? 'flex-end'
                        : captchaAlign === 'center'
                          ? 'center'
                          : 'flex-start',
                  }}
                >
                  <div id="form-captcha-widget" />
                </Flex>
              )}

              <Button sx={{ width: 'auto', maxWidth: '200px', mt: 10 }}>
                {isSubmitting ? (
                  <Spinner
                    aria-label="Loading"
                    sx={{
                      color: 'currentColor',
                      height: '60%',
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                ) : (
                  submitText
                )}
              </Button>

              <Flex sx={{ flexDirection: 'column', gap: 2 }}>
                <Box
                  dangerouslySetInnerHTML={{ __html: message }}
                  sx={{ '*': { color: 'text' } }}
                />

                {errors?.map((error) => (
                  <Paragraph key={error} sx={{ color: 'red' }}>
                    {error}
                  </Paragraph>
                ))}
              </Flex>
            </Flex>
          </Grid>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Form.displayName = 'Form';
Form.Schema = Schema;
