import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      width: '100%',
      py: '1.75rem',
    };
    this.heading = {
      my: 5,
    };
    this.text = {
      variant: 'text.base',
      textAlign: 'center',
      width: '100%',
      letterSpacing: 3,
    };
    this.icon = {
      maxWidth: 100,
      margin: 'auto',
      px: 5,
    };
  })()
);
