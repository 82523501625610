import { Container, Grid } from 'theme-ui';

import { useVariables } from '@tapcart/webbridge-react';
import { themed } from './Footer.theme';

import { DesktopMenu } from './Desktop';
import { MobileMenu } from './Mobile';
import { Legal } from './Legal';
import { EmailSignup } from './EmailSignup';
import { SocialAndCountrySelect } from './SocialAndCountrySelect';
import { Benefits } from './Benefits';

export const Footer = themed(({ theme }) => {
  const { isInitialized } = useVariables();
  if (!!isInitialized) return null;
  return (
    <Container>
      <Benefits />

      <Container data-comp={Footer.displayName} as="footer" sx={theme.wrapper}>
        <Container sx={theme.content}>
          <Grid sx={theme.grid}>
            <SocialAndCountrySelect />

            <DesktopMenu />

            <MobileMenu />

            <EmailSignup />
          </Grid>

          <Legal />
        </Container>
      </Container>
    </Container>
  );
});

Footer.displayName = 'Footer';
