import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      variant: 'layout.content',
    };

    this.holder = {
      pt: 0,
    };

    this.spinner = {
      width: '30px',
      color: 'darkGray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };

    this.widget = {
      position: 'relative',
      '.okeReviews .okeReviews-reviewsWidget-header-controls-writeReview': {
        display: 'none',
      },
      '.okeReviews .okeReviews-reviewsWidget.is-okeReviews-empty': {
        display: 'block',
      },
      '#oke-starSnippet': {
        pt: '5px',
      },
      '.okeReviews-reviewsWidget-poweredBy': {
        display: 'none',
      },
      '.okeReviews.okeReviews--theme .okeReviews-badge--rating': {
        backgroundColor: 'dark',
      },
      '.okeReviews okeReviews--theme': {
        display: 'flex !important',
        justifyContent: 'center',
      },
      '.okeReviews-button': {
        display: 'inline-block',
        mx: [90, 'initial'],
        variant: 'buttons.primary',
      },
      '.okeReviews.okeReviews--theme .okeReviews-badge--recommended': {
        py: 2,
        px: 4,
        backgroundColor: '#4ad2ae',
        fontWeight: 400,
      },

      '.okeReviews-barGraph': {
        background: 'dark',
      },
      '.okeReviews .okeReviews-barGraph-layer--foreground': {
        background: '#222',
      },
      '.okeReviews-review-reviewer-profile-name': {
        fontSize: 6,
      },
      '.okeReviews .okeReviews-flag': {
        display: 'none',
      },
      '.okeReviews.okeReviews--theme .okeReviews-review-reviewer-profile-status--verified':
        {
          color: 'mediumDarkGray',
        },
      '.okeReviews-review-reviewer-profile': {
        borderBottom: 'none',
      },
      '.okeReviews .okeReviews-reviewsAggregate-primary, .okeReviews .okeReviews-reviewsAggregate-side':
        {
          border: '1px solid',
          borderColor: 'mediumGray',
        },
      '.okeReviews .okeReviews-review-primary, .okeReviews .okeReviews-review-side':
        {
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none !important',
          borderColor: 'mediumGray',
        },
      '.okeReviews.okeReviews--theme, *': {
        fontFamily:
          '"MrEaves", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
      },
      '.okeReviews-review-main-heading': {
        variant: 'text.h4',
      },
      '.okeReviews.okeReviews--theme .okeReviews-review-recommendation--yes::before':
        {
          color: '#4ad2ae',
        },
      '.okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-large .okeReviews-review-primary, .okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-large .okeReviews-review-side, .okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-large .okeReviews-reviewsAggregate-primary, .okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-large .okeReviews-reviewsAggregate-side, .okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-medium .okeReviews-review-primary, .okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-medium .okeReviews-review-side, .okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-medium .okeReviews-reviewsAggregate-primary, .okeReviews .okeReviews-reviewsWidget.is-okeReviews-reviewsWidget-medium .okeReviews-reviewsAggregate-side':
        {
          borderLeft: 'none',
          borderRight: 'none',
        },
      '.okeReviews-reviews-controls': {
        display: 'none',
      },
      '.okeReviews.okeReviews--theme .okeReviews-starRating-indicator-layer--foreground':
        {
          backgroundImage: 'url(/svgs/okendo-stars.svg)',
        },
    };
  })()
);
