import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      mt: 10,
      mb: 17,
      width: '100%',
    };

    this.heading = {
      mb: 6,
    };

    this.items = {
      variant: 'flex.column.start',
      display: ['none', null, 'flex'],
      gap: 4,
    };

    this.carousel = {
      display: [null, null, 'none'],
      border: '1px solid',
      borderColor: 'gray',
      borderLeft: 'none',
      borderRight: 'none',
      position: 'relative',
      overflow: 'visible',
      '.swiper-container': {
        overflow: 'visible',
      },
      '.swiper-wrapper': {
        minHeight: '175px',
      },
      '.swiper-slide': {
        alignSelf: 'center',
      },
    };

    this.expand = {
      variant: 'buttons.plain',
      fontFamily: 'body',
      fontSize: 4,
      fontWeight: 400,
      height: 'auto',
      lineHeight: 22,
      mx: 'auto',
      textDecoration: 'underline',
      textTransform: 'none',
      ':hover': {
        background: 'transparent',
      },
    };
  })()
);
