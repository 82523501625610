export const icons = {
  options: [
    { label: 'none', value: 'none' },
    { label: 'exclusive', value: '/svgs/noprecache/exclusive.svg#exclusive' },
    { label: 'gift', value: '/svgs/noprecache/gift.svg#gift' },
    { label: 'next', value: '/svgs/noprecache/next.svg#next' },
    { label: 'discount', value: '/svgs/noprecache/discount.svg#discount' },
  ],
  value: {
    none: 'none',
    exclusive: '/svgs/noprecache/exclusive.svg#exclusive',
    gift: '/svgs/noprecache/gift.svg#gift',
    next: '/svgs/noprecache/next.svg#next',
    discount: '/svgs/noprecache/discount.svg#discount',
  },
};
