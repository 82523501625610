import { section, color } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Aftership',
    key: 'aftership',
    fields: [
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'full-bleed',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'full-bleed',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
};
