import { forwardRef } from 'react';

import { Svg } from '@snippets';

export const OkendoStarsIcon = forwardRef(
  (
    {
      averageScore,
      emptyColor,
      fullColor,
      index,
      onClickedStar,
      totalReviews,
      width,
      ...props
    },
    ref
  ) => {
    if (typeof averageScore === 'undefined') return null;

    const scoreMinusIndex = averageScore - (index + 1);
    const isEmpty = scoreMinusIndex < -0.75;
    const isHalf = !isEmpty && scoreMinusIndex <= -0.25;
    const starIndex = index + 1;

    const starSvgSrc =
      totalReviews === 0
        ? '/svgs/okendo/star-empty.svg#star-empty'
        : isEmpty
        ? '/svgs/okendo/star-empty.svg#star-empty'
        : isHalf
        ? '/svgs/okendo/star-half-empty.svg#star-half-empty'
        : '/svgs/okendo/star-full.svg#star-full'; // isFull

    return (
      <Svg
        alt={`${starIndex} ${starIndex === 1 ? 'star' : 'stars'}`}
        viewBox="0 0 24 24"
        key={index}
        width={width}
        src={starSvgSrc}
        onClick={() => onClickedStar(index + 1)}
        sx={{
          color: isEmpty ? emptyColor : fullColor,
        }}
      />
    );
  }
);

OkendoStarsIcon.displayName = 'OkendoStarsIcon';
