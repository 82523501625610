import { Accordions } from './Accordions';
import { Aftership } from './Aftership';
import { AccessibilityStatement } from './AccessibilityStatement';
import { BlogGrid } from './BlogGrid';
import { CollectionCategories } from './CollectionCategories';
import { CollectionGrid } from './CollectionGrid';
import { CollectionHero } from './CollectionHero';
import { FeaturedArticle } from './FeaturedArticle';
import { FeaturedThreeTile } from './FeaturedThreeTile';
import { FeaturedTwoTile } from './FeaturedTwoTile';
import { FeaturedIcons } from './FeaturedIcons';
import { Form } from './Form';
import { FormBuilder } from './FormBuilder';
import { FourSixty } from './FourSixty';
import { FullPageReviews } from './FullPageReviews';
import { FunctionalSchemaDemo } from './FunctionalSchemaDemo';
import { HalfMedia } from './HalfMedia';
import { Hero } from './Hero';
import { HeroSlider } from './HeroSlider';
import { Html } from './Html';
import { Image } from './Image';
import { LinkFarm } from './LinkFarm';
import { Locator } from './Locator';
import { MalomoTracking } from './MalomoTracking';
import { Markdown } from './Markdown';
import { PressSlider } from './PressSlider';
import { ProductRecs } from './ProductRecs';
import { ProductsSlider } from './ProductsSlider';
import { ProductUpsells } from './ProductUpsells';
import { QuickSelectCollections } from './QuickSelectCollections';
import { ReviewSlider } from './ReviewSlider';
import { SearchResults } from './SearchResults';
import { SuperCollection } from './SuperCollection';
import { TabbedThreeTile } from './TabbedThreeTile';
import { ThreeTile } from './ThreeTile';
import { TwoTile } from './TwoTile';
import { TwoThirdsMedia } from './TwoThirdsMedia';
import { Wishlist } from './Wishlist';
import { KlaviyoForm } from './KlaviyoForm';
import { LoyaltyLionWelcome } from './LoyaltyLionWelcome';
import { LoyaltyLionHowItWorks } from './LoyaltyLionHowItWorks';
import { LoyaltyLionPoints } from './LoyaltyLionPoints';
import { EmbedContent } from './EmbedContent';
import { LoyaltylionRewardsList } from './LoyaltylionRewardsList';
import { LoyaltylionStaticRewards } from './LoyaltylionStaticRewards';
import { VideoWise } from './VideoWise';
import { ShopByCategory } from './ShopByCategory';

export default [
  AccessibilityStatement,
  Accordions,
  Aftership,
  BlogGrid,
  CollectionCategories,
  CollectionGrid,
  CollectionHero,
  FeaturedArticle,
  FeaturedIcons,
  FeaturedThreeTile,
  FeaturedTwoTile,
  Form,
  FormBuilder,
  FourSixty,
  FullPageReviews,
  FunctionalSchemaDemo,
  HalfMedia,
  Hero,
  HeroSlider,
  Html,
  Image,
  KlaviyoForm,
  LinkFarm,
  Locator,
  MalomoTracking,
  Markdown,
  PressSlider,
  ProductRecs,
  ProductsSlider,
  ProductUpsells,
  QuickSelectCollections,
  ReviewSlider,
  SearchResults,
  SuperCollection,
  TabbedThreeTile,
  ThreeTile,
  TwoThirdsMedia,
  TwoTile,
  Wishlist,
  LoyaltyLionWelcome,
  LoyaltyLionHowItWorks,
  LoyaltyLionPoints,
  EmbedContent,
  LoyaltylionRewardsList,
  LoyaltylionStaticRewards,
  VideoWise,
  ShopByCategory
];
