import { useEffect, useRef } from 'react';

import { Box, Embed } from 'theme-ui';

import { themed } from './Video.theme';

export const Video = themed(({ theme, video, src, poster, isActive }) => {
  const isWebm = src && /\.webm/g.test(src);
  const isMp4 = src && /\.mp4/g.test(src);

  const pointerControls = video?.controls ? 'auto' : 'none';

  const videoRef = useRef(null);

  // Pause/Play on dependant on activeIndex slide.
  useEffect(() => {
    if (!videoRef.current) return;

    if (!isActive) {
      videoRef.current.pause();
    } else if (video?.autoplay) {
      videoRef.current.play();
    }
  }, [isActive, video?.autoplay]);

  return (
    <Box data-comp={Video.displayName} sx={theme.wrapper}>
      {!isWebm && !isMp4 ? (
        <Embed key={src} src={src} />
      ) : (
        <Box
          as="video"
          key={src}
          ref={videoRef}
          playsInline
          autoPlay={video?.autoplay}
          loop={video?.loop}
          muted={video?.autoplay ? true : video?.muted}
          controls={video?.controls}
          poster={poster?.src}
          sx={{
            ...theme.video,
            pointerEvents: pointerControls,
          }}
        >
          <source src={src} type="video/mp4" />
        </Box>
      )}
    </Box>
  );
});

Video.displayName = 'Video';
