import { Container, Box } from 'theme-ui';

import { themed } from './Displays.theme';
import { Tile } from '../Tile';

export const GridDisplay = themed(({ theme, tiles, tile }) => {
  const noImageAvailable = tiles.every((_tile) => !_tile.image);
  const imageRatioStyles =
    tile?.imageRatio === 'square' ? theme.square : theme.portrait;

  return (
    <Container data-comp={GridDisplay.displayName} sx={theme.grid}>
      {tiles?.slice(0, 3).map((item, index) => {
        return (
          <Box
            key={index}
            // Adds minimum aspect ratio before ThreeileItem js loads
            sx={!noImageAvailable ? imageRatioStyles : ''}
          >
            <Tile item={item} tile={tile} />
          </Box>
        );
      })}
    </Container>
  );
});

GridDisplay.displayName = 'GridDisplay';
