import { Box, Paragraph, Flex, Text } from 'theme-ui';

import { useOkendoStars, useOkendoScrollToReviews } from '@hooks';

import { OkendoStarsIcons } from './OkendoStarsIcons';

// Fetches stars data from yotpo and then renders them
export function OkendoStars({
  legacyResourceId,
  isProductItem = false,
  withCta = true,
  withScroll = true,
  onClickedStar = () => {},
  fullColor = 'black',
  emptyColor = 'black',
  ...props
}) {
  const { stars } = useOkendoStars({
    productId: legacyResourceId,
  });
  const { averageScore, totalReviews } = stars || {};
  const { scrollToReviews } = useOkendoScrollToReviews();

  return (
    <Box
      data-comp={OkendoStars.displayName}
      key={averageScore + totalReviews}
      className={totalReviews > 0 ? '' : 'hidden'}
    >
      <Flex
        variant="flex.row.start.center"
        tabIndex="0"
        onClick={withScroll ? scrollToReviews : null}
        {...props}
        sx={{
          minHeight: '18px',
          my: 3,
          cursor: 'pointer',
          ...props.sx,
        }}
        className={totalReviews > 0 ? `${props?.className}` : `before:hidden`}
      >
        {totalReviews >= 0 && (
          <>
            <OkendoStarsIcons
              totalReviews={totalReviews}
              averageScore={averageScore}
              onClickedStar={onClickedStar}
              fullColor={fullColor}
              emptyColor={emptyColor}
            />

            {withCta ? (
              <Paragraph
                data-comp={`${OkendoStars.displayName}Total`}
                aria-label={`${totalReviews} reviews`}
                variant="link2"
                sx={{
                  pl: 4,
                  textDecoration: withScroll ? 'underline' : 'none',
                  mr: 'auto',
                  span: { variant: 'text.sm' },
                  position: 'relative',
                  top: '-3px',
                }}
              >
                {totalReviews ? (
                  <Text data-comp={`${OkendoStars.displayName}TotalText`}>
                    ({totalReviews})
                  </Text>
                ) : (
                  <Text
                    data-comp={`${OkendoStars.displayName}TotalText`}
                    sx={{ display: ['none', 'inline-block'] }}
                  >
                    (0)
                  </Text>
                )}
              </Paragraph>
            ) : null}
          </>
        )}
      </Flex>
    </Box>
  );
}

OkendoStars.displayName = 'OkendoStars';
