import { useEffect } from 'react';
import Script from 'next/script';
import {
  MDXProvider,
  useMDXComponents,
  Components as MDXComponents,
  MergeComponents as MergeMDXComponents,
} from '@mdx-js/react';
import { useThemedStylesWithMdx } from '@theme-ui/mdx';
import { loadWebbridge, WebbridgeProvider } from '@tapcart/webbridge-react';

import { Box, Container, ThemeProvider } from 'theme-ui';

import {
  useSettings,
  useStorefrontInit,
  useCartAddDiscountUrl,
} from '@backpackjs/storefront';

import { StorefrontHead, PageHead } from '@head';
import {
  DataLayerEventListeners,
  Header,
  Footer,
  Fueled,
  Overlay,
  CartSearchSidebarShell,
  Modal,
  FiltersSidebarShell,
  SeoPageSchema,
  PasswordProtectedModal,
  LoyaltyLionScript,
  Vwo,
  VideoWiseScript,
  BuyWithPrime,
} from '@snippets';

import { useCartIntelliSuggest, useCartRejectDiscount } from '@snippets/Cart';

import {
  useTrackSettings,
  useSetViewportHeightCssVar,
  useInitWishlist,
  useCartRedirect,
  useShopperId,
  useGiftWithPurchase,
  useGeolocation,
  usePasswordProtected,
  useCJCookies,
  useShopifyAnalytics,
  usePackCookie,
} from '@hooks';

import { theme } from '../theme';

const gtmContainerId =
  /* process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || */ 'GTM-P3XTC38C'; // To Do - Switch back to using variable at launch.

function GlobalThemeProvider({ children, theme, components }) {
  const componentsWithStyles = useThemedStylesWithMdx(
    useMDXComponents(components)
  );
  return (
    <ThemeProvider theme={theme}>
      <MDXProvider components={componentsWithStyles}>{children}</MDXProvider>
    </ThemeProvider>
  );
}

const webbridgeClient = loadWebbridge();

function StorefrontLayout({ ContentForLayout, ...props }) {
  const settings = useSettings();
  const { getGiftProducts } = useGiftWithPurchase();
  const { hasAccess, ...status } = usePasswordProtected();

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  useGeolocation();

  // adds --viewport-height css var to the :root
  useSetViewportHeightCssVar();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl({
    queryParams: ['promo', 'discountCode', 'discount_code', 'discount'],
    removeParams: false,
  });

  // track siteSetting changes in recoil
  useTrackSettings();

  // initialize wishlists
  useInitWishlist();

  // redirect to home page from cart page & automatically open cart with cart param
  useCartRedirect();

  // set shopper id for searchspring intellisuggest
  useShopperId();

  // Get Gift Product for Gift With Purchase
  useEffect(() => {
    getGiftProducts();
  }, [settings?.gwp?.enabled, settings?.gwp?.giftProducts]);

  // send intellisuggest event on cart change
  useCartIntelliSuggest();

  useCartRejectDiscount();

  // set cj cookies
  useCJCookies();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  // set pack session cookie
  usePackCookie();

  return (
    <WebbridgeProvider webbridgeClient={webbridgeClient}>
      <GlobalThemeProvider theme={theme}>
        {/* SEO meta */}
        <PageHead page={props.page} />

        {/* <head> links, scripts... */}
        <StorefrontHead />

        <Fueled />
        <DataLayerEventListeners {...props} />

        <VideoWiseScript {...props} />
        <SeoPageSchema {...props} />

        <Header />

        {/* main content */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight:
              'calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height))',
            alignItems: 'stretch',
          }}
        >
          <Container
            as="main"
            role="main"
            sx={{
              flexGrow: 1,
              flexShrink: 0,
            }}
          >
            {status?.success && !hasAccess ? (
              <PasswordProtectedModal />
            ) : (
              <ContentForLayout {...props} />
            )}
          </Container>

          <Box sx={{ flexShrink: 0 }}>
            <Footer />
          </Box>
        </Box>

        <Overlay />
        {/*
          This is empty sidebar shell, the content's js
          is dynamically loaded only when opened dynamic
      */}
        <CartSearchSidebarShell />
        <FiltersSidebarShell />

        <Modal />
        <LoyaltyLionScript />
        <Vwo />
        <BuyWithPrime />

        <div
          dangerouslySetInnerHTML={{
            __html: `
            <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmContainerId}"
            height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
          `,
          }}
        />
      </GlobalThemeProvider>
    </WebbridgeProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default StorefrontLayout;
