export const requestOkendo = async ({ action, params }) => {
  try {
    if (!action) throw new Error('requestOkendo error: No action provided!');

    const url = `/api/okendo`;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        action,
        params,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(url, options);
    const data = await response.json();

    if (!data) throw new Error(`requestOkendo data error: ${data.message}`);

    return data;
  } catch (error) {
    console.error(`requestOkendo error: ${error.message}`);
    throw error;
  }
};
