import { useMemo } from 'react';
import { Container, Box } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Section } from '@snippets';

import { Video } from './Video';
import { themed } from './HalfMedia.theme';
import { Content } from './Content';
import { Image } from './Image';

export const HalfMedia = themed(({ theme, passedId }) => {
  if (!passedId) return null;

  const settings = useSettings();
  const modalArray = settings?.markdownmodal?.modals.length;
  const modalId = modalArray
    ? settings?.markdownmodal?.modals.find(
        (element) => parseInt(element.id, 10) === parseInt(passedId, 10)
      )
    : null;

  const modalSettings = useMemo(() => {
    return {
      ...modalId,
    };
  }, [modalId]);

  const showMedia = modalSettings?.showMedia;
  const mediaLeft = modalSettings?.media?.locationDt === 'left'; // mobile
  const mediaTop = modalSettings?.media?.location === 'top'; // desktop

  return (
    <Section>
      <Container
        data-comp={HalfMedia.displayName}
        sx={{
          ...theme.grid,
          gridTemplateAreas: showMedia
            ? [
                mediaTop ? '"media" "content"' : '"content" "media"',
                mediaLeft ? '"media content"' : '"content media"',
              ]
            : null,
        }}
      >
        <Box
          sx={{
            ...theme.media,
            ':before': {
              content: '""',
              float: 'left',
              pb: [modalSettings?.media?.ratio || '100%', 0],
            },
          }}
        >
          {showMedia ? (
            modalSettings?.video?.enabled ? (
              <Video video={modalSettings?.video} />
            ) : (
              <Image image={modalSettings?.image} />
            )
          ) : null}
        </Box>
        <Box
          sx={{
            ...theme.content,
            justifyContent: mediaLeft ? 'flex-start' : 'flex-end',
            ':before': {
              content: '""',
              float: 'left',
              pb: [0, modalSettings?.media?.ratioDt || '100%'],
            },
          }}
        >
          <Content content={modalSettings?.content} />
        </Box>
      </Container>
    </Section>
  );
});

HalfMedia.DisplayName = 'HalfMedia';
