import { useMemo } from 'react';
import Head from 'next/head';

export function ProductHead({ product = {}, pageUrl }) {
  const { ratingAndReviewCount, ratingAndReviewValuesTotal } =
    product.reviewAggregate || {};

  const firstImage = useMemo(() => {
    return product?.media?.find((item) => {
      return item.mediaContentType === 'IMAGE';
    })?.image;
  }, [product?.id]);

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Insulated Can Coolers, Tumblers and Drinkware",
                  "item": "${process.env.SITE_URL}/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${product.title}",
                  "item": "${pageUrl}"
                }
              ]
            }`,
        }}
      />

      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${product.title}",
              "image": "${firstImage?.originalSrc}",
              "description": "${product.description}",
              "brand": {
                "@type": "Brand",
                "name": "${process.env.NEXT_PUBLIC_SITE_TITLE}"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "${(
                  ratingAndReviewValuesTotal / ratingAndReviewCount || 0
                )
                  .toFixed(2)
                  .replace('.00', '')}",
                "ratingCount": "${ratingAndReviewCount || 0}",
                "bestRating": "5",
                "worstRating": "1"
              },
              ${
                product.reviews
                  ? `"review": [
                      ${product.reviews.map(
                        ({ body, dateCreated, rating, reviewer, title }) => {
                          return `{
                              "@type": "Review",
                              "author": "${reviewer?.displayName}",
                              "datePublished": "${dateCreated?.split('T')[0]}",
                              "reviewBody": "${body}",
                              "name": "${title}",
                              "reviewRating": {
                                "@type": "Rating",
                                "bestRating": "5",
                                "ratingValue": "${rating}",
                                "worstRating": "1"
                              }
                            }`;
                        }
                      )}
                    ],`
                  : ''
              }
              "offers": {
                "@type": "Offer",
                "url": "${pageUrl}",
                "priceCurrency": "USD",
                "price": "${product.priceRange?.minVariantPrice?.amount}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition",
                "shippingDetails": {
                  "@context": "https://schema.org/",
                  "@type": "OfferShippingDetails",
                  "shippingDestination": {
                    "@type": "DefinedRegion",
                    "name": "United States",
                    "postalCode": "Any"
                  },
                  "shippingRate": {
                    "@type": "MonetaryAmount",
                    "currency": "USD",
                    "value": "5.99"
                  },
                  "shippingSettingsLink": "https://brumate.gorgias.help/en-US/how-much-does-shipping-cost-47271",
                  "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "businessDays": {
                      "@type": "QuantitativeValue",
                      "minValue": "4",
                      "maxValue": "8",
                      "unitText": "Business Days"
                    },
                    "cutoffTime": "12:00:00-05:00"
                  },
                  "isUnlimitedShipping": true,
                  "shippingPolicy": "https://brumate.gorgias.help/en-US/how-long-will-it-take-for-me-to-receive-my-order-47268"
                },
                "hasMerchantReturnPolicy": {
                  "@context": "https://schema.org/",
                  "@type": "StoreReturnPolicy",
                  "name": "BrüMate Return Policy",
                  "merchantReturnDays": "30",
                  "merchantReturnLink": "https://brumate.gorgias.help/en-US/articles/returns-exchanges-2478",
                  "refundType": "Full",
                  "returnFees": "Customer is not responsible for return shipping costs for do",
                  "inStoreReturnsOffered": false,
                  "returnPolicyCategory": "Online Store"
                }
              }
            }`,
        }}
      />
    </Head>
  );
}

ProductHead.displayName = 'ProductHead';
