import { useMemo } from 'react';
import { Flex, Paragraph, Box } from 'theme-ui';
import { useCartTotals, useSettings } from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';

import { themed } from './FreeShippingMeter.theme';

export const FreeShippingMeter = themed(({ theme, inputRef, ...props }) => {
  const cartTotals = useCartTotals();
  const cartDiscountAmount = useRecoilValue(store.cartDiscountAmount);

  const settings = useSettings();
  const { freeShipping } = { ...settings?.cart };
  const {
    spend,
    message: messageRemainder,
    messageQualified,
    enabled,
  } = { ...freeShipping };

  const meter = useMemo(() => {
    let message = '';
    let progress = 0;
    if (!spend || !cartTotals?.subtotal || !enabled)
      return { message, progress };
    const total = parseFloat(
      parseFloat(cartTotals.subtotal - cartDiscountAmount)
    );
    progress = total / spend;
    if (progress < 1) {
      const remainder = (spend - total).toFixed(2);
      const remainderPrice = remainder.endsWith('00')
        ? remainder.split('.')[0]
        : remainder;
      message = messageRemainder.replace('$', `$${remainderPrice}`);
    } else {
      message = messageQualified;
      progress = 1;
    }
    return { message, progress };
  }, [
    enabled,
    messageRemainder,
    messageQualified,
    spend,
    cartTotals?.subtotal,
    cartDiscountAmount,
  ]);

  return enabled ? (
    <Flex
      data-comp={FreeShippingMeter.displayName}
      sx={{
        variant: 'flex.column.center.end',
        bg: 'gray',
        height: '66px',
        px: 10,
        pb: '17px',
      }}
    >
      <Paragraph
        sx={{
          variant: 'text.label.2',
          lineHeight: '14px',
          textAlign: 'center',
          minHeight: '18px',
          pb: 4,
        }}
      >
        {meter.message}
      </Paragraph>

      <Box
        sx={{
          width: '100%',
          bg: 'white',
          height: '12px',
          borderRadius: '6px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: 'calc(100% - 8px)',
            height: '8px',
            borderRadius: '4px',
            bg: 'dark',
            position: 'absolute',
            top: 1,
            left: 2,
            maxWidth: `${100 * meter.progress}%`,
            transition: 'max-width 0.4s ease-in-out',
            transformOrigin: 'left',
          }}
        />
      </Box>
    </Flex>
  ) : null;
});

FreeShippingMeter.displayName = 'FreeShippingMeter';
