import { useMemo } from 'react';

export const useProductSwiperParams = (
  { isProductRecs, loopEnabled, count } = {
    isProductRecs: false,
    loopEnabled: false,
    count: true,
  }
) => {
  const productRecsParams = useMemo(
    () => ({
      centerInsufficientSlides: !loopEnabled,
      centeredSlidesBounds: !loopEnabled,
      centeredSlides: true,
      loop: count ? loopEnabled : false,
      slidesOffsetBefore: 5,
      slidesOffsetAfter: 5,
      slidesPerView: 1.6,
      slidesPerGroup: 1,
      spaceBetween: 20,
      speed: 400,
      breakpoints: {
        480: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 5,
          slidesOffsetAfter: 5,
          slidesPerGroup: 1,
          slidesPerView: 1.6,
          spaceBetween: 20,
        },
        600: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 5,
          slidesOffsetAfter: 5,
          slidesPerView: 1.6,
          spaceBetween: 20,
          slidesPerGroup: 1,
        },
        768: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          centeredSlides: true,
          spaceBetween: 20,
          slidesPerView: 3,
          slidesPerGroup: 1,
        },
        1024: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 20,
          slidesPerView: 5,
          slidesPerGroup: 1,
        },
        1280: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 20,
          slidesPerView: 4.3,
          slidesPerGroup: 1,
        },
      },
    }),
    [loopEnabled, count]
  );

  const productCarouselParams = useMemo(
    () => ({
      centerInsufficientSlides: !loopEnabled,
      centeredSlidesBounds: !loopEnabled,
      centeredSlides: true,
      loop: count ? loopEnabled : false,
      slidesOffsetBefore: 5,
      slidesOffsetAfter: 5,
      slidesPerView: 1.6,
      slidesPerGroup: 1,
      spaceBetween: 20,
      speed: 400,
      breakpoints: {
        480: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 5,
          slidesOffsetAfter: 5,
          slidesPerGroup: 1,
          slidesPerView: 1.6,
          spaceBetween: 20,
        },
        600: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 5,
          slidesOffsetAfter: 5,
          slidesPerView: 1.6,
          spaceBetween: 20,
          slidesPerGroup: 1,
        },
        768: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          centeredSlides: true,
          spaceBetween: 20,
          slidesPerView: 2.5,
          slidesPerGroup: 1,
        },
        1024: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 20,
          slidesPerView: 5,
          slidesPerGroup: 1,
        },
        1280: {
          centerInsufficientSlides: !loopEnabled,
          centeredSlidesBounds: !loopEnabled,
          centeredSlides: true,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          spaceBetween: 20,
          slidesPerView: 4.3,
          slidesPerGroup: 1,
        },
      },
    }),
    [loopEnabled, count]
  );

  return isProductRecs ? productRecsParams : productCarouselParams;
};
