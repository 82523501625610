import HeadFonts from './Fonts';
import Libs from './Libs';
import HeadPreconnects from './Preconnects';

export function StorefrontHead() {
  return (
    <>
      <Libs />
      <HeadFonts />
      <HeadPreconnects />
    </>
  );
}
