import { color } from '@settings/common';

export default {
  label: 'Footer Settings',
  name: 'footer',
  component: 'group',
  fields: [
    {
      label: 'Email marketing',
      name: 'emailSignup',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'textarea',
        },
      ],
      defaultValue: {
        heading: 'Stay In Touch',
        subtext:
          'Keep the buzz going with our newsletter to get the inside scoop on new products, discounts, drink recipes and more.',
      },
    },
    {
      label: 'Social',
      name: 'social',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
      ],
      defaultValue: {
        heading: 'Stay Connected',
      },
    },
    {
      label: 'Benefits',
      name: 'benefits',
      component: 'group',
      fields: [
        {
          label: 'Items',
          name: 'items',
          component: 'group-list',
          itemProps: {
            label: '{{item.label}}',
          },
          defaultItem: {
            icon: 'design',
            label: 'Innovative Design',
          },
          fields: [
            {
              name: 'icon',
              component: 'select',
              label: 'Icon',
              options: [
                { label: 'Innovative Design', value: 'design' },
                { label: 'Lifetime Warranty', value: 'warranty' },
                { label: 'Customer Service', value: 'service' },
                { label: 'Free Shipping', value: 'shipping' },
              ],
            },
            {
              label: 'Label',
              name: 'label',
              component: 'text',
            },
          ],
          defaultValue: [
            {
              icon: 'design',
              label: 'Innovative Design',
            },
            {
              icon: 'warranty',
              label: 'Lifetime Warranty',
            },
            {
              icon: 'service',
              label: 'Flawless Customer Service',
            },
            {
              icon: 'shipping',
              label: 'Free Shipping & Returns on U.S. Orders Over $50',
            },
          ],
        },
        {
          component: 'select',
          name: 'bgColor',
          label: 'Background color',
          options: color.options,
          defaultValue: color.value.gray,
        },
        {
          component: 'select',
          name: 'color',
          label: 'Text & icon color',
          options: color.options,
          defaultValue: color.value.text,
        },
      ],
    },
    {
      label: 'Footer menu',
      name: 'menu',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      defaultItem: {
        name: 'Shop',
        subMenuItems: [
          {
            cta: {
              text: 'Wine',
              url: '/collections/wine',
            },
          },
          {
            cta: {
              text: 'Beer',
              url: '/collections/beer',
            },
          },
          {
            cta: {
              text: 'Spirits',
              url: '/collections/spirits',
            },
          },
        ],
      },
      fields: [
        {
          label: 'Menu item name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Sub menu',
          name: 'subMenuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.cta.text}}',
          },
          defaultItem: {
            cta: {
              text: 'Wine',
              url: '/collections/wine',
            },
          },
          fields: [
            {
              label: 'Link',
              name: 'cta',
              component: 'link',
            },
          ],
        },
      ],
      defaultValue: [
        {
          name: 'Shop',
          subMenuItems: [
            {
              cta: {
                text: 'Wine',
                url: '/collections/wine',
              },
            },
            {
              cta: {
                text: 'Beer',
                url: '/collections/beer',
              },
            },
            {
              cta: {
                text: 'Spirits',
                url: '/collections/spirits',
              },
            },
          ],
        },
        {
          name: 'Help',
          subMenuItems: [
            {
              cta: {
                text: 'Mobile App',
                url: '/pages/mobile-app',
              },
            },
            {
              cta: {
                text: 'Store Locator',
                url: '/pages/store-locator',
              },
            },
            {
              cta: {
                text: 'Media Inquiries',
                url: '/pages/media-inquiries',
              },
            },
          ],
        },
        {
          name: 'Brand',
          subMenuItems: [
            {
              cta: {
                text: 'Our Story',
                url: '/pages/our-story',
              },
            },
            {
              cta: {
                text: 'Sustainability',
                url: '/pages/sustainability',
              },
            },
            {
              cta: {
                text: 'Blog',
                url: '/blogs/articles',
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Legal links',
      name: 'legalLinks',
      component: 'group-list',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      defaultItem: {
        cta: {
          text: 'Privacy Policy',
          url: '/pages/privacy-policy',
        },
      },
      fields: [
        {
          label: 'Link',
          name: 'cta',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Privacy Policy',
            url: '/pages/privacy-policy',
          },
        },
        {
          cta: {
            text: 'Terms & Conditions',
            url: '/pages/terms-conditions',
          },
        },
      ],
    },
    {
      label: 'Site copyright notice',
      name: 'copyrightNotice',
      component: 'text',
      defaultValue: 'All Rights Reserved',
    },
  ],
};
