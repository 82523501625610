import { useRef, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import sha1 from 'sha-1';
import {
  useCustomerAuth,
  useCart,
  useCartAddItem,
  useCartAddDiscount,
  useCartRemoveItems,
  useCartUpdateItem,
} from '@backpackjs/storefront';
import store, {
  useRecoilState,
  useRecoilCallback,
  useGotoRecoilSnapshot,
} from '@store';

const logoutCustomer = () => {
  if (
    typeof window.loyaltylion === 'object' &&
    typeof window.loyaltylion.logoutCustomer === 'function'
  ) {
    window.loyaltylion.logoutCustomer();
  }
};
const injectScript = () => {
  // source: https://developers.loyaltylion.com/sdk/installation#add-the-sdk-snippet-to-your-template
  const script = document.createElement('script');
  script.async = true;
  script.text = `!function(t,n){var e=n.loyaltylion||[];if(!e.isLoyaltyLion){n.loyaltylion=e,void 0===n.lion&&(n.lion=e),e.version=2,e.isLoyaltyLion=!0;var o=n.URLSearchParams,i=n.sessionStorage,r="ll_loader_revision",a=(new Date).toISOString().replace(/-/g,""),s="function"==typeof o?function(){try{var t=new o(n.location.search).get(r);return t&&i.setItem(r,t),i.getItem(r)}catch(t){return""}}():null;c("https://sdk.loyaltylion.net/static/2/"+a.slice(0,8)+"/loader"+(s?"-"+s:"")+".js");var l=!1;e.init=function(t){if(l)throw new Error("Cannot call lion.init more than once");l=!0;var n=e._token=t.token;if(!n)throw new Error("Token must be supplied to lion.init");var o=[];function i(t,n){t[n]=function(){o.push([n,Array.prototype.slice.call(arguments,0)])}}"_push configure bootstrap shutdown on removeListener authenticateCustomer".split(" ").forEach(function(t){i(e,t)}),c("https://sdk.loyaltylion.net/sdk/start/"+a.slice(0,11)+"/"+n+".js"),e._initData=t,e._buffer=o}}function c(n){var e=t.getElementsByTagName("script")[0],o=t.createElement("script");o.src=n,o.crossOrigin="",e.parentNode.insertBefore(o,e)}}(document,window);`;
  script.dataset.script = 'loyaltylion';
  const t = document.getElementsByTagName('script')[0];
  if (t) {
    t.parentNode.insertBefore(script, t);
  } else {
    document.head.append(script);
  }
  return script;
};
const initializeScript = () => {
  const initializationValues = {
    token: process.env.NEXT_PUBLIC_LOYALTYLION_TOKEN,
  };
  // source: https://developers.loyaltylion.com/sdk/javascript-api/getting-started#initialization
  if (
    typeof window.loyaltylion === 'object' &&
    typeof window.loyaltylion.init === 'function' &&
    typeof window.loyaltylion.api === 'undefined' &&
    !window.loyaltylion.initialized
  ) {
    window.loyaltylion.init(initializationValues);
    window.loyaltylion.configure({
      disableBundledFonts: true,
      shopifyFrontend: 'unknown',
    });
    window.loyaltylion.initialized = true;
  }
};

const authenticateCustomer = (customer) => {
  // source: https://developers.loyaltylion.com/sdk/javascript-api/customer-authentication#authentication-after-initialization
  if (customer?.id === null || customer?.email === null) {
    return;
  }
  const customerId = customer?.id?.split('/')?.pop();
  const customerEmail = customer.email;
  const customerName = customer?.displayName;

  const date = new Date().toISOString();
  const authToken = `${customerId}${date}${customerEmail}${
    process.env.NEXT_PUBLIC_LOYALTYLION_SECRET ||
    '436713e61268c3a324443994e97e7b50'
  }`;
  const authenticationValues = {
    customer: { id: customerId, email: customerEmail, name: customerName },
    auth: {
      date,
      token: sha1(authToken),
    },
  };
  if (
    typeof window.loyaltylion === 'object' &&
    typeof window.loyaltylion.authenticateCustomer === 'function'
  ) {
    window.loyaltylion.authenticateCustomer(authenticationValues);
  }
};

export function LoyaltyLionScript() {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const cart = useCart({ sortByAddedAt: true });
  const { cartAddItem } = useCartAddItem();
  const { cartAddDiscount } = useCartAddDiscount();
  const { cartRemoveItems } = useCartRemoveItems();
  const { cartUpdateItem } = useCartUpdateItem();
  const [loyaltylionLoaded, setLoyaltylionLoaded] = useRecoilState(
    store.loyaltylionLoaded
  );
  const { authenticated, customer, customerInit } = useCustomerAuth();
  const router = useRouter();
  const loyaltyLionStylesheet = useRef();
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(0);
  const [llAuthenticated, setLlllAuthenticated] = useState(false);
  const [loyaltylionInitialized, setLoyaltylionInitialized] = useState(false);

  const toggleCartSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.modal, null))
        .map(({ set }) => set(store.overlay, true))
        .map(({ set }) => set(store.sidebar, 'cart'));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });
  const disableRewardIds = useMemo(() => {
    return (customer?.tags || []).reduce((acc, id) => {
      if (id.indexOf('_rewardId:') === 0) {
        const idInt = id.replace('_rewardId:', '');
        return [...acc, idInt];
      }
      return acc;
    }, []);
  }, [customer?.tags]);

  useEffect(() => {
    let script;
    if (!window?.loyaltylion?.isLoyaltyLion) {
      script = injectScript();
      setCount(count + 1);
    }
    return () => {
      if (typeof script === 'object') {
        script.remove();
      }
    };
  }, []); // should only run once

  useEffect(() => {
    const interval = setInterval(() => {
      if (!window?.loyaltylion?.isLoyaltyLion) {
        setCount(count + 1);
      } else {
        setLoyaltylionLoaded(true);
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      setLoyaltylionLoaded(false);
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    if (loyaltylionLoaded) {
      const timeout = window.setTimeout(() => {
        initializeScript();
        window.loyaltylion.configure({
          disableBundledFonts: true,
          shopifyFrontend: 'unknown',
        });
        setLoyaltylionInitialized(true);
        clearTimeout(timeout);
      }, 4000);
    }
  }, [loyaltylionLoaded, router]);

  useEffect(() => {
    if (customer?.id && loyaltylionLoaded && authenticated) {
      if (!llAuthenticated) {
        authenticateCustomer(customer);
      }
      setLlllAuthenticated(true);
    } else if (loyaltylionLoaded) {
      setLlllAuthenticated(false);
      logoutCustomer();
    }
  }, [
    customer?.id,
    customerInit,
    authenticated,
    loyaltylionInitialized,
    router,
  ]);

  useEffect(() => {
    if (cart?.id) {
      localStorage.setItem('ll-cart-object', JSON.stringify(cart));
    }
  }, [cart?.lines]);

  useEffect(() => {
    const addProductToCart = async (claimedReward) => {
      const llCartString = localStorage.getItem('ll-cart-object') || {};
      const llCart = JSON.parse(llCartString);
      if (
        claimedReward?.reward?.id &&
        disableRewardIds?.length &&
        disableRewardIds?.includes(`${claimedReward.reward.id}`)
      ) {
        return false;
      }
      if (claimedReward?.redeemable?.code && llCart?.id) {
        if (claimedReward?.reward?.targetType === 'product') {
          const loyaltyItemIds = (llCart?.lines || [])?.reduce((acc, item) => {
            if (
              item?.attributes?.find(
                (attr) =>
                  attr?.key === '_source' && attr?.value === 'll free product'
              )
            ) {
              return [...acc, item];
            }
            return acc;
          }, []);

          const couponCode = claimedReward.redeemable.code;
          const product = claimedReward?.reward?.targetProducts[0];
          const lineItemsId = (loyaltyItemIds || []).reduce((acc, item) => {
            if (
              item.variant.id !==
              `gid://shopify/ProductVariant/${product.variantId}`
            ) {
              return [...acc, item.id];
            }
            return acc;
          }, []);
          if (lineItemsId?.length) {
            await cartRemoveItems({ lineIds: lineItemsId });
          }
          const claimedItem = (loyaltyItemIds || []).find(
            (item) =>
              item.variant.id ===
              `gid://shopify/ProductVariant/${product.variantId}`
          );
          if (claimedItem) {
            const discountCode = claimedItem?.attributes?.find((item) => {
              return item.key === '_discountCode';
            });
            const rewardId = claimedItem?.attributes?.find((item) => {
              return item.key === '_rewardId';
            });
            if (
              couponCode === discountCode?.value &&
              `${claimedReward.reward.id}` === rewardId?.value
            ) {
              toggleCartSidebar();
            } else {
              const updateItem = {
                lineId: claimedItem.id,
                quantity: claimedItem.quantity,
              };
              updateItem.attributes = claimedItem.attributes.map((attr) => {
                if (attr.key === '_discountCode') {
                  return { key: '_discountCode', value: `${couponCode}` };
                }
                if (attr.key === '_rewardId') {
                  return {
                    key: '_rewardId',
                    value: `${claimedReward.reward.id}`,
                  };
                }
                return attr;
              });
              await cartUpdateItem(updateItem);
              if (
                couponCode &&
                !cart?.discountCodes?.some((dc) => dc.code === couponCode)
              ) {
                await cartAddDiscount({ discountCode: couponCode });
              }
              toggleCartSidebar();
            }
            return false;
          }
          const item = {
            merchandiseId: `gid://shopify/ProductVariant/${product.variantId}`,
            quantity: 1,
          };
          item.attributes = [
            { key: '_source', value: 'll free product' },
            { key: '_discountCode', value: `${claimedReward.redeemable.code}` },
            { key: '_rewardId', value: `${claimedReward.reward.id}` },
          ];
          await cartAddItem(item);
          if (
            couponCode &&
            !llCart?.discountCodes?.some(
              (discountCode) => discountCode.code === couponCode
            )
          ) {
            await cartAddDiscount({ discountCode: couponCode });
          }
          toggleCartSidebar();
        }
      }
      return true;
    };

    const t = setInterval(() => {
      if (timer > 20) {
        clearInterval(t);
      }
      if (window?.loyaltylion && window?.loyaltylion?.token && cart?.id) {
        clearInterval(t);
        window.loyaltylion.on('ready', () => {
          if (
            !window?.loyaltylion?.registeredHook?.length ||
            window?.loyaltylion?.registeredHook?.indexOf(
              'customers.rewards.claimed'
            ) === -1
          ) {
            window.loyaltylion.registerHook(
              'customers.rewards.claimed',
              async ({ claimedReward }) => {
                await addProductToCart(claimedReward);
                return { action: 'show_claim_modal' };
              }
            );
            window.loyaltylion.registeredHook = [];
            window.loyaltylion.registeredHook.push('customers.rewards.claimed');
          }
        });
      } else {
        setTimer(timer + 1);
      }
    }, 500);

    const camelize = (obj) =>
      _.transform(obj, (acc, value, key, target) => {
        const camelKey = _.isArray(target) ? key : _.camelCase(key);

        acc[camelKey] = _.isObject(value) ? camelize(value) : value;
      });

    const getRewardFromCouponCode = (couponCode) => {
      if (typeof window.lion !== 'object') {
        return null;
      }
      const claimedRewards = (window.lion?.customer?.claimedRewards || []).find(
        (item) => {
          return item?.redeemable?.code === couponCode;
        }
      );
      return camelize(claimedRewards);
    };

    const couponCodeClick = async (e) => {
      let couponCodeEl;
      if (e.target.classList.contains('lion-reward-code')) {
        return;
      }
      if (e.target.classList.contains('.lion-reward-code__code')) {
        couponCodeEl = e.target;
      }
      if (
        e.target.classList.contains('.lion-copy-to-clipboard-button') ||
        e.target.closest('.lion-history-info-modal-content .lion-reward-code')
      ) {
        couponCodeEl = e.target
          .closest('.lion-history-info-modal-content')
          .querySelector('.lion-reward-code__code');
      }
      if (couponCodeEl && couponCodeEl.innerText !== '') {
        const couponCode = couponCodeEl.innerText;
        const claimedReward = getRewardFromCouponCode(couponCode);
        if (claimedReward?.id) {
          await addProductToCart(claimedReward);
        } else {
          await cartAddDiscount({ discountCode: couponCode });
          toggleCartSidebar();
        }

        if (
          e.target
            .closest('.lion-modal')
            .querySelector('.lion-modal__close-button')
        ) {
          e.target
            .closest('.lion-modal')
            .querySelector('.lion-modal__close-button')
            .click();
        }
      }
    };

    document.body.addEventListener('click', couponCodeClick);
    return () => {
      clearInterval(t);
      document.body.removeEventListener('click', couponCodeClick);
    };
  }, [loyaltylionLoaded, cart?.updatedAt, disableRewardIds]);

  useEffect(() => {
    const handleBeforeHistoryChange = () => {
      const loyaltylionStyleEl = document.querySelector(
        'link[rel=stylesheet][data-lion-css]'
      );
      if (loyaltylionStyleEl && !loyaltyLionStylesheet?.current) {
        loyaltyLionStylesheet.current = loyaltylionStyleEl;
      }
    };
    const handleRouteChangeComplete = () => {
      const loyaltylionStyleEl = document.querySelector(
        'link[rel=stylesheet][data-lion-css]'
      );
      if (loyaltylionStyleEl && !loyaltyLionStylesheet?.current) {
        loyaltyLionStylesheet.current = loyaltylionStyleEl;
      }
      if (!loyaltylionStyleEl && loyaltyLionStylesheet?.current) {
        document.head.append(loyaltyLionStylesheet.current);
      }
    };

    router.events.on('beforeHistoryChange', handleBeforeHistoryChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      router.events.off('beforeHistoryChange', handleBeforeHistoryChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  return null;
}

LoyaltyLionScript.displayName = 'LoyaltyLionScript';
