import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Box } from 'theme-ui';
import { Section } from '@snippets';
import { withInView } from '@utils';
import store, { useRecoilState } from '@store';

import { Schema } from './VideoWise.schema';
import { themed } from './VideoWise.theme';

export const VideoWise = withInView(
  themed(({ cms, theme }) => {
    const { embedContent, section } = cms;
    const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
    const [loaded, setLoaded] = useState(false);
    const [videoWiseLoaded, setVideoWiseLoaded] = useRecoilState(store.videoWiseLoaded);
    const [videoWiseConfig, setVideoWiseConfig] = useRecoilState(store.videoWiseConfig);
    const { asPath } = useRouter();

    useEffect(() => {
      const style= document.getElementById('videowise-client-style');
      style && style.parentNode.removeChild(style);
      const t = document.getElementById('videowise-vendors-js');
      t && t.parentNode.removeChild(t);
      const s = document.getElementById('videowise-client-js');
      s && s.parentNode.removeChild(s);
      setLoaded(true);
      return () => {
        setLoaded(false);
      }
    }, []);

    useEffect(() => {
      if (typeof window !== 'undefined' && videoWiseConfig && loaded) {
        let style = window.document.createElement('link');
        style.href = 'https://assets.videowise.com/style.css.gz';
        style.rel = 'stylesheet';
        style.id = 'videowise-client-style';
        style.setAttribute('as', 'style');
        style.setAttribute('onload', 'this.onload=null;this.rel=\'stylesheet\'');
        style.onload = null;
        document.head.appendChild(style);

        let js = window.document.createElement('script');
        js.src = 'https://assets.videowise.com/vendors.js.gz';
        js.type = 'text/javascript';
        js.fetchPriority = 'high';
        js.id = 'videowise-vendors-js';
        document.head.appendChild(js);
        js = window.document.createElement('script');
        js.src = 'https://assets.videowise.com/client.js.gz';
        js.type = 'text/javascript';
        js.fetchPriority = 'high';
        js.id = 'videowise-client-js';
        document.head.appendChild(js);
        setVideoWiseLoaded(true);
      }
      return () => {
        setVideoWiseLoaded(false);
        const t = document.getElementById('videowise-vendors-js');
        t && t.parentNode.removeChild(t);
        const s = document.getElementById('videowise-client-js');
        s && s.parentNode.removeChild(s);
      };
    }, [asPath, videoWiseConfig, loaded]);

    return embedContent && videoWiseLoaded ? (
      <Section section={section}>
        <Box
          sx={{
            ...theme.html,
          }}
          id={moduleID}
          className="embedContent"
          dangerouslySetInnerHTML={{ __html: embedContent }}
        />
      </Section>
    ) : null;
  }),
  { triggerOnce: true }
);

VideoWise.displayName = 'VideoWise';
VideoWise.Schema = Schema;
