import { position, section, align, button, color } from '@settings/common';

export function Schema({ article, blog }) {
  if (article || blog) return null;

  return {
    label: 'Half Media',
    key: 'half-media',
    fields: [
      {
        label: 'Image Settings',
        name: 'image',
        description: 'Image, alt text',
        component: 'group',
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description:
              'Provide a brief description of the image for accessibility compliance',
          },
          {
            name: 'imageDt',
            label: 'Image (desktop)',
            component: 'image',
          },
          {
            label: 'Image (mobile)',
            name: 'image',
            component: 'image',
          },
        ],
        defaultValue: {
          alt: `A Woman sitting on a couch with a Hopsulator slim in her hand.`,
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FirstCanCooler.png',
          },
          imageDt: {
            src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FirstCanCooler.png',
          },
        },
      },
      {
        label: 'Video Settings',
        name: 'video',
        description: 'Direct Video Link, Thumbnail',
        component: 'group',
        fields: [
          {
            label: 'Enable Video',
            name: 'enabled',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Video Alt',
            name: 'alt',
            component: 'text',
            description:
              'Provide a brief description of the video for accessibility compliance',
          },
          {
            label: 'Direct Video Link',
            description: `Direct video links usually end in or contain '.mp4'`,
            name: 'src',
            component: 'text',
          },
          {
            label: 'Video Thumbnail',
            description: `Usually the first or best frame of the video`,
            name: 'poster',
            component: 'image',
          },
        ],
        defaultValue: {
          alt: 'Hopsulator Duo image.',
          enabled: true,
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/duoOptimized.mp4',
          poster: {
            src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorDuo_93eedf12-7170-4eee-bf26-61636f3674b8.png',
          },
        },
      },
      {
        label: 'HTML Settings',
        name: 'html',
        component: 'group',
        fields: [
          {
            label: 'HTML',
            name: 'html',
            component: 'html',
            description:
              'This html will replace any image or video used for the media section',
          },
        ],
      },
      {
        label: 'Media Settings',
        name: 'media',
        description: 'Position, placement, aspect ratio',
        component: 'group',
        fields: [
          {
            name: 'disableMobileMedia',
            label: 'Disable Media on Mobile',
            component: 'toggle',
            toggleLabels: {
              true: 'Disable',
              false: 'Enable (default)',
            },
          },
          {
            label: 'Media position',
            name: 'positionDt',
            component: 'select',
            options: position.object,
          },
          {
            label: 'Placement (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationDt',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
          },
          {
            label: 'Aspect ratio (desktop)',
            name: 'ratioDt',
            component: 'radio-group',
            description:
              'Only applies to images and video files not already set to 1:1 ratio',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: '80%',
                label: '5:4',
              },
              {
                value: '100%',
                label: '1:1',
              },
              {
                value: '120%',
                label: '5:6',
              },
              {
                value: 'calc(400% / 9)',
                label: '4:9',
              },
            ],
          },
          {
            label: 'Media position (mobile)',
            name: 'position',
            component: 'select',
            options: position.object,
          },
          {
            label: 'Placement (mobile)',
            name: 'location',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
          },
          {
            label: 'Aspect ratio (mobile)',
            component: 'radio-group',
            description:
              'Only applies to images and video files not already set to 1:1 ratio',
            direction: 'horizontal',
            variant: 'radio',
            name: 'ratio',
            options: [
              {
                value: '80%',
                label: '5:4',
              },
              {
                value: '100%',
                label: '1:1',
              },
              {
                value: '120%',
                label: '5:6',
              },
            ],
          },
        ],
        defaultValue: {
          disableMobileMedia: false,
          location: 'top',
          locationDt: 'left',
          ratio: '100%',
          ratioDt: '100%',
          position: 'center center',
          positionDt: 'center center',
        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, superheading body, link, content alignment',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            component: 'select',
            name: 'headingColor',
            label: 'Heading color',
            options: [{ label: 'Default', value: '' }, ...color.options],
          },
          {
            label: 'Superheading',
            name: 'superheading',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            name: 'ctaStyle',
            label: 'Link Style',
            component: 'select',
            options: button.options,
          },
          {
            label: 'Content alignment (mobile)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content alignment (desktop)',
            name: 'alignmentDt',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
        defaultValue: {
          heading: `THE WORLD'S FIRST SLIM CAN COOLER.`,
          body: 'The Hopsulator Slim keeps all of your\n          favorite 12oz slim canned beverages icy-\n          cold, no matter how hot the party gets.\n* Works with all 12oz Slim Cans\n* Works with all 12oz Slim Cans\n* Works with all 12oz Slim Cans\n* Works with all 12oz Slim Cans',
          ctaStyle: button.value.secondary,
          alignment: align.value.left,
          alignmentDt: align.value.left,
        },
      },
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
}
