import { Box, Flex, Paragraph } from 'theme-ui';

import { Markdown, Svg } from '@snippets';

import { themed } from './Header.theme';

export const Header = themed(({ theme, text }) => {
  return (
    <Box data-comp={Header.displayName} sx={theme.wrapper}>
      <Flex variant="flex.row.center.center" sx={theme.starWrapper}>
        <Svg
          alt="Five Review Stars."
          width="100px"
          src="/svgs/okendo-stars.svg"
          sx={theme.star}
        />
      </Flex>
      {text?.heading && (
        <Markdown
          text={text?.heading}
          textSx={{
            ...theme.heading,
            textAlign: 'center',
          }}
        />
      )}
      <Box sx={theme.subtextBox}>
        {text?.percentage && (
          <Paragraph as="h4" sx={theme.percentage}>
            {text?.percentage}%
          </Paragraph>
        )}
        {text?.subtext && (
          <Markdown
            text={text?.subtext}
            textSx={{
              ...theme.subtext,
              textAlign: 'center',
            }}
          />
        )}
      </Box>
    </Box>
  );
});

Header.displayName = 'Header';
