import { Section, SiteReviews } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './FullPageReviews.schema';

export const FullPageReviews = withInView(
  ({ cms }) => {
    const { section } = cms;
    return (
      <Section section={section}>
        <SiteReviews />
      </Section>
    );
  },
  { renderOnce: true }
);

FullPageReviews.displayName = 'FullPageReviews';
FullPageReviews.Schema = Schema;
