import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      minHeight: '34px',
      display: ['none', 'flex'],
      top: ['0', 'var(--header-height)'],
      position: 'sticky',
      zIndex: 2,
      bg: 'white',
      py: [0, '8px'],
    };

    this.title = {
      variant: 'text.label.1',
      display: 'inline-block',
      fontWeight: 700,
    };

    this.icon = {
      transition: 'transform 0.2s ease-in-out',
      width: '20px',
    };

    this.button = {
      minWidth: '104px',
      display: ['none', 'flex'],
      alignItems: 'center',
      textAlign: 'left',
      position: 'relative',
      borderStyle: 'solid',
      borderColor: '#5d5d5d',
      borderWidth: '1px',
      bg: 'background',
      py: 4,
      px: 8,
      columnGap: '20px',
      borderRadius: [0, '22px'],
    };
  })()
);
