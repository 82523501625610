import { Box, Heading, Flex, Container, Button } from 'theme-ui';

import { convertAlignToFlex } from '@utils';

import { themed } from './Header.theme';

export const Header = themed(
  ({ theme, tabs, settings = {}, activeIndex, setActiveIndex, swiper }) => {
    const { textAlign, textAlignDt, menuAlign, menuAlignDt, heading } =
      settings;

    const isTabs = tabs?.length > 1;

    const justifyContentArr = [
      convertAlignToFlex(textAlign),
      convertAlignToFlex(textAlign),
      isTabs ? 'space-between' : convertAlignToFlex(textAlignDt),
      isTabs ? 'space-between' : convertAlignToFlex(textAlignDt),
    ];
    const alignItemsArr = [
      convertAlignToFlex(textAlign),
      convertAlignToFlex(textAlign),
      convertAlignToFlex(textAlignDt),
      convertAlignToFlex(textAlignDt),
    ];
    const justifyMenuArr = [
      convertAlignToFlex(menuAlign),
      convertAlignToFlex(menuAlign),
      convertAlignToFlex(menuAlignDt),
      convertAlignToFlex(menuAlignDt),
    ];
    const textAlignArr = [
      textAlign,
      textAlign,
      isTabs ? 'left' : textAlignDt,
      isTabs ? 'left' : textAlignDt,
    ];

    return (
      <Box
        data-comp={Header.displayName}
        sx={{
          ...theme.header,
          justifyContent: justifyContentArr,
          alignItems: alignItemsArr,
        }}
      >
        <Heading
          as="h2"
          variant="h2"
          sx={{
            ...theme.heading,
            textAlign: textAlignArr,
          }}
        >
          {heading}
        </Heading>

        {isTabs && (
          <Box sx={theme.tabs}>
            <Flex
              role="tablist"
              sx={{
                ...theme.list,
                justifyContent: justifyMenuArr,
              }}
            >
              {tabs.slice(0, 5).map(({ name }, index, arr) => {
                const isActive = index === activeIndex;
                return (
                  <Box
                    key={name}
                    sx={{
                      ...theme.listItem,
                      mr: index !== arr.length - 1 ? ['30px', null, 17] : 0,
                      button: {
                        ':after': {
                          transform: isActive
                            ? 'translate3D(0, 0, 30px)'
                            : 'translateX(-102%)',
                        },
                      },
                    }}
                  >
                    <Button
                      role="tab"
                      variant="buttons.plain"
                      aria-selected={isActive ? 'true' : 'false'}
                      onClick={() => {
                        setActiveIndex(index);
                        if (swiper) swiper.slideTo(0);
                      }}
                      sx={{
                        ...theme.button,
                      }}
                    >
                      {name}
                    </Button>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        )}
      </Box>
    );
  }
);

Header.displayName = 'Header';
