import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      minHeight: '34px',
      mb: 10,
      display: ['none', 'flex'],
    };

    this.dropdown = {
      width: ['100%', '210px'],
      '[data-comp=DropdownDrawer]': {
        borderColor: 'gray',
      },
    };

    this.selected = {
      width: '100%',
      p: 8,
      borderColor: 'gray',
      bg: 'background',
      transition: 'all 0.2s ease-in-out',
      borderRadius: [0, '22px'],
      borderLeftStyle: ['none', 'solid'],
      borderRightStyle: ['none', 'solid'],
      ':hover': {
        borderColor: ['gray', 'mediumDarkGray'],
      },

      content: {
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },

      title: {
        variant: 'text.label.1',
      },

      icon: {
        transition: 'transform 0.2s ease-in-out',
        width: '20px',
      },
    };

    this.option = {
      width: '100%',
      px: 8,

      title: {
        variant: 'text.base',
        fontSize: 2,
        lineHeight: 20,
        width: '100%',
        ':hover': {
          fontWeight: 500,
        },
      },
    };
  })()
);
