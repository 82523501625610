import { Heading, Flex, Box } from 'theme-ui';

import { useSettings } from '@backpackjs/storefront';
import { useProductRecommendations } from '@hooks';
import { useSearch } from '../hooks';

import { RecommendationItem } from './RecommendationItem';
import { useRecommendationsBeacon } from './useRecommendationsBeacon';

import { themed } from './Recommendations.theme';

export const Recommendations = themed(({ theme }) => {
  const settings = useSettings();
  const [{ totalResults }] = useSearch();
  const {
    enable,
    heading,
    tag,
    limit = 8,
  } = { ...settings?.search?.searchSpring };

  const { recommendations, profileData, profileTag } =
    useProductRecommendations({
      tag: tag || 'cross-sell',
      limit,
    });

  const { sendBeaconEventsOnClick } = useRecommendationsBeacon({
    profileData,
    profileTag,
    visibleProducts: profileData?.results,
  });

  if (totalResults || !recommendations?.length || !enable) return null;

  return (
    <Box data-comp={Recommendations.displayName}>
      <Heading as="h5" sx={theme.heading}>
        {heading || 'Complete Your Collection'}
      </Heading>
      <Flex variant="flex.column">
        <Flex variant="flex.column" as="ul">
          {recommendations?.map((product, index, arr) => {
            if (!product?.handle) return null;
            return (
              <Box key={index} as="li" onClick={sendBeaconEventsOnClick}>
                <RecommendationItem
                  handle={product.handle}
                  isLast={index === arr.length - 1}
                />
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
});

Recommendations.displayName = 'Recommendations';
