import { Flex, Button, Paragraph, Box } from 'theme-ui';

import { Svg } from '@snippets';

import { useSearchFilters } from './useSearchFilters';
import { themed } from './Filters.theme';

export const CurrentFilters = themed(({ theme, ...props }) => {
  const [
    { filterSummary },
    { removeFromSelectedFilters, clearSelectedFilters },
  ] = useSearchFilters();

  return (
    <Box
      data-comp={CurrentFilters.displayName}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      <Flex sx={theme.filtersRow}>
        {filterSummary?.map((filter) => {
          return (
            <Button
              key={filter.field + filter.value}
              variant="buttons.plain"
              aria-label={`Remove ${filter.filterLabel} ${filter.filterValue} from filters`}
              onClick={() => {
                const { value } = filter;
                removeFromSelectedFilters({
                  field: filter.field,
                  option:
                    typeof value === 'object'
                      ? {
                          type: 'range',
                          low: value.rangeLow,
                          high: value.rangeHigh,
                        }
                      : { type: 'value', value },
                });
              }}
              sx={theme.filter}
            >
              <Svg
                alt="Close"
                src="/svgs/close.svg#close"
                viewBox="0 0 48 48"
                data-comp="Close"
                sx={theme.close}
              />

              <Paragraph sx={theme.filterValue}>{filter.filterValue}</Paragraph>
            </Button>
          );
        })}
      </Flex>

      {filterSummary?.length > 0 && (
        <Button
          variant="buttons.link.1"
          onClick={clearSelectedFilters}
          aria-label="Clear all filters"
          sx={{
            ...theme.clearAll,
            display: filterSummary?.length > 0 ? 'block' : 'none',
          }}
        >
          Clear All
        </Button>
      )}
    </Box>
  );
});

CurrentFilters.displayName = 'CurrentFilters';
