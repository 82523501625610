import { useCallback, useEffect } from 'react';

import store, { useRecoilState, useSetRecoilState } from '@store';
import { useDebounce } from '@hooks';

import { useSearch } from './useSearch';

export const useSearchInput = () => {
  const [, { getSearchResults, getSearchSuggestions, setSearchData }] =
    useSearch();

  const [rawTerm, setRawTerm] = useRecoilState(store.rawTerm);
  const [term, setTerm] = useRecoilState(store.term);
  const setSearchIsFocused = useSetRecoilState(store.searchIsFocused);
  const setLoading = useSetRecoilState(store.searchLoading);
  const debouncedTerm = useDebounce(rawTerm, 300);

  const isSearching = rawTerm && rawTerm !== '';

  // clear input
  const resetRawTerm = useCallback(() => setRawTerm(''), []);

  // update the search term with the debounced value
  const updateTermOnDebouncedTermChange = useCallback(() => {
    if (term !== debouncedTerm) {
      setTerm(debouncedTerm);
    }
  }, [debouncedTerm, term]);

  // check for search suggestions, then return results
  const getSuggestionsThenResults = useCallback(async () => {
    try {
      if (!debouncedTerm) {
        setSearchData(null);
      }

      setLoading(true);

      const data = await getSearchSuggestions({ query: debouncedTerm });

      const exactTerm = data?.['corrected-query'] || data?.query;
      const isAutocomplete = data?.suggested?.type === 'completed';
      const searchTerm = isAutocomplete ? data?.suggested?.text : exactTerm;
      await getSearchResults({ query: searchTerm, isAutocomplete });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  }, [debouncedTerm]);

  useEffect(() => {
    getSuggestionsThenResults();
  }, [debouncedTerm]);

  useEffect(() => {
    updateTermOnDebouncedTermChange();
  }, [debouncedTerm, term]);

  return [
    { term, rawTerm, isSearching },
    {
      setTerm,
      setRawTerm,
      resetRawTerm,
      setSearchIsFocused,
    },
  ];
};
