import { Flex, Button, Box } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Pagination.theme';

export const Pagination = themed(
  ({
    theme,
    currentPage,
    totalPages,
    goToPrevPage,
    goToNextPage,
    goToPage,
    resultsPerPage,
    ...props
  }) => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    return totalPages > 1 ? (
      <Flex
        data-comp={Pagination.displayName}
        {...props}
        sx={{
          ...theme.wrapper,
          ...props.sx,
        }}
      >
        <Box as="nav">
          <Flex as="ul" variant="flex.row.start.center" sx={theme.list}>
            {/* previous arrow */}
            <Box
              as="li"
              sx={{
                ...theme.arrowListItem,
                mr: 5,
              }}
            >
              <Button
                variant="buttons.plain"
                onClick={goToPrevPage}
                disabled={isFirstPage}
                aria-label="Go to previous page"
                sx={theme.arrowButton(isFirstPage)}
              >
                <Svg
                  alt="chevron"
                  viewBox="0 0 16 16"
                  src="/svgs/chevron/left.svg#left"
                  sx={theme.arrowIcon(isFirstPage)}
                />
              </Button>
            </Box>

            {/* pages */}
            {[...Array(totalPages).keys()].map((i) => {
              const page = i + 1;
              return (
                <Box key={page} as="li" sx={theme.listItem}>
                  <Button
                    key={page}
                    variant="buttons.plain"
                    onClick={() => goToPage(page)}
                    aria-label={`Go to page ${page}`}
                    sx={theme.pageButton(page === currentPage)}
                  >
                    {page}
                  </Button>
                </Box>
              );
            })}

            {/* next arrow */}
            <Box as="li" sx={theme.arrowListItem}>
              <Button
                variant="buttons.plain"
                onClick={goToNextPage}
                disabled={isLastPage}
                aria-label="Go to next page"
                sx={theme.arrowButton(isLastPage)}
              >
                <Svg
                  alt="chevron"
                  viewBox="0 0 16 16"
                  src="/svgs/chevron/right.svg#right"
                  sx={theme.arrowIcon(isLastPage)}
                />
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    ) : null;
  }
);

Pagination.displayName = 'Pagination';
