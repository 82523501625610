import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      variant: 'layout.content',
      maxWidth: '800px',
      py: [14, 17],
    };
    this.heading = {
      textAlign: 'center',
      mb: [11, 14],
    };
    this.accordionGroup = {
      mb: [11, 14],
      '&:last-child': {
        mb: 0,
      },
    };
    this.accordionGroupHeading = {
      mb: 8,
    };
    this.accordion = () => ({
      textAlign: 'left',
      overflow: 'hidden',
      transition: '.3s ease-in-out max-height',
      '&:last-child': {
        mb: 0,
      },
    });
    this.accordionHeading = (isActive = false) => ({
      position: 'relative',
      mb: 8,
      pl: [8, 12],
      pr: [16, 18],
      py: [8, 10],
      backgroundColor: isActive ? 'black' : '#F1F2F4',
      color: isActive ? 'white' : 'black',
    });
    this.accordionBody = () => ({
      mt: -8,
      mb: 8,
      px: [0, 11],
      py: [11, 12],
    });
    this.accordionIcon = (isActive = false) => ({
      width: '16px',
      position: 'absolute',
      top: 'calc(50% - 8px)',
      right: ['20px', '36px'],
      color: isActive ? 'white' : 'black',
      transform: isActive ? 'rotate(180deg)' : 'rotate(0)',
    });
  })()
);
