import { color, section, button, align } from '@settings/common';

export function Schema({ blog }) {
  if (!blog) return null;

  return {
    label: 'Blog Grid',
    key: 'blog-grid',
    fields: [
      // Todo: Max number of tiles to display before showing "More Articles" button/event.
      {
        label: 'Tile Settings',
        name: 'tile',
        description: 'Max of 3 tiles',
        component: 'group',
        fields: [
          {
            label: 'First tile visibility',
            name: 'toggleVisibility',
            description:
              'Hide the most recent article if Featured Article section is enabled',
            component: 'toggle',
            toggleLabels: {
              true: 'Visible',
              false: 'Hidden',
            },
          },
          {
            label: 'Content Settings',
            name: 'content',
            component: 'group',
            fields: [
              {
                label: 'Content alignment',
                name: 'align',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: align.options,
              },
              {
                label: 'Content alignment (mobile)',
                name: 'alignMobile',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: align.options,
              },
              {
                label: 'Link text',
                name: 'linkText',
                component: 'text',
              },
              {
                label: 'Link style',
                name: 'linkStyle',
                component: 'select',
                options: button.options,
              },
              {
                label: 'Link style (mobile)',
                name: 'linkStyleMobile',
                component: 'select',
                options: button.options,
              },
            ],
          },
          {
            label: 'Image Settings',
            name: 'image',
            component: 'group',
            fields: [
              {
                label: 'Image placement',
                name: 'location',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: [
                  {
                    value: 'top',
                    label: 'Top',
                  },
                  {
                    value: 'bottom',
                    label: 'Bottom',
                  },
                ],
              },
              {
                label: 'Image placement (mobile)',
                name: 'locationMobile',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                options: [
                  {
                    value: 'top',
                    label: 'Top',
                  },
                  {
                    value: 'bottom',
                    label: 'Bottom',
                  },
                ],
              },
            ],
          },
        ],
        defaultValue: {
          toggleVisibility: false,
          content: {
            align: 'left',
            alignMobile: 'left',
            linkText: 'Read More',
            linkStyle: 'articleLink',
            linkStyleMobile: 'articleLink',
          },
          image: {
            location: 'top',
            locationMobile: 'top',
          },
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'xs',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'xs',
          },
        },
      },
    ],
  };
}
