import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      border: 0,
      boxSizing: 'border-box',
      display: 'flex',
      height: 'auto',
      maxWidth: '100%',
      overflow: 'hidden',
      p: 0,
      position: 'relative',
      width: '100%',
    };

    this.image = {
      bottom: 0,
      left: 0,
      p: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
      willChange: 'opacity',
    };

    this.hoverImage = {
      ...this.image,
      border: 0,
    };

    this.video = {
      bottom: 0,
      left: 0,
      p: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
      willChange: 'opacity',
      objectFit: 'cover',
    };

    this.hoverVideo = {
      ...this.video,
      border: 0,
    };
  })()
);
