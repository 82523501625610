export const label = {
  // default
  variant: 'text.base',
  mb: 5,
  // label variants
  sellingPlan: {
    color: 'white',
    width: 'unset',
    display: 'inline-flex',
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'center',
    bg: 'black',
    py: 1,
    px: 3,
    border: '1px solid black',
    borderRadius: '4px',
    fontWeight: 100,
    fontSize: 2,
  },
};
