import { section, color } from '@settings/common';

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  const defaultEntries = [
    {
      name: 'Bustle',
      logo: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/bustleLogo.svg',
      },
      quote: 'Bustle short quote about how much they enjoy Brumate.',
    },
    {
      name: 'Business Insider',
      logo: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/BILogo.svg',
      },
      quote:
        'Business Insider quote helping build trust with the Brumate brand.',
    },
    {
      name: 'ABC News',
      logo: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/abcNewsLogo.svg',
      },
      quote: 'ABC News review quote regarding Brumate. 5 out of 5 stars.',
    },
    {
      name: 'Romper',
      logo: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/romper-logo-vector.svg',
      },
      quote: 'Sipping wine on your balcony or deck just got a little better.',
    },
    {
      name: 'Entrepreneur Magazine',
      logo: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/entrepreneur.svg',
      },
      quote: 'Entrepreneur Magazine quote about Brumate goes here.',
    },
    {
      name: 'Forbes',
      logo: {
        src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/forbeslogo.svg',
      },
      quote: 'Forbes quote about Brumate appears in this space.',
    },
  ];

  return {
    label: 'Press Slider',
    key: 'press-slider',
    description: 'A slider of press quotes and logos used as navigation.',
    fields: [
      {
        label: 'Entries',
        name: 'entries',
        component: 'group-list',
        description: 'Max of 6 entries',
        itemProps: {
          label: '{{item.name}}' || 'New Press Quote',
        },
        fields: [
          {
            name: 'name',
            label: 'Logo name',
            description:
              'Provide a brief description of the logo for accessibility compliance',
            component: 'text',
          },
          {
            name: 'logo',
            label: 'Logo',
            component: 'image',
            description: 'Upload or select a SVG logo for the entry',

          },
          {
            name: 'quote',
            label: 'Quote',
            component: 'textarea',
            description:
              'Works best with a short sentence or two. Opening and closing quotation marks are added automatically',
          },
        ],
        defaultValue: defaultEntries,
      },
      {
        name: 'Settings',
        label: 'Slider Settings',
        component: 'group',
        description: 'Loop, Transition effect & speed',
        fields: [
          {
            name: 'loop',
            label: 'Loop (mobile)',
            component: 'toggle',
            description:
              'Loop back to the first logo slide when the lasts slide is reached on mobile navigation.',
            toggleLabels: {
              true: 'Enable',
              false: 'Disable (default)',
            },
          },
          {
            name: 'speed',
            label: 'Transition speed',
            component: 'number',
            description:
              'The time in milliseconds it takes to transition from one slide to another when navigating (default: 300)',
          },
        ],
        defaultValue: {
          loop: false,
          speed: 300,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
