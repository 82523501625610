import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.nav = {
      position: 'relative',
    };

    this.list = {
      p: 0,
    };

    this.item = {
      button: {
        width: '100%',
        textAlign: 'left',
        position: 'relative',
        height: '52px',
        p: 8,
        borderBottom: (t) => `1px solid ${t.colors.gray}`,
      },

      text: {
        variant: 'text.label.1',
      },

      icon: {
        position: 'absolute',
        right: 8,
        top: '50%',
        width: '24px',
        height: '24px',
        transform: 'translateY(-50%)',
      },
    };
  })()
);
