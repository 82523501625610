import { Box, Paragraph } from 'theme-ui';

import { Link, Picture } from '@snippets';

import { themed } from './Tile.theme';

export const Tile = themed(({ theme, item, inputRef, ...props }) => {
  const { alt, image, cta, style } = item;

  return (
    <Box data-comp={Tile.displayName} {...props}>
      <Link
        href={cta?.url}
        newTab={cta?.newTab}
        sx={cta?.url ? theme.imageLink : theme.image}
      >
        <Picture
          alt={alt}
          images={[
            {
              src: image?.src,
              ratio: 0.75,
            },
          ]}
        />
        {cta?.text && (
          <Paragraph
            variant={`buttons.${style || 'inverse'}`}
            sx={theme.button}
          >
            {cta?.text}
          </Paragraph>
        )}
      </Link>
    </Box>
  );
});

Tile.displayName = 'Tile';
