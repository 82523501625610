import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridArea: 'menu',
      display: ['grid', 'none'],
      width: '100%',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gridGap: 0,
    };

    this.accordion = {
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
      transition: 'max-height .5s ease-in-out',
      position: 'relative',
      borderBottom: '1px solid',
      borderColor: 'mediumDarkGray',
    };

    this.header = {
      px: [8, 0],
    };

    this.body = {
      px: [8, 0],
    };

    this.item = {
      display: 'block',
      variant: 'text.base',
      lineHeight: '20px',
    };

    this.link = {
      variant: 'text.base',
      width: 'fit-content',
      display: 'inline',
      pb: 1,
      backgroundImage: 'linear-gradient(currentColor, currentColor)',
      backgroundPosition: '0% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 1px',
      transition: 'background-size 0.2s ease-in-out',
      ':hover, :focus': {
        backgroundSize: '100% 1px',
      },
    };
  })()
);
