const plain = {
  position: 'relative',
  minWidth: 'unset',
  p: 0,
  m: 0,
  bg: 'transparent',
  border: 0,
  borderRadius: 0,
  color: 'text',
  fontFamily: 'body',
  textDecoration: 'none',
  cursor: 'pointer',
  ':hover': {
    color: 'currentColor',
  },
};

const customize = {
  position: 'relative',
  minWidth: 'unset',
  p: 0,
  m: 0,
  mb: '24px',
  bg: 'chartreuseYellow',
  border: '1px',
  color: 'text',
  fontFamily: 'Fellix',
  fontSize: '14px',
  textDecoration: 'none',
  justifyContent: 'center',
  borderColor: 'chartreuseYellow',
  borderStyle: 'solid',
  borderRadius: '62px',
  cursor: 'pointer',
  height: 'auto',
  minHeight: '40px',
  ':hover': {
    color: 'currentColor',
    borderColor: 'black',
    bg: 'chartreuseYellow',
  },
};

export const buttons = {
  primary: {
    variant: 'text.buttonText',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    lineHeight: '38px',
    height: '42px',
    textAlign: 'center',
    color: 'white',
    minWidth: '160px',
    px: 11,
    py: 0,
    bg: 'text',
    border: '1px solid',
    borderRadius: '22px',
    borderColor: 'text',
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    ':hover': {
      bg: 'darkGray',
      borderColor: 'darkGray',
    },
  },

  secondary: {
    variant: 'buttons.primary',
    color: 'text',
    bg: 'white',
    borderColor: 'text',
    ':hover': {
      color: 'darkGray',
      bg: 'white',
      borderColor: 'darkGray',
    },
  },

  tertiary: {
    variant: 'buttons.primary',
    bg: 'tundora',
    borderColor: 'tundora',
    ':hover': {
      bg: '#786C68',
      borderColor: '#786C68',
    },
  },

  quaternary: {
    variant: 'buttons.primary',
    bg: '#000',
    borderColor: '#000',
    fontSize: ['13px', 3],
    ':hover': {
      bg: 'darkGray',
      borderColor: 'darkGray',
    },
  },

  groupText: {
    variant: 'text.buttonTextSecondary',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    minWidth: 'unset',
    position: 'relative',
    pb: 9,
    color: 'text',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ':after': {
      content: '""',
      width: '100%',
      height: '1px',
      position: 'absolute',
      top: 'calc(100% - 1px)',
      left: 0,
      transition: 'transform 0.2s ease-in',
      bg: 'text',
    },
    ':hover:after': {
      transform: 'translateX(0)',
    },
  },

  inverse: {
    variant: 'buttons.primary',
    color: 'white',
    bg: 'transparent',
    borderColor: 'white',
    ':hover': {
      bg: 'white',
      color: 'text',
      borderColor: 'text',
    },
  },

  inverseSecondary: {
    variant: 'buttons.secondary',
    color: 'text',
    borderColor: 'text',
    bg: 'transparent',
  },

  link: {
    1: {
      ...plain,
      variant: 'text.link.1',
      cursor: 'pointer',
    },
    2: {
      ...plain,
      variant: 'text.link.2',
      cursor: 'pointer',
    },
  },
  articleLink: {
    ...plain,
    variant: 'text.link.2',
    fontSize: 4,
    lineHeight: '20px',
    cursor: 'pointer',
  },
  lionRefer: {
    variant: 'buttons.tertiary',
  },
  plain,
  customize,
};
