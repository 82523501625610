// eslint-disable-next-line react/prop-types
export function Schema({ product }) {
  if (!product) return null;
  return {
    label: 'Product Upsells',
    key: 'productUpsells',
    fields: [
      {
        component: 'text',
        name: 'heading',
        label: 'Heading',
        defaultValue: 'Add-ons',
      },
      {
        component: 'group-list',
        name: 'products',
        label: 'Products',
        description: 'Add up to 5 products',
        itemProps: {
          label: '{{item.product.handle}}' || 'New Product',
        },
        fields: [
          {
            component: 'productSearch',
            name: 'product',
            label: 'Product',
          },
        ],
      },
    ],
  };
}
