import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.swiper = {
      width: '100%',
      position: 'relative',
      cursor: 'grab',
      '.swiper-container, .swiper-wrapper': {
        width: '100%',
        height: 'auto !important',
      },
      '.swiper-slide': {
        width: '100%',
      },
    };
  })()
);
