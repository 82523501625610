import { position, sectionWithHeight, color, align } from '@settings/common';

const image = ({ position }) => ({
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      description: 'Brief description of image for accessibility compliance',
      label: 'Alt',
    },
    {
      name: 'image',
      label: 'Image (mobile)',
      component: 'image',

    },
    {
      name: 'position',
      component: 'select',
      label: 'Image position (mobile)',
      options: position.object,
    },
    {
      name: 'imageDt',
      label: 'Image (desktop)',
      component: 'image',

    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Image position (desktop)',
      options: position.object,
    },
  ],
  defaultValue: {
    alt: 'Hero image',
    position: 'center center',
    positionDt: 'center center',
  },
});

const text = () => ({
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, subheading',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "H1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "H1".',
    },
    {
      name: 'subheading',
      component: 'markdown',
      label: 'Subheading',
    },
  ],
  defaultValue: {
    heading: '# Collection',
    subheading: '',
  },
});

const content = ({ position }) => ({
  label: 'Content Settings',
  name: 'content',
  description: 'Content alignment, content position',
  component: 'group',
  fields: [
    {
      name: 'alignment',
      label: 'Content alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'position',
      component: 'select',
      label: 'Content position (mobile)',
      options: position.flex,
    },
    {
      name: 'alignmentDt',
      label: 'Content alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'positionDt',
      component: 'select',
      label: 'Content position (desktop)',
      options: position.flex,
    },
    {
      name: 'maxWidth',
      component: 'number',
      label: 'Max content pixel width',
    },
  ],
  defaultValue: {
    alignment: 'center',
    position: 'center center',
    alignmentDt: 'center',
    positionDt: 'center center',
    maxWidth: 600,
  },
});

export function Schema({ collection }) {
  if (!collection) return null;

  return {
    label: 'Collection Hero',
    key: 'collection-hero',
    fields: [
      image({ position }),
      text({ color }),
      content({ position }),
      {
        ...sectionWithHeight,
        defaultValue: {
          bgColor: color.value.dark,
          color: color.value.white,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'none',
            maxHeight: 200,
            minHeight: 200,
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
            maxHeight: 380,
            minHeight: 380,
          },
        },
      },
    ],
  };
}
