import { section } from '@settings/common';
import { icons } from './Icons';

export function Schema() {
  return {
    label: 'Featured Icons',
    key: 'featured-icons',
    fields: [
      {
        label: 'heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'BECOME A VIP AMBASSADOR',
      },
      {
        label: 'subtitle',
        name: 'subtitle',
        component: 'textarea',
        defaultValue:
          'Be a part of a growing community of avid BrüMate fans, and gain exclusive access to new product developments, color releases, charity events, contests and more! Join now',
      },
      {
        label: 'Link',
        name: 'cta',
        component: 'link',
        defaultValue: {
          text: 'Join Now',
          url: '/account/login',
        },
      },
      {
        label: 'Content',
        name: 'icons',
        description: 'Set the content.',
        component: 'group-list',
        itemProps: {
          label: '{{item.text}}' || 'Default Title',
        },
        defaultItem: {
          text: 'Icon Description',
          icon: icons.value.exclusive,
        },
        // each icon
        fields: [
          {
            label: 'Icon',
            name: 'icon',
            component: 'select',
            options: icons.options,
            defaultValue: icons.value.exclusive,
          },
          {
            label: 'Text',
            name: 'text',
            component: 'text',
            defaultValue: 'Place an Order',
          },
        ],
        defaultValue: [
          {
            text: 'EXCLUSIVE CHARITY EVENTS',
            icon: icons.value.exclusive,
          },
          {
            text: 'Weekly contests',
            icon: icons.value.gift,
          },
          {
            text: 'have a say un what comes next',
            icon: icons.value.next,
          },
          {
            text: '15% discount for life',
            icon: icons.value.discount,
          },
        ],
      },

      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'content',
            mt: 'l',
          },
          mobile: {
            container: 'content',
            mt: 's',
          },
        },
      },
    ],
  };
}
