import { Grid, Flex } from 'theme-ui';

import { CtaSearch } from './CtaSearch';
import { CtaAccount } from './CtaAccount';
import { CtaCart } from './CtaCart';
import { CtaWishlist } from './CtaWishlist';
import { CtaRefer } from './CtaRefer';
import { themed } from '../Menu.theme';

export const Ctas = themed(({ theme }) => {
  return (
    <Flex sx={{ height: '100%', alignItems: 'center' }}>
      <CtaRefer
        sx={{
          display: ['none', null, 'block'],
        }}
      />
      <Grid data-comp={Ctas.displayName} sx={theme.ctas}>
        <CtaWishlist sx={{ display: ['none', null, 'block'] }} />

        <CtaSearch />

        <CtaAccount sx={{ display: ['none', null, 'block'] }} />
      </Grid>

      <CtaCart />
    </Flex>
  );
});

Ctas.displayName = 'Ctas';
