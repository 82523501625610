import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      flex: 1,
      overflow: 'hidden',
      mr: 'initial',
    };
    // cart empty — rendered in /cart
    this.page = {
      ...this.sidebar,
      width: '80%',
    };
  })()
);
