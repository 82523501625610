import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';

import { ProductItem } from '@snippets';

import { Navigation } from './Navigation';
import { useProductSwiperParams } from './useProductSwiperParams';
import { themed } from './ProductSwiper.theme';

SwiperCore.use([A11y]);

export const ProductSwiper = themed(
  ({
    theme,
    products,
    loop,
    count,
    params = {},
    isProductRecs,
    isProductRow,
    hideAddToCart,
    onItemClick,
    swiper: passedSwiper,
    setSwiper: setPassedSwiper,
    inputRef,
    ...props
  }) => {
    const defaultParams = useProductSwiperParams({
      isProductRecs,
      loopEnabled: loop,
      count,
    });

    const [localSwiper, setLocalSwiper] = useState(null);

    const swiper = passedSwiper || localSwiper;
    const setSwiper = setPassedSwiper || setLocalSwiper;

    return (
      <Box
        data-comp={ProductSwiper.displayName}
        {...props}
        sx={{
          ...theme.swiper,
          ...props.sx,
        }}
      >
        <Swiper
          onSwiper={setSwiper}
          a11y={{ enabled: true }}
          {...defaultParams}
          {...params}
        >
          {products?.map((item, index) => {
            const product = isProductRecs ? item : item.product;
            if (!product?.handle) return null;
            return (
              <SwiperSlide key={index}>
                <ProductItem
                  product={product}
                  hideAddToCart={product?.hideAddToCart}
                  isProductRow={isProductRow}
                  index={index}
                  onItemClick={onItemClick}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        {products?.length > 0 && (
          <Navigation swiper={swiper} loop={loop} count={count} />
        )}
      </Box>
    );
  }
);

ProductSwiper.displayName = 'ProductSwiper';
ProductSwiper.propTypes = {
  isProductRecs: PropTypes.bool,
  products: PropTypes.array,
  params: PropTypes.object,
  loop: PropTypes.bool,
  count: PropTypes.bool,
};
