import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.bar = {
      bg: 'gray',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '40px',
      pl: [8, 10, 11, 12],
      pr: [0, 2, 4, 8],
    };

    this.link = {
      fontColor: 'text',
      px: 8,
      fontSize: 3,
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textUnderlineOffset: '2px',
      lineHeight: '36px',
      outlineColor: '#0000FF',
      textAlign: 'right',
    };
  })()
);
