import { useEffect, useState } from 'react';
import { Box, Container } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { themed } from './FourSixty.theme';
import { Schema } from './FourSixty.schema';

export const FourSixty = withInView(
  themed(({ theme, cms, inView }) => {
    const { section } = cms;
    const galleryId = cms?.galleryId;

    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      if (isMounted || !galleryId || !inView) return;

      const scriptInit = async () => {
        try {
          const element = document.getElementById('four-sixty-div');
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = '//foursixty.com/media/scripts/fs.embed.v2.5.js';
          script.id = 'four-sixty-script';
          script.setAttribute('key', 'foursixtylib');
          script.setAttribute('data-feed-id', galleryId);
          script.setAttribute('data-open-links-in-same-page', 'true');
          script.setAttribute('data-theme', 'showcase_v2_5');
          script.setAttribute('data-page-size', '4');
          await element.appendChild(script);

          setIsMounted(true);
        } catch (error) {
          setIsMounted(false);
          throw new Error(error);
        }
      };
      scriptInit();
    }, [galleryId, isMounted, inView]);

    return (
      <Section section={section}>
        <Container data-comp={FourSixty.displayName}>
          <Box id="four-sixty-div" sx={theme.fourSixty} />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FourSixty.displayName = 'FourSixty';
FourSixty.Schema = Schema;
