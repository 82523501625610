import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.sidebar = {
      price: {
        variant: 'flex.row.between.center',
        mb: 0,

        title: {
          variant: 'text.h5',
          fontSize: 6,
          mr: 4,
        },

        amount: {
          variant: 'text.base',
          fontSize: ['12px', 3],
          textAlign: 'right',
        },
        regular: {
          variant: 'text.base',
          fontSize: ['12px', 3],
          textAlign: 'right',
        },
        strike: {
          variant: 'text.base',
          fontSize: ['12px', 3],
          textAlign: 'right',
          textDecoration: 'line-through',
          color: 'mediumDarkGray',
          ml: [0, 4],
        },
        container: {
          gap: 3,
          ml: 0,
          justifyContent: 'flex-end',
          flexFlow: ['row-reverse', 'row']
        },
      },
    }),
      // cart empty — rendered in /cart
      (this.page = {
        price: {
          ...this.sidebar.price,

          title: {
            variant: 'text.h5',
            mr: 4,
          },

          amount: {
            variant: 'text.base',
            fontSize: 3,
            textAlign: 'right',
          },
        },
      });
  })()
);
