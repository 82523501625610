import { useMemo } from 'react';
import { Flex, Paragraph, Spinner, Heading, Box } from 'theme-ui';

import { Locale, Link, OkendoStars } from '@snippets';

import { ColorOptions } from './ColorOptions';
import { themed } from './Details.theme';

export const Details = themed(
  ({
    theme,
    product,
    initialProduct,
    selectedHandle,
    setSelectedHandle,
    seeAllLink,
    description,
    enableFullProductAccess,
    handleClick,
  }) => {
    const titles = useMemo(() => {
      const titleArr = product?.title?.split('|');
      return {
        product: titleArr?.[0]?.trim() || '',
        variant: titleArr?.slice(1)?.join('|').trim() || '',
      };
    }, [product?.title]);

    return (
      <Flex data-comp={Details.displayName} sx={theme.wrapper}>
        {/* Title */}
        <Link
          href={enableFullProductAccess ? `/products/${product?.handle}` : null}
          tabIndex="-1"
          onClick={handleClick}
        >
          <Heading as="h5" sx={theme.title}>
            {titles?.product}
          </Heading>
        </Link>

        {description && (
          <Paragraph sx={theme.description}>{description}</Paragraph>
        )}

        <Flex sx={theme.variantStarsWrapper}>
          {titles.variant && (
            <Paragraph sx={theme.variant}>{titles.variant}</Paragraph>
          )}

          <Link
            href={`/products/${product?.handle}`}
            tabIndex="-1"
            onClick={handleClick}
          >
            <OkendoStars
              legacyResourceId={product?.legacyResourceId}
              withScroll={false}
              sx={{ my: 0 }}
            />
          </Link>
        </Flex>

        {/* Variant price */}
        <Box sx={theme.priceWrapper}>
          {product?.variants?.[0] && (
            <Locale.Variant variant={product.variants[0]}>
              <Locale.Price>
                {({ priceV2, compareAtPriceV2, isOnSale }) => {
                  return isOnSale ? (
                    <Flex>
                      <Paragraph sx={theme.newPrice}>
                        {priceV2.locale}
                      </Paragraph>
                      <Paragraph sx={theme.compareAtPrice}>
                        {compareAtPriceV2.locale}
                      </Paragraph>
                    </Flex>
                  ) : (
                    <Paragraph sx={theme.price}>{priceV2.locale}</Paragraph>
                  );
                }}
              </Locale.Price>

              <Locale.Loader>
                <Flex sx={theme.loader}>
                  <Spinner />
                </Flex>
              </Locale.Loader>
            </Locale.Variant>
          )}
        </Box>

        <ColorOptions
          initialProduct={initialProduct}
          selectedHandle={selectedHandle}
          setSelectedHandle={setSelectedHandle}
          seeAllLink={seeAllLink}
        />
      </Flex>
    );
  }
);

Details.displayName = 'Details';
