import { Flex, Paragraph } from 'theme-ui';

import { Link } from '@snippets';

import { useSearch } from '../../hooks';
import { themed } from './SearchPageSuggestions.theme';

export const SearchPageSuggestions = themed(({ theme }) => {
  const [{ pageSuggestions, pageResults, pageSuggested }] = useSearch();

  const isAutocomplete = pageSuggested?.type === 'completed';

  return pageSuggestions?.length && (!pageResults?.length || isAutocomplete) ? (
    <Flex data-comp={SearchPageSuggestions.displayName} sx={theme.wrapper}>
      <Paragraph sx={{ mr: 2 }}>Did you mean:</Paragraph>
      {pageSuggestions.map((suggestion, index, arr) => {
        const isLast = index === arr.length - 1;
        return (
          <Link
            key={suggestion}
            href="/pages/search"
            query={{ query: suggestion }}
            sx={theme.suggestion(isLast)}
          >
            {suggestion}
          </Link>
        );
      })}
      <Paragraph>?</Paragraph>
    </Flex>
  ) : null;
});

SearchPageSuggestions.displayName = 'SearchPageSuggestions';
