import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = (numOfGwpItems) => ({
      maxWidth: [null, numOfGwpItems === 1 ? '32em' : '64em'],
      width: ['auto', '90vw'],
      textAlign: 'center',
    });
    this.image = {
      height: '100%',
    };
    this.details = (numOfGwpItems) => ({
      alignSelf: 'center',
      px: numOfGwpItems === 1 ? 0 : [11, null, 17],
      py: numOfGwpItems === 1 ? 5 : [14, 17],
      width: '100%',
    });
    this.heading = {
      mb: 8,
    };
    this.buttonWrapper = {
      mt: [11, '50px'],
    };
    this.bullets = {
      mt: 11,
      display: ['flex', 'none'],
      justifyContent: 'center',
      gap: 9,
      width: '100%',
      height: '8px',
      '.swiper-pagination-bullet': {
        cursor: 'pointer',
        bg: 'black',
        opacity: 0.2,
      },
      '.swiper-pagination-bullet-active': {
        opacity: '1 !important',
      },
      position: 'static',
    };
    this.arrowBtn = {
      display: [null, 'none !important'],
      width: '44px',
      height: '44px',
      border: '1px solid #e8e8e8',
      borderRadius: '100%',
      backgroundColor: 'white',
      color: 'black',
      top: `calc(33.33% - ${22}px)`,
      ':after': {
        display: 'none !important',
      },
      svg: {
        width: '20px',
      },
    };
  })()
);
