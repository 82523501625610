import { Image as TempImage } from 'theme-ui';

import { themed } from './Image.theme';

export const Image = themed(({ theme, articleImage, alt }) => {
  const image =
    articleImage ||
    'https://cdn.shopify.com/s/files/1/1114/2308/files/brumate-blog-placeholder.jpg?v=1660081495';
  return (
    <TempImage
      src={image}
      alt={alt}
      sx={{
        ...theme.image,
        objectFit: 'cover',
        width: '100%',
        maxHeight: [240, 280],
        minHeight: [240, 280],
      }}
    />
    //  <Picture
    //   data-comp={Image.displayName}
    //   alt={`${alt} article image.`}
    //   images={[
    //     {
    //       src: articleImage,
    //       width: 512,
    //       ratio: 1.5,
    //     },
    //     {
    //       src: articleImage,
    //       width: 640,
    //       ratio: 1.5,
    //     },
    //     {
    //       src: articleImage,
    //       width: 768,
    //       ratio: 1.5,
    //     },
    //     {
    //       src: articleImage,
    //       width: 1024,
    //       ratio: 1.5,
    //     },
    //   ]}
    // />
  );
});

Image.displayName = 'Image';
