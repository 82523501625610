import { useState, useCallback, useEffect, useRef } from 'react';
import { Heading, Box } from 'theme-ui';
import { useCartEmpty, useSettings } from '@backpackjs/storefront';

import { useProductRecommendations } from '@hooks';

import { useUpsellBeacon } from './useUpsellBeacon';
import { UpsellSlider } from './UpsellSlider';
import { useUpsell } from './useUpsell';
import { themed } from './Upsell.theme';

export const Upsell = themed(({ theme, inSidebar, ...props }) => {
  const upsellRef = useRef(null);

  const cartEmpty = useCartEmpty();
  const settings = useSettings();
  const upsell = settings?.cart?.upsell || {};
  const { enabled, message, messageEmpty, limit } = upsell;

  const [visibleProduct, setVisibleProduct] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const activeIndex = swiper?.realIndex || 0;

  const { recommendations, profileData, profileTag } =
    useProductRecommendations({
      tag: 'view-cart',
      limit,
    });

  const { sendBeaconEventsOnClick } = useUpsellBeacon({
    profileData,
    profileTag,
    visibleProduct,
  });

  const { upsellProductsNotInCart } = useUpsell({ products: recommendations });

  const setVisibleProductOnSlideChange = useCallback(() => {
    if (!profileData?.results?.length || !upsellProductsNotInCart?.length)
      return;
    const product = profileData.results.find((result) => {
      return (
        result?.attributes?.handle ===
        upsellProductsNotInCart?.[activeIndex]?.handle
      );
    });
    setVisibleProduct(product);
  }, [upsellProductsNotInCart, profileData?.results, activeIndex]);

  useEffect(() => {
    setVisibleProductOnSlideChange();
  }, [upsellProductsNotInCart, profileData?.results, activeIndex]);

  return enabled && upsellProductsNotInCart?.length ? (
    <Box
      data-comp={Upsell.displayName}
      ref={upsellRef}
      onClick={sendBeaconEventsOnClick}
      {...props}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      <Heading as="h6" sx={theme.heading}>
        {cartEmpty ? messageEmpty : message}
      </Heading>

      <UpsellSlider
        swiper={swiper}
        setSwiper={setSwiper}
        products={upsellProductsNotInCart}
        profileData={profileData}
      />
    </Box>
  ) : null;
});

Upsell.displayName = 'CartUpsell';
