import { useMemo } from 'react';
import { Box, Flex, Heading, Button, Paragraph, Grid } from 'theme-ui';

import { Accordions, Accordion, FilterOption, Svg } from '@snippets';
import store, { useRecoilValue } from '@store';

import { useCollectionSort, useCollectionFilters } from '@templates/collection/hooks';
import { useSearchSort } from '@snippets/Search/SearchPage/Sort';

import { themed } from './FiltersSidebar.theme';

export const FiltersSidebar = themed(
  ({
    theme,
    filters,
    addToSelectedFilters,
    removeFromSelectedFilters,
    isSearchPage,
    toggleFiltersSidebar,
  }) => {
    const [collectionSortState, collectionSortActions] = useCollectionSort();
    const [searchSortState, searchSortActions] = useSearchSort();
    const sortOptions = isSearchPage
      ? searchSortState.sortOptions
      : collectionSortState.sortOptions;
    const selectedSort = isSearchPage
      ? searchSortState.selectedSort
      : collectionSortState.selectedSort;
    const sortCollection = isSearchPage
      ? searchSortActions.sortCollection
      : collectionSortActions.sortCollection;

    const selectedSortLabel = useMemo(() => {
      if (!selectedSort || !sortOptions) return null;
      const selectedOption = sortOptions.find(({ field, direction }) => {
        return (
          field === selectedSort.field && direction === selectedSort.direction
        );
      });
      return selectedOption?.label || null;
    }, [selectedSort?.field, selectedSort?.direction, sortOptions?.length]);

    const selectedFilters = useRecoilValue(store.selectedFilters);
    const searchSelectedFilters = useRecoilValue(store.searchSelectedFilters);
    const selectedFiltersLength = useMemo(() => {
      const removeDuplicatePriceFilters = isSearchPage
        ? searchSelectedFilters?.filter((filter) => {
            return filter.key !== 'filter.ss_price.low';
          })
        : selectedFilters?.filter((filter) => {
            return filter.key !== 'filter.ss_price.low';
          });
      return removeDuplicatePriceFilters?.length;
    }, [selectedFilters, searchSelectedFilters]);
    const [{ totalResults },] = useCollectionFilters();

    return (
      <Box data-comp={FiltersSidebar.displayName} sx={theme.scroll}>
        {filters?.length > 0 && (
          <Accordions
            textColor="text"
            borderColor="gray"
            iconColor="text"
            minHeight="52px"
            maxHeight="800px"
            oneOpenAtATime={false}
            defaultIndex={1}
            sx={{ borderTopStyle: 'none' }}
          >
            <Accordion sx={{ display: ['block', 'none'], borderColor: 'mediumGray' }}>
              <Accordion.Header sx={{ pl: 0, py: 8, pr: 8 }}>
                <Flex variant="flex.row.start.center">
                  <Heading as="h5" sx={theme.accordionHeader}>
                    Sort
                  </Heading>
                  {selectedSortLabel && (
                    <Paragraph sx={theme.selectedSort}>
                      {selectedSortLabel}
                    </Paragraph>
                  )}
                </Flex>
              </Accordion.Header>
              <Accordion.Body sx={{ pb: 0 }}>
                {({ isOpen }) => (
                  <Grid as="ul" sx={theme.grid}>
                    {sortOptions?.map(({ field, direction, label }, index) => {
                      const isActive =
                        selectedSort?.field === field &&
                        selectedSort?.direction === direction;
                      const isLast = index === sortOptions.length - 1;

                      return (
                        <Box as="li" key={label}>
                          <Button
                            variant="buttons.plain"
                            tabIndex={isOpen ? '0' : '-1'}
                            aria-hidden={isOpen ? 'false' : 'true'}
                            onClick={() => {
                              sortCollection({ field, direction });
                            }}
                            sx={theme.button(isLast)}
                          >
                            <Box sx={theme.swatchOuter(isActive)}>
                              <Box sx={theme.swatchInner(isActive)}>
                                {isActive && (
                                  <Svg
                                    src="/svgs/checkmark.svg#checkmark"
                                    alt="Checkmark"
                                    viewBox="0 0 14 12"
                                    sx={theme.checkmark()}
                                  />
                                )}
                              </Box>
                            </Box>

                            <Paragraph sx={theme.label(isActive)}>
                              {label}
                            </Paragraph>
                          </Button>
                        </Box>
                      );
                    })}
                  </Grid>
                )}
              </Accordion.Body>
            </Accordion>

            {filters?.map((filter, idx) => {
              return (
                <Accordion sx={{ borderColor: 'mediumGray' }} key={filter.label} collapsed={idx == 0 ? false:true}>
                  <Accordion.Header sx={{ pl: 0, py: 8, pr: 8 }}>
                    <Flex variant="flex.row.start.center">
                      <Heading as="h5" sx={theme.accordionHeader}>
                        {filter.label}
                      </Heading>
                    </Flex>
                  </Accordion.Header>

                  <Accordion.Body sx={{ pb: 0 }}>
                    {({ isOpen }) => (
                      <Grid as="ul" sx={theme.grid}>
                        {filter.values?.map((option, index) => {
                          const isLast = index === filter.values.length - 1;
                          return (
                            <Box key={option.label} as="li">
                              <FilterOption
                                buttonSx={theme.button(isLast)}
                                isMobile
                                option={option}
                                field={filter.field}
                                tabIndex={isOpen ? '0' : '-1'}
                                aria-hidden={isOpen ? 'false' : 'true'}
                                addToSelectedFilters={addToSelectedFilters}
                                removeFromSelectedFilters={
                                  removeFromSelectedFilters
                                }
                              />
                            </Box>
                          );
                        })}
                      </Grid>
                    )}
                  </Accordion.Body>
                </Accordion>
              );
            })}
          </Accordions>
        )}
        <Flex
          sx={{
            position: 'absolute',
            bottom: 11,
            left: 0,
            px: 8,
            width: '100%',
          }}
        >
          <Button
            variant="buttons.primary"
            sx={{
              width: '100%',
            }}
            onClick={() => {
              toggleFiltersSidebar();
            }}
          >
            {selectedFiltersLength
              ? `Apply Filters (${totalResults} Results)`
              : 'Apply Filters'}
          </Button>
        </Flex>
      </Box>
    );
  }
);

FiltersSidebar.displayName = 'FiltersSidebar';
