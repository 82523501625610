import { Fragment, useMemo } from 'react';
import { Box, Flex, Heading, Grid } from 'theme-ui';

import { Markdown, Link } from '@snippets';
import { convertAlignToFlex, parseContentPosition } from '@utils/common';
import { useMatchMedia } from '@hooks';
import { space } from '@theme/theme.space';

import { CountdownTimer } from '../CountdownTimer';
import { themed } from './Slide.theme';

export const Content = themed(
  ({
    theme,
    isBelowHero,
    slide,
    isVisibleContent = true,
    isFirstSlide,
    isMobile,
    clickable,
    showContentSliderMobile,
    showContentSliderDesktop,
    ...props
  }) => {
    const isMobileViewport = useMatchMedia('(max-width: 767px)');
    const isVisibleH1 =
      (isMobile && isMobileViewport) || (!isMobile && !isMobileViewport);

    const { text, button, content } = slide;

    const textAlign = [content?.alignment, content?.alignmentDt];
    const flexAlign = textAlign.map((align) => convertAlignToFlex(align));
    const color = [
      text?.color || 'currentColor',
      text?.colorDt || 'currentColor',
    ];
    const calcWidth = content?.maxWidth
      ? [content.maxWidth + space[12] * 2, content.maxWidthDt + space[15] * 2]
      : 'none';

    // Mobile content position
    const posMb = useMemo(
      () => parseContentPosition(content?.position),
      [content?.position]
    );

    // Desktop content position
    const posDt = useMemo(
      () => parseContentPosition(content?.positionDt),
      [content?.positionDt]
    );

    return (
      <Flex
        data-comp={Content.displayName}
        sx={{
          ...theme.contentWrapper,
          alignItems: [posMb.alignItems, posDt.alignItems],
          justifyContent: [posMb.justifyContent, posDt.justifyContent],
          ...props.sx,
          visibility: isVisibleContent ? 'visible' : 'hidden',
        }}
      >
        <CountdownTimer countdown={slide?.countdown} theme={theme} />
        {!!content?.enableContent && (
          <Box
            sx={{
              ...theme.content,
              maxWidth: calcWidth,
              pt: isBelowHero ? [6, 12] : [15, 17],
              px: isBelowHero ? 0 : 10,
            }}
          >
            {text?.superheading && (
              <Heading
                as="h6"
                sx={{
                  ...theme.superheading,
                  ...(text?.hiddenHeadingForSeo ? theme.hidden : null),
                  textAlign,
                  color: 'black',
                  pointerEvents: 'auto',
                }}
              >
                {text?.superheading}
              </Heading>
            )}

            <Markdown
              text={text?.heading}
              textSx={{
                ...theme?.heading(text?.headingOption),
                ...(text?.hiddenHeadingForSeo ? theme.hidden : null),
                textAlign,
                color,
              }}
              sx={{
                mb: text?.subheading ? 6 : 0,
                pointerEvents: 'auto',
              }}
              overrides={
                !isFirstSlide || !isVisibleH1
                  ? {
                      h1: {
                        component: 'h2',
                      },
                    }
                  : null
              }
            />

            {text?.subheading && (
              <Markdown
                text={text?.subheading}
                textSx={{
                  ...theme?.subheading,
                  ...(text?.hiddenHeadingForSeo ? theme.hidden : null),
                  textAlign,
                  color,
                }}
              />
            )}

            {!button?.hideButtons && button?.buttons?.length > 0 && (
              <Grid
                sx={{
                  ...theme?.buttons,
                  justifyContent: flexAlign,
                  '@media only screen and (max-width: 479px)': {
                    alignItems: flexAlign,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gridGap: 6,
                  },
                }}
              >
                {button?.buttons?.slice(0, 2).map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <Link
                        variant={`buttons.${item.style}`}
                        href={
                          clickable && !showContentSliderMobile
                            ? null
                            : item?.cta?.url
                        }
                        newTab={item?.cta?.newTab}
                        sx={{
                          pointerEvents: 'auto',
                          display: ['block', 'none'],
                          '@media only screen and (max-width: 479px)': {
                            minWidth: button?.buttonWidth,
                          },
                        }}
                      >
                        {item?.cta?.text}
                      </Link>
                      <Link
                        variant={`buttons.${item.style}`}
                        href={
                          clickable && !showContentSliderDesktop
                            ? null
                            : item?.cta?.url
                        }
                        newTab={item?.cta?.newTab}
                        sx={{
                          pointerEvents: 'auto',
                          display: ['none', 'block'],
                          '@media only screen and (max-width: 479px)': {
                            minWidth: button?.buttonWidth,
                          },
                        }}
                      >
                        {item?.cta?.text}
                      </Link>
                    </Fragment>
                  );
                })}
              </Grid>
            )}
          </Box>
        )}
      </Flex>
    );
  }
);

Content.displayName = 'Content';
