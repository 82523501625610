import { Heading, Flex } from 'theme-ui';

import { Markdown, Link, Svg } from '@snippets';
import { convertAlignToFlex } from '@utils';

import { themed } from './Tile.theme';

export const TileContent = themed(({ theme, item, tile = {} }) => {
  const { textAlign } = tile;
  const textColor = item?.color || 'currentColor';

  return (
    <Flex
      data-comp={TileContent.displayName}
      sx={{
        ...(tile?.textPosition === 'relative'
          ? theme.contentRelative
          : theme.contentAbsolute),
        alignItems: convertAlignToFlex(textAlign),
        textAlign,
        color: textColor,
      }}
    >
      <Link href={item?.cta?.url} newTab={item?.cta?.newTab}>
        <Flex sx={theme.flexed}>
          <Heading sx={theme.heading}>{item?.heading}</Heading>
          <Svg
            src="/svgs/arrow-right.svg#arrow-right"
            alt="Arrow pointing right"
            viewBox="0 0 24 24"
            sx={theme.icon}
          />
        </Flex>
      </Link>
      <Markdown
        text={item?.description}
        textAlign={textAlign}
        textSx={{
          ...theme.body,
          color: textColor,
        }}
      />
    </Flex>
  );
});

TileContent.displayName = 'TileContent';
