import { create } from '@theme/create';

export const themed = create(
  new (function () {
    // have results
    this.hidden = {
      variant: 'flex.column',
      display: 'none',
      p: 12,
    };

    this.visible = {
      ...this.hidden,
      display: 'block',
    };

    this.heading = {
      variant: 'text.label.1',
      mb: 6,
    };

    this.term = {
      variant: 'text.base',
      cursor: 'pointer',
      mb: 5,
      '&:hover': {
        textDecoration: 'underline',
      },
    };
  })()
);
