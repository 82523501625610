import { section, color, align, button } from '@settings/common';

const defaultBestSellers = [
  {
    name: 'Can Holders',
    items: [
      {
        alt: 'A person holding a Hopsulator Slim with a can inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        title: 'Hopsulator Slim',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
        },
      },
      {
        alt: 'A tattoed person presenting a marble textured Hopsulator Duo  with a canned beverage inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorDuo.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorDuo.png',
        },
        title: 'Hopsulator Trio',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-trio-v2-0-by-brumate-walnut',
        },
      },
      {
        alt: 'A tattoed person presenting a marble textured Hopsulator Duo  with a canned beverage inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorTrio.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorTrio.png',
        },
        title: 'Hopsulator Duo',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
        },
      },
    ],
    cta: {
      url: '/collections/can-holders',
      text: 'shop all can holders',
    },
  },

  // Mugs Section
  {
    name: 'Mugs',
    items: [
      {
        alt: 'A person holding a Hopsulator Slim with a can inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        title: 'Mug Example 1',
        bulletPoints: [
          {
            icon: {
              src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/arrow.png',
              alt: 'An arrow pointing right',
            },
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
          text: 'Buy Now',
        },
        cta: true,
      },
      {
        alt: 'A person holding a Hopsulator Slim with a can inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        title: 'Mug example 2',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
        },
      },
      {
        alt: 'A person holding a Hopsulator Slim with a can inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        title: 'Mug Example 3',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
        },
      },
    ],
    cta: {
      url: '/collections/mugs',
      text: 'shop all mugs',
    },
  },

  // Water Bottles Section
  {
    name: 'Water Bottles',
    items: [
      {
        alt: 'A person holding a Hopsulator Slim with a can inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        title: 'WB Example 1',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
        },
      },
      {
        alt: 'A person holding a Hopsulator Slim with a can inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        title: 'WB Example 2',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
          {
            text: 'Works with all 12oz Slim Cans',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
        },
      },
      {
        alt: 'A person holding a Hopsulator Slim with a can inside.',
        imageDt: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/HopsulatorSlim.png',
        },
        title: 'WB Example 3',
        bulletPoints: [
          {
            text: 'Works with all 12oz Slim Cans',
          },
          {
            text: '20x Colder than other Can Coolers',
          },
        ],
        link: {
          url: '/products/hopsulator-slim-gold-leopard-12oz-slim-cans',
        },
      },
    ],
    cta: {
      url: '/collections/water-bottles',
      text: 'shop all water bottles',
    },
  },
];

export function Schema({ article, blog, collection }) {
  if (article || blog || collection) return null;

  return {
    label: 'Tabbed Three Tile',
    key: 'tabbed-three-tile',
    fields: [
      {
        name: 'tabs',
        label: 'Product tabs',
        description: 'Max 5 tabs, with 3 items each',
        component: 'group-list',
        itemProps: {
          label: `{{item.name}}: {{item.items.length}}`,
        },
        defaultItem: {
          ...(defaultBestSellers[0] || 'New Item'),
        },
        fields: [
          {
            name: 'name',
            label: 'Tab name',
            component: 'text',
          },
          {
            name: 'items',
            label: 'Items',
            component: 'group-list',
            itemProps: {
              label: '{{item.title}}' || 'New Item',
            },
            fields: [
              {
                name: 'alt',
                label: 'Image alt',
                component: 'text',
                description:
                  'Provide a brief description of the image for accessibility compliance',
              },
              {
                name: 'imageDt',
                label: 'Image (desktop)',
                description: 'Image to be displayed on desktop devices',
                component: 'image',
              },
              {
                name: 'image',
                label: 'Image (mobile)',
                description: 'Image to be displayed on mobile devices',
                component: 'image',
              },
              {
                name: 'title',
                label: 'Title',
                component: 'text',
                description: 'Title heading of the tile',
              },
              {
                name: 'bulletPoints',
                label: 'Bullet Points',
                component: 'group-list',
                description: 'Icon and short text (Max: 3)',
                itemProps: {
                  label: '{{item.text}}' || 'Empty bullet point',
                },
                defaultItem: {
                  ...(defaultBestSellers[0]?.items[0]?.bulletPoints[0] ||
                    'New BulletPoint'),
                },
                fields: [
                  {
                    name: 'text',
                    label: 'Bullet point text',
                    component: 'markdown',
                    description:
                      'Works best with 50 characters or less. **Note: highlight a section of text and click the link button in the markdown menu to hyperlink to a page or product',
                  },
                  {
                    name: 'icon',
                    label: 'Custom Icon',
                    component: 'image',
                    description:
                      'Optional: Upload a 32x32 transparent PNG. (If left blank the default icon will be used)',
                  },
                  {
                    name: 'alt',
                    label: 'Custom Icon alt',
                    component: 'text',
                    description:
                      'Provide a brief description of the custom icon for accessibility compliance. (Not used if no custom icon is selected above)',
                  },
                ],
              },
              {
                name: 'link',
                label: 'Link',
                component: 'link',
                description:
                  'Provides the title and image a link to a page or product',
              },
              {
                name: 'cta',
                label: 'Tile CTA button',
                component: 'toggle',
                description:
                  'Optional: Add a button to the bottom of this tile, using the link field above for its URL and text',
                toggleLabels: {
                  true: 'Enable',
                  false: 'Disable',
                },
                defaultValue: false,
              },
            ],
            defaultValue: {
              imageDt: {
                src: defaultBestSellers[0]?.items[0]?.imageDt.src,
              },
              image: {
                src: defaultBestSellers[0]?.items[0]?.image.src,
              },
              title: defaultBestSellers[0]?.items[0]?.title,
              bulletPoints: defaultBestSellers[0]?.items[0]?.bulletPoints,
              link: defaultBestSellers[0]?.items[0]?.link,
            },
          },
          {
            name: 'cta',
            component: 'link',
            label: 'Tab CTA button',
            description: 'Appears at the bottom of the tab',
          },
        ],
        defaultValue: defaultBestSellers,
      },

      {
        label: 'Tab & tiles Settings',
        name: 'settings',
        description: 'Heading, menu/text alignment, image ratio, button style',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
            description: 'Appears above the menu at the top of the section',
          },
          {
            name: 'textAlignDt',
            component: 'radio-group',
            label: 'Heading alignment (desktop)',
            description: 'Aligns the heading text on desktop',
            variant: 'radio',
            direction: 'horizontal',
            options: align.options,
          },
          {
            name: 'textAlign',
            component: 'radio-group',
            label: 'Heading alignment (mobile)',
            variant: 'radio',
            direction: 'horizontal',
            options: align.options,
          },
          {
            name: 'menuAlignDt',
            label: 'Tab menu alignment (desktop)',
            description: 'Heading menu alignment on desktop',
            component: 'radio-group',
            variant: 'radio',
            direction: 'horizontal',
            options: align.options,
          },
          {
            name: 'menuAlign',
            label: 'Tab menu alignment (mobile)',
            component: 'radio-group',
            variant: 'radio',
            direction: 'horizontal',
            options: align.options,
          },
          {
            name: 'imageRatio',
            label: 'Tile image aspect ratio',
            direction: 'horizontal',
            variant: 'radio',
            component: 'radio-group',
            options: [
              { label: '1:1', value: 'square' },
              { label: '3:4', value: 'portrait' },
            ],
          },
          {
            name: 'ctaStyle',
            label: 'Tab CTA button style',
            description: 'Appearance of the CTA buttons on the tile and tab',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          heading: 'Best sellers',
          textAlignDt: align.value.center,
          textAlign: align.value.left,
          menuAlignDt: align.value.center,
          menuAlign: align.value.left,
          imageRatio: 'portrait',
          ctaStyle: button.value.inverseSecondary,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.gray,
          color: color.value.text,
          desktop: {
            container: 'padded',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
