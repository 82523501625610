import { useMemo } from 'react';
import { Box, Flex } from 'theme-ui';
import { useCollection } from '@backpackjs/storefront';

import { Markdown, Breadcrumbs } from '@snippets';
import { parseContentPosition } from '@utils/common';
import { space } from '@theme/theme.space';

import { themed } from './CollectionHero.theme';

export const Content = themed(({ theme, text, content, showBreadCrumb }) => {
  const textAlign = [content?.alignment, content?.alignmentDt];
  const collection = useCollection();

  // Mobile content position
  const posMb = useMemo(
    () => parseContentPosition(content?.position),
    [content?.position]
  );

  // Desktop content position
  const posDt = useMemo(
    () => parseContentPosition(content?.positionDt),
    [content?.positionDt]
  );

  const breadJustifyContent = useMemo(() => {
    return [
      content?.alignment?.replace('left', 'start')?.replace('right', 'end'),
      content?.alignmentDt?.replace('left', 'start')?.replace('right', 'end'),
    ];
  }, [content?.alignment, content?.alignmentDt]);

  return (
    <Flex
      data-comp={Content.displayName}
      sx={{
        ...theme.contentWrapper,
        alignItems: [posMb.alignItems, posDt.alignItems],
        justifyContent: [posMb.justifyContent, posDt.justifyContent],
      }}
    >
      <Box
        sx={{
          ...theme.content,
          maxWidth: content?.maxWidth
            ? [
                content.maxWidth + space[12] * 2,
                content.maxWidth + space[15] * 2,
              ]
            : 'none',
        }}
      >
        {collection?.handle && !!showBreadCrumb && (
          <Flex
            sx={{
              mx: [-8, -10, -11, -80],
              alignItems: [posMb.alignItems, posDt.alignItems],
              justifyContent: breadJustifyContent,
              textAlign,
            }}
          >
            <Breadcrumbs
              isCollection
              collectionTitle={collection?.title || ''}
            />
          </Flex>
        )}
        <Markdown
          text={text?.heading}
          textSx={{
            ...theme.heading,
            textAlign,
          }}
          sx={{
            mb: text?.subheading ? [4, 6] : 0,
          }}
        />

        {text?.subheading && (
          <Markdown
            text={text?.subheading}
            textSx={{
              ...theme.subheading,
              textAlign,
            }}
          />
        )}
      </Box>
    </Flex>
  );
});

Content.displayName = 'Content';
