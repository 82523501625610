import { useCallback } from 'react';
import { useStartFinishErrors } from '@backpackjs/storefront';

export const useEmailSignup = () => {
  const { setStarted, setFinished, setErrors, reset, ...status } =
    useStartFinishErrors();

  const submitEmailSignup = useCallback(async (emailListId, email, source) => {
    try {
      if (!email) {
        return console.error('Missing required parameter: email');
      }

      reset();
      setStarted(true);
      const url = `/api/klaviyo`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'emailSignup',
          emailListId,
          email,
          source,
        }),
      };
      const response = await fetch(url, options);
      const data = await response.json();
      if (data?.success) {
        setFinished(true);
        return data;
      }
      setErrors(['Error with subscribing to Klaviyo.']);
      console.error(`Response back from API not successful: ${data}`);
      return null;
    } catch (error) {
      setErrors(['Error with subscribing to Klaviyo.']);
      console.error(`Error with subscribeToBackInStock: ${error}`);
    }
  }, []);

  return {
    submitEmailSignup,
    setErrors,
    reset,
    ...status,
  };
};
