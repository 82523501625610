import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'relative',
      zIndex: 31,
      minHeight: '32px',
      transition: 'max-height 0.2s ease-in-out',
    };

    this.promobarWrapper = {
      variant: 'layout.header',
      minHeight: '32px',
      maxHeight: '70px',
    };

    this.swiperWrapper = {
      alignItems: 'center',
      width: '100%',
      '.swiper-wrapper': {
        alignItems: 'center',
      },
      '.swiper-container': {
        width: '100% !important',
      },
    };

    this.swiper = {
      width: '100%',
    };

    this.message = {
      variant: 'flex.row.center',
      width: '100%',

      link: {
        py: 4,
        px: 11,
      },

      text: {
        variant: 'text.label.1',
      },
    };

    this.accessibilityBar = {
      bg: 'gray',
      variant: 'layout.header',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '20px',
    };

    this.accessibilityButton = {
      fontColor: 'text',
      fontSize: 1,
      textDecoration: 'underline',
    };
  })()
);
