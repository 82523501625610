import store, { useRecoilState } from '@store';

export const useCartStatus = () => {
  const [addingToCartStatus, setAddingToCartStatus] = useRecoilState(
    store.addingToCartStatus
  );
  const [updatingCartStatus, setUpdatingCartStatus] = useRecoilState(
    store.updatingCartStatus
  );

  return [
    {
      addingToCartStatus,
      updatingCartStatus,
    },
    {
      setAddingToCartStatus,
      setUpdatingCartStatus,
    },
  ];
};
