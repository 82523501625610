import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.link = {
      width: '100%',
    };

    this.overlay = {
      width: '100%',
      minHeight: '100%',
      bg: 'white',
      opacity: 0.5,
    };

    this.title = {
      textAlign: 'center',
      mt: 2,
      textUnderlineOffset: '2px',
      textDecorationThickness: '1px',
    };

    this.list = {
      display: ['none', 'flex'],
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 3,
      mb: 10,
    };
  })()
);
