import { isBrowser } from '@utils';
import { store } from '@backpackjs/storefront';

// update the "variant" query param on selected variant change
export const onSetUpdateVariantUrlParam =
  () =>
  ({ onSet }) => {
    onSet((selectedVariant) => {
      if (!isBrowser || !window?.location) return;
      if (!selectedVariant?.legacyResourceId) return;

      const { origin, search } = window.location;

      // update variant param
      const params = new URLSearchParams(search);
      params.set('variant', selectedVariant.legacyResourceId);

      const updatedUrl = `${origin}/products/${selectedVariant.product.handle}?${params}`;

      window.history.replaceState(window.history.state, '', updatedUrl);

      return selectedVariant;
    });
  };

export const updateLocalStorage =
  (storageKey) =>
  ({ setSelf, onSet }) => {
    if (!isBrowser) return;

    const savedValue = window.localStorage.getItem(storageKey);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        window.localStorage.removeItem(storageKey);
      } else {
        switch (storageKey) {
          case 'WISHLIST_EMAIL': {
            window.localStorage.setItem(
              storageKey,
              JSON.stringify({ email: newValue })
            );
            break;
          }
          case 'WISHLIST_ID': {
            window.localStorage.setItem(
              storageKey,
              JSON.stringify({ lid: newValue })
            );
            break;
          }
          case 'WISHLIST': {
            window.localStorage.setItem(
              storageKey,
              JSON.stringify({ items: newValue })
            );
            break;
          }
          default:
            console.log(
              'Unsupported localStorage key',
              storageKey,
              '. Skipping.'
            );
        }
      }
    });
  };

export default {
  ...store.effects,
  // more effects here
  onSetUpdateVariantUrlParam,
  updateLocalStorage,
};
