import { Box } from 'theme-ui';
import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Html.schema';
import { themed } from './Html.theme';

export const Html = withInView(
  themed(({ cms, theme }) => {
    const { content, html, section } = cms;

    return html ? (
      <Section section={section}>
        <Box
          sx={{
            ...theme.html,
            textAlign: content?.textAlign,
            display: 'flex',
            flexDirection: 'column',
            alignItems:
              content?.contentAlign === 'center'
                ? 'center'
                : content?.contentAlign === 'right'
                ? 'flex-end'
                : 'flex-start',
          }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: cms.html }}
        />
      </Section>
    ) : null;
  }),
  { triggerOnce: true }
);

Html.displayName = 'Html';
Html.Schema = Schema;
