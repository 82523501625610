import { Box } from 'theme-ui';

import { Link } from '@snippets';

import { themed } from './NestedSidebar.theme';

export const NestedSidebarFooter = themed(
  ({ theme, selectedItem, closeMenuSidebar }) => {
    const link = selectedItem?.link;
    return (
      <Box data-comp={NestedSidebarFooter.displayName} sx={theme.footer}>
        {link?.url && link?.text && (
          <Link
            href={link.url}
            newTab={link.newTab}
            onClick={closeMenuSidebar}
            sx={theme.footer.link}
          >
            Shop All {link.text}
          </Link>
        )}
      </Box>
    );
  }
);

NestedSidebarFooter.displayName = 'NestedSidebarFooter';
