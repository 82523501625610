import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      mr: 'auto',
      display: 'flex',
      alignItems: 'center',
      mt:[4, 8],
    };

    // todo: nested variants should really be button variants that modify primary
    // so that they can be used through a project and not just here
    this.button = {
      variant: 'primary',
      width: '100%',
      outline: 'none',
      whiteSpace: 'nowrap',

      available: {
        cursor: 'pointer',

        oneTime: {
          bg: 'black',
        },

        subscribe: {
          border: (t) => `1px solid ${t.colors.dark}`,
          bg: 'dark',
          '&:hover': {
            color: 'dark',
          },
        },
      },

      unavailable: {
        oneTime: {
          variant: 'buttons.secondary',
          color: 'mediumDarkGray',
          // bg: 'gray',
          // border: t => `1px solid ${t.colors.gray}`,
          // '&:hover': {
          //   bg: 'gray',
          //   color: 'background'
          // }
        },

        subscribe: {
          bg: 'gray',
          border: (t) => `1px solid ${t.colors.dark}`,
          '&:hover': {
            bg: 'gray',
            color: 'background',
          },
        },
      },
    };
  })()
);
