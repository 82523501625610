import { useState } from 'react';
import { Container, Box, Paragraph } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';

import { useSliderParams } from './useSliderParams';
import { themed } from './Slider.theme';
import { Navigation } from './Navigation';

SwiperCore.use([A11y]);

export const Slider = themed(
  ({ theme, entries, settings, activeIndex, setActiveIndex }) => {
    const defaultParams = useSliderParams();
    const [slider, setSlider] = useState();

    return (
      <Container data-comp={Slider.displayName} sx={theme.wrapper}>
        <Swiper
          onSwiper={setSlider}
          a11y={{ enabled: true }}
          {...defaultParams}
          speed={settings?.speed}
          sx={theme.swiper}
        >
          {entries.map((entry, index) => {
            if (index !== activeIndex) return null;
            if (!entry?.quote) return null;
            return (
              <SwiperSlide key={entry?.logo?.src + index}>
                <Box sx={theme.slide}>
                  <Paragraph sx={theme.quote}>{entry?.quote}</Paragraph>
                </Box>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <Navigation
          slider={slider}
          entries={entries}
          customParams={settings}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </Container>
    );
  }
);

Slider.displayName = 'Slider';
