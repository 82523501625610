import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'relative',
      width: '100%',
    };

    this.label = {
      position: 'relative',
      m: 0,
      height: '44px',
    };

    this.labelText = {
      variant: 'text.label.2',
      position: 'absolute',
      top: 2,
      left: '21px',
      zIndex: 1,
      pointerEvents: 'none',
      color: 'dark',
    };

    this.input = {
      variant: 'text.base',
      py: 0,
      px: 10,
      borderRadius: '30px',
      m: 0,
      lineHeight: '28px',
      pt: '14px',
      border: '1px solid',
      borderColor: 'mediumDarkGray',
      bg: 'background',
      '::placeholder': {
        color: 'dark',
        variant: 'text.base',
      },
    };

    this.button = {
      width: '100%',
      mt: 6,
    };
  })()
);
