import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.body = {
      width: '100%',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gridGap: 5,
      pb: 8,
      pl: 0,
    };
  })()
);
