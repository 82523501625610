import { Heading, Flex, Paragraph } from 'theme-ui';

import { convertAlignToFlex } from '@utils/common';
import { Link } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, content }) => {
  const textAlign = [content?.alignment, content?.alignmentDt];
  const flexAlign = textAlign.map((align) => convertAlignToFlex(align));

  const [, month, day, year] = new Date(
    Date.parse(content?._createdAt || content?.createdAt)
  )
    .toString()
    .split(' ');
  const date = `${month} ${day}, ${year}`;

  const excerpt = content?.excerpt;

  const slicedExcerpt =
    excerpt.length > 80
      ? `${excerpt.slice(0, excerpt.indexOf(' ', 60))}...`
      : excerpt;
  return (
    <Flex data-comp={Content.displayName} sx={theme.outer}>
      <Flex
        sx={{
          variant: 'flex.column',
          width: '100%',
          alignItems: flexAlign,
        }}
      >
        <Paragraph
          sx={{
            ...theme.date,
            textAlign,
          }}
        >
          {content?.author} | {date}
        </Paragraph>

        <Heading
          as="h4"
          sx={{
            ...theme.heading,
            textAlign,
          }}
        >
          {content?.title}
        </Heading>
        <Paragraph
          sx={{
            ...theme.excerpt,
            textAlign,
          }}
        >
          {slicedExcerpt}
        </Paragraph>

        <Link
          variant={`buttons.${content?.ctaStyle || 'articleLink'}`}
          href={`articles/${content?.handle}`}
          sx={{
            ...theme.readMore,
            alignSelf: flexAlign,
          }}
        >
          {content?.linkText || 'Read More'}
        </Link>
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
