import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Paragraph, Flex, Spinner, Box } from 'theme-ui';
import { useCartUpdatedAt, useCartUpdateItem } from '@backpackjs/storefront';

import { useCartStatus } from '@hooks';

import { QuantityButton } from './QuantityButton';
import { themed } from './Quantity.theme';

export const Quantity = themed(
  ({
    theme,
    inSidebar,
    id,
    quantity,
    cartRemoveItem,
    setIsDeleting,
    handle,
    maxQty,
    isMaxed,
    qtyInCart,
    ignoredFromMaxQty,
    ...props
  }) => {
    const { cartUpdateItem, ...updateStatus } = useCartUpdateItem({
      autoReset: 100,
    });
    const cartUpdatedAt = useCartUpdatedAt();
    const [isUpdating, setIsUpdating] = useState(false);
    const disabled = !id || isUpdating;
    const isStatic = ['byob-charge'].includes(handle);

    const [, { setUpdatingCartStatus }] = useCartStatus();
    const { started, finished, success, errors } = updateStatus;

    const handleDecrement = useCallback(async () => {
      if (disabled) return;
      const prevQuantity = quantity - 1;

      if (prevQuantity) {
        setIsUpdating(true);
        await cartUpdateItem({ lineId: id, quantity: prevQuantity });
        setIsUpdating(false);
      } else {
        setIsDeleting(true);
        await cartRemoveItem({ lineId: id });
        setIsDeleting(false);
      }
    }, [id, quantity, disabled]);

    const handleIncrement = useCallback(async () => {
      if (disabled || isMaxed) return;
      setIsUpdating(true);
      await cartUpdateItem({ lineId: id, quantity: quantity + 1 });
      setIsUpdating(false);
    }, [id, quantity, disabled, isMaxed]);

    const handleInputChange = useCallback(
      async (e) => {
        try {
          if (disabled) return;
          let typedQuantity = parseInt(e.target.value, 10);
          if (quantity === typedQuantity) return;

          if (typedQuantity) {
            setIsUpdating(true);
            if (
              qtyInCart - quantity + typedQuantity > maxQty &&
              !ignoredFromMaxQty
            ) {
              typedQuantity = maxQty - qtyInCart + quantity;
            }
            await cartUpdateItem({ lineId: id, quantity: typedQuantity });
            setIsUpdating(false);
          } else {
            setIsDeleting(true);
            await cartRemoveItem({ lineId: id });
            setIsUpdating(false);
          }
        } catch (error) {
          setIsUpdating(false);
          console.error(error.message);
        }
      },
      [id, quantity, disabled, maxQty, qtyInCart, ignoredFromMaxQty]
    );

    const setGlobalCartStatusOnUpdating = useCallback(() => {
      setUpdatingCartStatus({ started, finished, success, errors });
      return () => {
        setUpdatingCartStatus({
          started: false,
          finished: false,
          success: false,
          errors: [],
        });
      };
    }, [started, finished, success]);

    useEffect(() => {
      setGlobalCartStatusOnUpdating();
    }, [started, finished, success]);

    return !id ? (
      <Box />
    ) : (
      <Flex data-comp={Quantity.displayName} {...props} sx={theme.wrapper}>
        <QuantityButton
          type="Decrease"
          quantity={quantity}
          onClick={handleDecrement}
          disabled={disabled}
          isStatic={isStatic}
        />

        {/* changing key on update ensures re-renders on changes */}
        <Flex sx={theme.inputWrapper}>
          <Paragraph
            key={cartUpdatedAt}
            as={Input}
            type="number"
            min="-1"
            max={maxQty}
            name="quantity"
            tabIndex="-1"
            disabled={disabled || isStatic}
            defaultValue={quantity}
            onBlur={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleInputChange(e);
              }
            }}
            sx={{
              ...theme.input,
              opacity: isUpdating ? 0 : 1,
            }}
          />
          {isUpdating && <Spinner sx={theme.spinner} />}
        </Flex>

        {/* Add item */}
        <QuantityButton
          type="Increase"
          quantity={quantity}
          onClick={handleIncrement}
          disabled={disabled || isMaxed}
          isStatic={isStatic}
        />
      </Flex>
    );
  }
);

Quantity.displayName = 'Quantity';
Quantity.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
