import Head from 'next/head';
import { useEffect, useRef, useState, useCallback } from 'react';
import { Container, Flex, Grid, Button, Paragraph, Heading } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { FormField } from './FormField';
import { useForm } from './useForm';
import { themed } from './FormBuilder.theme';
import { Schema } from './FormBuilder.schema';

export const FormBuilder = withInView(
  themed(({ theme, cms }) => {
    const { endpoint, heading, fields, submitText, recaptchaEnabled, section } =
      cms;
    const formRef = useRef(null);
    const captchaRef = useRef(null);

    const [errors, setErrors] = useState([]);

    const { parsedFields } = useForm({ fields });

    const [captchaLoaded, setCaptchaLoaded] = useState(false);

    const renderCaptcha = typeof window !== 'undefined' ? window?.grecaptcha?.render : null;
    const captchaReady = typeof renderCaptcha === 'function';

    const handleSubmit = useCallback(
      async (e) => {
        try {
          setErrors([]);
          const formIsValid = formRef.current?.checkValidity();
          if (!recaptchaEnabled || !captchaLoaded || !formIsValid) return;
          e.preventDefault();
          // Check if captcha is verified if captcha was originally rendered
          const captchaResponse = await window.grecaptcha?.getResponse();
          if (!captchaResponse) {
            setErrors(['Please verify that you are not a robot']);
            return;
          }
          formRef.current.submit();
        } catch (error) {
          console.error(error.message);
        }
      },
      [recaptchaEnabled, captchaLoaded]
    );

    useEffect(() => {
      try {
        if (!captchaReady || !recaptchaEnabled) return;
        renderCaptcha('form-captcha-widget', {
          sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
        });
      } catch (error) {
        console.error(error);
      }
    }, [captchaReady, recaptchaEnabled]);

    useEffect(() => {
      if (!captchaRef.current || !recaptchaEnabled) return;
      const observer = new MutationObserver(() => {
        setCaptchaLoaded(true);
        observer.disconnect();
      });
      observer.observe(captchaRef.current, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }, [recaptchaEnabled]);

    return (
      <Section section={section}>
        <Head>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Head>

        <Container data-comp={FormBuilder.displayName}>
          {heading && <Heading sx={theme.heading}>{heading}</Heading>}
          <Grid
            as="form"
            ref={formRef}
            sx={theme.form}
            action={endpoint}
            method="POST"
            encType="multipart/form-data"
          >
            {parsedFields?.map((field, index) => (
              <FormField field={field} index={index} />
            ))}

            <Flex sx={theme.submitWrapper}>
              {recaptchaEnabled && (
                <Flex ref={captchaRef} sx={theme.recaptcha}>
                  <div id="form-captcha-widget" />
                </Flex>
              )}

              <Button
                type="submit"
                sx={{
                  ...theme.submit,
                  opacity: !endpoint ? 0.2 : 1,
                }}
                disabled={!endpoint}
                onClick={handleSubmit}
              >
                {submitText || 'Submit'}
              </Button>

              <Flex sx={theme.errors}>
                {errors?.map((error) => (
                  <Paragraph key={error} sx={theme.error}>
                    {error}
                  </Paragraph>
                ))}
              </Flex>
            </Flex>
          </Grid>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FormBuilder.displayName = 'FormBuilder';
FormBuilder.Schema = Schema;
