import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    };

    this.swiper = {
      mx: 'auto',
      width: '100%',
    };

    this.slides = {
      '.swiper-slide': {
        opacity: 0.25,
      },
      '.swiper-slide-active, .swiper-slide-next, .swiper-slide-prev': {
        opacity: 1,
      },
    };

    this.button = {
      mt: [12, 14],
    };
  })()
);
