import { useEffect } from 'react';
import { useCartItems, useSettings } from '@backpackjs/storefront';

import store, { useRecoilState } from '@store';

export const useUpsell = ({ products } = { products: [] }) => {
  const settings = useSettings();
  const upsellEnabled = settings?.cart?.upsell?.enabled;
  const cartItems = useCartItems();
  const [upsellProductsNotInCart, setUpsellProductsNotInCart] = useRecoilState(
    store.upsellProductsNotInCart
  );

  const cartItemsStr = upsellEnabled
    ? cartItems?.map((item, index) => item.id + index).join()
    : null;

  useEffect(() => {
    if (!upsellEnabled || !products?.length) return;

    let productsNotInCart;
    if (!cartItems?.length) {
      productsNotInCart = products;
    } else {
      productsNotInCart = products.filter((product) => {
        const inCart = cartItems.some((item) => {
          return item.variant.product.handle === product.handle;
        });
        return !inCart;
      });
    }
    setUpsellProductsNotInCart(productsNotInCart);
  }, [upsellEnabled, cartItemsStr, products]);

  return {
    upsellProductsNotInCart,
  };
};
