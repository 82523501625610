import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridArea: 'menu',
      display: ['none', 'grid'],
      flex: 1,
      gridTemplateColumns: `repeat(4, min(25%, 210px))`,
      columnGap: 0,
      rowGap: 10,
    };

    this.menuItem = {
      pr: 10,
    };

    this.name = {
      mb: 10,
    };

    this.list = {
      display: 'grid',
      gridGap: 5,
      pl: 0,
    };

    this.item = {
      display: 'block',
      variant: 'text.base',
      lineHeight: '20px',
    };

    this.link = {
      position: 'relative',
      display: 'inline',
      pb: 1,
      backgroundImage: 'linear-gradient(currentColor, currentColor)',
      backgroundPosition: '0% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 1px',
      transition: 'background-size 0.2s ease-in-out',
      ':hover, :focus': {
        backgroundSize: '100% 1px',
      },
    };
  })()
);
