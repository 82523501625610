import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.price = {
      flexWrap: 'wrap',
      ml: 2,
      justifyContent: 'flex-end',

      regular: {
        variant: 'text.base',
        fontSize: 1,
        textAlign: 'right',
      },

      strike: {
        variant: 'text.base',
        fontSize: 1,
        textAlign: 'right',
        textDecoration: 'line-through',
        textDecorationThickness: '2px',
        color: 'black',
        ml: 2,
      },
    };
  })()
);
