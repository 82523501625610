import { useCallback } from 'react';
import { useCartItems, useRouter, useCustomer } from '@backpackjs/storefront';

import { useSearchspring, usePasswordProtected } from '@hooks';
import store, { useRecoilState, useRecoilValue } from '@store';
import { decodedId, isBrowser } from '@utils';

export const useSearchResults = () => {
  const { requestSearchspring } = useSearchspring();
  const { hiddenProductHandles } = usePasswordProtected();
  const customer = useCustomer();
  const router = useRouter();
  const cartItems = useCartItems();
  const lastViewed =
    (isBrowser && window.localStorage.getItem('lastViewed')) || '';

  const [searchData, setSearchData] = useRecoilState(store.searchData);
  const [searchPageData, setSearchPageData] = useRecoilState(
    store.searchPageData
  );
  const currentResultsPage = useRecoilValue(store.searchCurrentResultsPage);
  const selectedSort = useRecoilValue(store.searchSelectedSort);

  const customerId = customer?.id || '';
  const cart = cartItems?.map((item) => item.variant.sku).join(',') || '';

  const { asPath } = router;

  // Get search results from query from ss
  const getSearchResults = useCallback(
    async ({ query, tag, isAutocomplete, isPage, selectedFilters }) => {
      try {
        if (isPage && !query && !tag) {
          setSearchPageData(null);
          return null;
        }

        if (!isPage && !query) {
          setSearchData(null);
          return null;
        }

        const shopper = decodedId(customerId)?.split('/').pop();

        const data = await requestSearchspring({
          action: 'getSearchResults',
          params: {
            query: query || '',
            resultsPerPage: isPage ? 60 : 10, // limit # of results shown; integer from 1-100
            page: isPage ? currentResultsPage : 1,
            isAutocomplete,
            domain: `${process.env.SITE_URL}/${asPath?.split('?')[0]}`, // full url of current page
            cart, // comma-separated list of product skus in the user's cart
            lastViewed, // comma-separated list of 5 most recent product ids or skus
            ...(isPage && !query && tag ? { tag } : null), // tag in url for landing pages
            ...(shopper ? { shopper } : null), // customer id (if logged in)
            ...(selectedSort ? { sort: selectedSort } : null), // sort order
            ...(isPage && selectedFilters
              ? { filters: selectedFilters }
              : null), // filters
          },
        });

        let _data = data;
        const banners = data?.merchandising?.content?.inline;
        if (isPage && banners?.length) {
          const resultsLength = _data.results?.length;
          const results = banners.reduce(
            (arr, item, index) => {
              const pos = item.config.position.index;
              if (resultsLength + index <= pos) return arr;
              return [
                ...arr.slice(0, pos),
                {
                  isBanner: true,
                  value: item.value,
                  id: `banner-index-${index}`,
                },
                ...arr.slice(pos),
              ];
            },
            [..._data.results]
          );
          _data = { ..._data, results };
        }

        // removes hidden products that are password protected
        if (hiddenProductHandles) {
          _data.results = [..._data.results].filter(
            (result) =>
              !hiddenProductHandles.some((handle) => handle === result.handle)
          );
        }

        if (isPage) {
          setSearchPageData(_data || null);
        } else {
          setSearchData(_data || null);
        }

        return _data || null;
      } catch (error) {
        console.error(`Error with getSearchResults: ${error.message}`);
        throw error;
      }
    },
    [customerId, asPath, cart, lastViewed, currentResultsPage, selectedSort]
  );

  return {
    results: searchData?.results || [],
    totalResults: searchData?.pagination?.totalResults || 0,
    pageResults: searchPageData?.results || [],
    totalPageResults: searchPageData?.pagination?.totalResults || 0,
    merchandising: searchData?.merchandising || null,
    getSearchResults,
    setSearchData,
    setSearchPageData,
  };
};

// Example data from api
// {
//   "pagination": {...},
//   "sorting": {
//       "options": [...]
//   },
//   "resultLayout": "grid",
//   "results": [
//       {
//           "brand": "Cuts Clothing",
//           "handle": "merino-crew-sweater-pacific-blue",
//           "id": "857a83a6620bcd84620c384e5270818c",
//           "imageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/SoldOutTemplateUPDATEDcopy_0c56ee39-683e-4e83-8090-e0a05fafd056_720x.jpg?v=1637021328",
//           "intellisuggestData": "eJwyNzIpLK5i0M0JyHI2KfQPd0nOikrONK6Kco3wYSguT00sSS0qZvANNjQwMDH0izcxMIkPZjBkMGQwYDBiSC_KTAEEAAD__423Emk",
//           "intellisuggestSignature": "a6ce27ca96e70d0dd65bf503ae25ff29f21f66fec01f9bdabe74b48553e231c2",
//           "msrp": "0",
//           "name": "Merino Crew Sweater | Pacific Blue Signature-fit Merino Wool",
//           "price": "150",
//           "product_type_unigram": "wool",
//           "sku": "MS10041N_404_S",
//           "ss_available": "1",
//           "ss_has_image": "1",
//           "ss_id": "3.965104206652e+13",
//           "ss_instock_pct": "40",
//           "ss_inventory_count": "3",
//           "ss_is_published": "1",
//           "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/SoldOutTemplateUPDATEDcopy_0c56ee39-683e-4e83-8090-e0a05fafd056_720x.jpg?v=1637021328",
//           "uid": "6798711226456",
//           "url": "https://www.cutsclothing.com/products/merino-crew-sweater-pacific-blue"
//       },
//       ...
//   ],
//   "facets": [...],
//   "breadcrumbs": [...],
//   "filterSummary": [...],
//   "merchandising": {...}
// }
