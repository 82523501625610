import { useCallback, useEffect } from 'react';

import store, { useRecoilState, useRecoilValue } from '@store';

export const useCollectionPagination = () => {
  const collection = useRecoilValue(store.collection);
  const [selectedResultsPerPage, setSelectedResultsPerPage] = useRecoilState(
    store.selectedResultsPerPage
  );
  const [currentResultsPage, setCurrentResultsPage] = useRecoilState(
    store.currentResultsPage
  );

  const { previousPage, nextPage, totalPages } = collection?.pagination || {};

  const updateResultsPerPage = useCallback((num) => {
    if (!num) {
      return console.error(
        'updateResultsPerPage: an integer from 1-100 must be passed'
      );
    }
    setSelectedResultsPerPage(num);
  }, []);

  const goToPrevPage = useCallback(() => {
    if (!previousPage) return;
    setCurrentResultsPage(previousPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [previousPage]);

  const goToNextPage = useCallback(() => {
    if (!nextPage) return;
    setCurrentResultsPage(nextPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [nextPage]);

  const goToPage = useCallback(
    (num) => {
      if (!num || num > totalPages) return;
      setCurrentResultsPage(num);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    [totalPages]
  );

  useEffect(() => {
    goToPage(1);
  }, []);

  return [
    // state
    {
      resultsPerPage: selectedResultsPerPage,
      currentPage: currentResultsPage,
      totalPages: totalPages || 1,
    },
    // actions
    {
      updateResultsPerPage,
      goToPrevPage,
      goToNextPage,
      goToPage,
    },
  ];
};
