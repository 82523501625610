import { Button, Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCart, useCartCount, useCartCheckout } from '@backpackjs/storefront';

import { useCartStatus } from '@hooks';
import { Locale } from '@snippets';

import { Prices } from './Prices';
import { Discounts } from './Discounts';
import { themed } from './Totals.theme';

export const Totals = themed(
  ({ theme: themes, inSidebar = false, inputRef, ...props }) => {
    const cart = useCart();
    const cartCount = useCartCount();
    const { cartCheckout } = useCartCheckout();
    const [{ updatingCartStatus }] = useCartStatus();

    const theme = inSidebar ? themes.sidebar : themes.page;
    const cartAmount = cart?.estimatedCost?.subtotalAmount?.amount;

    return cartCount > 0 ? (
      <Flex
        data-comp={Totals.displayName}
        {...props}
        ref={inputRef}
        sx={{
          ...props.sx,
          ...theme.wrapper,
        }}
      >
        <Paragraph sx={theme.summary}>Order Summary</Paragraph>

        {inSidebar && <Discounts />}

        {/* Checkout button */}
        <Button
          onClick={cartCheckout}
          disabled={updatingCartStatus.started}
          sx={theme.checkout}
        >
          Checkout <span className="px-1">-</span>
          <Locale.CartTotals cart={cart} key={cartAmount}>
            <Locale.Price>
              {(propsLoc) => <Prices inSidebar={inSidebar} {...propsLoc} />}
            </Locale.Price>
          </Locale.CartTotals>
        </Button>
      </Flex>
    ) : null;
  }
);

Totals.displayName = 'CartTotals';
Totals.propTypes = {
  inSidebar: PropTypes.bool,
};
