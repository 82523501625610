import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: ['none', 'block'],
      mt: '64px',
    };

    this.heading = {
      variant: 'text.h2',
      mb: '28px',
      overflowWrap: 'break-word',
    };

    this.accordionHeader = {
      heading: {
        variant: 'text.label.1',
        display: 'inline-block',
      },

      selected: {
        variant: 'text.base',
        textTransform: 'none',
        display: 'inline',
        color: 'mediumDarkGray',
        ml: 4,
      },
    };

    this.clearButton = {
      mt: 10,
    };
  })()
);
