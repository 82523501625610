import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.buttonPrev = {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      left: 8,
      right: 'auto',
      transition: 'opacity 0.2s ease-in-out',
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    this.buttonNext = {
      ...this.buttonPrev,
      left: 'auto',
      right: 8,
    };

    this.icon = {
      width: '20px',
      userSelect: 'none',
      color: 'white',
    };
  })()
);
