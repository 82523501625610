import { useCallback, useMemo } from 'react';
import { Flex, Spinner, Paragraph } from 'theme-ui';
import { useCountry, useCountries } from '@backpackjs/storefront';

import { Svg, Dropdown } from '@snippets';

import { themed } from './CountrySelector.theme';

export const CountrySelector = themed(({ theme, ...props }) => {
  const countries = useCountries();
  const { country, updateCountry, ...updateStatus } = useCountry();
  const singleCountry = countries?.length === 1;
  const haveCountries = countries?.length > 0;

  const countryOptions = useMemo(() => {
    if (!countries?.length) return null;
    const codes = ['US', 'AU', 'CA', 'FR', 'GB', 'NZ'];
    return countries.filter((country) => codes.includes(country.isoCode));
  }, [countries?.length]);

  const updateSelectedCountry = useCallback(
    async (selected) => {
      const selectedCountry = countries.find(
        (_country) => _country.isoCode === selected.isoCode
      );
      await updateCountry({
        country: selectedCountry,
      });
    },
    [countries?.length]
  );

  return singleCountry ? null : haveCountries ? ( // only one country
    <Dropdown
      variant="countries"
      label="Location"
      initial={country}
      onSelected={updateSelectedCountry}
      {...props}
      sx={{
        ...theme.dropdown,
        ...props.sx,
      }}
    >
      <Dropdown.Selected>
        {({ selecting, selected }) => (
          <Flex
            sx={{
              ...theme.item,
              bg: selecting ? 'lightGray' : 'background',
            }}
          >
            <Paragraph sx={theme.item.selectedName}>
              {selected.currency?.isoCode}
            </Paragraph>

            <Svg
              alt="Select a country"
              viewBox="0 0 16 16"
              src="/svgs/chevron/up.svg#up"
              sx={{
                ...theme.item.toggle,
                transition: 'all 0.2s ease-in-out',
                transform: selecting ? 'rotate(0deg)' : 'rotate(-180deg)',
              }}
            />
          </Flex>
        )}
      </Dropdown.Selected>

      {countryOptions.map((_country) => (
        <Dropdown.Option key={_country.name} option={_country}>
          {/* todo: theme values do not auto refresh */}
          {({ selecting, isSelected, option }) =>
            isSelected ? null : (
              <Flex
                sx={{
                  ...theme.item,
                  bg: isSelected ? 'lightGray' : 'background',
                }}
              >
                <Paragraph sx={theme.item.optionName}>
                  {option.currency?.isoCode}
                </Paragraph>
              </Flex>
            )
          }
        </Dropdown.Option>
      ))}
    </Dropdown>
  ) : (
    <Flex
      variant="flex.row.center.center"
      sx={{ width: '100%', maxWidth: '150px', height: '44px' }}
    >
      <Spinner height="20px" />
    </Flex>
  );
});

CountrySelector.displayName = 'CountrySelector';
