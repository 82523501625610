import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'background',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      display: ['none', null, 'block'],
      left: 0,
      position: 'absolute',
      top: '100%',
      width: '100%',
      zIndex: -1,
      // visibility: 'hidden',
      transition: 'all 0.2s ease-in-out',

      closed: {
        borderTopColor: 'transparent',
        boxShadow: 'none',
        opacity: 0,
        pointerEvents: 'none',
      },

      open: {
        borderTopColor: 'lightGray',
        boxShadow: 'bottom',
        // visibility: 'visible',
        opacity: 1,
        pointerEvents: 'auto',
      },
    };

    this.drawer = {
      position: 'relative',
      mx: 'auto',
      bg: 'background',
    };

    this.megaMenu = {
      variant: 'layout.header',
      py: '32px',
      maxWidth: '1440px',
      mx: 'auto',
    };

    this.grid = {
      pl: [0, null, null, '172px'],
      pr: [0, null, null, '8px'],
      gridTemplateColumns: '220px 1fr',
    };
  })()
);
