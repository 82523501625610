import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      maxWidth: '1220px',
      flexDirection: ['column', 'row-reverse'],
      alignItems: ['center', 'flex-start'],
      justifyContent: ['flex-start', 'flex-end'],
      px: [8, 0],
    };

    this.row = {
      justifyContent: ['center', 'flex-start'],
      flexWrap: 'wrap',
      mb: [2, 0],
      flex: 1,
      pl: 0,
    };

    this.text = {
      variant: 'text.base',
      fontSize: ['12px', '14px'],
      position: 'relative',
      // :before
      before: {
        position: 'absolute',
        content: '"|"',
        left: '-9px',
        top: 0,
        variant: 'text.base',
        fontSize: ['12px', '14px'],
        color: 'currentColor',
        pointerEvents: 'none',
      },
    };

    this.link = {
      textAlign: ['center', 'left'],
      position: 'relative',
      whiteSpace: 'nowrap',
      display: 'inline',
      pb: 1,
      backgroundImage: 'linear-gradient(currentColor, currentColor)',
      backgroundPosition: '0% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 1px',
      transition: 'background-size 0.2s ease-in-out',
      ':hover, :focus': {
        backgroundSize: '100% 1px',
      },
    };

    this.copyright = {
      variant: 'text.base',
      fontSize: ['12px', '14px'],
      mr: [0, 4],
    };
  })()
);
