import { Heading, Container } from 'theme-ui';

import { convertAlignToFlex } from '@utils';

import { themed } from './Header.theme';

export const Header = themed(({ theme, content }) => {
  return (
    <Container data-comp={Header.displayName} sx={theme.header}>
      <Heading variant="h2" sx={theme.heading}>
        {content?.heading}
      </Heading>
    </Container>
  );
});

Header.displayName = 'Header';
