import { useCallback, useState, useEffect } from 'react';
import { useCartItems, useCustomer } from '@backpackjs/storefront';

import { useSearchspring } from '@hooks';
import { getValidId, isBrowser } from '@utils';

// https://searchspring.zendesk.com/hc/en-us/articles/360052114611-Personalized-Recommendations-API-Request

export const useProductRecommendations = ({ tag, productId, limit }) => {
  const { requestSearchspring } = useSearchspring();
  const customer = useCustomer();
  const cartItems = useCartItems();
  const [mounted, setMounted] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [recommendations, setRecommendations] = useState(null);

  const customerId = customer?.id || '';

  const cart = cartItems?.map((item) => item.variant.sku).join(',');
  const lastViewed = isBrowser
    ? window.localStorage.getItem('lastViewed') || ''
    : '';
  // Get product recommendations from Searchspring
  const getProductRecommendations = useCallback(async () => {
    try {
      if (!mounted) return;
      if (!tag) {
        // Tag derived from product recommendation profile from Searchspring
        // E.g. 'customers-also-bought', 'customers-also-viewed', 'recently-viewed', 'home', 'view-cart'
        throw new Error('Tag is required to get product recommendations');
      }

      const shopper = getValidId(customerId);

      const params = {
        tags: tag, // required; comma-separated list of profile ids from ss
        product: productId || '', // product id or sku of product being viewed
        ...(shopper ? { shopper } : null), // customer id (if logged in)
        cart, // comma-separated list of product skus in the user's cart
        lastViewed, // comma-separated list of 5 most recent product ids or skus
        limits: limit > 20 ? 20 : limit, // max of 20
      };

      const data = await requestSearchspring({
        action: 'getRecommendations',
        params,
      });

      const formattedRecs = data?.[0]?.results?.map((item) => {
        return {
          handle: item.mappings?.core?.url?.split('/').pop() || '',
        };
      });

      setProfileData(data?.[0] || null);
      setRecommendations(formattedRecs || null);
    } catch (error) {
      console.error(`Error with getProductRecommendations: ${error.message}`);
      throw error;
    }
  }, [cart, customerId, tag, productId, limit, lastViewed, mounted]);

  // Add delay for cart
  useEffect(() => {
    setTimeout(() => setMounted(true), 300);
  }, []);

  useEffect(() => {
    getProductRecommendations();
  }, [cart, customerId, tag, productId, limit, lastViewed, mounted]);

  return {
    profileData,
    profileTag: profileData?.profile?.tag || null,
    recommendations,
    getProductRecommendations,
  };
};
