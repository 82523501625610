import { section, color, align, button } from '@settings/common';

// Pool of products used to set random default products
const products = [
  {
    product: {
      handle: 'toddy-16oz-onyx-leopard',
    },
  },
  {
    product: {
      handle: 'brumate-25oz-rehydration-bottle-onyx-leopard',
    },
  },
  {
    product: {
      handle: 'hopsulator-slim-onyx-leopard-12oz-slim-cans',
    },
  },
  {
    product: {
      handle: 'uncorkd-xl-14oz-wine-tumbler-onyx-leopard',
    },
  },
  {
    product: {
      handle: 'hopsulator-juggernaut-onyx-leopard-24-25oz-cans',
    },
  },
  {
    product: {
      handle: 'brumate-highball-12oz-onyx-leopard',
    },
  },
];

export function Schema() {
  return {
    label: 'Products Slider',
    key: 'products-slider',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, text align',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'textAlign',
            label: 'Heading alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            name: 'textAlignMobile',
            label: 'Heading alignment (mobile)',
            component: 'radio-group',
            variant: 'radio',
            direction: 'horizontal',
            options: align.options,
          },
        ],
        defaultValue: {
          heading: 'New Releases',
          textAlign: align.value.center,
          textAlignMobile: align.value.center,
        },
      },
      {
        name: 'products',
        label: 'Products',
        component: 'group-list',
        description:
          'Add a minimum of 5 products set to enable slider functionality',
        itemProps: {
          label: '{{item.product.handle}}' || 'New Product',
        },
        fields: [
          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
          },
        ],
        defaultValue: products,
      },
      {
        name: 'loop',
        label: 'Loop',
        component: 'toggle',
        variant: 'horizontal',
        description:
          'Loop products when they reach the end. Note: Requires 5 or more products to be set',
        defaultValue: true,
      },
      {
        label: 'Call To Action',
        name: 'cta',
        component: 'link',
        description: 'Optional CTA button appearing below the slider',
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
}
