import { useEffect } from 'react';
import { useProduct, useRouter } from '@backpackjs/storefront';

import store, { useSetRecoilState, useRecoilValue } from '@store';

const VERBOSE = false;

export const useInitSelectedVariant = (initProduct = null) => {
  let product = useProduct();
  if (initProduct?.handle) {
    product = initProduct;
  }
  const router = useRouter();
  const setSelectedOptions = useSetRecoilState(
    store.setSelectedOptions$(product?.handle)
  );
  const selectedVariant = useRecoilValue(
    store.selectedVariant(product?.handle)
  );

  // set initial variant
  useEffect(() => {
    if (!product) return;

    const queryKey = 'variant';
    const queryValue =
      router.query[queryKey] ||
      router.asPath.match(new RegExp(`[&?]${queryKey}=(.*)(&|$)`));

    const queriedVariantId =
      Array.isArray(queryValue) && queryValue?.length
        ? queryValue[1]
        : queryValue;

    if (queriedVariantId) {
      // if ?variant=123123123 is passed we use that as selectedVariant
      // VERBOSE && console.log('useInitSelectedVariant:queriedVariantId', queriedVariantId)
      const queriedVariant = product?.variants?.find(
        ({ legacyResourceId }) => legacyResourceId === queriedVariantId
      );

      if (!queriedVariant) {
        VERBOSE && console.log('Set initial variant to first variant');
        setSelectedOptions(product?.variants?.[0]?.selectedOptionsMap);
      } else {
        // good
        VERBOSE &&
          console.log('Set initial variant to queried variant', queriedVariant);
        setSelectedOptions(queriedVariant.selectedOptionsMap);
      }
    } else {
      const isApparel = product?.productType === 'Apparel';
      const firstAvailableVariant = isApparel
        ? product?.variants?.find(({ availableForSale }) => availableForSale)
        : null;

      if (firstAvailableVariant) {
        VERBOSE && console.log('Set initial variant to first available');
        setSelectedOptions(firstAvailableVariant.selectedOptionsMap);
      } else if (product?.variants?.length) {
        // set initial selectedVariant
        VERBOSE && console.log('Set initial variant to first variant');
        setSelectedOptions(product?.variants?.[0]?.selectedOptionsMap);
      } else {
        // Should never be get here..
        setSelectedOptions(null);
      }
    }

    return () => {
      // on unmount
      setSelectedOptions(null);
    };
  }, [product?.handle]);

  return selectedVariant;
};
