import { Picture, StatefulButton } from '@snippets';
import { Box, Button, Container, Heading, Paragraph } from 'theme-ui';
import { themed } from './GiftWithPurchaseImage.theme';

export const GiftWithPurchaseImage = themed(
  ({
    theme,
    cartHasGwp,
    gwpProducts,
    handleAddToCart,
    imageGroup,
    isLoading,
    modalText,
    openSidebar,
  }) => {
    const {
      heading = 'You qualified for a free gift with purchase!',
      body = "Code 'TREAT' will be applied at checkout",
      ctaLabel = 'Add to Bag',
      ctaSuccessLabel = 'Product Added',
      cancelLabel = 'No Thanks',
    } = { ...modalText };
    const { desktopImage, mobileImage } = { ...imageGroup };

    return (
      <Container
        data-comp={GiftWithPurchaseImage.displayName}
        sx={theme.wrapper}
      >
        <Box>
          <Picture
            sx={theme.image}
            images={[
              {
                src: mobileImage?.src || desktopImage?.src,
                ratio: 1,
              },
              {
                src: desktopImage?.src,
                ratio: 1,
              },
            ]}
          />
        </Box>
        <Box sx={theme.details}>
          <Heading variant="text.h2" sx={theme.heading}>
            {heading}
          </Heading>
          <Paragraph variant="text.h5">{body}</Paragraph>
          <Box sx={theme.buttonWrapper}>
            <StatefulButton
              started={isLoading}
              finished={cartHasGwp}
              success={cartHasGwp}
              disabled={cartHasGwp}
              text={{ success: ctaSuccessLabel, default: ctaLabel }}
              onClick={() =>
                handleAddToCart(gwpProducts?.[0]?.variants?.[0]?.id)
              }
              sx={{
                backgroundColor: cartHasGwp ? 'green' : null,
                borderColor: cartHasGwp ? 'green' : null,
              }}
            />
            {cartHasGwp ? null : (
              <Button
                variant="buttons.link.1"
                sx={theme.cancel}
                onClick={() => {
                  openSidebar('cart');
                }}
              >
                {cancelLabel}
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    );
  }
);

GiftWithPurchaseImage.displayName = 'GiftWithPurchaseImage';
