import { Heading, Flex, Paragraph, Text } from 'theme-ui';

import { Link, Svg } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, content }) => {
  if (!content) return null;
  const { heading, subtitle, icons, cta } = content;

  return (
    <Flex data-comp={Content.displayName} sx={theme.container}>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Heading
          as="h2"
          variant="text.h2"
          sx={{
            ...theme.heading,
          }}
        >
          {heading}
        </Heading>
        <Paragraph variant="text.base">{subtitle}</Paragraph>

        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            my: 80,
          }}
        >
          {icons?.map((item, index) => (
            <Flex
            key={item.id || index}
              sx={{
                flexDirection: 'column',
                px: 80,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Svg
                alt={item.text}
                src={item.icon}
                viewBox="0 0 90 100"
                sx={theme.icon}
              />
              <Text sx={theme.text}>{item.text}</Text>
            </Flex>
          ))}
        </Flex>
        <Link
          variant={`buttons.${'primary'}`}
          href={cta?.url}
          newTab={cta?.newTab}
          sx={{
            width: '40%',
          }}
        >
          {cta?.text}
        </Link>
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
