import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = (backgroundColor) => ({
      backgroundColor,
      position: 'relative',
    });

    this.main = {
      minHeight: ['75vh', '700px'],
      position: 'relative',
      flexWrap: 'wrap',
      py: 14,
      px: 10,
    };

    this.close = {
      position: 'absolute',
      top: 10,
      right: 10,
      zIndex: 1,
    };

    this.icon = (iconColor) => ({
      width: '24px',
      height: '24px',
      color: iconColor,
    });

    this.image = (position) => ({
      ...(position === 'full'
        ? { position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }
        : {
            width: ['100%', '50%'],
            mb: [14, 0],
          }),
    });

    this.content = (position) => ({
      textAlign: 'center',
      zIndex: '1',
      width: position === 'full' ? '100%' : ['100%', '50%'],
    });

    this.superheading = (superheadingColor) => ({
      color: superheadingColor,
      fontSize: [4, 7],
    });

    this.heading = (headingOption = 'text.h1', headingColor) => ({
      variant: headingOption,
      fontSize: ['64px', null, '120px'],
      fontWeight: 500,
      lineHeight: '1',
      letterSpacing: ['-0.02em', null, '-0.01em'],
      mt: [-5, null, -10],
      color: headingColor,
    });

    this.copy = (copyColor) => ({
      variant: 'text.base',
      fontSize: [4, 6],
      color: copyColor,
    });

    this.passwordInput = (passwordColor) => ({
      fontSize: ['13px', '13px'],
      textAlign: 'center',
      color: passwordColor,
      width: '236px',
      height: '35px',
      px: '35px',
      py: 0,
      borderRadius: '62px',
      '::placeholder': {
        color: passwordColor,
        textTransform: 'uppercase',
      },
      '@keyframes shake1': {
        '0%': {
          ml: 0,
        },
        '25%': {
          ml: '0.5rem',
        },
        '50%': {
          ml: '-0.5rem',
        },
        '100%': {
          ml: 0,
        },
      },
    });

    this.animationError = (errorMessageColor) => ({
      animation: 'shake1 0.2s ease-in-out 0s 2',
      color: errorMessageColor,
      '::placeholder': {
        color: errorMessageColor,
        textTransform: 'uppercase',
      },
    });

    this.passwordSubmitBtn = {
      position: 'absolute',
      top: 'calc(50% - 10px)',
      right: '13px',
    };

    this.passwordInputIcon = (passwordColor) => ({
      width: '20px',
      color: passwordColor,
    });
  })()
);
