import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.imageLink = ({ isActive, borderColor }) => ({
      position: 'relative',
      ':hover': {
        img: {
          pointerEvents: 'none',
          border: '2px solid',
          borderColor,
        },
      },
      img: {
        borderRadius: '4px',
        border: isActive ? '2px solid' : 'unset',
        borderColor,
      },
    });

    this.heading = {
      position: 'absolute',
      pointerEvents: 'none',
      width: '100%',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      p: 6,
    };
  })()
);
