import { position, sectionWithHeight, align, button } from '@settings/common';

export function Schema({ blog }) {
  if (!blog) return null;

  return {
    label: 'Featured Article',
    key: 'featured-article',
    fields: [
      {
        label: 'Article handle',
        name: 'articleHandle',
        component: 'text',
        description: 'Provide the handle of the article to feature.',
      },
      {
        label: 'Hide on mobile',
        name: 'hideMobile',
        component: 'toggle',
        description:
          'Optional: Hide the featured article on mobile. This can be useful to prevent duplicate articles',
        toggleLabels: {
          true: 'Visible',
          false: 'Hidden',
        },
        defaultValue: false,
      },
      {
        label: 'Image Settings',
        name: 'image',
        description: 'Image, position, placement, aspect ratio',
        component: 'group',
        fields: [
          {
            label: 'Placement',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationDt',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
          },
          {
            label: 'Image position',
            name: 'positionDt',
            component: 'select',
            options: position.object,
          },
          {
            label: 'Placement (mobile)',
            name: 'location',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image position (mobile)',
            options: position.object,
          },
        ],
        defaultValue: {
          positionDt: 'center center',
          locationDt: 'left',
          location: 'top',
          position: 'center center',
        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Link style, content alignment',
        component: 'group',
        fields: [
          {
            label: 'Full article link text',
            name: 'linkText',
            component: 'text',
          },
          {
            name: 'ctaStyle',
            label: 'Link Style',
            component: 'select',
            options: button.options,
          },
          {
            label: 'Content alignment (desktop)',
            name: 'alignmentDt',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content alignment (mobile)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
        defaultValue: {
          linkText: 'Read more',
          ctaStyle: 'articleLink',
          alignmentDt: align.value.left,
          alignment: align.value.left,
        },
      },
      {
        ...sectionWithHeight,
        defaultValue: {
          desktop: {
            container: 'content',
            mt: 'l',
            minHeight: 460,
          },
          mobile: {
            container: 'content',
            mt: 's',
          },
        },
      },
    ],
  };
}
