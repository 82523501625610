import { useCustomer } from '@backpackjs/storefront';
import { Heading, Paragraph } from 'theme-ui';
import { convertAlignToFlex } from '@utils/common';
import { Markdown, Link, Section } from '@snippets';
import { themed } from './LoyaltyLionPoints.theme';
import { Schema } from './LoyaltyLionPoints.schema';

export const LoyaltyLionPoints = themed(({ theme, cms }) => {
  const customer = useCustomer();
  const { heading, subHeading, body, cta, ctaStyle, section } = cms;
  const textAlign = [cms?.alignment, cms?.alignmentDt];
  const flexAlign = textAlign.map((align) => convertAlignToFlex(align));

  if (!customer?.id) return null;

  return (
    <Section data-comp={LoyaltyLionPoints.displayName} section={section}>
      <div className="flex flex-col gap-2 py-14">
        {heading && (
          <Heading
            sx={{
              ...theme.heading,
              textAlign,
            }}
            dangerouslySetInnerHTML={{
              __html: heading?.replace(
                /\[Points\]/gi,
                '<span data-lion-points></span>'
              ),
            }}
          />
        )}

        {subHeading && (
          <Paragraph
            sx={{
              ...theme.paragraph,
              textAlign,
            }}
            dangerouslySetInnerHTML={{
              __html: subHeading?.replace(
                /\[Pending_points\]/gi,
                '<span data-lion-points="pending"></span>'
              ),
            }}
          />
        )}

        {body && (
          <Markdown
            text={body}
            sx={{
              ...(heading ? theme.bodyWithHeading : theme.bodyWithoutHeading),
            }}
            textSx={{
              textAlign,
            }}
          />
        )}

        {cta?.text && (
          <div
            className="flex gap-5 md:gap-6"
            sx={{ justifyContent: flexAlign }}
          >
            {ctaStyle === 'lionRefer' ? (
              <Link
                variant={`buttons.${ctaStyle || 'secondary'}`}
                href={cta?.url}
                newTab={cta?.newTab}
                data-lion-refer
              >
                {cta?.text}
              </Link>
            ) : (
              <Link
                variant={`buttons.${ctaStyle || 'secondary'}`}
                href={cta?.url}
                newTab={cta?.newTab}
              >
                {cta?.text}
              </Link>
            )}
          </div>
        )}
      </div>
    </Section>
  );
});

LoyaltyLionPoints.displayName = 'LoyaltyLion Customer Points';
LoyaltyLionPoints.Schema = Schema;
