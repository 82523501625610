import { Link } from '@snippets';

import { useSettings } from '@backpackjs/storefront';
import { themed } from '../Menu.theme';

export const CtaRefer = themed(({ theme, ...props }) => {
  const settings = useSettings();
  const dtSettings = settings?.header?.refer?.desktop;
  if (!dtSettings?.enabled) return null;

  return (
    <Link
      {...props}
      data-comp={CtaRefer.displayName}
      href={dtSettings?.link?.url}
      newTab={dtSettings?.link?.newTab}
      aria-label={dtSettings?.link?.text}
      sx={{
        ...props.sx,
        ...theme.refer,
      }}
    >
      {dtSettings?.link?.text}
    </Link>
  );
});

CtaRefer.displayName = 'CtaRefer';
