import React from 'react';
import { DateTime } from 'luxon';
import { useCountdown } from '../../../hooks';

function DateTimeDisplay({ value, type, isDanger }) {
  return (
    <div
      className={`${
        isDanger ? 'countdown danger' : 'countdown'
      } flex gap-1 flex-col items-center`}
    >
      <span className="tracking-[1.7px]">{value}</span>
      <span className="capitalize" sx={{ fontSize: 1, lineHeight: 'normal' }}>
        {type}
      </span>
    </div>
  );
}

function ExpiredNotice() {
  return null;
}

function ShowCountDown({
  days,
  hours,
  minutes,
  seconds,
  clockColor,
  clockFontSize,
  theme,
}) {
  return (
    <div
      className="coundown-timer flex items-start justify-center gap-1 lg:pt-1"
      sx={theme?.countDown?.clock(clockColor, clockFontSize)}
    >
      <DateTimeDisplay value={days} type="Days" isDanger={days <= 3} />
      <span>:</span>
      <DateTimeDisplay value={hours} type="Hours" isDanger={false} />
      <span>:</span>
      <DateTimeDisplay value={minutes} type="Mins" isDanger={false} />
      <span>:</span>
      <DateTimeDisplay value={seconds} type="Seconds" isDanger={false} />
    </div>
  );
}

export function CountdownTimer({ countdown, theme }) {
  const {
    title,
    titleFont,
    titleFontSize,
    background,
    clockColor,
    clockFontSize,
    targetTime,
    timezone,
  } = countdown || {};
  const targetDate = targetTime;
  const targetDateZone = DateTime.fromFormat(
    `${targetDate} ${timezone || 'America/Los_Angeles'}`,
    'MM-dd-yyyy tt z'
  );
  const [days, hours, minutes, seconds] = useCountdown(targetDateZone);
  if (!targetDateZone.isValid) return null;

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  }

  return (
    <div
      className="countdown-wrapper px-6 py-9 md:pt-6 md:pb-8"
      sx={{
        fontFamily: `${titleFont == 'text.h1' ? 'Reckless Neue' : 'Fellix'}`,
        background: [background],
      }}
    >
      <div
        className="countdown__title text-center mb-3 md:mb-5"
        sx={theme?.countDown?.title(titleFont, clockColor, titleFontSize)}
      >
        {title}
      </div>
      <ShowCountDown
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        clockColor={clockColor}
        clockFontSize={clockFontSize}
        theme={theme}
      />
    </div>
  );
}
