import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      gridArea: 'collection',
      pt: 0,
      pb: [14, 18, '80px'],
    };

    this.grid = {
      display: 'grid',
      columnGap: [10, 12],
      rowGap: [14, 12],
    };

    this.noProducts = {
      variant: 'flex.column',

      text: {
        variant: 'text.lg',
        mt: 20,
        textAlign: 'center',
      },
    };

    this.banner = {
      width: '100%',
      height: ['100%', 'calc(100% - 60px)'],
      'h2,h3,h4,h5,h6': {
        variant: 'text.h4',
        mt: [10, 11],
        mb: 3,
      },
      p: {
        mt: 0,
        variant: 'text.lg',
      },
      'p:last-of-type': {
        mb: 0,
      },
    };

    this.loaderWrapper = {
      variant: 'flex.row.center.center',
      width: '100%',
      height: ['400px', '600px'],
    };

    this.loader = {
      color: 'mediumDarkGray',
    };

    this.filter = {
      content: {
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },

      title: {
        variant: 'text.label.1',
      },

      icon: {
        transition: 'transform 0.2s ease-in-out',
        width: '20px',
      },
    };
  })()
);
