import { NavigationButton } from './NavigationButton';
import { themed } from './Navigation.theme';

export const Navigation = themed(({ theme, swiper }) => {
  return (
    <>
      <NavigationButton onClick={() => swiper.slidePrev()} />

      <NavigationButton
        onClick={() => swiper.slideNext()}
        src="/svgs/chevron/right.svg#right"
        alt="Next product"
        isNext
      />
    </>
  );
});

Navigation.displayName = 'CartUpsellNavigation';
