import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.list = {
      gridTemplateColumns: '1fr',
      justifyItems: 'flex-start',
      gridGap: 3,
      px: 8,
    };

    this.item = {
      display: 'block',
      variant: 'text.lg',
    };

    this.link = {
      position: 'relative',
      display: 'inline',
      pb: 1,
      backgroundImage: 'linear-gradient(currentColor, currentColor)',
      backgroundPosition: '0% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '0% 1px',
      transition: 'background-size 0.2s ease-in-out',
      ':hover, :focus': {
        backgroundSize: '100% 1px',
      },
    };
  })()
);
