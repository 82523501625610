import { Container } from 'theme-ui';

import { useMenuSidebar } from '../useMenuSidebar';
import { NestedSidebarFooter } from './NestedSidebarFooter';
import { NestedSidebarHeader } from './NestedSidebarHeader';
import { NestedSidebarMenu } from './NestedSidebarMenu';
import { themed } from './NestedSidebar.theme';

export const NestedSidebar = themed(({ theme }) => {
  const [{ selectedItem }, { toggleNestedSidebar, closeMenuSidebar }] =
    useMenuSidebar();
  const hasFooter = !!selectedItem?.footerLink?.text;
  return (
    <Container
      data-comp={NestedSidebar.displayName}
      sx={{
        ...theme.shell,
        pb: hasFooter ? '52px' : 0,
        transform: `translateX(${selectedItem ? '0' : '100%'})`,
      }}
    >
      <Container data-comp="NestedSidebarScroll" sx={theme.scroll}>
        <NestedSidebarHeader
          selectedItem={selectedItem}
          toggleNestedSidebar={toggleNestedSidebar}
        />

        <NestedSidebarMenu
          closeMenuSidebar={closeMenuSidebar}
          {...selectedItem}
        />
      </Container>

      <NestedSidebarFooter
        selectedItem={selectedItem}
        closeMenuSidebar={closeMenuSidebar}
      />
    </Container>
  );
});

NestedSidebar.displayName = 'NestedSidebar';
