import store, {
  useRecoilCallback,
  useRecoilValue,
  useGotoRecoilSnapshot,
} from '@store';

export const useMenuSidebar = () => {
  // @refresh reset
  const gotoSnapshot = useGotoRecoilSnapshot();
  const menuSidebar = useRecoilValue(store.menuSidebar);
  const selectedItem = useRecoilValue(store.selectedItem);
  const selectedIndex = useRecoilValue(store.selectedIndex);

  // on click, close the sideBar and navigate to url
  const closeMenuSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot.map(({ set }) => {
        set(store.selectedItem, false);
        set(store.menuSidebar, false);
        set(store.overlay, false);
      });

      // update state
      gotoSnapshot(updatedState);
    } catch (error) {
      console.log('closeMenuSidebar', error.message);
      throw error;
    } finally {
      release();
    }
  });

  const toggleNestedSidebar = useRecoilCallback(
    ({ snapshot }) =>
      async (selected) => {
        const release = snapshot.retain();

        const selectors = {
          links: `[data-comp='MenuSidebarLinks']`,
          menuSidebar: `[data-comp="MenuSidebar"]`,
          products: `[data-comp='MenuSidebarProductsRow']`,
          subNavItem: `[data-comp='SubNavItem']`,
          searchInput: `[data-comp='MenuSidebarSearchInput']`,
          nestedSidebar: `[data-comp='NestedSidebar']`,
          nestedContent: `[data-comp='NestedSidebarScroll']`,
          nestedFooter: `[data-comp='NestedSidebarFooter']`,
        };

        try {
          let nextState;
          const isOpening = selected?.item;

          if (isOpening) {
            // mark item as selected

            nextState = snapshot.map(({ set }) => {
              set(store.selectedItem, selected.item);
            });

            gotoSnapshot(nextState);
          } else {
            // isClosing
            nextState = snapshot.map(({ set }) => {
              set(store.selectedItem, null);
            });

            gotoSnapshot(nextState);
          }
        } catch (error) {
          console.log('toggleNestedSidebar', error.message);
          throw error;
        } finally {
          release();
        }
      }
  );

  const toggleSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      let nextState;
      const prevMenuSidebar = snapshot.getLoadable(store.menuSidebar).contents;

      const isOpening = prevMenuSidebar === false;
      if (isOpening) {
        // open the drawer
        nextState = snapshot.map(({ set }) => {
          set(store.menuSidebar, true);
        });

        // update state
        gotoSnapshot(nextState);
      } else {
        // reset selected item
        // close sidebar
        nextState = snapshot.map(({ set }) => {
          set(store.selectedIndex, 0);
          set(store.selectedItem, null);
          set(store.menuSidebar, false);
          set(store.overlay, false);
        });

        // update state
        gotoSnapshot(nextState);
      }
    } catch (error) {
      console.log('toggleSidebar', error.message);
      throw error;
    } finally {
      release();
    }
  });

  return [
    // state
    {
      menuSidebar,
      menuSidebarNested: Boolean(selectedItem),
      selectedItem,
      selectedIndex,
    },
    // actions
    {
      toggleSidebar,
      toggleNestedSidebar,
      closeMenuSidebar,
    },
  ];
};
