import { useMemo } from 'react';

export const useSliderParams = () => {
  const defaultParams = useMemo(
    () => ({
      spaceBetween: 8,
      slidesPerView: 2.75,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 16,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
      autoHeight: true,
      navigation: false,
      loop: false,
      watchOverflow: true,
      breakpoints: {
        480: {
          spaceBetween: 16,
          slidesPerView: 2.75,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 16,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: true,
        },
        600: {
          spaceBetween: 16,
          slidesPerView: 2.75,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: true,
        },
        768: {
          spaceBetween: 16,
          slidesPerView: 6,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 6,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
        1280: {
          spaceBetween: 20,
          slidesPerView: 6,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: true,
        },
      },
    }),
    []
  );

  return defaultParams;
};
