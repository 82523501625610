import { Container, Heading } from 'theme-ui';

import { Link } from '@snippets';
import { convertAlignToFlex, pxWithFullBleed } from '@utils';

import { themed } from './Content.theme';

export const Header = themed(({ theme, header, section }) => {
  return header?.heading ? (
    <Container
      data-comp={Header.displayName}
      sx={{
        ...theme.header,
        ...pxWithFullBleed(section),
        justifyContent: [
          convertAlignToFlex(header?.textAlign),
          header?.ctas?.[0]?.cta?.text
            ? 'space-between'
            : convertAlignToFlex(header?.textAlign),
        ],
      }}
    >
      <Heading
        sx={{
          ...theme.heading,
          textAlign: header?.ctas?.[0]?.cta?.text
            ? [header?.textAlign, 'left']
            : [header?.textAlign || 'left'],
        }}
      >
        {header?.heading}
      </Heading>

      {header?.ctas?.[0]?.cta?.text && (
        <Link
          variant={`buttons.${header.ctas[0].style || 'primary'}`}
          href={header.ctas[0].cta.url}
          newTab={header.ctas[0].cta.newTab}
          sx={{
            display: ['none', 'block'],
          }}
        >
          {header.ctas[0].cta.text}
        </Link>
      )}
    </Container>
  ) : null;
});

Header.displayName = 'Header';
