export default {
  label: 'Collection Settings',
  name: 'collection',
  component: 'group',
  fields: [
    {
      label: 'Enable sort',
      name: 'sortEnabled',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      fields: [
        {
          label: 'Color Swatches',
          name: 'swatches',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          defaultItem: {
            name: 'New Color',
          },
          fields: [
            {
              label: 'Color Name',
              name: 'name',
              component: 'text',
              description:
                'Must match exactly the color name in the color filters',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              name: 'image',
              label: 'Image',
              component: 'image',
              description: 'If provided, this image will override the color',
            },
          ],
          defaultValue: [
            {
              name: 'Purple',
              color: '#9873AF',
            },
            {
              name: 'Gray',
              color: '#8C8C8D',
            },
            {
              name: 'Blue',
              color: '#7190BE',
            },
            {
              name: 'Black',
              color: '#000000',
            },
            {
              name: 'Pink',
              color: '#FFC0CB',
            },
            {
              name: 'Brown',
              color: '#6F4E37',
            },
            {
              name: 'Green',
              color: '#98D28F',
            },
            {
              name: 'Multi',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/multi-color-swatch.jpg?v=1657306616',
              },
            },
            {
              name: 'White',
              color: '#FFFFFF',
            },
            {
              name: 'Red',
              color: '#C51F1F',
            },
            {
              name: 'Gold',
              color: '#C9AA7C',
            },
            {
              name: 'Orange',
              color: '#D85720',
            },
            {
              name: 'Yellow',
              color: '#EEDF8F',
            },
          ],
        },
      ],
    },
    {
      label: 'SEO Footer',
      name: 'seo',
      component: 'group',
      fields: [
        {
          label: 'SEO Text Groups',
          name: 'groups',
          component: 'group-list',
          itemProps: {
            label: '{{item.title}}',
          },
          defaultItem: {
            title: 'New SEO Text Group',
            body: '',
          },
          fields: [
            {
              label: 'Title (internal)',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Tags (collection handles)',
              name: 'collections',
              component: 'tags',
              description:
                'Collection handles that will use this SEO text, e.g. "hopsulator-slim". Hit enter or spacebar to add to tags',
            },
            {
              label: 'Body',
              name: 'body',
              component: 'markdown',
            },
          ],
        },
      ],
    },
  ],
};
