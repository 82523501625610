import { useState } from 'react';
import { Box, Button, Container, Flex, Heading } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';
import { Navigation } from '@snippets/Cart/Upsell/Navigation';
import { ProductUpsellsListItem } from './ProductUpsellsListItem';
import { ProductUpselItem } from './ProductUpselItem';
import { themed } from './ProductUpsellsList.theme';

SwiperCore.use([A11y]);

export const ProductUpsellsList = themed(({ theme, heading, products }) => {
  const [showMore, setShowMore] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [uProducts, setUProducts] = useState([]);

  if (!products?.length) return null;

  const handles = products.reduce((_handles, { product }) => {
    if (product?.handle) _handles.push(product.handle);
    return _handles;
  }, []);

  if (!handles.length) return null;

  return (
    <Container data-comp={ProductUpsellsList.displayName} sx={theme.wrapper}>
      {/* Header (from settings) */}
      <Heading variant="text.label" sx={theme.heading}>
        {heading}
      </Heading>

      {handles.map((handle, index) => (
        <ProductUpselItem
          key={`us-item-${handle}-${index}`}
          handle={handle}
          uProducts={uProducts}
          setUProducts={setUProducts}
        />
      ))}

      {!!uProducts?.length && (
        <>
          {/* Mobile Carousel */}
          <Box sx={theme.carousel}>
            <Swiper
              onSwiper={setSwiper}
              spaceBetween={0}
              slidesPerView={1}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              a11y={{ enabled: true }}
              loop={products?.length > 1}
            >
              {(uProducts || []).map((product) => {
                return (
                  <SwiperSlide key={product?.handle}>
                    <ProductUpsellsListItem
                      handle={product?.handle}
                      fullProduct={product}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {uProducts?.length > 1 && <Navigation swiper={swiper} />}
          </Box>

          {/* Desktop Expandable List */}
          <Flex sx={theme.items}>
            {(uProducts || []).map((product, index) => {
              if (!showMore && index > 1) return null;
              return (
                <ProductUpsellsListItem
                  key={product?.handle}
                  handle={product?.handle}
                  fullProduct={product}
                  zIndex={uProducts.length - index}
                />
              );
            })}
            {uProducts?.length > 2 ? (
              <Button
                sx={theme.expand}
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {showMore ? 'See Less' : 'See More'}
              </Button>
            ) : null}
          </Flex>
        </>
      )}
    </Container>
  );
});

ProductUpsellsList.displayName = 'ProductUpsellsList';
