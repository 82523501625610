import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
    };

    this.header = {
      minHeight: ['35px', '45px', '60px'],
    };

    this.slider = {
      minHeight: '300px',
      position: 'relative',
    };

    this.spinner = {
      width: '30px',
      color: 'mediumDarkGray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  })()
);
