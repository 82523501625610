import { Box, Heading, Flex } from 'theme-ui';

import { Accordions, Accordion, FilterOption } from '@snippets';

import { useSearchFilters } from '../useSearchFilters';
import { CurrentFilters } from '../CurrentFilters';
import { themed } from './DesktopFilters.theme';

export const DesktopFilters = themed(({ theme, merchandising }) => {
  const [{ filters }, { addToSelectedFilters, removeFromSelectedFilters }] =
    useSearchFilters();

  const left = merchandising?.content?.left?.[0];

  return (
    <Box data-comp={DesktopFilters.displayName} sx={theme.wrapper}>
      {filters?.length > 0 && (
        <Accordions
          textColor="text"
          borderColor="gray"
          iconColor="text"
          minHeight="52px"
          maxHeight="800px"
          oneOpenAtATime={false}
        >
          {filters.map((filter) => {
            return (
              <Accordion key={filter.label} collapsed={!!filter.collapse}>
                <Accordion.Header>
                  <Flex variant="flex.row.start.center">
                    <Heading as="h5" sx={theme.accordionHeader.heading}>
                      {filter.label}
                    </Heading>
                  </Flex>
                </Accordion.Header>

                <Accordion.Body>
                  {({ isOpen }) =>
                    filter.values?.map((option) => {
                      return (
                        <Box key={option.label} as="li">
                          <FilterOption
                            option={option}
                            field={filter.field}
                            tabIndex={isOpen ? '0' : '-1'}
                            aria-hidden={isOpen ? 'false' : 'true'}
                            addToSelectedFilters={addToSelectedFilters}
                            removeFromSelectedFilters={
                              removeFromSelectedFilters
                            }
                          />
                        </Box>
                      );
                    })
                  }
                </Accordion.Body>
              </Accordion>
            );
          })}
        </Accordions>
      )}

      <CurrentFilters />

      {left && (
        <Box
          dangerouslySetInnerHTML={{
            __html: left,
          }}
          sx={{ mt: 12, display: ['none', 'block'] }}
        />
      )}
    </Box>
  );
});

DesktopFilters.displayName = 'DesktopFilters';
