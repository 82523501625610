import Head from 'next/head';

import { marked } from 'marked';

export const FAQPageSeo = ({ accordions }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'FAQPage',
            mainEntity: accordions.map((item) => {
              if (!item.body || !item.heading) return null;
              return {
                '@type': 'Question',
                name: item.heading,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: marked.parse(item.body),
                },
              };
            }),
          }),
        }}
      />
    </Head>
  );
};
