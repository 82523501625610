// menu
export default {
  label: 'Account Menu Settings',
  name: 'menu',
  component: 'group',
  fields: [
    {
      name: 'items',
      label: 'Menu items',
      component: 'group-list',
      itemProps: {
        label: '{{item.text}}',
      },
      fields: [
        {
          name: 'text',
          label: 'Text',
          component: 'text',
        },
        {
          name: 'url',
          label: 'Url',
          component: 'text',
        },
        {
          name: 'newTab',
          label: 'Open In New Tab',
          component: 'toggle',
          toggleLabels: {
            true: 'Yes',
            false: 'No',
          },
          defaultValue: false,
        },
      ],
    },
    {
      label: 'Sign Out',
      name: 'withSignOut',
      component: 'toggle',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: true,
    },
  ],
  defaultValue: {
    items: [
      {
        text: 'Order History',
        handle: 'orders',
      },
      {
        text: 'Edit Profile',
        handle: 'edit-profile',
      },
      {
        text: 'Address Book',
        handle: 'address-book',
      },
      {
        text: 'Payment Methods',
        handle: 'payment-methods',
      },
      {
        text: 'Favorites',
        handle: 'favorites',
      },
    ],
    withSignOut: true,
  },
};
