import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.accordions = {
      borderTop: '1px solid',
      display: 'grid',
      gridGap: 0,
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      width: '100%',
    };

    this.accordion = {
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
      transition: 'max-height .5s ease-in-out',
      position: 'relative',
      borderBottom: '1px solid',
    };
  })()
);
