import { useState, useRef, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSettings } from '@backpackjs/storefront';

import store, { useRecoilState } from '@store';

import { useSetPromoBarHeightCssVar } from './useSetPromoBarHeightCssVar';

export const usePromobar = () => {
  const swiperRef = useRef(false);
  const { ref: promoBarRef, inView, entry } = useInView();

  const settings = useSettings();
  const { header } = { ...settings };
  const { promoBar } = { ...header };

  const haveMessages = promoBar?.messages?.length;
  const isSingleMessage = haveMessages === 1;

  const [promobarClosed, setPromobarClosed] = useRecoilState(
    store.promobarClosed
  ); // closed when clicked X
  const [swiper, setSwiper] = useState();
  // const [closed, setClosed] = useState(false); // closed when clicked X
  const [activeIndex, setActiveIndex] = useState(0);

  const updateActiveIndex = useCallback((swiper) => {
    setActiveIndex(swiper.realIndex);
  }, []);

  const animateClose = useCallback(() => {
    setPromobarClosed(true);
  }, []);

  useSetPromoBarHeightCssVar(inView, entry);

  return [
    {
      // refs
      swiperRef,
      promoBarRef,

      // state
      swiper,

      isDisabled: !haveMessages || !promoBar?.enabled,
      closed: promobarClosed,
      isSingleMessage,

      // site settings
      promoBar,

      currentColor: promoBar?.messages?.[activeIndex]?.color || 'white',
      bgColor: promoBar?.messages?.[activeIndex]?.background || 'dark',
    },
    {
      animateClose,
      updateActiveIndex,
      setSwiper,
    },
  ];
};
