export default {
  component: 'group',
  name: 'gwp',
  label: 'Gift With Purchase Settings',
  fields: [
    {
      component: 'toggle',
      name: 'enabled',
      label: 'Enable GWP',
      toggleLabels: {
        true: 'Enable',
        false: 'Disable',
      },
      defaultValue: false,
    },
    {
      component: 'number',
      name: 'threshold',
      label: 'Cart Threshold',
      defaultValue: 100,
    },
    {
      component: 'text',
      name: 'code',
      label: 'Discount Code',
      defaultValue: 'TREAT',
    },
    {
      component: 'group',
      name: 'modalText',
      label: 'Modal Text Settings',
      fields: [
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
          defaultValue: 'You qualified for a free gift with purchase!',
        },
        {
          component: 'text',
          name: 'body',
          label: 'Body text',
          defaultValue: "Code 'TREAT' will be applied at checkout.",
        },
        {
          component: 'text',
          name: 'ctaLabel',
          label: 'Add to Bag Label',
          defaultValue: 'Add to Bag',
        },
        {
          component: 'text',
          name: 'ctaSuccessLabel',
          label: 'Add to Bag Success Label',
          defaultValue: 'Product Added',
        },
        {
          component: 'text',
          name: 'cancelLabel',
          label: 'Cancel Label',
          defaultValue: 'No Thanks',
        },
      ],
    },
    {
      component: 'group',
      name: 'cart',
      label: 'Cart Settings',
      fields: [
        {
          component: 'markdown',
          name: 'link',
          label: 'Reopen Message',
          defaultValue: 'You qualified for a free gift. Select your gift.',
        },
        {
          component: 'markdown',
          name: 'link_success',
          label: 'Gift Added to cart Message',
          defaultValue: 'Free gift added to cart',
        },
      ],
    },
    {
      component: 'group',
      name: 'products',
      label: 'Products Settings',
      fields: [
        {
          label: 'Product(s) Display Type',
          name: 'displayType',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            { value: 'image', label: 'Image (1 product)' },
            { value: 'slider', label: 'Product Slider (1-3 products)' },
          ],
        },
        {
          component: 'group',
          name: 'imageGroup',
          label: 'Image Settings',
          fields: [
            {
              component: 'productSearch',
              name: 'giftProduct',
              label: 'Gift Product',
              description:
                'Ensure product is not draft, otherwise it will be ignored',
            },
            {
              component: 'image',
              name: 'desktopImage',
              label: 'Desktop Image',
            },
            {
              component: 'image',
              name: 'mobileImage',
              label: 'Mobile Image',
            },
          ],
        },
        {
          component: 'group',
          name: 'sliderGroup',
          label: 'Products Slider Settings',
          fields: [
            {
              component: 'group-list',
              name: 'giftProducts',
              label: 'Gift Products',
              description:
                'Add up to 3 products. Ensure these are not draft products, otherwise they will be ignored',
              itemProps: {
                label: '{{item.giftProduct.handle}}' || 'New Product',
              },
              fields: [
                {
                  component: 'text',
                  name: 'heading',
                  label: 'Heading',
                },
                {
                  component: 'text',
                  name: 'copy',
                  label: 'Copy',
                },
                {
                  component: 'productSearch',
                  name: 'giftProduct',
                  label: 'Gift Product',
                },
              ],
            },
          ],
        },
        {
          name: 'excludedProducts',
          label: 'Excluded Products',
          component: 'group-list',
          description: 'Products excluded from cart threshold',
          itemProps: {
            label: '{{item.product.handle}}' || 'New Product',
          },
          fields: [
            {
              name: 'product',
              component: 'productSearch',
              label: 'Product',
            },
          ],
        },
      ],
    },
  ],
};
