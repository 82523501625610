import { useEffect } from 'react';
import { isBrowser } from '@utils';
import { useRouter } from 'next/router';

const requestGeolocation = async () => {
  try {
    const url = '/api/geolocation';
    const response = await fetch(url);
    if (response.ok) {
      return await response.json();
    }
    return {};
  } catch (err) {
    throw new Error(err);
  }
};

export const useGeolocation = () => {
  const router = useRouter();

  useEffect(() => {
    if (!isBrowser) return;
    const getGeo = async () => {
      try {
        const data = await requestGeolocation();
        const countryCode = data?.['x-country'];

        if (countryCode) {
          if (countryCode === 'JP') {
            router.replace('https://www.brumate.jp/');
          }
        }
      } catch (error) {
        console.log('useGeolocation:error', error.message);
      }
    };
    getGeo();
  }, []);
};
