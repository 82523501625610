import { useState, useMemo } from 'react';
import { Box, Button, Flex, Heading, Input } from 'theme-ui';
import { Svg } from '@snippets';
import {
  useCart,
  useCartAddDiscount,
  useCartRemoveDiscount,
  useCartRemoveItem,
} from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';
import { useCartRejectDiscount } from '@snippets/Cart';

import { themed } from './Discounts.theme';

export const Discounts = themed(({ theme }) => {
  const cart = useCart();
  const { cartAddDiscount } = useCartAddDiscount();
  const { cartRemoveDiscount } = useCartRemoveDiscount();
  const { cartRemoveItem } = useCartRemoveItem({
    autoReset: 100,
  });

  const { showDiscountInput } = useCartRejectDiscount();

  const cartDiscountAmount = useRecoilValue(store.cartDiscountAmount);
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const discountCode = showDiscountInput
    ? cart?.discountCodes?.[0]?.applicable && cart?.discountCodes?.[0]?.code
    : '';

  const linesDiscountAmount = useMemo(() => {
    if (!cart?.lines?.length) return 0;
    return cart?.lines?.reduce((acc, line) => {
      const allocation = line?.discountAllocations?.[0];
      const amount = Number(allocation?.discountedAmount?.amount) || 0;
      return acc + amount;
    }, 0);
  }, [cart?.lines]);

  // 10off6d8b0b0b70d5 - $10
  // 5OFFf4dad56e2c22 - $5
  // BM0PVZ8HV6M4 - 30% off only brus
  // 3MANRH3XMJ0B

  const handleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    try {
      setCode('');
      const updatedCart = await cartAddDiscount({ discountCode: code });
      const discount = updatedCart?.discountCodes?.[0];
      if (discount?.applicable) {
        if (discount?.code?.toLowerCase()?.startsWith('app')) {
          setMessage('This coupon is valid for mobile app only.');
        } else {
          setCode('');
          setMessage('');
        }
      } else {
        setCode('');
        cartRemoveDiscount();
        setMessage('This code is invalid');
      }
    } catch (error) {
      console.log(error);
      setMessage('Uh something went wrong');
    }
    setLoading(false);
  };

  const loyaltyItems = useMemo(() => {
    if (!cart?.discountCodes?.[0]?.applicable) {
      return [];
    }
    if (!cart?.lines?.length) return [];
    return (cart?.lines || [])?.reduce((acc, item) => {
      if (
        item?.attributes?.find(
          (attr) => attr?.key === '_source' && attr?.value === 'll free product'
        ) &&
        item?.attributes?.find(
          (attr) =>
            attr?.key === '_discountCode' &&
            attr?.value === cart?.discountCodes?.[0]?.code
        )
      ) {
        return [...acc, item?.id];
      }
      return acc;
    }, []);
  }, [cart?.id, cart?.lines, cart?.discountCodes]);

  const removeDiscount = async () => {
    if (loyaltyItems?.length) {
      loyaltyItems?.forEach(async (item) => {
        await cartRemoveItem({ lineId: item });
        await cartRemoveDiscount();
      });
    } else {
      await cartRemoveDiscount();
    }
  };

  return (
    <Box data-comp={Discounts.displayName} mx={-10}>
      <Box mb={5} py="9px" px={10} bg="#F1F1F1">
        {!discountCode && (
          <Heading
            sx={{
              cursor: 'pointer',
              fontSize: '12px',
              fontWeight: 500,
              display: 'flex',
            }}
            as="h3"
            onClick={() => setIsOpen(!isOpen)}
          >
            Add Discount Code
            <Svg
              alt="chevron"
              viewBox="0 0 16 16"
              src="/svgs/chevron/down.svg#down"
              sx={{
                ml: 5,
                width: '14px',
                transition: 'all 250ms',
                transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
              }}
            />
          </Heading>
        )}
        {discountCode && (
          <Flex
            sx={{ alignItems: 'center', fontSize: '12px', fontWeight: 500 }}
          >
            <Box className="pr-2">Discount Code</Box>
            <Box
              sx={{
                bg: '#000',
                color: '#fff',
                display: 'grid',
                padding: '4px 8px',
                borderRadius: '5px',
                gridTemplateColumns: 'auto 1fr',
              }}
            >
              <Svg
                alt="close"
                viewBox="0 0 48 48"
                src="/svgs/close.svg#close"
                sx={{
                  mr: 2,
                  mt: 0,
                  width: '14px',
                  cursor: 'pointer',
                }}
                onClick={removeDiscount}
              />
              {discountCode}
            </Box>
            {cartDiscountAmount > 0 && (
              <Box sx={{ ml: 'auto', color: 'redSalePrice' }}>
                $-
                {parseFloat(cartDiscountAmount)?.toFixed(2)?.replace('.00', '')}
              </Box>
            )}
            {cartDiscountAmount <= 0 && linesDiscountAmount > 0 && (
              <Box sx={{ ml: 'auto', color: 'redSalePrice' }}>
                $-
                {parseFloat(linesDiscountAmount)
                  ?.toFixed(2)
                  ?.replace('.00', '')}
              </Box>
            )}
          </Flex>
        )}
        {isOpen && (!discountCode || message) && (
          <Box mt={4} mb={'3px'}>
            {!discountCode && (
              <Box sx={{ position: 'relative' }}>
                <Input
                  name="code"
                  placeholder="Code"
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                  sx={{
                    width: '100%',
                    height: '32px',
                    borderRadius: '50px',
                    py: 0,
                    fontSize: '12px',
                    bg: '#FFFFFF',
                  }}
                />

                <Button
                  sx={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    background: '#222222',
                    textAlign: 'center',
                    borderRadius: '60px',
                    color: 'white',
                    height: '32px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    minWidth: ['102px', '160px'],
                    fontSize: '12px',
                  }}
                  onClick={handleClick}
                >
                  {loading ? 'Loading' : 'Apply'}
                </Button>
              </Box>
            )}

            {message && (
              <Box
                sx={{
                  mt: 3,
                  textAlign: 'center',
                  ml: 3,
                  color: message?.includes('Success') ? '#222222' : '#AE2807',
                }}
              >
                {message}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
});

Discounts.displayName = 'Discounts';
