import { useState, useEffect } from 'react';
import { requestOkendo } from './requestOkendo';

export const useOkendoStars = ({ productId }) => {
  const [stars, setStars] = useState(null);

  useEffect(() => {
    if (!productId) return;

    const paramsAndData = {
      action: 'getProductReviewAggregate',
      params: {
        productId,
      },
    };

    requestOkendo(paramsAndData).then((res) => {
      const data = res.data?.reviewAggregate;
      if (!data) return;
      const count = data?.reviewCount;
      const totalStars = data?.reviewRatingValuesTotal;
      setStars({
        totalReviews: count,
        averageScore: totalStars / count,
      });
    });
  }, [productId]);

  return {
    stars,
  };
};
