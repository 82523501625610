import Head from 'next/head';

export function CollectionHead({ collection = {}, pageUrl }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Insulated Can Coolers, Tumblers and Drinkware",
                    "item": "${process.env.SITE_URL}/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "${collection.title}",
                    "item": "${pageUrl}"
                  }
                ]
              }`,
        }}
      />
    </Head>
  );
}

CollectionHead.displayName = 'CollectionHead';
