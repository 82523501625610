import Script from 'next/script';
import Head from 'next/head';

export function SiteReviewsHead() {
  return (
    <>
      <Head>
        <link
          href="https://d3hw6dc1ow8pp2.cloudfront.net/styles/main.min.css"
          rel="stylesheet"
          type="text/css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://dov7r31oq5dkj.cloudfront.net/64fdab67-9789-4d89-bfa4-42a4cef5e5d8/widget-style-customisations.css?v=f0633da3-c6f5-4b6d-9610-b39db21cde11"
        />
      </Head>
      <Script
        type="text/javascript"
        strategy="afterInteractive"
        id="oke-reviews-script"
        dangerouslySetInnerHTML={{
          __html: `
                (function () {
                  function asyncLoad() {
                      var urls = ["https:\/\/d3hw6dc1ow8pp2.cloudfront.net\/reviewsWidget.min.js?shop=kryo-gear.myshopify.com"];
                      for (var i = 0; i < urls.length; i++) {
                          var s = document.createElement("script");
                          s.type = "text/javascript";
                          s.async = true;
                          s.src = urls[i];
                          var x = document.getElementsByTagName("script")[0];
                          x.parentNode.insertBefore(s, x);
                      }
                  }
                  asyncLoad();
              })();
            `,
        }}
      />
      <Script
        type="text/javascript"
        strategy="afterInteractive"
        id="oke-reviews-widget"
        dangerouslySetInnerHTML={{
          __html: `
            window.okeReviewsWidgetOnInit = function (okendoInitApi) {
              window.okendoInitApi = okendoInitApi;
            }
            `,
        }}
      />
      <Script
        type="application/json"
        strategy="afterInteractive"
        id="oke-reviews-settings"
        dangerouslySetInnerHTML={{
          __html: `
              {
                "disableInitialReviewsFetchOnProducts":false,
                "filtersEnabled": false,
                "initialReviewDisplayCount":5,
                "locale":"en",
                "omitMicrodata": true,
                "reviewSortOrder":"rating",
                "subscriberId": "64fdab67-9789-4d89-bfa4-42a4cef5e5d8",
                "widgetTemplateId": "default",
                "recorderPlus":true
              }
            `,
        }}
      />
    </>
  );
}
