import { Box, Heading, Button } from 'theme-ui';
import { Svg } from '@snippets';
import { useFiltersSidebar } from '@hooks';
import { CurrentFilters } from '../CurrentFilters';
import { themed } from './MobileFilters.theme';

export const MobileFilters = themed(({ theme }) => {
  const [, { toggleFiltersSidebar }] = useFiltersSidebar();

  return (
    <Box data-comp={MobileFilters.displayName} sx={theme.wrapper}>
      <Button
        variant="buttons.plain"
        onClick={() =>
          toggleFiltersSidebar({
            label: 'Filter & Sort',
            isFiltersAndSort: true,
            isSearchPage: true,
          })
        }
        sx={theme.button}
      >
        <Heading as="h5" sx={theme.title}>
          Filter & Sort
        </Heading>

        <Svg
          viewBox="0 0 24 24"
          src="/svgs/arrow-right.svg#arrow-right"
          sx={theme.icon}
        />
      </Button>

      <CurrentFilters />
    </Box>
  );
});

MobileFilters.displayName = 'MobileFilters';
