import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSettings, useStartFinishErrors } from '@backpackjs/storefront';

import store, { useRecoilState } from '@store';

const DEBUG = false;

const getExpirationDate = () => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 1000 * 604800; // 7 days
  now.setTime(expireTime);
  return now;
};

export const usePasswordProtected = () => {
  const { asPath } = useRouter();
  const settings = useSettings();
  const { gatedPages } = { ...settings?.gatedPages } || {};

  const [hasAccess, setHasAccess] = useRecoilState(store.hasAccess);
  const [hasCookie, setHasCookie] = useState(false);
  const [gatedPage, setGatedPage] = useRecoilState(store.gatedPage);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { setStarted, setFinished, setErrors, reset, ...status } =
    useStartFinishErrors();

  // gatedPage needs to be store in global state
  const getGatedPage = useCallback(() => {
    const _gatedPage =
      !!gatedPages?.length &&
      gatedPages?.find(({ enable, page }) => {
        const { pages } = { ...page };
        if (!enable) return false;

        const pageAndProductUrls =
          pages?.reduce((carry, _page) => {
            const productHandles = _page?.products
              ?.map(({ product }) => `/products/${product?.handle}`)
              ?.filter(Boolean);

            return [
              ...carry,
              ...(productHandles || []),
              _page?.pageUrl || null,
            ]?.filter(Boolean);
          }, []) || [];

        return pageAndProductUrls?.some((url) => url === asPath?.split('?')[0]);
      });
    if (_gatedPage) {
      setGatedPage(_gatedPage);
    } else {
      setGatedPage(null);
    }
  }, [asPath, gatedPages]);

  // creates an array of product handles used as reference to hide from search results
  const hiddenProductHandles = useMemo(() => {
    return (
      !!gatedPages?.length &&
      gatedPages.reduce((carry, { enable, page }) => {
        if (enable) {
          const { pages } = { ...page };
          const _products = pages?.reduce((_carry, _page) => {
            return [
              ..._carry,
              ...(_page?.products
                ?.map(({ product }) => product?.handle)
                ?.filter(Boolean) || []),
            ];
          }, []);
          return [...carry, ...(_products || [])];
        }
        return carry;
      }, [])
    );
  }, [gatedPages]);

  const requiredPassword = useMemo(() => {
    return gatedPage?.password?.password;
  }, [gatedPage]);

  const submitPassword = useCallback(
    (passwordInput) => {
      if (
        passwordInput &&
        requiredPassword?.toLowerCase() === passwordInput?.toLowerCase()
      ) {
        let gatedPagePasswords = null;

        document.cookie?.split(/\s*;\s*/).find((cookie) => {
          const [key, value] = cookie.split(/\s*=\s*/);
          if (key !== 'gated_page_passwords') return false;
          gatedPagePasswords = value;
          return true;
        });

        DEBUG && console.log('password entered was successful...');

        const passwords = gatedPagePasswords
          ? JSON.parse(gatedPagePasswords)
          : [];

        if (!gatedPagePasswords) {
          // if cookie doesn't exist, create one with password
          document.cookie = `gated_page_passwords=${JSON.stringify([
            requiredPassword?.toLowerCase(),
          ])};expires=${getExpirationDate()?.toUTCString()}`;
          DEBUG && console.log('creating cookie with saved password...');
        } else if (
          !passwords.some(
            (password) =>
              password?.toLowerCase() === requiredPassword?.toLowerCase()
          )
        ) {
          // if cookie already exists, add additional password if cookie doesn't contain password
          passwords.push(requiredPassword);
          document.cookie = `gated_page_passwords=${JSON.stringify(
            passwords
          )};expires=${getExpirationDate()?.toUTCString()}`;
          DEBUG && console.log('updating cookie with saved passwords...');
        }

        setHasCookie(true);
      } else {
        setShowErrorMessage(true);
      }
    },
    [requiredPassword]
  );

  useEffect(() => {
    reset();
    setStarted(true);
    getGatedPage();
  }, [asPath, gatedPages]);

  useEffect(() => {
    const checkCookie = () => {
      let gatedPagePasswords = null;

      document.cookie?.split(/\s*;\s*/).find((cookie) => {
        const [key, value] = cookie.split(/\s*=\s*/);
        if (key !== 'gated_page_passwords') return false;
        gatedPagePasswords = value;
        return true;
      });

      const passwords = gatedPagePasswords
        ? JSON.parse(gatedPagePasswords)
        : [];

      DEBUG && console.log('passwords:', passwords);

      if (
        passwords.some(
          (password) =>
            password?.toLowerCase() === requiredPassword?.toLowerCase()
        )
      ) {
        DEBUG && console.log('has cookie with password');
        setHasCookie(true);
      } else {
        DEBUG && console.log('does not have cookie with password');
        setHasCookie(false);
      }
    };
    checkCookie();
  }, [requiredPassword]);

  useEffect(() => {
    if (!gatedPage || (hasCookie && !!gatedPage) || !gatedPage?.show) {
      setHasAccess(true);
    } else {
      setHasAccess(false);
    }
    setFinished(true);
  }, [hasCookie, gatedPage]);

  return {
    hasAccess, // always has access if page isn't gated OR page is gated AND has cookie
    hiddenProductHandles,
    gatedPage,
    setShowErrorMessage,
    showErrorMessage,
    submitPassword,
    ...status,
  };
};
