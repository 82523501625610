import {
  convertAlignToFlex,
  parseContentPosition,
  pxWithFullBleed,
  calcResponsiveHeight,
} from './common';
import { formatLocalePrice } from './formatLocalePrice';
import { isBrowser } from './isBrowser';
import { loading } from './loading';
import { shopifySrcFilter } from './shopifySrcFilter';
import { withInView } from './withInView';
import {
  getValidId,
  getValidIdNoKey,
  getEncodedId,
  isEncoded,
} from './getValidId';
import { decodedId } from './decodedId';
import { fetchProductFromHandle, fetchProductsFromHandles } from './product';

export {
  calcResponsiveHeight,
  convertAlignToFlex,
  formatLocalePrice,
  getValidId,
  getValidIdNoKey,
  getEncodedId,
  isBrowser,
  isEncoded,
  loading,
  parseContentPosition,
  pxWithFullBleed,
  shopifySrcFilter,
  withInView,
  decodedId,
  fetchProductFromHandle,
  fetchProductsFromHandles,
};
