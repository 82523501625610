import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.outer = {
      width: '100%',
      justifyContent: 'center',
      alignItems: ['flex-start', 'center'],
      px: 12,
      py: [18, 16],
      '@media only screen and (max-width: 479px)': {
        py: 15,
      },
    };

    this.heading = {
      variant: 'text.h2',
      fontSize: [11, 13],
    };

    this.bodyWithoutHeading = {
      variant: 'text.base',
      fontSize: ['13px', 6], // [13px, 18px]
      lineHeight: ['normal', '24px'],
      mt: 0,
      ul: {
        ml: 0,
      },
      'p:last-of-type': {
        mb: 0,
      },
    };

    this.bodyWithHeading = {
      ...this.bodyWithoutHeading,
    };
  })()
);
