import { useEffect } from 'react';
import { Section } from '@snippets';
import { Schema } from './Aftership.schema';

export function Aftership({ cms }) {
  const { section } = cms;

  useEffect(() => {
    const iframe = document.getElementById('aftership-iframe');
    const addIframeScrollHeight = (event) => {
      if (
        event.data &&
        event.data.type === 'resize' &&
        typeof event.data.scrollHeight === 'number' &&
        iframe
      ) {
        iframe.style.height = `${event.data.scrollHeight}px`;
      }
    };
    window.addEventListener('message', addIframeScrollHeight);
    return () => window.removeEventListener('message', addIframeScrollHeight);
  }, []);

  return (
    <Section data-comp={Aftership.displayName} section={section}>
      <iframe
        id="aftership-iframe"
        title="aftership"
        src="https://brumate.aftership.com/iframe?page-name=tracking-page"
        scrolling="no"
        frameBorder="0"
        style={{ width: '100%' }}
      />
    </Section>
  );
}

Aftership.displayName = 'Aftership';
Aftership.Schema = Schema;
