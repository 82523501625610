const defaultProps = {
  firstOf: [],
  anyOf: [],
  sections: [],
};

export const usePickedSections = (passedProps) => {
  const filteredFirstOfs = [];
  let filteredAnyOfs = [];
  const props = { ...defaultProps, ...passedProps };
  const sections = Array.isArray(props.sections) ? props.sections : [];
  const firstOf = Array.isArray(props.firstOf) ? props.firstOf : [];
  const anyOf = Array.isArray(props.anyOf) ? props.anyOf : [];

  const firstOfSections = firstOf?.length
    ? firstOf.reduce((_firstOf, key) => {
        const matchingKey = (section) => section?.props?.['data-comp'] === key;
        const firstOfKeyIndex = sections.findIndex(matchingKey);

        // track filtered
        filteredFirstOfs.push(firstOfKeyIndex);

        _firstOf[key] = sections[firstOfKeyIndex];
        return _firstOf;
      }, {})
    : null;

  const anyOfSections = anyOf?.length
    ? anyOf.reduce((_anyOf, key) => {
        const matchingKey = (section) => section?.props?.['data-comp'] === key;
        const matchingIndexes = sections.reduce((_indexes, section, index) => {
          const matchesKey = section?.props?.['data-comp'] === key;
          if (matchesKey) {
            _indexes = [..._indexes, index];
          }
          return _indexes;
        }, []);

        // track anyOfs
        filteredAnyOfs = [...new Set([...filteredAnyOfs, ...matchingIndexes])];

        _anyOf[key] = sections.filter(matchingKey);
        return _anyOf;
      }, {})
    : [];

  const pickedIndexes = [...new Set([...filteredFirstOfs, ...filteredAnyOfs])];

  const unpickedSections = sections.filter(
    (_, index) => !pickedIndexes.includes(index)
  );

  return {
    firstOf: firstOfSections,
    anyOf: anyOfSections,
    otherSections: unpickedSections,
  };
};
