import { color } from '@settings/common';

export function Schema() {
  return {
    label: 'Accordions',
    key: 'accordions',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'Accordions Heading',
      },
      {
        name: 'accordionsGroup',
        label: 'Accordions Group',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'Accordions Group Heading',
          accordions: [
            {
              heading: 'Accordion Heading',
              headingBgColor: '#F1F2F4',
              headingTextColor: '#000000',
              body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem.',
            },
          ],
        },
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
            defaultValue: 'Accordions Group Heading',
          },
          {
            name: 'accordions',
            label: 'Accordions',
            component: 'group-list',
            itemProps: {
              label: '{{item.heading}}',
            },
            defaultItem: {
              heading: 'Accordion Heading',
              headingBgColor: '#F1F2F4',
              headingTextColor: '#000000',
              body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem.',

            },
            fields: [
              {
                name: 'heading',
                label: 'Heading',
                component: 'text',
                defaultValue: 'Accordion Heading',
              },
              {
                name: 'headingBgColor',
                label: 'Heading Background Color',
                component: 'select',
                options: color.options,
                defaultValue: '#F1F2F4',
              },
              {
                name: 'headingTextColor',
                label: 'Heading Text Color',
                component: 'select',
                component: 'select',
                options: color.options,
                defaultValue: '#000000',
              },
              {
                name: 'body',
                label: 'Body',
                component: 'markdown',
                defaultValue: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem.',
              },
            ],
            defaultValue: [
              {
                heading: 'Accordion Heading 1',
                headingBgColor: '#F1F2F4',
                headingTextColor: '#000000',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem.',
              },

            ],

          },
        ],
        defaultValue: [
          {
            heading: 'Accordions Group Heading 1',
            accordions: [
              {
                heading: 'Accordion Heading 1',
                headingBgColor: '#F1F2F4',
                headingTextColor: '#000000',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem.',
              },
            ],
          },
        ],
      },
    ],
    defaultValue: {
      heading: 'Accordions Heading',
      accordionsGroup: [
        {
          heading: 'Accordions Group Heading 1',
          accordions: [
            {
              heading: 'Accordion Heading 1',
              headingBgColor: '#F1F2F4',
              headingTextColor: '#000000',
              body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem. Sed euismod, nisl vel tincidunt aliquam, nunc nisl aliquet nisl, eget aliquam nisl nisl eget lorem.',
            },
          ],
        },
      ],
    },
  };
}
