import { Container, Spinner, Box } from 'theme-ui';
import { useEffect, useRef, useState } from 'react';

import { isBrowser } from '@utils';
import { useSettings, useProductByHandle } from '@backpackjs/storefront';

import { useSelectedVariant } from '@hooks';

import { ProductReviewsHead } from './ProductReviewsHead';
import { Header } from './Header';
import { themed } from './ProductReviews.theme';

export const ProductReviews = themed(({ theme }) => {
  const reviewsRef = useRef(null);
  const [selectedVariant] = useSelectedVariant();
  const settings = useSettings();

  const { product } = useProductByHandle({
    handle: selectedVariant?.product?.handle,
  });

  const [okendoReady, setOkendoReady] = useState(false);

  const text = settings?.product?.reviews;
  const productId = product?.legacyResourceId;
  const setProduct = isBrowser && window.okeWidgetApi?.setProduct;

  useEffect(() => {
    setOkendoReady(typeof setProduct === 'function');
  }, [setProduct]);

  useEffect(() => {
    if (!okendoReady) return;
    setProduct(reviewsRef.current, `shopify-${productId || ''}`);
  }, [okendoReady, productId]);

  return (
    <>
      <ProductReviewsHead />

      <Container data-comp={ProductReviews.displayName} sx={theme.container}>
        {/* Header */}
        <Header text={text} />

        {/* Widget */}
        <div
          ref={reviewsRef}
          data-oke-widget
          data-oke-reviews-product-id={`shopify-${productId || ''}`}
        />

        {!okendoReady && (
          <Box sx={theme.spinnerWrapper}>
            <Spinner sx={theme.spinner} />
          </Box>
        )}
      </Container>
    </>
  );
});

ProductReviews.displayName = 'ProductReviews';
