import { Flex, Grid } from 'theme-ui';
import { useEffect, useRef } from 'react';

import { useCustomer } from '@backpackjs/storefront';
import store, { useRecoilState } from '@store';
import { Link } from '@snippets';

import { themed } from './Links.theme';
import { useMenuSidebar } from '../useMenuSidebar';

export const Links = themed(({ theme, links }) => {
  const [{}, { closeMenuSidebar }] = useMenuSidebar();
  const [loyaltylionLoaded] = useRecoilState(store.loyaltylionLoaded);
  const customer = useCustomer();
  const llPointsRef = useRef(null);

  useEffect(() => {
    if (
      customer &&
      typeof window.loyaltylion !== 'undefined' &&
      typeof window.loyaltylion.ui !== 'undefined' &&
      llPointsRef?.current &&
      loyaltylionLoaded
    ) {
      window.loyaltylion.ui.refresh();
    }
  }, [customer, llPointsRef, loyaltylionLoaded]);

  return (
    <Grid data-comp={Links.displayName} as="ul" sx={theme.list}>
      {links?.map((link, index) => (
        <Flex
          key={index}
          as="li"
          sx={theme.item}
          className={`${
            customer && link.link.url.indexOf('/account') !== -1
              ? '!flex gap-3 items-center'
              : ''
          }`}
        >
          <Link
            href={link.link.url}
            newTab={link.link.newTab}
            onClick={closeMenuSidebar}
            sx={theme.link}
          >
            {link.link.text}
          </Link>
          {customer && link.link.url.indexOf('/account') !== -1 && (
            <Link
              href="/pages/rewards"
              onClick={closeMenuSidebar}
              sx={theme.link}
              className={`${
                link.link.url.indexOf('/account') !== -1
                  ? '!hidden max-lg:!flex items-center'
                  : ''
              }`}
            >
              <div className="lg:hidden text-[13px] md:text-[15px] leading-[15px]">
                <span className="font-bold">Hi {customer?.firstName}</span>, <span data-lion-points ref={llPointsRef} /> Points
              </div>
            </Link>
          )}
        </Flex>
      ))}
    </Grid>
  );
});

Links.displayName = 'MenuSidebarLinks';
