import { Box, Heading } from 'theme-ui';

import { Markdown } from '@snippets';

import { themed } from './PasswordProtectedModal.theme';

export const PasswordProtectedContent = themed(({ text, theme }) => {
  const {
    copy,
    copyColor,
    heading,
    headingColor,
    headingOption,
    superheading,
    superheadingColor,
  } = { ...text };
  return (
    <Box data-comp={PasswordProtectedContent.displayName}>
      {superheading && (
        <Heading sx={theme.superheading(superheadingColor)}>
          {superheading}
        </Heading>
      )}
      <Markdown
        text={heading}
        textSx={{
          ...theme.heading(headingOption, headingColor),
        }}
        sx={{
          mb: copy ? 10 : 0,
        }}
      />
      {copy && <Markdown text={copy} textSx={theme.copy(copyColor)} />}
    </Box>
  );
});

PasswordProtectedContent.displayName = 'PasswordProtectedContent';
