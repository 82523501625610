import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useCJCookies = () => {
  const { query } = useRouter();
  const cjEvent = query.cjevent;

  useEffect(() => {
    if (!cjEvent) return;
    const expiration = new Date(Date.now() + 86400000 * 390).toGMTString();
    document.cookie = `brumate_cje=${cjEvent};expires=${expiration};domain=.brumate.com;path=/;SameSite=None;Secure`;
  }, [cjEvent]);
};
