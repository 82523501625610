const label = {
  fontSize: 3, // 15px
  lineHeight: '20px',
  fontWeight: 500,
  fontFamily: 'body',
};

const link = {
  fontSize: 1, // 12px
  lineHeight: 15,
  fontWeight: 500,
  fontFamily: 'body',
  position: 'relative',
  overflow: 'hidden',
  ':after': {
    content: '""',
    position: 'absolute',
    top: 'calc(100% - 1px)',
    left: 0,
    width: '100%',
    height: '1px',
    bg: 'currentColor',
    transition: 'transform 0.4s ease-in-out',
  },
  ':before': {
    content: '""',
    position: 'absolute',
    top: 'calc(100% - 1px)',
    left: '-200%',
    width: '100%',
    height: '1px',
    bg: 'currentColor',
    transition: 'transform 0.4s ease-in-out',
  },
  ':hover:after': {
    transform: 'translateX(200%)',
  },
  ':hover:before': {
    transform: 'translateX(200%)',
  },
};

export const text = {
  // HEADINGS
  h1: {
    fontFamily: 'Reckless Neue',
    fontSize: [10, '66px'], // [36px, 66px]
    lineHeight: ['40px', '70px'],
    fontWeight: 300,
    letterSpacing: '-0.05em',
    2: {
      fontFamily: 'heading', // Fellix
      fontSize: ['33px', '63px'], // [33px, 63px]
      lineHeight: ['40px', '67px'],
      fontWeight: 400,
      letterSpacing: '-0.05em',
    },
  },
  h2: {
    variant: 'text.h1',
    fontFamily: 'Reckless Neue',
    fontSize: ['34px', 11], // [34px, 40px]
    lineHeight: ['38px', '43px'],
    letterSpacing: '-0.03em',
  },
  h3: {
    variant: 'text.h1.2',
    fontSize: ['26px', '34px'], // [26px, 34px]
    lineHeight: ['32px', '38px'],
    letterSpacing: '-0.03em',
  },
  h4: {
    variant: 'text.h1.2',
    fontSize: ['22px', '28px'], // [22px, 28px]
    lineHeight: ['28px', '32px'],
    letterSpacing: ['-0.05em', '-0.01em'],
    fontWeight: 500,
  },
  h5: {
    variant: 'text.h1.2',
    fontSize: [3, 6], // [15px, 18px]
    lineHeight: '22px',
    fontWeight: 500,
  },
  h6: {
    variant: 'text.h1.2',
    fontSize: [2, 4], // [14px, 16px]
    lineHeight: '18px',
    fontWeight: 500,
  },

  // BODY
  sm: {
    variant: 'text.base',
    fontSize: [0, 1], // [11px, 12px]
    lineHeight: '16px',
  },
  base: {
    fontFamily: 'body',
    fontSize: ['13px', 3], // [13px, 15px]
    lineHeight: '19px',
  },
  lg: {
    variant: 'text.base',
    fontSize: [3, 4], // [15px, 16px]
    lineHeight: '20px',
  },

  // LABELS, LINKS, OTHERS
  label: {
    ...label,
    1: label, // 15px
    2: {
      ...label,
      fontSize: [1, 1], // 12px
    },
  },

  link: {
    ...link,
    1: link, // 12px
    2: {
      ...link,
      fontSize: [1, 2], // [12, 14px]
      lineHeight: [13, 18],
    },
  },

  buttonText: {
    fontFamily: 'body',
    fontSize: 3, // 15px
    lineHeight: 20,
    fontWeight: 500,
  },

  buttonTextSecondary: {
    variant: 'buttonText',
    fontSize: 5, // 17px
    lineHeight: 22,
    fontWeight: 500,
  },

  // NAVIGATION
  navItem: {
    fontSize: 3, // 14px
    lineHeight: 18,
    fontWeight: 500,
    fontFamily: 'body',
    cursor: 'pointer',
  },
  navSubItem: {
    fontSize: 6, // 18px
    lineHeight: 22,
    fontWeight: 400,
    cursor: 'pointer',
    fontFamily: 'body',
  },
  searchHeading: {
    variant: 'text.navItem',
    position: 'sticky',
    top: ['-32px', '56px'],
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'lightGray',
    zIndex: 2,
    pt: 8,
    pb: 4,
    mb: 5,
    bg: 'background',
  },

  // SECTION SPECIFIC
  promoQuote: {
    variant: 'text.h3',
    fontSize: ['32px', 12], // 32px, 48px
    lineHeight: [38, '56px'],
    textTransform: 'normal',
  },
  reviewsHeading: {
    variant: 'text.h2',
    fontWeight: 500,
    fontSize: [10, 11], // [36px, 40px]
  },
  articleTitle: {
    variant: 'text.h4',
  },
};
