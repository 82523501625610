import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignContent: 'center',
      minHeight: [115, 220],
      '.swiper-slide-active': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
    };

    this.swiper = {
      width: '100%',
      textAlign: 'center',
    };

    this.slide = {
      display: 'flex',
      minHeight: [115, 170],
      flexDirection: 'column',
      justifyContent: 'center',
    };

    this.quote = {
      variant: 'text.promoQuote',
      width: ['auto', 750],
      px: [7, 0],
      ':before': {
        content: 'open-quote',
      },
      ':after': {
        content: 'close-quote',
      },
    };
  })()
);
