import { useEffect, useCallback, useState, useMemo } from 'react';
import { Box, Button, Paragraph } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartItems, useCart, useSettings } from '@backpackjs/storefront';

import { GiftWithPurchase, Markdown } from '@snippets';
import store, { useRecoilState, useRecoilValue } from '@store';
import { useGiftWithPurchase, useModal } from '@hooks';
import { decodedId } from '@utils';
import { useCartDiscountPrices } from '../useCartDiscountPrices';

import { useByobLineItems } from './useByobLineItems';
import { LineItem, LoyaltyLionItem } from '../LineItem';
import { themed } from './LineItems.theme';

export const LineItems = themed(
  ({ theme: themes, inSidebar = false, ...props }) => {
    const gwpProducts = useRecoilValue(store.gwpProducts);
    const settings = useSettings();
    const cart = useCart();
    const cartItems = useCartItems({ sortByAddedAt: true });
    const { updateOrAddByobChargeItem, byobDependency } = useByobLineItems({
      cart,
    });
    useCartDiscountPrices();
    const [, { openModal }] = useModal();
    const gwp = useGiftWithPurchase();

    const cartHasGwp = useMemo(() => {
      if (!gwpProducts) return false;
      return cartItems?.some((item) =>
        gwpProducts?.some(
          (product) =>
            decodedId(product?.variants?.[0]?.storefrontId) ===
              decodedId(item.variant?.id) &&
            parseInt(item.estimatedCost.subtotalAmount.amount, 10) === 0
        )
      );
    }, [cart?.updatedAt, gwpProducts?.map((p) => p.handle).join('')]);

    const hasLoyaltyItem = useMemo(() => {
      if (!cart?.lines?.length) return [];
      return (cart?.lines || [])?.reduce((acc, item) => {
        if (
          item?.attributes?.find(
            (attr) =>
              attr?.key === '_source' && attr?.value === 'll free product'
          ) &&
          item?.attributes?.find(
            (attr) =>
              attr?.key === '_discountCode' &&
              attr?.value === cart?.discountCodes?.[0]?.code
          ) &&
          parseInt(item.estimatedCost.subtotalAmount.amount, 10) === 0
        ) {
          return [...acc, item?.id];
        }
        return acc;
      }, []);
    }, [cart?.id, cart?.lines, cart?.discountCodes]);

    const chargeItems = cart?.lines?.reduce((acc, lineItem) => {
      const jtbAttr = (lineItem?.attributes || []).find(
        (attr) =>
          attr?.key === 'recipe_id' && attr?.value?.indexOf('uc-') !== -1
      );
      if (jtbAttr) {
        const attrValue = jtbAttr?.value?.replace('uc-', '');
        return { ...acc, [attrValue]: lineItem };
      }
      return acc;
    }, {});

    const [isMaxed, setIsMaxed] = useRecoilState(store.cartIsMaxed);
    const [qtyInCart, setQtyInCart] = useState(0);

    const theme = inSidebar ? themes.sidebar : themes.page;

    const {
      maxQty,
      itemMessage = 'Please contact bulkorders@brumate.com for orders over 150.',
    } = settings?.cart?.bulkOrdering || {};

    useEffect(() => {
      try {
        if (!cart?.id) return;
        document.cookie = `pp_widget_cart_id=${cart.id}`;
        document.cookie = `pp_widget_cart_id=${cart.id};domain=.brumate.com;path=/`;
      } catch (error) {
        console.error(error);
      }
    }, [cart?.id]);

    useEffect(() => {
      updateOrAddByobChargeItem();
    }, [byobDependency]);

    const checkIfCartIsMaxedOnCartUpdate = useCallback(() => {
      if (!maxQty) return false;
      const totalProductsInCart =
        cartItems?.reduce((total, { variant, quantity: itemQty }) => {
          const ignoredHandles = [
            'byob-charge',
            'parcel-protection',
            'brumate-e-gift-card',
          ];
          if (ignoredHandles.includes(variant.product.handle)) {
            return total;
          }
          return total + itemQty;
        }, 0) || 0;
      setQtyInCart(totalProductsInCart);
      setIsMaxed(totalProductsInCart >= maxQty);
      return false;
    }, [maxQty, cart?.updatedAt]);

    const handleGiftWithPurchase = () => {
      openModal(<GiftWithPurchase />, true);
    };

    useEffect(() => {
      checkIfCartIsMaxedOnCartUpdate();
    }, [maxQty, cart?.updatedAt]);

    const showQualifiedGiftMessage = gwp.isEligible(cart) && !cartHasGwp;

    return (
      <Box sx={{ position: 'relative' }}>
        {isMaxed && itemMessage && (
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              bg: 'background',
              zIndex: 1,
              px: 12,
              py: 5,
              borderBottom: (t) => `1px solid ${t.colors.gray}`,
            }}
          >
            <Markdown
              text={itemMessage}
              sx={{ a: { textDecoration: 'none' } }}
              textSx={{
                variant: 'text.label.2',
                color: 'red',
                fontSize: 0,
                textAlign: 'center',
              }}
            />
          </Box>
        )}
        {showQualifiedGiftMessage && (
          <Box
            sx={{
              px: 12,
              py: 3,
              textAlign: 'center',
            }}
          >
            <Button
              variant="text.label.2"
              sx={{ bg: 'transparent', color: 'text', cursor: 'pointer' }}
              onClick={handleGiftWithPurchase}
            >
              <Markdown
                text={
                  settings?.gwp?.cart?.link ||
                  'You qualified for a free gift. Select your gift.'
                }
                textSx={{
                  variant: 'text.label.2',
                }}
              />
            </Button>
          </Box>
        )}
        {cartHasGwp && (
          <Box
            sx={{
              px: 12,
              py: 3,
              textAlign: 'center',
            }}
          >
            <Paragraph variant="text.label.2">
              <Markdown
                text={
                  settings?.gwp?.cart?.link_success || 'Free gift added to cart'
                }
                textSx={{
                  variant: 'text.label.2',
                }}
              />
            </Paragraph>
          </Box>
        )}
        {!!hasLoyaltyItem?.length && (
          <Box
            sx={{
              px: 12,
              py: 3,
              textAlign: 'center',
            }}
          >
            <Markdown
              text="<p>Free product added to cart</p>"
              textSx={{
                variant: 'text.label.2',
              }}
            />
          </Box>
        )}
        <Box
          as="ul"
          key={LineItems.displayName}
          data-comp={LineItems.displayName}
          {...props}
          sx={{
            ...props.sx,
            ...theme,
          }}
        >
          {cartItems.map((item, index) => {
            const isLoyaltyItem = item?.attributes?.find(
              (attr) =>
                attr?.key === '_source' && attr?.value === 'll free product'
            );
            const CartLine = isLoyaltyItem ? LoyaltyLionItem : LineItem;
            return (
              <li key={`${item.id}-${index}`}>
                <CartLine
                  item={item}
                  isLast={index === cartItems.length - 1}
                  inSidebar={inSidebar}
                  cart={cart}
                  isMaxed={isMaxed}
                  qtyInCart={qtyInCart}
                  maxQty={maxQty}
                  chargeItems={chargeItems}
                />
              </li>
            );
          })}
        </Box>
      </Box>
    );
  }
);

LineItems.displayName = 'LineItems';
LineItems.propTypes = {
  inSidebar: PropTypes.bool,
};
