import { useMemo } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import { Paragraph, Box } from 'theme-ui';

import { useSidebar } from '@hooks';
import { Link, Picture, Badge } from '@snippets';

import { themed } from './ColorsSidebar.theme';

export const ColorsSidebarItem = themed(({ theme, handle, selectedColor }) => {
  const { product } = useProductByHandle({ handle });
  const [, { closeSidebar }] = useSidebar();

  const color = useMemo(() => {
    if (!product) return null;
    return product?.tags
      ?.find((tag) => tag.match(new RegExp('color::', 'gi')))
      ?.split('::')[1]
      ?.trim();
  }, [product?.id]);

  const firstImage = useMemo(() => {
    return product?.media?.find((item) => {
      return item.mediaContentType === 'IMAGE';
    })?.image;
  }, [product?.id]);

  const isSelected = color === selectedColor;
  const selectText = isSelected ? 'Selected' : 'Select';

  return (
    <Box data-comp={ColorsSidebarItem.displayName} as="li" key={handle}>
      <Link
        href={`/products/${handle}`}
        onClick={(e) => {
          if (isSelected) {
            e.preventDefault();
          } else {
            closeSidebar();
          }
        }}
        sx={{
          ...theme.option,
          ...(isSelected
            ? theme.option.available.selected
            : theme.option.available.unselected),
          cursor: isSelected ? 'default' : 'pointer',
        }}
      >
        <Picture
          alt={color}
          images={[
            {
              src: firstImage?.originalSrc,
              ratio: 1,
              width: 200,
            },
          ]}
        >
          <Badge handle={handle} product={product} />
        </Picture>

        <Paragraph sx={theme.color}>{color}</Paragraph>

        <Box
          variant={isSelected ? 'buttons.primary' : 'buttons.secondary'}
          sx={{
            ':hover': isSelected
              ? {
                  bg: 'text',
                  color: 'white',
                }
              : {},
          }}
        >
          {selectText}
        </Box>
      </Link>
    </Box>
  );
});

ColorsSidebarItem.displayName = 'ColorsSidebarItem';
