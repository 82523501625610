import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slide = {
      height: '100%',
      position: 'relative',
      width: '100%',
    };

    this.image = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    this.contentWrapper = {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      pointerEvents: 'none',
      position: 'relative',
      width: '100%',
    };

    this.content = {
      px: 0,
      pt: [6, 17],
      mb: [14, 16],
      pointerEvents: 'auto',
    };

    this.superheading = {
      variant: 'text.label.1',
    };

    this.heading = (headingOption = 'text.h1') => ({
      variant: headingOption,
      m: 0,
    });

    this.subheading = {
      variant: 'text.lg',
    };

    this.countDown = {
      title: (titleFont = 'text.h1', color = 'colors.black', fontSize = 'text.h1') => ({
        variant: fontSize,
        fontFamily: 'inherit',
        color: [color],
        letterSpacing: [0],
        fontWeight: [400],
      }),
      clock: (color = 'colors.black', fontSize = 'text.h2') => ({
        variant: fontSize,
        fontFamily: 'inherit',
        color: [color],
        letterSpacing: [0],
        fontWeight: [400],
      }),
    };

    this.buttons = {
      mt: [8, 11],
      gridAutoFlow: 'column',
      gridGap: 9,
      '@media only screen and (max-width: 479px)': {
        // gridAutoFlow: 'row',
      },
    };

    this.hidden = {
      opacity: 0,
      pointerEvents: 'none',
    };
  })()
);
