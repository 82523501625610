import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      overflow: 'hidden',
    };
    this.header = {
    };
  })()
);
