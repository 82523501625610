import { Svg, Link } from '@snippets';

import { themed } from './Menu.theme';

export const Logo = themed(({ theme }) => {
  return (
    <Link href="/" aria-label="Go to Brumate homepage">
      <Svg
        role="presentation"
        viewBox="0 0 1588 375"
        src="/svgs/brumate-logo.svg#brumate-logo"
        alt="Brumate logo"
        sx={theme.logo}
      />
    </Link>
  );
});
