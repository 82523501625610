import { Box, Heading, Flex } from 'theme-ui';

import { Markdown, Link, Picture, Svg } from '@snippets';
import { convertAlignToFlex } from '@utils';

import { themed } from './Tile.theme';

export const Tile = themed(
  ({ theme, item, settings = {}, inputRef, ...props }) => {
    const { imageRatio, textAlign, ctaStyle } = settings;

    const defaultIcon = {
      src: '/svgs/circle-checkmark.svg#circle-checkmark',
      alt: 'checkmark',
    };

    return (
      <Box data-comp={Tile.displayName} {...props}>
        <Link
          tabIndex="-1"
          aria-hidden="true"
          href={item?.link?.url}
          newTab={item?.link?.newTab}
          sx={item?.link?.url ? theme.imageLink : theme.image}
        >
          <Picture
            alt={item?.alt}
            images={[
              {
                src: item?.image?.src,
                width: 250,
                ratio: imageRatio === 'square' ? 1 : 0.75,
              },
              {
                src: item?.imageDt?.src,
                width: 410,
                ratio: imageRatio === 'square' ? 1 : 0.75,
              },
            ]}
          />
        </Link>
        <Flex
          sx={{
            ...theme.content,
            alignItems: convertAlignToFlex(textAlign),
            textAlign,
          }}
        >
          <Link href={item?.link?.url} newTab={item?.link?.newTab}>
            <Flex sx={theme.flexed}>
              <Heading as="h3" sx={theme.heading}>
                {item?.title}
              </Heading>
              <Svg
                src="/svgs/arrow-right.svg#arrow-right"
                alt="Arrow pointing right"
                viewBox="0 0 24 24"
                sx={theme.icon}
              />
            </Flex>
          </Link>
          {item?.bulletPoints &&
            item?.bulletPoints.slice(0, 3).map((bulletpoint, index) => {
              const isCustomIcon = bulletpoint?.icon?.src
                ? bulletpoint.icon.src
                : defaultIcon.src;
              return (
                <Flex key={index} sx={theme.bulletpoint}>
                  <Svg
                    src={isCustomIcon}
                    alt={bulletpoint?.icon?.alt || defaultIcon?.alt}
                    viewBox="0 0 32 32"
                    sx={theme.bulletpoint.icon}
                  />
                  <Markdown
                    text={bulletpoint?.text}
                    textSx={{
                      ...theme.body,
                      textAlign,
                    }}
                  />
                </Flex>
              );
            })}
          {item?.cta && (
            <Link
              href={item?.link?.url}
              newTab={item?.link?.newTab}
              variant={`buttons.${ctaStyle || 'secondary'}`}
              sx={theme.button}
            >
              {item?.link?.text}
            </Link>
          )}
        </Flex>
      </Box>
    );
  }
);

Tile.displayName = 'Tile';
