export default {
  label: 'Search Settings',
  name: 'search',
  component: 'group',
  fields: [
    {
      label: 'Suggested Terms',
      name: 'suggested',
      component: 'group-list',
      itemProps: {
        key: '{{item.handle}}',
        label: '{{item.label}}',
      },
      fields: [
        {
          label: 'Term',
          name: 'label',
          component: 'text',
        },
      ],
    },
    {
      label: 'Suggested Terms Heading',
      name: 'suggestedHeading',
      component: 'text',
    },
    {
      label: 'Search Spring Settings',
      name: 'searchSpring',
      description: 'Heading, Search Spring Recommendation Tag',
      component: 'group',
      fields: [
        {
          label: 'Enable',
          name: 'enable',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          name: 'heading',
          label: 'Heading',
          component: 'text',
          defaultValue: 'Complete Your Collection',
        },
        {
          name: 'tag',
          label: 'Searchspring Product Recs Tag',
          component: 'text',
          description:
            'The product recommendations profile tag taken from Searchspring admin, e.g. "cross-sell", "similar", "home", "recently-viewed"',
          defaultValue: 'cross-sell',
        },
        {
          name: 'limit',
          label: 'Number of recommendations',
          component: 'number',
          defaultValue: 8,
        },
      ],
    },
  ],
  defaultValue: {
    suggested: [{ label: 'Black' }, { label: 'Micro' }],
    suggestedHeading: 'Top search terms',
  },
};
