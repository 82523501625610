import { Flex, Paragraph } from 'theme-ui';

import { Svg, Dropdown } from '@snippets';

import { useSearchSort } from './useSearchSort';
import { themed } from './Sort.theme';

export const SortDropdown = themed(({ theme }) => {
  const [{ sortOptions }, { sortCollection }] = useSearchSort();

  return sortOptions?.length ? (
    <Dropdown
      data-comp={SortDropdown.displayName}
      initial=""
      onSelected={({ field, direction }) =>
        sortCollection({ field, direction })
      }
      sx={theme.dropdown}
    >
      <Dropdown.Selected sx={theme.selected}>
        {({ selecting, selected }) => {
          return (
            <Flex sx={theme.selected.content}>
              <Paragraph sx={theme.selected.title}>
                {selected.label || 'Sort By'}
              </Paragraph>

              <Svg
                alt="chevron"
                viewBox="0 0 16 16"
                src="/svgs/chevron/down.svg#down"
                sx={{
                  ...theme.selected.icon,
                  transform: selecting ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </Flex>
          );
        }}
      </Dropdown.Selected>

      {sortOptions.map((item, index) => {
        return (
          <Dropdown.Option key={index} option={item} sx={theme.option}>
            {({ selecting, isSelected, option }) =>
              isSelected ? null : (
                <Paragraph sx={theme.option.title}>{option.label}</Paragraph>
              )
            }
          </Dropdown.Option>
        );
      })}
    </Dropdown>
  ) : null;
});

SortDropdown.displayName = 'SortDropdown';
