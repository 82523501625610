import { Box } from 'theme-ui';

import { Link, Picture } from '@snippets';
import { useMenuDrawer } from '../useMenuDrawer';

export function NavMenuImage({ theme, featuredImage }) {
  const { image, link } = { ...featuredImage };
  const [, { closeMenuDrawer }] = useMenuDrawer();
  return (
    <Box data-comp={NavMenuImage.displayName}>
      {image?.src && (
        <Link
          href={link?.url}
          newTab={link?.newTab}
          aria-label={link?.text}
          onClick={closeMenuDrawer}
        >
          <Picture
            alt={link?.text}
            images={[
              {
                src: image.src,
                width: 1160,
                ratio: 1,
              },
            ]}
            sx={{
              width: '100%',
              minHeight: '260px',
              maxHeight: '260px',
              maxWidth: '580px',
              position: 'relative',
            }}
          />
        </Link>
      )}
      <Link
        href={link?.url}
        newTab={link?.newTab}
        aria-label={link?.text}
        onClick={closeMenuDrawer}
        sx={{
          ...theme.link,
          fontSize: 4,
          mt: 4,
        }}
      >
        {link?.text}
      </Link>
    </Box>
  );
}

NavMenuImage.displayName = 'NavMenuImage';
