import Script from 'next/script';
import { Section } from '@snippets';
import { Schema } from './KlaviyoForm.schema';

export function KlaviyoForm({ cms }) {
  const { formId, section } = cms;

  return (
    <Section section={section}>
      <div className={`klaviyo-form-${formId}`} />
    </Section>
  );
}

KlaviyoForm.displayName = 'KlaviyoForm';
KlaviyoForm.Schema = Schema;
