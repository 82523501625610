import { Picture } from '@snippets';

import { themed } from './PasswordProtectedModal.theme';

export const PasswordProtectedImage = themed(({ image, theme }) => {
  const { alt, image: imageMobile, imageDt, position } = { ...image };

  if (!imageDt?.src && !imageMobile?.src) return null;

  return (
    <Picture
      data-comp={PasswordProtectedImage.displayName}
      alt={alt}
      images={[
        {
          src: imageMobile?.src || imageDt?.src,
          width: 768,
        },
        {
          src: imageDt?.src,
          width: 1024,
        },
        {
          src: imageDt?.src,
          width: 1280,
        },
        {
          src: imageDt?.src,
          width: 1440,
        },
      ]}
      sx={theme.image(position)}
      imageSx={{
        objectFit: position === 'full' ? 'contain' : 'cover',
      }}
    />
  );
});

PasswordProtectedImage.displayName = 'PasswordProtectedImage';
