export const account = {
  input: {
    variant: 'text.base',
    p: 8,
    mb: [9],
    border: (t) => `1px solid ${t.colors.borderGrey}`,
    borderRadius: 0,
    ':focus': {
      outline: 'none !important',
      border: (t) => `1px solid ${t.colors.borderGrey}`,
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161);',
    },
  },
};
