export function Schema({ collection }) {
  if (collection) return null;
  return {
    label: 'Collection Grid',
    key: 'collection-grid',
    fields: [
      {
        component: 'group-list',
        name: 'products',
        label: 'Products',
        itemProps: {
          label: '{{item.product.handle}}' || 'New Product',
        },
        fields: [
          {
            component: 'productSearch',
            name: 'product',
            label: 'Product',
          },
        ],
      },
    ],
  };
}
