import { useMemo, Children, cloneElement } from 'react';
import { Flex } from 'theme-ui';

import { themed } from './Header.theme';

export const Header = themed(
  ({ theme, open, setHeight, children, inputRef, ...props }) => {
    const childrenWithRefs = useMemo(
      () =>
        Children.map(children, (child) =>
          cloneElement(child, {
            ref: (node) => {
              if (node) {
                const cartHeaderHeight = node?.getBoundingClientRect()?.height;
                setHeight(cartHeaderHeight);
              }
            },
          })
        ),
      [Children.count(children), open]
    );

    return (
      <Flex
        data-comp={Header.displayName}
        {...props}
        ref={inputRef}
        sx={theme.header}
      >
        {childrenWithRefs}
      </Flex>
    );
  }
);

Header.displayName = 'SidebarHeader';
