import { section, align } from '@settings/common';
import { color } from '@settings/common/color';

export const Schema = () => {
  return {
    category: 'LoyaltyLion',
    label: 'Loyaltylion Rewards List',
    key: 'loyaltylion-rewards-list',
    fields: [
      {
        label: 'Enable If',
        name: 'state',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          {
            value: 'guest',
            label: 'Signed Out',
          },
          {
            value: 'user',
            label: 'Signed In',
          },
          {
            value: 'enable',
            label: 'Always',
          },
          {
            value: 'userByTag',
            label: 'User By Tag',
          },
          {
            value: 'userWithoutTag',
            label: 'User without Tag',
          },
        ],
        defaultValue: 'enable',
      },
      {
        label: 'User Tag',
        name: 'userTag',
        component: 'text',
        defaultValue: 'tier: Platinum',
      },
      {
        label: 'Rewards type',
        name: 'rewardsType',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          {
            value: 'cartDiscount',
            label: 'Cart Discount Voucher',
          },
          {
            value: 'productDiscount',
            label: 'Product Discount Voucher',
          },
          {
            value: 'rewardIds',
            label: 'Filter by Reward Ids',
          },
          {
            value: 'all',
            label: 'All types',
          },
        ],
        defaultValue: 'all',
      },
      {
        label: 'Reward Ids',
        name: 'rewardIds',
        component: 'text',
        defaultValue: '',
      },
      {
        label: 'Display type',
        name: 'displayType',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: [
          {
            value: 'grid',
            label: 'Grid',
          },
          {
            value: 'slider',
            label: 'Slider',
          },
        ],
        defaultValue: 'grid',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Heading Color',
        name: 'headingColor',
        component: 'select',
        options: color.options,
        defaultValue: '#574F4C',
      },
      {
        label: 'SubHeading',
        name: 'subHeading',
        component: 'text',
        defaultValue: '(You need a paid item in your cart to redeem these)',
      },
      {
        label: 'Content alignment (mobile)',
        name: 'alignment',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
      },
      {
        label: 'Content alignment (desktop)',
        name: 'alignmentDt',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
      },
      {
        name: 'icons',
        label: 'Icons replacement',
        component: 'group-list',
        description: 'List icons',
        fields: [
          {
            name: 'icon',
            label: 'Icon',
            component: 'image',
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
};
