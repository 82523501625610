import { Markdown as MarkdownText, Section } from '@snippets';

import { Schema } from './Markdown.schema';

export function Markdown({ cms }) {
  const { body, text, section } = cms;

  return (
    <Section section={section}>
      <MarkdownText text={body} textAlign={text?.textAlign} />
    </Section>
  );
}

Markdown.displayName = 'Markdown';
Markdown.Schema = Schema;
