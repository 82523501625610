import { Section, StoreLocator } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Locator.schema';

export const Locator = withInView(
  ({ cms }) => {
    const { section } = cms;
    return (
      <Section data-comp={Locator.displayName} section={section}>
        <StoreLocator />
      </Section>
    );
  },
  { triggerOnce: true }
);

Locator.displayName = 'Locator';
Locator.Schema = Schema;
