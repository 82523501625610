import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      position: 'relative',
      display: 'grid',
      gridGap: [0, 0, 15],
    };

    this.media = {
      gridArea: 'media',
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      ':before': {
        content: '""',
        float: 'left',
        pb: '65%',
      },
    };

    this.content = {
      gridArea: 'content',
      display: 'flex',
      position: 'relative',
      width: '100%',
      height: '100%',
    };
  })()
);
