import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      wrapper: {
        variant: 'flex.column',
        py: 0,
        px: 10,
        bg: 'background',
      },

      summary: {
        display: 'none',
        color: 'red',
      },

      checkout: {
        variant: 'buttons.primary',
        fontSize: ['12px', '15px'],
        height: ['40px', '42px'],
      },
    };
    // cart empty — rendered in /cart
    this.page = {
      wrapper: {
        ...this.sidebar.wrapper,
        boxShadow: ['none', 'top'],
        pt: [0, null, 11],
        pb: [0, null, 10],
        px: [0, null, 9],
        mb: [11, null, 9],
      },

      summary: {
        variant: 'text.lg',
        display: ['none', null, 'block'],
        mb: 8,
      },
    };
  })()
);
