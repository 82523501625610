import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      display: 'flex',
      position: 'relative',
      gap: [4, 4, 7],
    };

    this.image = {
      cursor: 'pointer',
    };
  })()
);
