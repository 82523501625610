import { color } from '../common/color';

export const menu = {
  label: 'Menu',
  name: 'menu',
  component: 'group-list',
  itemProps: {
    label: '{{item.link.text}}',
  },
  defaultItem: {
    link: {
      url: '',
      text: 'Menu Item',
    },
  },
  fields: [
    {
      label: 'Featured Image',
      name: 'featuredImage',
      component: 'group',
      itemProps: {
        label: '{{item.link.text}}',
      },
      fields: [
        {
          label: 'Image',
          name: 'image',
          component: 'image',
        },
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
    {
      name: 'color',
      label: 'Text color',
      component: 'select',
      options: color.options,
      defaultValue: color.value.text,
    },
    {
      label: 'Secondary Menu',
      name: 'secondaryMenu',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      defaultItem: {
        link: {
          url: '/collections/best-sellers',
          text: 'Best Sellers',
        },
      },
      fields: [
        {
          name: 'hideShopAll',
          label: 'Hide Shop All',
          component: 'toggle',
          toggleLabels: {
            true: 'Hide',
            false: 'Show',
          },
          defaultValue: false,
        },
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        {
          name: 'color',
          label: 'Text color',
          component: 'select',
          options: color.options,
          defaultValue: color.value.text,
        },
        {
          label: 'Mobile Layout',
          name: 'mobileLayout',
          component: 'radio-group',
          direction: 'vertical',
          variant: 'radio',
          options: [
            {
              value: 'images',
              label: 'Images',
            },
            {
              value: 'cards',
              label: 'Cards',
            },
          ],
          default: 'images',
        },
        {
          label: 'Tertiary Menu',
          name: 'tertiaryMenu',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          defaultItem: {
            link: {
              url: '/collections/best-sellers',
              text: 'Best Sellers',
            },
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
            {
              name: 'color',
              label: 'Text color',
              component: 'select',
              options: color.options,
              defaultValue: color.value.text,
            },
            {
              name: 'product',
              component: 'productSearch',
              label: 'Default Image of Specific Product',
            },
          ],
        },
      ],
    },
  ],
};
