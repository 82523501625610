import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.row.center',
      my: 13,
    };

    this.list = {
      pl: 0,
    };

    this.arrowListItem = {
      display: 'flex',
      alignItems: 'center',
    };

    this.arrowButton = (disabled) => ({
      cursor: disabled ? 'default' : 'pointer',
    });

    this.arrowIcon = (disabled) => ({
      width: '16px',
      transition: 'all .2s ease-in-out',
      color: disabled ? 'gray' : 'text',
    });

    this.listItem = {
      mr: 5,
    };

    this.pageButton = (isCurrentPage) => ({
      position: 'relative',
      ':after': {
        content: '""',
        width: '100%',
        position: 'absolute',
        height: '1px',
        left: 0,
        top: '100%',
        bg: 'text',
        transition: 'transform .2s ease-in-out',
        transform: isCurrentPage ? 'scaleX(1)' : 'scaleX(0)',
      },
    });
  })()
);
