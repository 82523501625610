import { useSearchResults } from './useSearchResults';
import { useSearchSuggestions } from './useSearchSuggestions';
import { useSearchTrending } from './useSearchTrending';

export const useSearch = () => {
  const {
    getSearchResults,
    merchandising,
    pageResults,
    results,
    totalPageResults,
    totalResults,
    setSearchData,
    setSearchPageData,
  } = useSearchResults();
  const {
    getSearchSuggestions,
    isAutocomplete,
    pageSpellCorrection,
    pageSuggested,
    pageSuggestions,
    spellCorrection,
    suggested,
    suggestions,
  } = useSearchSuggestions();
  const { trending, getSearchTrending } = useSearchTrending();

  return [
    // state
    {
      isAutocomplete,
      merchandising,
      pageResults,
      pageSpellCorrection,
      pageSuggested,
      pageSuggestions,
      results,
      spellCorrection,
      suggested,
      suggestions,
      totalPageResults,
      totalResults,
      trending,
    },
    // actions
    {
      getSearchResults,
      getSearchSuggestions,
      getSearchTrending,
      setSearchData,
      setSearchPageData,
    },
  ];
};
