import { Box, Grid } from 'theme-ui';
import { isEqual } from 'lodash';

import { Link, Svg } from '@snippets';
import { useMenuDrawer } from '../useMenuDrawer';
import { themed } from './NavList.theme';

export const NavList = themed(
  ({ theme, links, activeNavMenu, setActiveNavMenu }) => {
    const [{}, { closeMenuDrawer }] = useMenuDrawer();
    return (
      <Box data-comp={NavList.displayName}>
        <Grid sx={theme.navList}>
          {links?.map((item, index) => {
            const { link, color: textColor, tertiaryMenu } = item;
            const isActive = isEqual(item, activeNavMenu);
            return (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                }}
                onMouseEnter={() => {
                  if (tertiaryMenu) {
                    setActiveNavMenu(item);
                  }
                }}
              >
                <Link
                  href={link.url}
                  newTab={link.newTab}
                  aria-label={link.text}
                  onClick={closeMenuDrawer}
                  sx={{
                    ...(tertiaryMenu ? theme.linkHasMenu : theme.link),
                    color: textColor || 'black',
                  }}
                >
                  {link.text}
                </Link>
                {tertiaryMenu && (
                  <Svg
                    viewBox="0 0 16 16"
                    src="/svgs/chevron/right.svg#right"
                    sx={theme.arrowIcon(isActive)}
                  />
                )}
              </Box>
            );
          })}
        </Grid>
      </Box>
    );
  }
);

NavList.displayName = 'NavList';
