import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.swiper = {
      '.swiper-container': {
        cursor: ['grab', 'auto'],
      },
      '.swiper-container, .swiper-wrapper, .swiper-slide': {
        height: 'auto !important',
        width: '100%',
      },
    };

    this.grid = {
      display: 'grid',
      gridGap: [10],
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
    };

    this.flex = {
      variant: 'flex.row.center.center',
    };

    this.button = {
      width: 'fit-content',
      mt: [12, 14],
    };

    this.portrait = {
      width: '100%',
      ':before': {
        content: '""',
        pb: `${(1 / 0.75) * 100}%`,
        float: 'left',
      },
    };

    this.square = {
      width: '100%',
      ':before': {
        content: '""',
        pb: '100%',
        float: 'left',
      },
    };
  })()
);
