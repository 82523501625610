import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      position: 'relative',
    };

    this.imageLink = {
      ...this.image,
      ':hover': {
        img: {
          pointerEvents: 'none',
          transform: 'scale3d(1.03, 1.03, 1.03)',
        },
      },
      img: {
        transform: 'scale3d(1.01, 1.01, 1.01)',
        transition: 'transform 0.3s ease-in-out',
      },
    };

    this.overlay = {
      width: '100%',
      paddingTop: '12px',
      pointerEvents: 'auto',
      display: 'block',
      '@media only screen and (max-width: 767px)': {
        display: 'none',
      },
    };
    this.overlayMobile = {
      width: '100%',
      paddingTop: '12px',
      pointerEvents: 'auto',
      display: 'none',
      '@media only screen and (max-width: 767px)': {
        display: 'block',
      },
    };
    this.overlayContained = {
      position: 'absolute',
      width: 'fit-content',
      padding: '24px',
      pointerEvents: 'auto',
      bottom: '0',
      display: 'block',
      '@media only screen and (max-width: 767px)': {
        display: 'none',
      },
    };
    this.overlayContainedMobile = {
      position: 'absolute',
      width: 'fit-content',
      padding: '24px',
      pointerEvents: 'auto',
      bottom: '0',
      display: 'none',
      '@media only screen and (max-width: 767px)': {
        display: 'block',
      },
    };
    this.heading = {
      color: 'black',
      fontSize: '21px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.63px',
      mb: '6px',
      '@media only screen and (max-width: 767px)': {
        fontSize: '16px',
      },
    };
    this.description = {
      color: 'black',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      letterSpacing: '-0.32px',
      mb: '20px',
      '@media only screen and (max-width: 767px)': {
        fontSize: '13px',
        mb: '16px',
      },
    };
    this.button = {
      display: 'inline-block',
      minWidth: 'fit-content',
    };
  })()
);
