import { Box } from 'theme-ui';

import { Content } from './Content';
import { themed } from './Slide.theme';

export const ContentSlide = themed(
  ({
    theme,
    bgColor,
    slide,
    showContentSliderMobile,
    showContentSliderDesktop,
    isBelowHero,
    isVisibleContent,
    isFirstSlide,
  }) => {
    const showSlideMobile =
      (showContentSliderMobile && isBelowHero) ||
      (!showContentSliderMobile && !isBelowHero);
    const showSlideDesktop =
      (showContentSliderDesktop && isBelowHero) ||
      (!showContentSliderDesktop && !isBelowHero);

    return (
      <>
        {/* mobile content */}
        {showSlideMobile && (
          <Box
            data-comp={`${ContentSlide.displayName}.Mobile`}
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              pointerEvents: isBelowHero ? null : 'none',
              bg: isBelowHero ? bgColor || 'background' : 'unset',
              display: ['block', 'none'],
            }}
          >
            <Content
              slide={slide}
              isVisibleContent={isVisibleContent}
              isBelowHero={isBelowHero}
              isFirstSlide={isFirstSlide}
              isMobile
              showContentSliderMobile={showContentSliderMobile}
              showContentSliderDesktop={showContentSliderDesktop}
            />
          </Box>
        )}

        {/* desktop content */}
        {showSlideDesktop && (
          <Box
            data-comp={`${ContentSlide.displayName}.Desktop`}
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              pointerEvents: isBelowHero ? null : 'none',
              bg: isBelowHero ? bgColor || 'background' : 'unset',
              display: ['none', 'block'],
            }}
          >
            <Content
              slide={slide}
              isVisibleContent={isVisibleContent}
              isBelowHero={isBelowHero}
              isFirstSlide={isFirstSlide}
              showContentSliderMobile={showContentSliderMobile}
              showContentSliderDesktop={showContentSliderDesktop}
            />
          </Box>
        )}
      </>
    );
  }
);

ContentSlide.displayName = 'ContentSlide';
