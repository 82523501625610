import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = (isLast) => ({
      variant: 'flex.row.start.center',
      width: '100%',
      minHeight: '122px',
      px: 12,
      py: 10,
      borderBottomWidth: '1px',
      borderBottomColor: 'gray',
      borderBottomStyle: isLast ? 'none' : 'solid',
      mb: isLast ? 10 : 0,
    });

    this.picture = {
      width: '105px',
      mr: 8,

      spinner: {
        width: '16px',
        color: 'mediumDarkGray',
        m: 'auto',
      },
    };

    this.details = {
      variant: 'flex.column.start',
      flex: 1,
      pr: 10,
    };

    this.title = {
      variant: 'text.h6',
      fontWeight: 500,
      minHeight: '18px',
    };

    this.variant = {
      variant: 'text.sm',
      color: 'mediumDarkGray',
      minHeight: '18px',
    };

    this.price = {
      minHeight: '18px',
      mb: 3,
      p: {
        variant: 'text.sm',
        fontSize: '13px',
      },
    };

    this.newPrice = {
      color: 'red',
      mr: 4,
    };

    this.compareAtPrice = {
      textDecoration: 'line-through',
      color: 'mediumDarkGray',
    };

    this.atc = {
      fontSize: ['13px', '13px'],
    };
  })()
);
