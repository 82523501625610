import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      table: {
        width: '100%',
      },
      thead: {
        backgroundColor: 'lightgrey',
      },
      'th, td': {
        py: '5px',
      },
      'tr:nth-of-type(even)': {
        backgroundColor: '#f5f5f5',
      },
      p: {
        mb: 8,
        fontFamily: 'body',
      },
      'ul, ol': {
        mb: 8,
        pl: 13,
      },
      ul: {
        li: {
          listStyleType: 'disc',
        },
      },
      ol: {
        li: {
          listStyleType: 'decimal',
        },
      },
      li: {
        mb: 4,
        listStylePosition: 'inside',
        textIndent: '-22px',
        fontFamily: 'body',
      },
      a: {
        textDecoration: 'underline',
        fontFamily: 'body',
      },
      h1: {
        variant: 'text.h1',
        mb: 11,
      },
      h2: {
        variant: 'text.h2',
        mt: 11,
        mb: 10,
      },
      h3: {
        variant: 'text.h3',
        mt: [9, null, 11],
        mb: 9,
      },
      h4: {
        variant: 'text.h4',
        mb: 9,
      },
      h5: {
        variant: 'text.h5',
        mb: 9,
      },
      h6: {
        variant: 'text.h6',
        mb: 9,
      },
      'span, del': {
        textDecorationColor: 'currentColor',
      },
      strong: {
        fontWeight: '700 !important',
      },
      modal: {
        textDecoration: 'underline',
        fontFamily: 'body',
        cursor: 'pointer',
      },
      modal: {
        textDecoration: 'underline',
        fontFamily: 'body',
        cursor: 'pointer',
      },
    };
  })()
);
