import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.outer = {
      width: '100%',
      justifyContent: 'center',
      alignItems: ['flex-start', 'center'],
      px: 12,
      py: [18, 16],
      '@media only screen and (max-width: 479px)': {
        py: 15,
      },
    };

    this.heading = {
      variant: 'text.h2',
      fontSize: [10, 12],
    };

    this.bodyWithoutHeading = {
      variant: 'text.base',
      mt: 0,
      ul: {
        ml: 0,
      },
      'p:last-of-type': {
        mb: 0,
      },
    };

    this.listNumber = {
      counterReset: 'step-counter',
      li: {
        position: 'relative',
      },
      'li::before': {
        counterIncrement: 'step-counter',
        content: 'counter(step-counter)". "',
      },
    };

    this.listCirlce = {
      counterReset: 'step-counter',
      li: {
        position: 'relative',
        pl: ['61px', '66px'],
      },
      'li::before': {
        counterIncrement: 'step-counter',
        content: 'counter(step-counter)',
        position: 'absolute',
        background: 'tundora',
        borderRadius: '50%',
        top: '50%',
        left: '0',
        mt: ['-20.5px', '-23px'],
        width: ['41px', '46px'],
        height: ['41px', '46px'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    };

    this.bodyWithHeading = {
      ...this.bodyWithoutHeading,
    };
  })()
);
