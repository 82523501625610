/* eslint-disable consistent-return */
import { useMemo } from 'react';
import { Container, Box } from 'theme-ui';

import { withInView } from '@utils';
import { Section, Link } from '@snippets';
import { useBlog } from '@backpackjs/storefront';

import { Content } from './Content';
import { Image } from './Image';
import { Schema } from './FeaturedArticle.schema';
import { themed } from './FeaturedArticle.theme';

export const FeaturedArticle = withInView(
  themed(({ theme, cms }) => {
    const { articleHandle, hideMobile, image, content, section } = cms;
    const blog = useBlog();
    const matchedArticle = Object.values({ ...blog?.articles }).find(
      (article) => article.handle === articleHandle
    );

    // Fall back scaffolding.
    const articleObj = !matchedArticle
      ? {
          title: 'ERROR!',
          image:
            'https://cdn.shopify.com/s/files/1/1114/2308/files/brumate-blog-placeholder.jpg',
          author: 'Platform Error',
          _createdAt: 'February 04, 2022',
          excerpt: `ERROR: Handle invalid.`,
        }
      : matchedArticle;
    const imageLeft = image?.locationDt === 'left'; // mobile
    const imageTop = image?.location === 'top'; // desktop
    const gridTemplateAreas = imageLeft
      ? ['1fr', '2fr 1fr']
      : ['1fr', '1fr 2fr'];

    // Commented for now while imported featured images are figured out.
    // const featuredImage = {
    //   ...image,
    //   ...articleObj,
    // };

    const contentObj = {
      ...content,
      ...articleObj,
    };

    const articleImage = useMemo(() => {
      if (!matchedArticle) return null;
      if (matchedArticle.seo?.image) return matchedArticle.seo.image;
      const body = matchedArticle.bodyHtml;
      return body
        ? `https://${body.slice(body.indexOf('cdn'), body.indexOf('.jpg'))}.jpg`
        : 'https://cdn.shopify.com/s/files/1/1114/2308/files/brumate-blog-placeholder.jpg?v=1660081495';
    }, [matchedArticle]);

    return (
      <Section section={section}>
        <Container
          data-comp={FeaturedArticle.displayName}
          sx={{
            ...theme.grid,
            display: [hideMobile ? 'grid' : 'none', 'grid'],
            gridTemplateAreas: [
              imageTop ? '"media" "content"' : '"content" "media"',
              imageLeft ? '"media content"' : '"content media"',
            ],
            gridTemplateColumns: gridTemplateAreas,
            minHeight: [
              section?.mobile?.minHeight,
              section?.desktop?.minHeight,
            ],
            maxHeight: [
              section?.mobile?.maxHeight,
              section?.desktop?.maxHeight,
            ],
          }}
        >
          <Link
            href={matchedArticle ? `/articles/${matchedArticle.handle}` : ''}
            aria-label={matchedArticle?.title}
            sx={theme.media}
          >
            <Image image={articleImage} />
          </Link>

          <Box
            sx={{
              ...theme.content,
              justifyContent: imageLeft ? 'flex-start' : 'flex-end',
              ':before': {
                content: '""',
                float: 'left',
              },
            }}
          >
            <Content content={contentObj} />
          </Box>
        </Container>
      </Section>
    );
  }),
  { renderOnce: true }
);

FeaturedArticle.displayName = 'FeaturedArticle';
FeaturedArticle.Schema = Schema;
