import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: [10, 11],
    };

    this.imageLink = {
      ...this.image,
      ':hover': {
        img: {
          transform: 'scale(1.03)',
        },
      },
      img: {
        transform: 'scale(1.01)',
        transition: 'transform 0.3s ease-in-out',
      },
    };

    this.content = {
      flexDirection: 'column',
      py: 0,
    };

    this.heading = {
      variant: 'text.h4',
    };

    this.body = {
      variant: 'text.base',
      ml: [2, 3],
      flex: 1,
    };

    this.button = {
      mt: 4,
    };

    this.flexed = {
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        svg: {
          transform: 'translate3d(5px, 0, 0)',
          transition: 'transform 0.3s ease-in-out',
        },
      },
    };

    this.icon = {
      ml: 7,
      width: '24px',
    };

    this.bulletpoint = {
      variant: 'flex.row.center',
      pt: [3, 5],
      icon: {
        minWidth: '32px',
        maxWidth: '32px',
      },
    };
  })()
);
