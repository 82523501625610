import { useState, useEffect, useCallback } from 'react';

import { NavigationButton } from './NavigationButton';
import { themed } from './Navigation.theme';

export const Navigation = themed(
  ({ theme, swiper, loop, count, leftSx, rightSx }) => {
    const [isStart, setIsStart] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const setStartAndEndOnSwiperChange = useCallback(() => {
      if (!swiper) return;

      if (loop) {
        setIsStart(false);
        setIsEnd(false);
      }

      if (!loop) {
        setIsStart(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
        swiper.on('slideChange', () => {
          setIsStart(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        });
      }
    }, [swiper, loop]);

    useEffect(() => {
      setStartAndEndOnSwiperChange();
    }, [swiper, loop]);

    return (
      <>
        <NavigationButton
          onClick={() => swiper.slidePrev()}
          inactive={isStart}
          count={count}
          sx={leftSx}
          alt="Previous product"
        />

        <NavigationButton
          onClick={() => swiper.slideNext()}
          src="/svgs/arrow-right.svg#arrow-right"
          alt="Next product"
          inactive={isEnd}
          isNext
          count={count}
          sx={rightSx}
        />
      </>
    );
  }
);

Navigation.displayName = 'Navigation';
