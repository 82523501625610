import { position, section, align, button } from '@settings/common';

export function Schema({ article, blog }) {
  if (article || blog) return null;

  return {
    category: 'LoyaltyLion',
    label: 'LoyaltyLion customer poins',
    key: 'loyaltyllion-customer-poins',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        description: 'use [Points] for real customer points',
      },
      {
        label: 'SubHeading',
        name: 'subHeading',
        component: 'text',
        description: 'use [Pending_points] for real customer pending points',
      },
      {
        label: 'Body',
        name: 'body',
        component: 'markdown',
      },
      {
        label: 'Link',
        name: 'cta',
        component: 'link',
      },
      {
        name: 'ctaStyle',
        label: 'Link Style',
        component: 'select',
        options: button.options,
      },
      {
        label: 'Content alignment (mobile)',
        name: 'alignment',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
      },
      {
        label: 'Content alignment (desktop)',
        name: 'alignmentDt',
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        options: align.options,
      },
      {
        ...section,
        defaultValue: {
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
    defaultValue: {
      heading: "You Have [Points] Points",
      subHeading: "([Pending_points] Points Pending)",
      body: '',
      alignment: align.value.left,
      alignmentDt: align.value.left,
    },
  };
}