import { useState, useEffect } from 'react';
import Script from 'next/script';
import store, { useRecoilValue } from '@store';

export function MainSchemaMarkup({ pageUrl }) {
  const [itemListSchema, setItemListSchema] = useState(null);

  const subCollections = useRecoilValue(store?.collectionsMap);

  useEffect(() => {
    if (!subCollections) return;
    const itemList = subCollections?.map((collection, index) => {
      const name = collection?.title;
      const { url } = collection;

      return {
        '@type': 'ListItem',
        position: index,
        name,
        url,
      };
    });

    setItemListSchema(itemList?.splice(1, itemList?.length));
  }, [subCollections, pageUrl]);
  return (
    <>
      <Script
        id="schema-markup-org"
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "${process.env.NEXT_PUBLIC_SITE_TITLE}",
        "url": "${pageUrl}",
        ${
          process.env.NEXT_PUBLIC_SOCIAL_INSTAGRAM
            ? `"sameAs": "https://www.instagram.com/${process.env.NEXT_PUBLIC_SOCIAL_INSTAGRAM.replace(
                '@',
                ''
              )}/",`
            : ''
        }
        "logo": "${process.env.NEXT_PUBLIC_SITE_LOGO}"
      }
      `,
        }}
      />

      {itemListSchema?.length > 0 && (
        <Script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'ItemList',
              itemListElement: itemListSchema,
            }),
          }}
        />
      )}
    </>
  );
}

MainSchemaMarkup.displayName = 'MainSchemaMarkup';
