import { Picture } from '@snippets';
import { useProductItemImages } from '@hooks';
import { useProductByHandle } from '@backpackjs/storefront';

import { themed } from './RebuyModal.theme';

export const RebuyItemImage = themed(({ selectedVariant, fullProduct }) => {
  const isIcePack = selectedVariant?.product?.handle.includes('ice-pack');

  const { primary, hover } = useProductItemImages({
    product: fullProduct,
  });

  const selected = selectedVariant?.image;

  const primaryImage = selected?.originalSrc || primary?.originalSrc;

  if (!primaryImage) return null;

  return (
    <Picture
      alt={selectedVariant?.product?.title}
      images={[
        {
          src: primaryImage,
          width: 240,
          ratio: 1,
        },
      ]}
      hoverImages={
        isIcePack
          ? []
          : [
              {
                src: hover?.originalSrc,
                width: 240,
                ratio: 1,
              },
            ]
      }
      disableHoverMobile={false}
      imageBgColor="lightGray"
      hoverImageBgColor="lightGray"
    ></Picture>
  );
});

RebuyItemImage.displayName = 'RebuyItemImage';
