import { useEffect, useState, useCallback } from 'react';
import { useCart, useRouter } from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';

export function useCartIntelliSuggest() {
  const { route } = useRouter();
  const cart = useCart();
  const shopperId = useRecoilValue(store.shopperId);
  const [mounted, setMounted] = useState(false);

  const cartItems = cart?.lines;

  const setMountAfterDelayForCustomerId = useCallback(() => {
    setTimeout(() => {
      setMounted(true);
    }, 3000);
  }, []);

  const sendIntelliSuggestEventOnMountOrCartChange = useCallback(() => {
    try {
      if (!cart?.updatedAt || !mounted || !window.IntelliSuggest) return;

      const skus = JSON.stringify(
        cartItems?.map((item) => item.variant.sku) || []
      );

      IntelliSuggest.init({
        siteId: process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID,
        context: 'Basket',
        seed: skus,
      });

      if (shopperId) {
        IntelliSuggest.setShopperId(shopperId);
      }

      cartItems?.forEach((item) => {
        IntelliSuggest.haveItem({
          sku: item.variant.sku,
          qty: item.quantity,
          price: (
            item.estimatedCost.subtotalAmount.amount / item.quantity
          ).toFixed(2),
        });
      });

      IntelliSuggest.inBasket({});
    } catch (error) {
      console.error(error.message);
    }
  }, [cart?.updatedAt, mounted, shopperId]);

  const clearProductSkuInWindowOnNonProductPage = useCallback(() => {
    if (!route || route.startsWith('/product')) return;
    if (window.__brumate_product_sku) {
      window.__brumate_product_sku = null;
    }
  }, [route]);

  useEffect(() => {
    setMountAfterDelayForCustomerId();
  }, []);
  useEffect(() => {
    sendIntelliSuggestEventOnMountOrCartChange();
  }, [cart?.updatedAt, mounted]);
  useEffect(() => {
    clearProductSkuInWindowOnNonProductPage();
  }, [route]);
}
