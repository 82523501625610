import { Heading, Container } from 'theme-ui';

import { convertAlignToFlex } from '@utils';

import { themed } from './Header.theme';

export const Header = themed(({ theme, content = {} }) => {
  const { textAlign, textAlignMobile } = content;

  return (
    <Container
      data-comp={Header.displayName}
      sx={{
        ...theme.header,
        justifyContent: [
          convertAlignToFlex(textAlignMobile),
          null,
          null,
          convertAlignToFlex(textAlign),
        ],
        alignItems: [
          convertAlignToFlex(textAlignMobile),
          null,
          null,
          convertAlignToFlex(textAlign),
        ],
      }}
    >
      <Heading
        variant="h2"
        sx={{
          ...theme.heading,
          textAlign,
        }}
      >
        {content?.heading}
      </Heading>
    </Container>
  );
});

Header.displayName = 'Header';
