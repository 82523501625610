import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      height: '46px',
      mt: '11px',
      alignItems: 'center',
      px: '24px',
    };

    this.link = {};

    this.text = {
      fontSize: 6, // 18px
      lineHeight: 22,
      letterSpacing: '0.02em',
      fontFamily: 'body',
      cursor: 'pointer',
    };
  })()
);
