import { Picture } from '@snippets';

export function Image({ image, media }) {
  return (
    <Picture
      data-comp={Image.displayName}
      alt={image?.alt}
      images={[
        {
          src: image?.image?.src,
          width: 768,
        },
        {
          src: image?.imageDt?.src,
          width: 512,
        },
        {
          src: image?.imageDt?.src,
          width: 640,
        },
        {
          src: image?.imageDt?.src,
          width: 720,
        },
      ]}
      sx={{
        ':before': {
          content: '""',
          paddingBottom: [media?.ratio, media?.ratioDt],
          width: 0,
        },
      }}
    />
  );
}

Image.displayName = 'Image';
