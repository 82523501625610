import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.hero = {
      bg: 'ghostwhite',
      display: 'flex',
      flexDirection: 'column',
    }),
      (this.contentWrapper = {
        height: '100%',
        position: 'relative',
        width: '100%',
      });
  })()
);
