import { useMemo, useCallback } from 'react';
import { useCartUpdateItem, useCartRemoveItem } from '@backpackjs/storefront';

import { useCartStatus } from '@hooks';

const startStatus = {
  started: true,
  finished: false,
  success: false,
  errors: [],
};
const endStatus = {
  started: false,
  finished: true,
  success: true,
  errors: [],
};

export const useByobLineItems = ({ cart }) => {
  const [, { setUpdatingCartStatus }] = useCartStatus();
  const { cartUpdateItem } = useCartUpdateItem();
  const { cartRemoveItem } = useCartRemoveItem();

  const byob = useMemo(() => {
    if (!cart?.lines?.length)
      return { lineItems: [], chargeItems: [], quantity: 0, updatedAt: null };
    let quantity = 0;
    const lineItems = cart.lines.filter((line) => {
      if (
        line.variant.product.handle !== 'byob-charge' &&
        line.attributes.some((attr) => attr.key === 'recipe_id')
      ) {
        quantity += line.quantity;
        return true;
      }
      return false;
    });
    const chargeItems = cart.lines.filter((line) => {
      return line.variant.product.handle === 'byob-charge';
    });

    return {
      lineItems,
      quantity,
      chargeItems,
      dependency: [...lineItems, ...chargeItems]
        .map(
          (v) =>
            v.id +
            v.quantity +
            (v.attributes.find((attr) => attr.key === 'recipe_id')?.value || '')
        )
        .join(''),
    };
  }, [cart?.updatedAt, cart?.lines]);

  const updateOrAddByobChargeItem = useCallback(async () => {
    const recipeIdArr = [];
    const lineItemPromises = byob.chargeItems.length
      ? null
      : byob.lineItems.map(async (byobItem) => {
          const recipeId = byobItem.attributes.find(
            (attr) => attr.key === 'recipe_id'
          )?.value;
          if (recipeIdArr?.indexOf(recipeId) === -1) {
            recipeIdArr.push(recipeId);
            const quantity = byob.lineItems.reduce((acc, lines) => {
              if (
                lines.attributes.find((attr) => attr.key === 'recipe_id')
                  ?.value === recipeId
              ) {
                return acc + lines.quantity;
              }
              return acc;
            }, 0);

            const byobChargeItem = byob.chargeItems.find((lineItem) => {
              return (
                lineItem.variant.product.handle === 'byob-charge' &&
                lineItem.attributes.find((attr) => attr.key === 'recipe_id')
                  ?.value === `uc-${recipeId}`
              );
            });

            if (quantity > 0) {
              if (byobChargeItem && quantity !== byobChargeItem.quantity) {
                // update byob charge quantity on byob product change
                await setUpdatingCartStatus(startStatus);
                await cartUpdateItem({
                  lineId: byobChargeItem.id,
                  quantity,
                });
                await setUpdatingCartStatus(endStatus);
              }
            }
          }
        });

    const chargeItemPromises = byob.chargeItems.map(async (byobChargeItem) => {
      const recipeId = byobChargeItem.attributes
        .find((attr) => attr.key === 'recipe_id')
        ?.value?.split('-')[1];
      if (recipeIdArr?.indexOf(recipeId) === -1) {
        recipeIdArr.push(recipeId);

        const quantity = cart?.lines?.reduce((acc, lines) => {
          if (
            lines.attributes.find((attr) => attr.key === 'recipe_id')?.value ===
            recipeId
          ) {
            return acc + lines.quantity;
          }
          return acc;
        }, 0);

        if (quantity > 0) {
          if (quantity !== byobChargeItem.quantity) {
            // update byob charge quantity on byob product change
            await setUpdatingCartStatus(startStatus);
            await cartUpdateItem({
              lineId: byobChargeItem.id,
              quantity,
            });
            await setUpdatingCartStatus(endStatus);
          }
        } else {
          // remove byob charge item if no byob products
          setUpdatingCartStatus(startStatus);
          await cartRemoveItem({ lineId: byobChargeItem.id });
          setUpdatingCartStatus(endStatus);
        }
      }
    });
    if (lineItemPromises) {
      await Promise.all(lineItemPromises);
    }
    await Promise.all(chargeItemPromises);
  }, [byob.dependency]);

  return { updateOrAddByobChargeItem, byobDependency: byob?.dependency };
};
