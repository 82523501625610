import { useCallback, useState } from 'react';
import { Box, Button, Input, Label, Paragraph, Text } from 'theme-ui';
import { useDataLayerActions, useEmailSignup } from '@hooks';
import { themed } from './EmailSignup.theme';

export const EmailSignup = themed(({ theme, ...props }) => {
  const { sendSubscribeEvent } = useDataLayerActions();

  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageText, setMessageText] = useState('');
  const { submitEmailSignup } = useEmailSignup();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!email || submitted || isLoading) return;
      const emailListId = 'Mrk7yV';

      setIsLoading(true);

      const data = await submitEmailSignup(emailListId, email);

      if (data.success) {
        setIsLoading(false);
        setSubmitted(true);
        setMessageText('Thank you for signing up!');
        sendSubscribeEvent({ email });
        setEmail('');
        setTimeout(() => {
          setMessageText('');
          setSubmitted(false);
        }, 4000);
      } else {
        setIsLoading(false);
        setMessageText('Please try again later');
      }
    },
    [email, isLoading]
  );

  return (
    <Box
      data-comp="EmailSignup"
      as="form"
      email={email}
      onSubmit={handleSubmit}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      <Label sx={theme.label}>
        <Text sx={theme.labelText}>Email Address</Text>
        <Input
          placeholder="Enter your email address"
          type="email"
          required
          onChange={(e) => setEmail(e.target.value)}
          sx={theme.input}
        />
      </Label>

      <Button variant="buttons.inverse" sx={theme.button}>
        Sign Up
      </Button>

      <Paragraph
        as="div"
        variant="text.label2"
        sx={{
          mt: 8,
          mb: 8,
        }}
      >
        {messageText}
      </Paragraph>
    </Box>
  );
});
