import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      variant: 'text.label.1',
      px: 12,
      py: 10,
      borderTopWidth: '1px',
      borderTopColor: 'gray',
      borderTopStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'gray',
      borderBottomStyle: 'solid',
    };
  })()
);
