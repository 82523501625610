import { withInView } from '@utils';
import { CollectionCategories as CollectionCategoriesSnippet } from '@snippets';

import { Schema } from './CollectionCategories.schema';
import { themed } from './CollectionCategories.theme';

export const CollectionCategories = withInView(
  themed(({ cms }) => {
    const { categories, name, collapsed } = cms;

    return (
      <CollectionCategoriesSnippet
        name={name}
        categories={categories}
        collapsed={collapsed}
      />
    );
  }),
  { triggerOnce: true }
);

CollectionCategories.displayName = 'CollectionCategories';
CollectionCategories.Schema = Schema;
