import { Box } from 'theme-ui';

import store, { useRecoilValue } from '@store';
import { useSelectedOptions } from '@hooks';

import { ColorsSidebarItem } from './ColorsSidebarItem';
import { themed } from './ColorsSidebar.theme';

export const ColorsSidebar = themed(({ theme }) => {
  const [selectedOptions] = useSelectedOptions();

  const product = useRecoilValue(store.initialProduct);

  const selectedColor = selectedOptions?.color;
  const handles = product?.groupOptionsMap.handle;

  return product?.isGrouped && handles?.length ? (
    <Box data-comp={ColorsSidebar.displayName} as="ul" sx={theme.wrapper}>
      {handles.map((handle, index) => {
        return (
          <ColorsSidebarItem
            key={`color-sidebar-item-${index}`}
            handle={handle}
            selectedColor={selectedColor}
          />
        );
      })}
    </Box>
  ) : null;
});

ColorsSidebar.displayName = 'ColorsSidebar';
