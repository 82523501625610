import { useSettings } from '@backpackjs/storefront';
import store, { useRecoilState, useSetRecoilState } from '@store';

import { getValidId } from '@utils';

export const useGiftWithPurchase = () => {
  const settings = useSettings();
  const [gwpVariantIds, setGwpVariantIds] = useRecoilState(store.gwpVariantIds);
  const setGwpProducts = useSetRecoilState(store.gwpProducts);
  const { enabled = false, threshold = 100, products } = { ...settings?.gwp };
  const { displayType, imageGroup, sliderGroup, excludedProducts } = {
    ...products,
  };

  const getGiftProducts = async () => {
    if (!enabled) return;
    try {
      let giftProducts = [];
      if (displayType === 'image') {
        giftProducts = imageGroup?.giftProduct?.handle
          ? [imageGroup.giftProduct]
          : [];
      } else {
        giftProducts =
          sliderGroup?.giftProducts
            ?.map(({ giftProduct }) => giftProduct)
            .filter((giftProduct) => !!giftProduct?.handle) || [];
      }
      if (giftProducts) {
        const fetchUrls = giftProducts.map(
          (giftProduct) => `/json/products/${giftProduct.handle}.json`
        );

        const giftResponses = await Promise.all(
          fetchUrls.map((url) => fetch(url))
        );

        const gifts = await Promise.all(
          giftResponses.map((giftResp) => giftResp.json())
        );

        const validGifts = gifts?.filter(({ status }) => {
          return status !== 'DRAFT';
        });

        if (validGifts?.length) {
          const variantIds = validGifts.map(
            (gift) => gift.variants[0]?.storefrontId
          );
          setGwpProducts(
            validGifts
              .map((gift) => {
                const gwpSettings = sliderGroup?.giftProducts?.find(
                  ({ giftProduct }) => giftProduct.handle === gift.handle
                );
                return {
                  ...gift,
                  gwpSettings,
                };
              })
              ?.slice(0, 3)
          );
          setGwpVariantIds(variantIds);
          return;
        }
      }
      const response = await fetch(
        'https://mystery-gift.herokuapp.com/get-discount-codes'
      );
      if (!response.ok) return;

      const responseJSON = await response.json();

      if (!responseJSON?.data?.[0]?.variants_id?.length) return;

      setGwpProducts({
        ...responseJSON.data[0],
      });

      setGwpVariantIds(
        Buffer.from(
          `gid://shopify/ProductVariant/${responseJSON.data[0].variants_id[0]}`
        ).toString('base64')
      );
    } catch (error) {
      console.error(error);
    }
  };

  const isEligible = (cart) => {
    const ids = gwpVariantIds;
    if (!ids) return false;

    const subtotalAmount = parseFloat(
      cart?.estimatedCost?.subtotalAmount?.amount
    );

    const excludedProductHandles = excludedProducts?.map(
      ({ product }) => product?.handle
    );

    const trueSubtotalAmount = cart?.lines?.reduce((carry, line) => {
      const itemHandle = line?.variant?.product?.handle;
      if (!excludedProductHandles?.some((handle) => handle === itemHandle))
        return carry;
      // remove line item subtotal from cart subtotal if product is excluded from GWP's cart threshold
      const itemSubtotal = parseFloat(
        line?.estimatedCost?.subtotalAmount?.amount
      );
      return (carry * 100 - itemSubtotal * 100) / 100;
    }, subtotalAmount);

    return (
      enabled &&
      trueSubtotalAmount >= parseFloat(threshold) &&
      !cart?.lines?.some((line) => {
        if (!line?.variant?.id) return false;
        const id = `gid://shopify/ProductVariant/${getValidId(
          line.variant.id
        )}`;
        return ids.some((_id) => _id === id);
      }) // GWP is not in cart
    );
  };

  return {
    getGiftProducts,
    isEligible,
  };
};
