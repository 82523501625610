import { useMemo } from 'react';
import { Container, Box } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './HalfMedia.schema';
import { themed } from './HalfMedia.theme';

import { Video } from './Video';
import { Content } from './Content';
import { Image } from './Image';

export const HalfMedia = withInView(
  themed(({ theme, cms, inView }) => {
    const {
      image,
      video,
      media,
      content,
      section,
      html,
      showBreadCrumb = false,
    } = cms;
    const mediaLeft = media?.locationDt === 'left'; // mobile
    const mediaTop = media?.location === 'top'; // desktop

    const hasDtMedia =
      image?.imageDt?.src || (video?.enabled && video?.src) || html?.html;
    const hasMbMedia =
      (image?.image?.src || (video?.enabled && video?.src) || html?.html) &&
      !media?.disableMobileMedia;
    const hasMedia = hasDtMedia && hasMbMedia;
    const gridTemplateColumns = useMemo(() => {
      const arr = ['1fr'];
      if (hasDtMedia) {
        arr.push('1fr 1fr');
      } else {
        arr.push('1fr');
      }
      return arr;
    }, [hasDtMedia, hasMbMedia]);

    const gridTemplateAreas = useMemo(() => {
      const arr = Array(0);
      if (hasMbMedia) {
        if (mediaTop) {
          arr.push('"media" "content"');
        } else {
          arr.push('"content" "media"');
        }
      } else {
        arr.push('"content"');
      }
      if (hasDtMedia) {
        if (mediaLeft) {
          arr.push('"media content"');
        } else {
          arr.push('"content media"');
        }
      } else {
        arr.push('"content"');
      }
      return arr;
    }, [hasDtMedia, hasMbMedia, mediaTop, mediaLeft]);

    return (
      <Section section={section}>
        <Container
          data-comp={HalfMedia.displayName}
          sx={{
            ...(!hasMedia ? theme.gridNoMedia : theme.grid),
            gridTemplateAreas,
            gridTemplateColumns,
          }}
        >
          <Box
            sx={{
              ...theme.media,
              ':before': {
                content: '""',
                float: 'left',
                pb: [
                  hasMbMedia ? media?.ratio || '100%' : 0,
                  hasDtMedia ? media?.ratioDt || '100%' : 0,
                ],
              },
            }}
            className={`${hasDtMedia ? '' : 'md:hidden'} ${
              hasMbMedia ? '' : 'max-md:hidden'
            }`}
          >
            {!html?.html &&
              (inView && video?.enabled ? (
                <Video video={video} media={media} />
              ) : (
                <Image image={image} media={media} />
              ))}

            {html?.html && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                dangerouslySetInnerHTML={{ __html: html?.html }}
              />
            )}
          </Box>

          <Box
            sx={{
              ...theme.content,
              justifyContent: mediaLeft ? 'flex-start' : 'flex-end',
              ':before': {
                content: '""',
                float: 'left',
                pb: [0, hasDtMedia ? media?.ratioDt || '100%' : 0],
              },
            }}
          >
            <Content
              content={content}
              hasMedia={hasMedia}
              showBreadCrumb={showBreadCrumb}
            />
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

HalfMedia.displayName = 'HalfMedia';
HalfMedia.Schema = Schema;
