import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      py: 3,
      px: 3,
    };

    this.heading = {
      variant: 'text.h6',
      fontSize: '10px',
      fontWeight: 600,
      letterSpacing: 0,
      textAlign: 'center',
      mb: 5,
    };

    this.slider = {
      position: 'relative',
      border: (t) => `1px solid ${t.colors.gray}`,
      cursor: 'grab',
    };
  })()
);
