import { Fragment, useRef } from 'react';
import { Container } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';

import { useSetHeaderHeightCssVar } from '@hooks';

import { useVariables } from '@tapcart/webbridge-react';
import { PromoBar } from './PromoBar';
import { Menu } from './Menu';
import { MenuSidebarShell } from './MenuSidebar';
import { MenuDrawerShell } from './MenuDrawer';
import { themed } from './Header.theme';

export const Header = themed(({ theme }) => {
  const { isPreview } = useRouter();
  const headerRef = useRef(null);
  const { isInitialized } = useVariables();

  // Sets --header-height CSS var
  useSetHeaderHeightCssVar({ headerRef });

  if (!!isInitialized) return null;

  return (
    <>
      <PromoBar />

      <Container
        data-comp={Header.displayName}
        as="header"
        ref={headerRef}
        sx={isPreview ? theme.inCustomizer : theme.inProduction}
      >
        {/* logo, top level menu items, search, account cart */}
        <Menu />

        {/* mobile: menu sliding sidebar on menu click */}
        <MenuSidebarShell />

        {/* desktop: mega-nav drawer on hover */}
        <MenuDrawerShell />
      </Container>
    </>
  );
});

Header.displayName = 'Header';
