import { section } from '@settings/common';

export function Schema({ page }) {
  if (page?.handle !== '/') return null;

  return {
    label: 'Quick Select Collections',
    key: 'quick-select-collections',
    fields: [
      {
        label: 'Quick select collections',
        name: 'links',
        component: 'group-list',
        description: 'Collection buttons below the header. Mobile only',
        itemProps: {
          label: '{{item.cta.text}}',
        },
        defaultItem: {
          cta: {
            url: '/collections/best-sellers',
            text: 'Best Sellers',
          },
        },
        fields: [
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
        ],
        defaultValue: [
          {
            cta: {
              url: '/collections/best-sellers',
              text: 'Best Sellers',
            },
          },
          {
            cta: {
              url: '/collections/drinkware',
              text: 'Drinkware',
            },
          },
          {
            cta: {
              url: '/collections/coolers',
              text: 'Coolers',
            },
          },
          {
            cta: {
              url: '/collections/giftsets',
              text: 'Gifts',
            },
          },
        ],
      },
      section,
    ],
  };
}
