/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@snippets';

export function CTA({ mobile, desktop, index, url, newTab, clickable, text }) {
  return (
    <>
      <Link
        data-comp={`${CTA.displayName}desktopButton`}
        variant={`buttons.${desktop || 'secondary'}`}
        key={`${index}desktop`}
        href={clickable ? null : url}
        sx={{
          display: ['none', 'block'],
          cursor: 'pointer',
        }}
      >
        {text}
      </Link>
      <Link
        data-comp={`${CTA.displayName}mobileButton`}
        variant={`buttons.${mobile || 'secondary'}`}
        key={`${index}mobile`}
        href={clickable ? null : url}
        sx={{
          display: ['block', 'none'],
          cursor: 'pointer',
        }}
      >
        {text}
      </Link>
    </>
  );
}

CTA.displayName = 'CTA';
