import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = (isLast) => ({
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      columnGap: 6,
      pl: 6,
      pr: 10,
      py: 10,
      cursor: 'pointer',
      borderBottomWidth: '1px',
      borderBottomColor: 'gray',
      borderBottomStyle: isLast ? 'none' : 'solid',
      alignItems: 'center',
    });

    this.picture = {
      width: '84px',
      height: '84px',
    };

    this.details = {
      variant: 'flex.column',
      ml: 8,
    };

    this.title = {
      variant: 'text.sm',
      fontWeight: 500,
      pt: 3,
    };

    this.variant = {
      fontSize: '13px',
      color: 'mediumDarkGray',
      minHeight: '22px',
    };

    this.type = {
      variant: 'text.sm',
      color: 'grey',
    };

    this.newPrice = {
      variant: 'text.base',
      color: 'red',
    };

    this.compareAtPrice = {
      variant: 'text.base',
      textDecoration: 'line-through',
      color: 'mediumDarkGray',
    };

    this.price = {
      variant: 'text.base',
    };

    this.loader = {
      height: '22px',
      svg: {
        height: '100%',
        color: 'gray',
      },
    };
  })()
);
