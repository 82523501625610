import { useMemo } from 'react';
import { Paragraph } from 'theme-ui';
import { useProductByHandle, useRouter } from '@backpackjs/storefront';

import { Link, Picture } from '@snippets';

import { themed } from './SizeSidebar.theme';

export const SizeSidebarItem = themed(
  ({ item, toggleFiltersSidebar, theme }) => {
    const router = useRouter();
    const collectionHandle = router.query?.handle;
    const productHandle = item.mobileImage?.product?.handle;
    const manualSrc = item.mobileImage?.image?.src;

    const { product } = useProductByHandle({
      handle: productHandle,
    });

    const firstImage = useMemo(() => {
      return product?.media?.find((item) => {
        return item.mediaContentType === 'IMAGE';
      })?.image;
    }, [product?.id]);

    const isActive = useMemo(
      () => item.link?.url?.split('/').pop() === collectionHandle,
      [item.link?.url, collectionHandle]
    );

    return (
      <Link
        data-comp={SizeSidebarItem.displayName}
        href={item.link?.url}
        newTab={item.link?.newTab}
        sx={theme.item(isActive).link}
        onClick={toggleFiltersSidebar}
      >
        <Picture
          alt={item.link?.text}
          images={[
            {
              src: firstImage?.originalSrc,
              width: 40,
              ratio: 1,
            },
          ]}
          sx={theme.item(isActive).picture}
          imageSx={{
            objectFit: manualSrc ? 'cover' : 'contain',
          }}
        />

        <Paragraph sx={theme.item(isActive).label}>{item.link?.text}</Paragraph>
      </Link>
    );
  }
);

SizeSidebarItem.displayName = 'SizeSidebarItem';
