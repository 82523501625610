import { useCustomer } from '@backpackjs/storefront';
import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './LoyaltyLionHowItWorks.schema';

import { Content } from './Content';
import { Image } from './Image';

export const LoyaltyLionHowItWorks = withInView(
  ({ cms }) => {
    const customer = useCustomer();
    const { state, image, media, content, section } = cms;
    const mediaLeft = media?.locationDt === 'left'; // mobile
    const mediaTop = media?.location === 'top'; // desktop

    const enable =
      state === 'enable' ||
      (state === 'guest' && !customer?.id) ||
      (state === 'user' && customer?.id);

    if (!enable) return null;

    return (
      <Section data-comp={LoyaltyLionHowItWorks.displayName} section={section}>
        <div
          className={`flex items-center ${
            mediaTop ? 'flex-col' : 'flex-col-reverse'
          } ${mediaLeft ? 'md:flex-row' : 'md:flex-row-reverse'}`}
        >
          <div className="w-full md:w-1/2">
            <Image image={image} media={media} />
          </div>
          <div className="w-full md:w-1/2">
            <Content content={content} customer={customer} />
          </div>
        </div>
      </Section>
    );
  },
  { triggerOnce: true }
);

LoyaltyLionHowItWorks.displayName = 'LoyaltyLion Welcome';
LoyaltyLionHowItWorks.Schema = Schema;
