import { create } from '@theme/create';

export const themed = create(
  new (function () {
    
    this.heading = {
      variant: 'text.h2',
      fontSize: [10, 12],
    };

    this.paragraph = {
      fontSize: ['13px', 6],
      lineHeight: ['normal', '24px'],
      fontWeight: 'body',
    };

    this.bodyWithoutHeading = {
      variant: 'text.base',
      fontSize: ['13px', 6], // [13px, 18px]
      lineHeight: ['normal', '24px'],
      mt: 0,
      py: 10,
      ul: {
        ml: 0,
      },
      'p:last-of-type': {
        mb: 0,
      },
    };

    this.bodyWithHeading = {
      ...this.bodyWithoutHeading,
    };
  })()
);
