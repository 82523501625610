import { Picture } from '@snippets';

import { themed } from './CollectionHero.theme';

export const Image = themed(({ theme, image }) => {
  const mobileImg = image?.image?.src;
  const desktopImg = image?.imageDt?.src;
  return mobileImg || desktopImg ? (
    <Picture
      data-comp={Image.displayName}
      alt={image?.alt}
      aboveTheFold
      images={[
        {
          src: mobileImg,
          width: 768,
          ratio: image?.image?.ratio || 1.2,
        },
        {
          src: desktopImg,
          width: 1024,
          ratio: image?.imageDt?.ratio || 1.2,
        },
        {
          src: desktopImg,
          width: 1280,
          ratio: image?.imageDt?.ratio || 1.2,
        },
        {
          src: desktopImg,
          width: 1440,
          ratio: image?.imageDt?.ratio || 1.2,
        },
      ]}
      sx={theme.image}
      imageSx={{
        objectPosition: [image?.position, image?.positionDt],
      }}
    />
  ) : null;
});

Image.displayName = 'Image';
