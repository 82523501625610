import { Box } from 'theme-ui';

import { SubNavMenuItem } from './SubNavMenuItem';
import { SubNavLinkItem } from './SubNavLinkItem';
import { useMenuSidebar } from '../useMenuSidebar';
import { themed } from './SubNav.theme';

export const SubNav = themed(({ theme, items }) => {
  const [, { closeMenuSidebar, toggleNestedSidebar }] = useMenuSidebar();

  // anim
  return (
    <Box
      data-comp={SubNav.displayName}
      as="nav"
      role="navigation"
      sx={theme.nav}
    >
      <Box as="ul" sx={theme.list}>
        {items?.map((item, index) => (
          <Box
            data-comp={`${SubNav.displayName}Item`}
            key={index}
            as="li"
            sx={theme.item}
          >
            {item.secondaryMenu?.length ? (
              <SubNavMenuItem
                item={item}
                index={index}
                toggleNestedSidebar={toggleNestedSidebar}
              />
            ) : (
              <SubNavLinkItem item={item} closeMenuSidebar={closeMenuSidebar} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
});

SubNav.displayName = 'SubNav';
