import { color, section, button, align } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Featured Two Tile',
    key: 'featuredTwoTile',
    fields: [
      {
        name: 'tiles',
        label: 'Tiles',
        description:
          'Image, heading, description, link, settings. Note: Max of 2 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'For the adventurer',
          description: 'Nulla vitae elit libero, a pharetra augue.',
          alt: 'A woman holding a cansulator.',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FeatCollections1.png',
          },
          cta: {
            text: 'for the adventurer',
            url: '/collections/all',
          },
        },
        validate: {
          maxItems: 2
        },
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
          },
          {
            component: 'select',
            name: 'color',
            label: 'Text color',
            options: [{ label: 'Default', value: '' }, ...color.options],
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description:
              'Provide a brief description of the image for accessibility compliance',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Optional: Image Alt (mobile)',
            name: 'altMobile',
            component: 'text',
            description:
              'If using custom mobile image that is different from the desktop version, provide a brief description of the image for accessibility compliance',
          },
          {
            label: 'Optional: Image (mobile)',
            name: 'imageMobile',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            heading: 'For the adventurer',
            description: 'Nulla vitae elit libero, a pharetra augue.',
            cta: {
              text: 'for the adventurer',
              url: '/collections/all',
            },
            alt: 'A woman holding a cansulator.',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FeatCollections1.png',
            },
          },
          {
            heading: 'For the coffee lover',
            description: 'Nulla vitae elit libero, a pharetra augue.',
            alt: 'Two women enjoying their beverages in the winter.',
            color: 'Nulla vitae elit libero, a pharetra augue.',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1114/2308/files/FeatCollections2.png',
            },
            cta: {
              text: 'For the coffee lover',
              url: '/collections/all',
            },
          },
        ],
      },
      {
        label: 'Tile Settings',
        name: 'tile',
        description: 'Image ratio, text position, text align, link style',
        component: 'group',
        fields: [
          {
            name: 'textPosition',
            label: 'Text position',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Contained', value: 'absolute' },
              { label: 'Below', value: 'relative' },
            ],
          },
          {
            name: 'textAlign',
            label: 'Text alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            name: 'imageRatio',
            label: 'Image aspect ratio (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: '3:4', value: 'portrait' },
              { label: '1:1', value: 'square' },
            ],
          },
          {
            name: 'imageRatioDt',
            label: 'Image aspect ratio (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: '3:4', value: 'portrait' },
              { label: '1:3', value: 'wide' },
            ],
          },
          {
            name: 'overlay',
            label: 'Image overlay',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'None', value: 'none' },
              { label: 'Dark', value: 'dark' },
            ],
          },
          {
            name: 'ctaStyle',
            label: 'Link style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          textPosition: 'relative',
          textAlign: align.value.left,
          imageRatio: 'wide',
          imageRatioDt: 'wide',
          overlay: 'none',
          ctaStyle: button.value.secondary,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'content',
            mt: 's',
            py: 'none',
          },
          desktop: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
