import { Flex, Button, Box } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Header.theme';

export const Header = themed(
  ({
    theme,
    isOpen,
    iconColor,
    minHeight,
    index,
    inputRef,
    children,
    ...props
  }) => {
    return (
      <Button
        data-comp={Header.displayName}
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${children}`}
        variant="buttons.plain"
        {...props}
        sx={{
          ...theme.header,
          minHeight,
          lineHeight: minHeight,
          ...props.sx,
        }}
      >
        <Flex sx={theme.flex}>
          <Box sx={theme.text}>{children}</Box>
          <Svg
            src="/svgs/plus.svg"
            alt="plus"
            viewBox="0 0 16 16"
            sx={{
              ...theme.icon,
              color: iconColor,
              display: isOpen ? 'none' : 'block',
            }}
          />
          <Svg
            src="/svgs/minus.svg"
            alt="plus"
            viewBox="0 0 16 16"
            sx={{
              ...theme.icon,
              color: iconColor,
              display: isOpen ? 'block' : 'none',
            }}
          />
        </Flex>
      </Button>
    );
  }
);

Header.displayName = 'Header';
