import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.notFound = {
      variant: 'flex.column',
      pb: 0,
    };

    this.found = {
      ...this.notFound,
    };

    (this.products = {
      variant: 'flex.column',
    }),
      (this.collections = {
        variant: 'flex.column',
        py: 8,
      });

    this.list = {
      variant: 'flex.column',
    };

    this.heading = {
      variant: 'text.searchHeading',
    };
  })()
);
