import { Container, Paragraph, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Svg } from '@snippets';

import { themed } from './Benefits.theme';

export const Benefits = themed(({ theme }) => {
  const settings = useSettings();
  const benefits = settings?.footer?.benefits;

  return benefits?.items?.length ? (
    <Container
      data-comp={Benefits.displayName}
      sx={{
        ...theme.wrapper,
        bg: benefits?.bgColor || 'gray',
        color: benefits?.color || 'text',
      }}
    >
      <Container sx={theme.grid}>
        {benefits?.items?.map((item, index) => (
          <Flex key={index} sx={theme.item}>
            <Svg
              src={ICONS[item.icon]?.src}
              alt={item.label}
              viewBox={ICONS[item.icon]?.viewBox}
              sx={theme.item.icon}
            />
            <Paragraph sx={theme.item.label}>{item.label}</Paragraph>
          </Flex>
        ))}
      </Container>
    </Container>
  ) : null;
});

Benefits.displayName = 'FooterIcons';

const ICONS = {
  design: {
    src: '/svgs/noprecache/icons/innovative-design.svg#innovative-design',
    viewBox: '0 0 61.73 91.48',
  },
  warranty: {
    src: '/svgs/noprecache/icons/lifetime-warranty.svg#lifetime-warranty',
    viewBox: '0 0 82.72 95.32',
  },
  service: {
    src: '/svgs/noprecache/icons/customer-service.svg#customer-service',
    viewBox: '0 0 88.81 85.71',
  },
  shipping: {
    src: '/svgs/noprecache/icons/free-shipping.svg#free-shipping',
    viewBox: '0 0 85.48 95.87',
  },
};
