import { Container } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './FeaturedTwoTile.schema';
import { GridDisplay } from './GridDisplay';

export const FeaturedTwoTile = withInView(
  ({ cms }) => {
    const { section, tiles, tile } = cms;
    return (
      <Section section={section}>
        <Container data-comp={FeaturedTwoTile.displayName}>
          <GridDisplay tiles={tiles} tile={tile} />
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

FeaturedTwoTile.displayName = 'FeaturedTwoTile';
FeaturedTwoTile.Schema = Schema;
