import { useEffect, useRef } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import store, { useRecoilState } from '@store';

import { Link, Svg } from '@snippets';

import { themed } from '../Menu.theme';

export const CtaAccount = themed(({ theme, inputRef, ...props }) => {
  const [loyaltylionLoaded] = useRecoilState(store.loyaltylionLoaded);
  const customer = useCustomer();
  const llPointsRef = useRef(null);

  useEffect(() => {
    if (
      customer &&
      typeof window.loyaltylion !== 'undefined' &&
      typeof window.loyaltylion.ui !== 'undefined' &&
      llPointsRef?.current &&
      loyaltylionLoaded
    ) {
      window.loyaltylion.ui.refresh();
    }
  }, [customer, llPointsRef, loyaltylionLoaded]);

  return (
    <div className="max-lg:!hidden !flex items-center gap-3">
      <Link
        data-comp={CtaAccount.displayName}
        {...props}
        aria-label={customer ? "View account" : "Login"}
        href={customer ? '/account/orders' : '/account/login'}
        sx={{
          ...props.sx,
          ...theme.cta,
        }}      >
        <Svg
          alt={customer ? "View account" : "Login"}
          src="/svgs/cta/account.svg#account"
          viewBox="0 0 48 48"
          sx={theme.cta.icon}
        />
      </Link>
      {customer && (
        <Link
          href="/pages/rewards"
          sx={theme.link}
          aria-label="Rewards page"
          className="max-md:hidden items-center"
        >
          <div className="text-[13px] font-medium leading-[15px]">
            Hi {customer?.firstName},<br />
            <span data-lion-points ref={llPointsRef} /> Points
          </div>
        </Link>
      )}
    </div>
  );
});

CtaAccount.displayName = 'CtaAccount';
