import { create } from '@theme/create';
import { text } from '@theme/theme.text';

export const themed = create(
  new (function () {
    this.header = {
      display: 'flex',
      width: '100%',
      flexDirection: ['column', null, null, 'row'],
      marginBottom: 14,
      '@media only screen and (max-width: 767px)': {
        marginBottom: 0,
      },
    };

    this.heading = {
      width: '100%',
      variant: 'text.h2',
      mb: [10, null, null, 0],
      flex: 1,
      px: 0,
      fontSize: '40px',
      '@media only screen and (max-width: 767px)': {
        fontSize: '28px',
        px: 8,
      },
    };
  })()
);
